import classNames from "classnames"
import { differenceInDays, parse } from "date-fns"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Loader from "src/components/Loader"

import { inchesToFeet, inchesToSquareFeet } from "src/utils/UnitConversion"

import AvailabilityCalendar from "./AvailabilityCalendar"
import AvailabilityDonut from "./AvailabilityDonut"

const AvailabilityBySpaceGroup = (props) => {
  const [expandedRows, setExpandedRows] = useState([])

  const toggleDetails = (rowId) => {
    // eslint-disable-next-line no-undef
    $(".popover").remove()
    const isRowCurrentlyExpanded = expandedRows.includes(rowId)
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter((id) => id !== rowId)
      : expandedRows.concat(rowId)
    setExpandedRows(newExpandedRows)
  }

  const splitToMonths = (nightsData) =>
    _.groupBy(arrayOfNightsData(nightsData), "month")

  const arrayOfNightsData = (nightsData) =>
    _.values(
      _.map(nightsData, (value, key) => {
        value.night = key
        return value
      })
    )

  const sortDataByDisplayOrder = () => {
    const array = _.map(props.data.occupancies, (value, key) => {
      value.id = key
      return value
    })
    return _.orderBy(array, ["display_order"], ["asc"])
  }

  const roundToViewablePercentage = (percent) => {
    if (percent > 0 && percent < 1) {
      return 1
    } else {
      return percent.toFixed(0)
    }
  }

  const occupancy = (spaceGroupData, night) => {
    if (
      ["SpaceGroup::Linear", "SpaceGroup::LinearV2"].includes(
        spaceGroupData.type
      )
    ) {
      return night.loa
    } else if (spaceGroupData.type === "SpaceGroup::SquareFootage") {
      return night.square_inches
    } else {
      return night.count
    }
  }

  const warningPercent = (spaceGroupData) => {
    const total = spaceGroupData.inventory_total
    let numOfWarningNights = 0
    _.each(spaceGroupData.nights, (night) => {
      const calculatedOccupancy = occupancy(spaceGroupData, night)
      if (
        (calculatedOccupancy < total && calculatedOccupancy >= total * 0.9) ||
        total - calculatedOccupancy === 1
      ) {
        numOfWarningNights += 1
      }
    })
    const percent = (numOfWarningNights / _.size(spaceGroupData.nights)) * 100
    return roundToViewablePercentage(percent)
  }

  const dangerPercent = (spaceGroupData) => {
    const total = spaceGroupData.inventory_total
    let numOfDangerNights = 0
    _.each(spaceGroupData.nights, (night) => {
      const calculatedOccupancy = occupancy(spaceGroupData, night)
      if (calculatedOccupancy >= total) {
        numOfDangerNights += 1
      }
    })
    const percent = (numOfDangerNights / _.size(spaceGroupData.nights)) * 100
    return roundToViewablePercentage(percent)
  }

  const titleClass = () => {
    return "text-gray-600 font-semibold font-size-10 mb-2"
  }

  const rowClass = () => {
    return "flex items-center"
  }

  const headerSpacing = () => {
    if (isShortTimeFrame()) {
      return "minmax(50px, 70px) 1fr 4fr"
    } else if (props.compact) {
      return "minmax(50px, 70px) 1fr 1fr 1fr 1fr"
    } else {
      return "minmax(50px, 70px) 2fr 1fr 1fr 1fr 1fr 1fr"
    }
  }

  const renderExpandButton = (isExpanded) => {
    return (
      <button type="button" className="btn btn-ghost group-hover:bg-blue-50">
        <span className="text-transparent group-hover:text-blue-700">{`${
          isExpanded ? "Hide" : "Show"
        } Calendar`}</span>
        <i
          className={classNames(
            "icon icon-chevron text-xl transition duration-100",
            {
              "icon-rotate-90": isExpanded,
            }
          )}
        />
      </button>
    )
  }

  const renderExpandIcon = (isExpanded) => (
    <i
      className={classNames(
        "icon icon-chevron text-xl transition duration-100",
        {
          "icon-rotate-90": isExpanded,
        }
      )}
    />
  )

  const renderTitledItem = (title, value) => (
    <div className={`px-1 ${rowClass()}`}>
      <span className="inline-block">
        <div className={titleClass()}>{title.toUpperCase()}</div>
        {value}
      </span>
    </div>
  )

  const inchesToFeetString = (value) => `${inchesToFeet(value)}'`

  const squareInchesToSquareFeetString = (value) =>
    `${inchesToSquareFeet(value)}sqft`

  const dimensionDisplayRange = (min, max) => {
    if (max === 1200) {
      return `${inchesToFeetString(min)}+`
    } else {
      return `${inchesToFeetString(min)} - ${inchesToFeetString(max)}`
    }
  }

  const shouldDisplayDimension = (min, max) => min !== 0 || max !== 12000

  const isShortTimeFrame = () => {
    const nights = Object.keys(Object.values(props.data.occupancies)[0].nights)
    const start = parse(nights[0], "yyyy-MM-dd", new Date())
    const end = parse(nights[nights.length - 1], "yyyy-MM-dd", new Date())
    return differenceInDays(end, start) <= 14
  }

  const popoverData = (data, totalString) => {
    return `
      <div class="space-y-5">
        <div class="row form-row">
          <div class="col-xs-6">
            <div class="${titleClass()}">TOTAL</div>
            ${totalString}
          </div>
          <div class="col-xs-6">
            <div class="${titleClass()}">LOA</div>
            ${dimensionDisplayRange(data.min_length, data.max_length)}
          </div>
        </div>
        <div class="row form-row">
          <div class="col-xs-6">
            <div class="${titleClass()}">DRAFT</div>
            ${dimensionDisplayRange(data.min_draft, data.max_draft)}
          </div>
          <div class="col-xs-6">
            <div class="${titleClass()}">BEAM</div>
            ${dimensionDisplayRange(data.min_beam, data.max_beam)}
          </div>
        </div>
      </div>
    `
  }

  const renderRow = (data) => {
    const spaceGroupID = data.id
    const onRowClick = isShortTimeFrame()
      ? () => {}
      : () => toggleDetails(spaceGroupID)
    const isExpanded = expandedRows.includes(spaceGroupID)
    const isSuggested = props.suggestedGroupID === spaceGroupID
    let totalString = data.inventory_total
    let kindString = data.dock_type || "All"
    if (["SpaceGroup::Linear", "SpaceGroup::LinearV2"].includes(data.type)) {
      totalString = inchesToFeetString(data.inventory_total)
      kindString = "Linear dock"
    } else if (data.type === "SpaceGroup::SquareFootage") {
      totalString = squareInchesToSquareFeetString(data.inventory_total)
    }
    const itemRows = []

    if (isShortTimeFrame()) {
      itemRows.push(
        <AvailabilityCalendar
          key={spaceGroupID}
          nights={arrayOfNightsData(data.nights)}
          type={data.type}
          total={data.inventory_total}
          isShortTimeFrame={true}
        />
      )
    } else if (props.compact) {
      itemRows.push(
        renderTitledItem(
          "loa",
          dimensionDisplayRange(data.min_length, data.max_length)
        ),
        <div />,
        <div className={rowClass()}>{renderExpandButton(isExpanded)}</div>
      )
    } else {
      itemRows.push(
        renderTitledItem(
          "loa",
          dimensionDisplayRange(data.min_length, data.max_length)
        ),
        shouldDisplayDimension(data.min_draft, data.max_draft) ? (
          renderTitledItem(
            "draft",
            dimensionDisplayRange(data.min_draft, data.max_draft)
          )
        ) : (
          <div />
        ),
        shouldDisplayDimension(data.min_beam, data.max_beam) ? (
          renderTitledItem(
            "beam",
            dimensionDisplayRange(data.min_beam, data.max_beam)
          )
        ) : (
          <div />
        ),
        <div />,
        <div className={rowClass()}>{renderExpandButton(isExpanded)}</div>
      )
    }

    if (isExpanded) {
      itemRows.push(
        <div className={rowClass()} />,
        <div className="col-span-6 col-start-2 flex flex-row flex-wrap border-t py-2">
          {_.map(splitToMonths(data.nights), (nights) => {
            return (
              <AvailabilityCalendar
                key={nights.month}
                nights={nights}
                type={data.type}
                total={data.inventory_total}
                isShortTimeFrame={false}
              />
            )
          })}
        </div>
      )
    }
    return (
      <div key={spaceGroupID}>
        <div
          className={`${isSuggested ? "border-soft-green rounded border" : ""}`}
        >
          {isSuggested && (
            <div
              className="badge badge-soft-green center"
              style={{ marginTop: -10, marginBottom: -8 }}
            >
              Recommended
            </div>
          )}
          <div
            className="group grid cursor-pointer"
            onClick={onRowClick}
            style={{ gridTemplateColumns: headerSpacing() }}
            data-content={popoverData(data, totalString)}
            data-container="body"
            data-toggle="popover"
            data-placement="auto left"
            data-animation="false"
            data-html="true"
          >
            <div className={`px-2 py-4 ${rowClass()}`}>
              <AvailabilityDonut
                warningPercent={warningPercent(data)}
                dangerPercent={dangerPercent(data)}
              />
            </div>
            {renderTitledItem(kindString, data.name)}
            {itemRows}
          </div>
        </div>
      </div>
    )
  }

  const issueRow = (data, rowID, title, link, expandedHtml) => {
    const count = data.length
    const totalLoa = _.sumBy(data, (overage) => overage.loa)
    const onRowClick = () => toggleDetails(rowID)
    const isExpanded = expandedRows.includes(rowID)

    return (
      <div>
        <div
          className="grid cursor-pointer"
          onClick={onRowClick}
          style={{
            gridTemplateColumns:
              "minmax(50px, 70px) 2fr 1fr 1fr 1fr 1fr minmax(20px, 40px)",
            minHeight: "70px",
          }}
        >
          <div className={`relative px-2 py-4 ${rowClass()}`}>
            <svg
              width="40px"
              height="40px"
              viewBox="0 0 42 42"
              className="donut"
            >
              <circle
                className="donut-hole"
                cx="50%"
                cy="50%"
                r="15.91549430918954"
                fill="#fff"
              />
              <circle
                className="donut-segment stroke-gray-400"
                cx="50%"
                cy="50%"
                r="15.91549430918954"
                fill="transparent"
                style={{
                  strokeWidth: 7,
                }}
              />
              <div
                className="font-semibold text-gray-400"
                style={{
                  position: "absolute",
                  left: 23,
                  lineHeight: 12,
                  fontSize: 20,
                }}
              >
                !
              </div>
            </svg>
          </div>
          <div className={rowClass()}>
            <span className="inline-block">
              <div className={titleClass()}>POTENTIAL ISSUE</div>
              <span>{title}</span>
            </span>
          </div>
          {renderTitledItem("Total boats", count)}
          {renderTitledItem("Total loa", inchesToFeetString(totalLoa))}
          <div className={rowClass()} style={{ gridColumn: "5 / 7" }}>
            {link}
          </div>
          <div className={rowClass()} style={{ gridColumn: "7 / -1" }}>
            <div className={rowClass()}>{renderExpandIcon(isExpanded)}</div>
          </div>
          {isExpanded && expandedHtml}
        </div>
      </div>
    )
  }

  const overflowExpandedText = () => {
    return [
      <div key="overflow-section-1" className={rowClass()} />,
      <div
        key="overflow-section-2"
        className="border-t py-4"
        style={{ gridColumn: "2 / -1" }}
      >
        <div className="font-semibold">What does this mean?</div>
        <div>
          The requested space type and dimensions of these boats do not fit in
          any currently configured space groups.
        </div>
        <div className="mt-3 font-semibold">What can you do?</div>
        <div>
          The availability calculations will be more accurate if you assign
          boats you wish to accommodate or adjust your space group settings.
        </div>
      </div>,
    ]
  }

  const overflowLink = () => {
    const ids = _.map(
      props.data.overages,
      (overage) => overage.reservation_encoded_id
    ).join("+")
    return (
      <a
        className="inline-block font-semibold"
        target="_blank"
        rel="noopener noreferrer"
        href={`/manage/${props.marinaSlug}/reservations?search=${ids}`}
      >
        View and assign reservations →
      </a>
    )
  }

  const spaceGrouplessExpandedText = () => {
    return [
      <div key="space-group-less-section-1" className={rowClass()} />,
      <div
        key="space-group-less-section-2"
        className="border-t py-4"
        style={{ gridColumn: "2 / -1" }}
      >
        <div className="font-semibold">What does this mean?</div>
        <div>
          There are some reservations that are assigned to spaces that do not
          have space groups. This becomes an issue when trying to view
          availability by space group.
        </div>
        <div className="mt-3 font-semibold">What can you do?</div>
        <div>
          The availability calculations will be more accurate if you adjust your
          space settings so they are associated to a space group.
        </div>
      </div>,
    ]
  }

  const spaceGrouplessLink = () => {
    return (
      <a
        className="inline-block font-semibold"
        target="_blank"
        rel="noopener noreferrer"
        href={`/manage/${props.marinaSlug}/settings#spaces`}
      >
        Edit space settings →
      </a>
    )
  }

  const renderSpaceGroups = () => {
    if (!props.data) {
      return null
    }
    if (props.data.has_space_groups) {
      return (
        <div
          className="divide-y p-2 transition duration-100"
          style={{ opacity: props.loading ? 0.25 : 1 }}
        >
          {_.map(sortDataByDisplayOrder(), (occupancies) =>
            renderRow(occupancies)
          )}
          {!props.compact &&
            props.data.overages &&
            props.data.overages.length > 0 &&
            issueRow(
              props.data.overages,
              "overage",
              "Boats that don't fit",
              overflowLink(),
              overflowExpandedText()
            )}
          {!props.compact &&
            props.data.space_groupless &&
            props.data.space_groupless.length > 0 &&
            issueRow(
              props.data.space_groupless,
              "spaceGroupless",
              "Spaces with no Space Group",
              spaceGrouplessLink(),
              spaceGrouplessExpandedText()
            )}
        </div>
      )
    } else {
      return (
        <div className="p-2">
          <span className="mr-2">
            {" "}
            Please set up space groups to gain more insight into your
            marina&apos;s availability.
          </span>
          <a
            className="inline-block font-semibold"
            target="_blank"
            rel="noopener noreferrer"
            href={`/manage/${props.marinaSlug}/settings/space_groups`}
          >
            Set up space groups →
          </a>
        </div>
      )
    }
  }

  const renderLoadingView = () => {
    if (props.loading && !props.data) {
      return (
        <div className="flex justify-center">
          <Loader name="Availability" />
        </div>
      )
    }
    return null
  }

  return (
    <div
      data-testid="trw-availability-by-space-group"
      className="row form-row pt-1"
    >
      {renderLoadingView()}
      {renderSpaceGroups()}
    </div>
  )
}

AvailabilityBySpaceGroup.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    occupancies: PropTypes.object,
    overages: PropTypes.arrayOf(
      PropTypes.shape({
        reservation_encoded_id: PropTypes.string,
        loa: PropTypes.number,
        square_inches: PropTypes.number,
      })
    ),
    has_space_groups: PropTypes.bool,
    space_groupless: PropTypes.arrayOf(
      PropTypes.shape({
        reservation_encoded_id: PropTypes.string,
        loa: PropTypes.number,
        square_inches: PropTypes.number,
        id: PropTypes.string,
      })
    ),
  }),
  compact: PropTypes.bool,
  suggestedGroupID: PropTypes.string,
  marinaSlug: PropTypes.string.isRequired,
}
export default AvailabilityBySpaceGroup
