/* eslint react-hooks/rules-of-hooks: 0 */
/* the linter is not a fan of conditional hooks and for good reason, however we will not be flipping the feature
 * flag on and off during usage, more likely one time per marina, and most likely all at once. afterwards we
 * will remove the condition and should remove this disabled rule. adding boater_tmp_fuel_discounts so this is found */
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { createContext, useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { Outlet } from "react-router-dom"
import useCart from "src/main/PointOfSale/hooks/useCart"
import { useSelectedCardReader } from "src/main/PointOfSale/hooks/useSelectedCardReader"
import useServerCart from "src/main/PointOfSale/hooks/useServerCart"
import { formatItemForCart } from "src/main/PointOfSale/utils"

import usePOSAccess, {
  marinaAccessProps,
} from "src/hooks/module_gate_hooks/use_pos_access"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const POSContext = createContext()

export default function Layout({
  layouts,
  cardReaders,
  receiptPrinters,
  canManagePOS,
  marinaUsers,
  tipItem,
  marinaAccess,
  currentUserEncodedId,
  serverCartFeatureEnabled,
}) {
  const marinaSlug = getCurrentMarinaSlug()
  const { access } = usePOSAccess({ marinaSlug, initialData: marinaAccess })

  const [selectedCardReader, setSelectedCardReader] = useSelectedCardReader()
  const { isLargeScreen } = useWindowSize()

  const cartController = serverCartFeatureEnabled
    ? useServerCart(marinaUsers)
    : useCart(marinaUsers)

  const {
    methods,
    soldByUser,
    setSoldByUser,
    contact,
    setContact,
    boat,
    setBoat,
  } = cartController

  useEffect(() => {
    if (cardReaders.length > 0) {
      setSelectedCardReader(
        cardReaders.find((cr) => cr.id === selectedCardReader?.id) ||
          cardReaders[0]
      )
    } else {
      setSelectedCardReader({})
    }
  }, [cardReaders, selectedCardReader?.id, setSelectedCardReader])

  return (
    <POSContext.Provider
      value={{
        layouts,
        cartController,
        receiptPrinters,
        canManagePOS,
        marinaUsers,
        cardReaders,
        currentUserEncodedId,
        tipItem: formatItemForCart(tipItem),
        marinaAccess: access,
        serverCartFeatureEnabled,
        soldByUser,
        setSoldByUser,
        contact,
        setContact,
        boat,
        setBoat,
      }}
    >
      <FormProvider {...methods}>
        <div
          role="main"
          data-testid="pos-layout"
          className={classNames({
            "h-[calc(100dvh-65px)] px-2": isLargeScreen,
            "mt-4 h-[calc(100dvh-160px)]": !isLargeScreen,
          })}
        >
          <Outlet />
        </div>
      </FormProvider>
    </POSContext.Provider>
  )
}

Layout.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  cardReaders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  canManagePOS: PropTypes.bool,
  marinaUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  tipItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
  currentUserEncodedId: PropTypes.string.isRequired,
  serverCartFeatureEnabled: PropTypes.bool.isRequired,
}
