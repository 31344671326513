import PropTypes from "prop-types"
import React from "react"

const StepWrapper = ({ children, testId }) => {
  return (
    <div
      data-testid={testId}
      className="flex w-full flex-wrap space-y-4 lg:space-x-4 lg:space-y-0"
    >
      {children}
    </div>
  )
}

StepWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string.isRequired,
}
export default StepWrapper
