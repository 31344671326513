import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation } from "react-query"
import { BoatDetailContext } from "src/main/Account/Boats/BoatDetails"
import HomeportSelectField from "src/main/BoaterProfile/HomeportSelectField/HomeportSelectField"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { updateHomeport } from "src/api/Account/Boats"

import { useToast } from "src/hooks/use_toast"

const HomeportModal = ({ isOpen, boat, setIsOpen }) => {
  const {
    homeportSelectProps,
    mcomId,
    setMcomId,
    setBoatData,
    setHomeportSelectPropsData,
    setHomeportPromptProps,
    setIsHomeportPromptOpen,
  } = useContext(BoatDetailContext)
  const showToast = useToast()

  const handleClose = () => {
    setIsOpen(false)
  }

  const { mutate: markAsNoHomeport, isLoading: markingAsNoHomeport } =
    useMutation(() => updateHomeport({ noHomeport: true }, boat.encodedId), {
      onSuccess: (data) => {
        showToast("Homeport Marina setting successfully updated!", {
          type: "success",
        })
        setHomeportSelectPropsData(data.homeportSelectProps)
        handleClose()
      },
      onError: (error) => {
        showToast(error.message, {
          type: "error",
        })
      },
    })

  const { mutate: updateHomeportMarina, isLoading: updatingHomeportMarina } =
    useMutation(() => updateHomeport({ mcomId }, boat.encodedId), {
      onSuccess: (data) => {
        showToast("Homeport Marina successfully updated", { type: "success" })
        setHomeportSelectPropsData(data.homeportSelectProps)
        setBoatData(data.boat)
        handleClose()
        if (data.homeportPromptProps) {
          setHomeportPromptProps(data.homeportPromptProps)
          setIsHomeportPromptOpen(true)
        }
      },
      onError: () => {
        showToast("An error occurred. Please contact mayday@dockwa.com.", {
          type: "error",
        })
      },
    })

  const submitButtonText = () => {
    if (updatingHomeportMarina) {
      return "Saving"
    } else {
      return "Save"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Assign new homeport" />
      <Modal.Body controlOverflow={false}>
        <HomeportSelectField
          noHomeport={homeportSelectProps.noHomeport}
          homeport={homeportSelectProps.homeport}
          tracking={homeportSelectProps.tracking}
          showNoHomeportButton={false}
          setMcomId={setMcomId}
        />
      </Modal.Body>
      <Modal.Footer>
        <div
          className={classNames("flex", {
            "justify-end": !homeportSelectProps.homeport,
            "justify-between": homeportSelectProps.homeport,
          })}
        >
          {homeportSelectProps.homeport && (
            <Button
              variant="danger"
              onClick={markAsNoHomeport}
              isLoading={markingAsNoHomeport}
              disabled={updatingHomeportMarina}
            >
              Remove
            </Button>
          )}
          <div className="flex space-x-2">
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={updatingHomeportMarina || markingAsNoHomeport}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              isLoading={updatingHomeportMarina}
              disabled={markingAsNoHomeport}
              onClick={updateHomeportMarina}
            >
              {submitButtonText()}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

HomeportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  boat: PropTypes.object.isRequired,
}

export default HomeportModal
