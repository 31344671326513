import React from "react"

import DealTicketWithIcon from "src/components/DockwaPlus/DealTicketWithIcon"

const DockwaPlusFuelDeals = () => (
  <div className="flex flex-col space-y-4">
    <div className="grid grid-cols-9 gap-2">
      <div className="col-span-2 flex items-center md:col-span-1">
        <DealTicketWithIcon />
      </div>
      <div className="col-span-7 flex flex-col md:col-span-8">
        <span className="text-base font-semibold text-supplementary-orange-600">
          Dockwa+ fuel discounts available
        </span>
        <span className="text-base text-gray-600">
          Redeem discount at fuel purchase with membership verification
          <a
            href="#listed-pump-prices"
            className="ml-1 w-min whitespace-nowrap font-semibold text-blue-600"
          >
            view details
          </a>
        </span>
      </div>
    </div>
  </div>
)

export default DockwaPlusFuelDeals
