export const statusBadgeColor = (status) => {
  switch (status) {
    case "Pending":
    case "Created":
    case "Requested":
    case "Unsent":
    case "Undeliverable":
    case "Sent":
    case "Offer Pending":
    case "Processing":
      return "yellow"
    case "Confirmed":
    case "Accepted":
    case "In Progress":
      return "blue"
    case "Checked In":
      return "blue-inverted"
    case "Checked Out":
    case "Expired":
    case "Voided":
    case "Inactive":
    case "Scheduled":
      return "gray"
    case "Completed":
    case "Signed":
    case "Completed In House":
    case "Active":
    case "Settled":
    case "Refunded":
    case "Succeeded":
      return "teal"
    case "Canceled":
    case "Cancelled":
    case "Payment Declined":
    case "Declined":
    case "Boater Declined":
    case "Past Due":
      return "red"
    case "Waitlisted":
    case "Disputed":
    case "Processing Refund":
    case "Due Today":
      return "orange"
    case "Failed":
    case "Unpaid":
      return "error"
    default:
      return "gray"
  }
}
