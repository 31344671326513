import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { createProduct, updateProduct } from "src/api/ManageItems"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import {
  availabilitiesValue,
  defaultRecurrenceValue,
  defaultValuesForProduct,
  defaultValuesForType,
  dollarsToPrecisionForType,
} from "../Form/helpers"

const useItemForm = ({ product, availabilityOptions, productCategories }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const urlParams = new URLSearchParams(window.location.search)
  const effectiveType =
    urlParams.get("type") === "cancellationFee" ? "fee" : "standard"

  const onSubmit = (formData) => {
    const data = {
      product_type: formData.type,
      product_category_id: formData.category,
      name: formData.name,
      sku: formData.sku,
      barcode: formData.barcode,
      space_type: formData.spaceType,
      price_per_unit: dollarsToPrecisionForType({
        type: formData.type,
        dollars: formData.defaultPrice,
      }),
      tax_rate: formData.defaultTax && formData.defaultTax / 100,
      default_recurrence: defaultRecurrenceValue(formData.defaultRecurrence),
      default_pricing_structure: formData.defaultPriceType,
      availabilities: availabilitiesValue(formData.availabilities),
      electric_type: formData.electricType,
      is_inventory: formData.isInventory,
      low_quantity_balance_threshold: formData.lowQuantityBalanceThreshold,
      inventory_product_id: formData.inventoryProductId,
    }

    const mutation = product ? updateMutation : createMutation
    mutation.mutate(data)
  }

  const createMutation = useMutation(
    (data) => createProduct({ marinaSlug, data }),
    {
      onError: ({ message }) => {
        methods.setError("root.error", {
          message,
        })
      },
      onSuccess: () => {
        window.location.assign(`/manage/${marinaSlug}/items`)
      },
    }
  )

  const updateMutation = useMutation(
    (data) => updateProduct({ marinaSlug, productId: product.id, data }),
    {
      onError: ({ message }) => {
        methods.setError("root.error", {
          message,
        })
      },
      onSuccess: () => {
        window.location.assign(`/manage/${marinaSlug}/items`)
      },
    }
  )

  const defaultValues = product
    ? defaultValuesForProduct({ product, availabilityOptions })
    : defaultValuesForType({
        type: effectiveType,
        availabilityOptions,
        productCategories,
      })

  const methods = useForm({ defaultValues, mode: "onBlur" })

  const type = methods.watch("type")
  const electricType = methods.watch("electricType")

  useEffect(() => {
    if (product) return

    // handle resetting default values when type changes
    const defaultValues = defaultValuesForType({
      type,
      electricType,
      availabilityOptions,
      productCategories,
    })
    methods.reset(defaultValues)
  }, [type, electricType, availabilityOptions, methods])

  return {
    formTitle: product ? "Edit item" : "New item",
    isEdit: !!product,
    isLoading: product ? updateMutation.isLoading : createMutation.isLoading,
    isSuccess: product ? updateMutation.isSuccess : createMutation.isSuccess,
    methods,
    onSubmit,
    marinaSlug,
  }
}

export default useItemForm
