import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { cancelInquiry } from "src/api/Account/Inquiries"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const CancelInquiryModal = ({ closeModal, isOpen, inquiryId, tracking }) => {
  const [error, setError] = useState("")
  const showToast = useToast()
  const tracker = useTracker()

  const onClose = () => {
    closeModal()
    setError("")
  }

  const onSuccess = (response) => {
    showToast(response.message, { type: "success" })
    closeModal()
  }

  const onError = () => {
    setError(
      "There was an error canceling your request. Please contact mayday@dockwa.com."
    )
  }

  const { mutate, isLoading, isSuccess } = useMutation(
    (data) => cancelInquiry(data),
    { onSuccess, onError }
  )

  const onSubmit = (data) => {
    tracker.trackEvent(tracking.submit, tracking.eventProperties)
    mutate({
      id: inquiryId,
      cancellationReason: data.cancellationReason,
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header title="Are you sure you want to cancel?" />
        <Modal.Body>
          <div className="flex flex-col space-y-4 pb-4">
            <span>
              Canceling will remove your request from the marina. This action
              cannot be undone.
            </span>
            <div className="flex flex-col">
              <Form.Label htmlFor="cancellationReason" required>
                Cancellation Reason
              </Form.Label>
              <Form.Textarea
                id="cancellationReason"
                {...register("cancellationReason", {
                  required: "Cancellation reason is required",
                })}
                hasErrors={!!errors?.cancellationReason}
              />
              <Form.Error>{errors?.cancellationReason?.message}</Form.Error>
            </div>
            {error && <Form.Error>{error}</Form.Error>}
          </div>
        </Modal.Body>
        <Modal.Footer
          cancelBtnText="Close"
          onClose={onClose}
          confirmBtnText="Cancel"
          confirmBtnVariant="danger"
          confirmBtnType="submit"
          confirmBtnLoading={isLoading || isSuccess}
          disabled={isLoading || isSuccess}
        />
      </Form>
    </Modal>
  )
}

CancelInquiryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  inquiryId: PropTypes.string.isRequired,
  tracking: PropTypes.shape({
    submit: PropTypes.string.isRequired,
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
      inquiry_id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CancelInquiryModal
