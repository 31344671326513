import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { useTracker } from "src/hooks/use_tracker"

import { PRINT_RECEIPT_PRESSED } from "../../amplitude_events"

const PrintButton = ({ disabled, isLoading, onClick, selectedPrinterName }) => {
  const { marinaAccess } = useContext(POSContext)
  const manageHardware = marinaAccess.manageHardware
  const tracker = useTracker()

  const handleClick = () => {
    if (disabled || !manageHardware) return

    tracker.trackEvent(PRINT_RECEIPT_PRESSED)
    onClick()
  }

  return (
    <button
      role="button"
      className={classNames(
        "flex-1 rounded border border-gray-300 bg-white text-center font-semibold no-underline",
        {
          "text-gray-600 hover:border-gray-900 hover:bg-gray-100 hover:text-gray-900":
            !disabled && manageHardware,
          "cursor-not-allowed text-gray-400": disabled || !manageHardware,
        }
      )}
      onClick={handleClick}
      disabled={disabled || !manageHardware}
    >
      <MaybeRestrictedTooltip
        text={
          !manageHardware
            ? "The ability to print receipts is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
            : null
        }
        restricted={!manageHardware}
        allowedRoles={["Admin", "Finance", "Dockmaster", "Viewer"]}
      >
        <div className="py-6">
          <div>
            <i
              role="icon"
              className={classNames("icon text-2xl", {
                "icon-print": !isLoading,
                "icon-spin icon-spinner": isLoading,
              })}
              aria-label="Printer"
            />
          </div>
          <div>Print Receipt</div>
          {selectedPrinterName && manageHardware ? (
            <div className="font-bold">{selectedPrinterName}</div>
          ) : null}
        </div>
      </MaybeRestrictedTooltip>
    </button>
  )
}

PrintButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selectedPrinterName: PropTypes.string,
}

export default PrintButton
