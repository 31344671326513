import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { AUTOPAY_DISABLED, AUTOPAY_ENABLED } from "../../constants"

const AutopaySelector = () => {
  const { register } = useFormContext()

  return (
    <div>
      <Form.Label htmlFor="autopay">Autopay</Form.Label>
      <Form.Select id="autopay" {...register("payment_type")}>
        <option value={AUTOPAY_ENABLED}>Enabled</option>
        <option value={AUTOPAY_DISABLED}>Disabled (settle manually)</option>
      </Form.Select>
    </div>
  )
}

export default AutopaySelector
