import PropTypes from "prop-types"
import React, { useContext } from "react"
import { ChatContext } from "src/main/Chat/ChatContainer"

import Modal from "src/components/Modal"
import Sidebar from "src/components/Sidebar"

import useWindowSize from "src/hooks/use_window_size"

import Item from "./Item"

const ChatSidebar = ({ items }) => {
  const { showSidebar, setShowSidebar } = useContext(ChatContext)
  const { isLargeScreen } = useWindowSize()

  const renderBody = () => (
    <Modal.Body>
      <div className="flex flex-col space-y-3">
        {items.map((item, index) => (
          <Item
            type={item.type}
            key={`sidebar-${item.type}-${index}`}
            {...item}
          />
        ))}
      </div>
    </Modal.Body>
  )

  return (
    <Sidebar onClose={() => setShowSidebar(false)} isOpen={showSidebar}>
      <Modal.Header title="Details" />
      {isLargeScreen && <div className="-mt-2 mb-4 w-full border-b" />}
      {renderBody()}
    </Sidebar>
  )
}

ChatSidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export default ChatSidebar
