import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import { WizardContext } from "../../WizardContextProvider"

const StorageProductSelector = () => {
  const { storageProducts } = useContext(WizardContext)
  const { register } = useFormContext()
  const tooltipText =
    "Easily customize boat storage type and revenue category by selecting from the 'Type' dropdown"

  const renderTooltip = () => {
    return (
      <Tooltip
        text={tooltipText}
        placement="top"
        variant="dark"
        maxWidth="275px"
        textCenter
      >
        <i className="icon icon-info-circle m-1 cursor-pointer text-gray-600" />
      </Tooltip>
    )
  }

  return (
    <div data-testid="trw-storage-product-select">
      <div className="flex flex-row">
        <Form.Label htmlFor="trw-storage-product">Type</Form.Label>
        {renderTooltip()}
      </div>
      <Form.Select id="trw-storage-product" {...register("storage_product_id")}>
        {storageProducts.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </div>
  )
}

export default StorageProductSelector
