import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { useTracker } from "src/hooks/use_tracker"

import { SOLD_BY_USER_SWITCHED } from "../amplitude_events"

const SoldByModal = ({ onClose }) => {
  const tracker = useTracker()
  const { marinaUsers, soldByUser, setSoldByUser } = useContext(POSContext)

  const [selectedSoldByUser, setSelectedSoldByUser] = useState(soldByUser)

  const onChange = (marinaUserId) => {
    setSelectedSoldByUser(marinaUsers.find((mu) => mu.id === marinaUserId))
  }

  const onSave = () => {
    setSoldByUser(selectedSoldByUser)
    tracker.trackEvent(SOLD_BY_USER_SWITCHED)
    onClose()
  }

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header title="Sold by" />
      <Modal.Body>
        <Form.Select.Custom
          formLabel="Select user"
          onChange={onChange}
          value={selectedSoldByUser?.id}
        >
          {marinaUsers.map(({ id, name }) => (
            <Form.Select.RichOption key={id} value={id} hideCheck>
              {name}
            </Form.Select.RichOption>
          ))}
        </Form.Select.Custom>
      </Modal.Body>
      <Modal.Footer onClose={onClose} onSubmit={onSave} />
    </Modal>
  )
}

SoldByModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
export default SoldByModal
