import PropTypes from "prop-types"
import React from "react"

import AlertBanner from "src/components/AlertBanner"
import ReloadableWidget from "src/components/ReloadableWidget"
import Table from "src/components/Table"
import { DangerousDeprecatedOldReactAdapterComponent } from "src/components/__dangerous__"

import { hasDisputedInvoice } from "../helpers"
import PaymentRow from "./PaymentRow"

const PaymentsTable = ({
  payments,
  editPayment,
  settlePayment,
  isLoading,
  isError,
  renderMenuItems,
  editable = true,
}) => {
  const renderPayments = () =>
    payments.length > 0 ? (
      payments.map((payment, idx) => {
        const lastRow = payments.length - 1 === idx
        return (
          <PaymentRow
            key={payment.id}
            payment={payment}
            editPayment={editPayment}
            settlePayment={settlePayment}
            renderMenuItems={renderMenuItems}
            editable={editable}
            menuPosition={lastRow ? "topLeft" : "bottomLeft"}
          />
        )
      })
    ) : (
      <Table.Row>
        <td className="px-4" colSpan="5" align="center">
          No payments match the current filter
        </td>
      </Table.Row>
    )

  const hasUnresolvedDisputes = hasDisputedInvoice(payments)

  return (
    <Table autoColumnWidth>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Cell>Status</Table.Head.Cell>
          <Table.Head.Cell>Method</Table.Head.Cell>
          <Table.Head.Cell>Due Date</Table.Head.Cell>
          <Table.Head.Cell>Settled Date</Table.Head.Cell>
          <Table.Head.Cell>Amount</Table.Head.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body isLoading={isLoading && Boolean(payments)}>
        {payments ? (
          <>
            {renderPayments()}
            {hasUnresolvedDisputes && (
              <DangerousDeprecatedOldReactAdapterComponent
                targetId="signed-contract-dispute-alert-banner"
                weAcknowledgeThisTechniqueIsDangerous
              >
                <div className="my-4">
                  <AlertBanner type="error">
                    Payment(s) are under dispute
                  </AlertBanner>
                </div>
              </DangerousDeprecatedOldReactAdapterComponent>
            )}
          </>
        ) : (
          <Table.Row>
            <td className="px-4" colSpan="2">
              <ReloadableWidget
                isLoading={isLoading}
                isError={isError}
                border={false}
              />
            </td>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

PaymentsTable.propTypes = {
  payments: PropTypes.array,
  editPayment: PropTypes.func.isRequired,
  settlePayment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  updatedIndex: PropTypes.number,
  renderMenuItems: PropTypes.func,
  editable: PropTypes.bool,
}

export default PaymentsTable
