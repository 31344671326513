import PropTypes from "prop-types"
import React, { createContext } from "react"

export const FeatureFlagContext = createContext(null)

const FeatureFlagContextProvider = ({ children, featureFlags }) => {
  return (
    <FeatureFlagContext.Provider
      value={{
        transientEditDates: featureFlags?.transientEditDates ?? false,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

FeatureFlagContextProvider.propTypes = {
  featureFlags: PropTypes.shape({
    transientEditDates: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
}

export default FeatureFlagContextProvider
