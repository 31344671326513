import { formPostApi, mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const queryContracts = async ({ marinaSlug, queryString }) => {
  return queryApi(`/manage/${marinaSlug}/contracts?${queryString}`)
}

export const queryContractQuotesByGroup = async ({ groupId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return queryApi(
    `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2`
  )
}

export const queryContractQuoteStatus = async ({ groupId, quoteId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return queryApi(
    `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${quoteId}/status`
  )
}

export const queryContractTemplates = async ({ marinaSlug, page }) => {
  return queryApi(
    `/manage/${marinaSlug}/contracts/docuseal_templates?page=${page}&status=active`
  )
}

export const queryArchivedContractTemplates = async ({ marinaSlug, page }) => {
  return queryApi(
    `/manage/${marinaSlug}/contracts/docuseal_templates?page=${page}&status=archived`
  )
}

export const createContractTemplate = async ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/contracts/docuseal_templates`,
    method: "POST",
    data,
  })
}

export const updateDocusealTemplate = async ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/contracts/docuseal_templates/${data.id}`,
    method: "PATCH",
    data: data,
  })
}

export const cloneContractTemplate = async ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/contracts/docuseal_templates/${data.id}/clone`,
    method: "POST",
    data: {
      name: data.name,
    },
  })
}

export const queryContractTemplateCloneName = async ({ id }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return queryApi(
    `/manage/${marinaSlug}/contracts/docuseal_templates/${id}/clone_name`
  )
}

export const archiveContractTemplate = async (templateId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contracts/docuseal_templates/${templateId}/archive`,
    method: "POST",
  })
}

export const updateDocusealSubmission = async ({ data, quoteId }) => {
  return mutateApi({
    url: `/contract_quotes/${quoteId}/update_docuseal_submission_status`,
    method: "PATCH",
    data: data,
  })
}

export const restoreContractTemplate = async (templateId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contracts/docuseal_templates/${templateId}/restore`,
    method: "POST",
  })
}

export const archiveContractGroup = async (groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/archive`,
    method: "POST",
  })
}

export const restoreContractGroup = async (groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/restore`,
    method: "PATCH",
  })
}

export const queryContractGroups = ({
  marinaSlug,
  page,
  sortDirection = "DESC",
  sortField = "updated_at",
  status = "active",
}) => {
  return queryApi(
    `/manage/${marinaSlug}/contract_groups_v2/contract_groups?page=${page}&status=${status}&sort_field=${sortField}&sort_direction=${sortDirection}`
  )
}

export const createContractGroup = async (params, duplicateId) => {
  const marinaSlug = getCurrentMarinaSlug()
  let url = `/manage/${marinaSlug}/contract_groups_v2`
  if (duplicateId) {
    url = `/manage/${marinaSlug}/contract_groups_v2/${duplicateId}/clone`
  }
  return mutateApi({ url, method: "POST", data: params })
}

export const sendContractQuote = async ({ quoteId, groupId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${quoteId}/send_contract`,
    method: "POST",
  })
}

export const expireContractQuote = async ({ quoteId, groupId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${quoteId}/expire`,
    method: "POST",
  })
}

export const archiveContractQuote = async ({ quoteId, groupId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${quoteId}/archive`,
    method: "POST",
  })
}

export const retryContractQuote = async ({ quoteId, groupId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${quoteId}/retry`,
    method: "PATCH",
  })
}

export const markContractQuoteDeclined = async ({ quoteId, groupId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${quoteId}/mark_as_declined`,
    method: "PATCH",
  })
}

export const sendAllContractQuotes = async ({ groupId, selectedContracts }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/bulk_send`,
    method: "POST",
    data: { contract_quotes: selectedContracts },
  })
}

export const createContractQuote = async (params, groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2`,
    method: "POST",
    data: params,
  })
}

export const updateContractGroup = async (params, groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}`,
    method: "PATCH",
    data: params,
  })
}

export const updateContractQuote = async (params, groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/${params.id}`,
    method: "PATCH",
    data: params,
  })
}

export const getPricingSummary = async ({ groupId, params }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const formData = new FormData()
  const keys = Object.keys(params)
  keys.forEach((key) => {
    formData.append(key, params[key] ?? "") // formData stringifies null values, should pass in empty string instead
  })
  return formPostApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/${groupId}/contract_quotes_v2/pricing_summary`,
    data: formData,
    headers: {
      "X-CSRF-Token":
        document.querySelector('meta[name="csrf-token"]')?.content ?? "",
    },
  })
}

export const cancelContract = async (contractId, params) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contracts/${contractId}/cancel`,
    method: "PUT",
    data: params,
  })
}
