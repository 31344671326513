import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

const AddMonthlyDiscountModal = ({ onClose, onSubmit }) => {
  const handleSubmitDiscount = () => {
    // const discount = {foo: 'bar'}
    // onSubmit(discount)
    // onClose()
  }

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header title="Add Discount" />
      <Modal.Body>Monthly Discount Modal</Modal.Body>
      <Modal.Footer
        confirmBtnText="Add"
        onClose={onClose}
        onSubmit={handleSubmitDiscount}
      />
    </Modal>
  )
}

AddMonthlyDiscountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AddMonthlyDiscountModal
