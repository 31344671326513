import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  PREFIX,
  SELECTED_SOLD_BY_USER,
} from "src/main/PointOfSale/storage_keys"
import { v4 as uuidv4 } from "uuid"

import {
  addCartItem,
  createCart as createCartApi,
  getCart,
  removeCartItem,
  updateCart as updateCartApi,
  updateCartItem,
} from "src/api/PointOfSale/cart"

import useDebounce from "src/hooks/use_debounce"
import { useLocalStorageState } from "src/hooks/use_local_storage_state"
import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const PanelState = Object.freeze({
  CLOSED: "closed",
  OPEN: "open",
})

const useServerCart = (marinaUsers) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const cartIdQueryParameter = searchParams.get("cart_id")
  const marinaSlug = getCurrentMarinaSlug()
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { isLargeScreen } = useWindowSize()
  const [panelState, setPanelState] = useState({})
  const [cartId, setCartId] = useState(cartIdQueryParameter)
  const addItemMutex = useRef(Promise.resolve())
  const cartInitialized = useRef(false)

  const {
    data: cartData,
    isLoading: cartDataLoading,
    isFetching: cartDataFetching,
  } = useQuery({
    queryKey: ["cart", marinaSlug, cartId],
    queryFn: async () => {
      const response = await getCart({ marinaSlug, cartId })
      return response.data
    },
    initialData: {
      id: cartId,
      note: "",
      contactId: null,
      soldById: null,
      cartItems: [],
      lineItems: [],
      totals: {
        subtotal: null,
        discount: null,
        tax: null,
        total: null,
      },
    },
    enabled: !!cartId,
  })

  const methods = useForm({
    defaultValues: { cart: [], note: "" },
    mode: "all",
    values: { cart: cartData.cartItems, note: cartData.note },
  })

  const { fields } = useFieldArray({
    name: "cart",
    control: methods.control,
    keyName: "formId",
  })

  useEffect(() => {
    if (!cartData || cartDataFetching) return
    if (cartData.status === "expired") {
      navigate("/expired")
    }
  }, [cartData, cartDataFetching, navigate])

  /* Sync cart id to query params */

  useEffect(() => {
    if (cartId) {
      setSearchParams({ cart_id: cartId })
    }
  }, [cartId, setSearchParams])

  useEffect(() => {
    if (!cartData || cartDataFetching) return

    if (cartData.dockwaPlusDiscountAdded) {
      showToast("Dockwa+ member discount added", { type: "success" })
    }
  }, [cartData, cartDataFetching, showToast])

  /* Contact and Boat Management */

  const [contact, setContactState] = useState(null)
  const [boat, setBoatState] = useState(null)

  useEffect(() => {
    if (!cartData || cartDataFetching) return

    const { contact } = cartData

    if (contact) {
      setContactState(contact)
    } else {
      setContactState(null)
    }
  }, [cartData, cartDataFetching])

  /* Sold By User */

  const [defaultSoldByUser, setDefaultSoldByUser] = useLocalStorageState(
    PREFIX,
    SELECTED_SOLD_BY_USER
  )

  const [soldByUser, setSoldByUserState] = useState(null)

  useEffect(() => {
    if (!defaultSoldByUser) {
      setDefaultSoldByUser(marinaUsers[0])
    }
  }, [defaultSoldByUser, marinaUsers, setDefaultSoldByUser])

  useEffect(() => {
    const { id, soldById } = cartData

    if (!id) return
    if (soldByUser) return
    if (!soldById) return

    setSoldByUserState(marinaUsers.find((user) => user.id === soldById))
  }, [cartData, cartDataFetching, marinaUsers, soldByUser])

  const { mutate: syncSoldBy } = useMutation({
    mutationFn: async (soldById) => {
      const { data: updatedCart } = await updateCartApi({
        marinaSlug,
        cart: { soldById },
        cartId: cartData.id,
      })
      return updatedCart
    },
    onMutate: (soldById) => {
      const previousSoldById = soldByUser?.id
      setSoldByUserState(marinaUsers.find((user) => user.id === soldById))

      return { previousSoldById }
    },
    onError: (error, variables, context) => {
      console.error(error)
      setSoldByUserState(
        marinaUsers.find((user) => user.id === context.previousSoldById)
      )
      showToast(`Failed to update sold by user: ${error.message}`, {
        type: "error",
      })
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
  })

  const setSoldByUser = useCallback(
    (user) => {
      syncSoldBy(user.id)
      setDefaultSoldByUser(user)
    },
    [setDefaultSoldByUser, syncSoldBy]
  )

  /* Initialize a cart if one is not provided */

  const { mutate: createCart, isLoading: isCreatingCart } = useMutation({
    mutationFn: async ({ soldById }) => {
      const { data: cart } = await createCartApi({
        marinaSlug,
        soldById,
      })
      return cart
    },
    onMutate: async ({ cartId: previousCartId }) => {
      const previousCart = queryClient.getQueryData([
        "cart",
        marinaSlug,
        previousCartId,
      ])
      queryClient.removeQueries(["cart", marinaSlug])
      return { previousCart }
    },
    onSuccess: async (cart) => {
      setCartId(cart.id)
      setSoldByUserState(marinaUsers.find((user) => user.id === cart.soldById))
      await queryClient.setQueryData(["cart", marinaSlug, cart.id], cart)
      setPanelState({})
    },
    onError: (error, variables, context) => {
      console.error(error)
      queryClient.setQueryData(
        ["cart", marinaSlug, context.previousCart.id],
        context.previousCart
      )
      showToast("Failed to clear cart", { type: "error" })
    },
  })

  useEffect(() => {
    if (!cartId && !cartInitialized.current && defaultSoldByUser) {
      cartInitialized.current = true
      createCart({ cartId, soldById: defaultSoldByUser.id })
    }
  }, [cartId, createCart, defaultSoldByUser])

  /* Update Sale Note */

  const { mutate: updateNote } = useMutation({
    mutationFn: async (updates) => {
      const { data: updatedCart } = await updateCartApi({
        marinaSlug,
        cart: updates,
        cartId: cartData.id,
      })
      return updatedCart
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
  })

  const [debouncedUpdateNote] = useDebounce(updateNote, 1000)

  useEffect(() => {
    if (cartId === null) return

    const subscription = methods.watch((formData, { name }) => {
      if (name === "note") {
        debouncedUpdateNote({ note: formData.note })
      }
    })

    return () => subscription.unsubscribe()
  }, [cartId, debouncedUpdateNote, methods])

  /* Update Item */

  const { mutate: updateItem, isLoading: isUpdatingItem } = useMutation({
    mutationFn: async ({ item }) => {
      const { data } = await updateCartItem({
        marinaSlug,
        cartId: cartData.id,
        itemId: item.id,
        item,
      })
      return data
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
    onError: (error) => {
      showToast(`There was an error updating the item: ${error.message}`, {
        type: "error",
      })
    },
  })

  /* Add Item */

  const { mutateAsync: addItem, isLoading: isAddingItem } = useMutation({
    mutationFn: async ({ item }) => {
      const { data: cart } = await addCartItem({
        marinaSlug,
        cartId,
        item,
      })
      return { cart, item }
    },
    onSuccess: ({ cart, item }) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
      if (!isLargeScreen)
        showToast(`${item.name} Added`, { type: "success", duration: 2 })
    },
  })

  const addItemToCart = ({ item: product }) => {
    const item = {
      id: uuidv4(),
      name: product.name,
      productId: product.id,
      price: product.price_per_unit || 0,
      taxRate: product.tax_rate || 0,
      quantity: product.quantity || 1,
      discount: 0,
      discountAmount: 0,
      pricePrecision: product.price_precision,
    }

    closeAllPanels()
    togglePanelOpen({ id: item.id })

    addItemMutex.current = addItemMutex.current
      .then(async () => {
        return addItem({ item })
      })
      .catch((error) => {
        showToast(`There was an error adding the item: ${error.message}`, {
          type: "error",
        })
      })

    return addItemMutex.current
  }

  /* Remove Item */

  const { mutate: removeItem, isLoading: isRemovingItem } = useMutation({
    mutationFn: async ({ itemId }) => {
      const { data: cart } = await removeCartItem({
        marinaSlug,
        cartId,
        itemId,
      })
      return { cart, itemId }
    },
    onMutate: async ({ itemId }) => {
      await queryClient.cancelQueries(["cart", marinaSlug, cartId])

      const previousCart = queryClient.getQueryData([
        "cart",
        marinaSlug,
        cartId,
      ])

      queryClient.setQueryData(["cart", marinaSlug, cartId], (old) => ({
        ...old,
        cartItems: old.cartItems.filter((item) => item.id !== itemId),
      }))

      return { previousCart }
    },
    onSuccess: async ({ cart }) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
    onError: (err, variables, context) => {
      console.error(err)
      queryClient.setQueryData(
        ["cart", marinaSlug, cartId],
        context.previousCart
      )
      showToast("Failed to remove item from cart", { type: "error" })
    },
  })

  const removeItemFromCart = (itemId) => {
    removeItem({ itemId })
  }

  /* Update Cart */

  const { mutate: updateCart, isLoading: isUpdatingCart } = useMutation({
    mutationFn: async (updates) => {
      const { data: updatedCart } = await updateCartApi({
        marinaSlug,
        cart: updates,
        cartId: cartData.id,
      })
      return updatedCart
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
  })

  /* Clear Cart */

  const clearCart = async () => {
    setContactState(null)
    setBoatState(null)

    await createCart({ cartId, soldById: soldByUser.id })
  }

  const refreshCart = async () => {
    await queryClient.invalidateQueries(["cart", marinaSlug, cartId])
  }

  /* Contact and Boat Management */

  const setContact = useCallback(
    (newContact) => {
      if (!newContact) {
        setContactState(null)
      } else {
        setContactState(newContact)
      }

      if (cartId) {
        updateCart({ contactId: newContact?.id || null })
      }

      setBoatState(null)
    },
    [cartId, updateCart]
  )

  const setBoat = useCallback(
    (newBoat) => {
      if (!newBoat) {
        setBoatState(null)
        setContactState(null)
      } else {
        setBoatState(newBoat)
        setContactState(newBoat.contact)
      }

      if (cartId) {
        updateCart({ contactId: newBoat?.contact?.id || null })
      }
    },
    [cartId, updateCart]
  )

  /* Panel Management */

  const panelIsOpen = ({ id }) => {
    return panelState[id] === PanelState.OPEN
  }
  const closeAllPanels = () => {
    setPanelState((current) =>
      Object.fromEntries(
        Object.keys(current).map((key) => [key, PanelState.CLOSED])
      )
    )
  }
  const togglePanelOpen = ({ id }) => {
    setPanelState((current) => ({
      ...current,
      [id]:
        panelState[id] === PanelState.OPEN
          ? PanelState.CLOSED
          : PanelState.OPEN,
    }))
  }

  const totals = useMemo(() => {
    const { totals } = cartData

    return Object.fromEntries(
      Object.entries(totals).map(([key, value]) => [
        key,
        value === null ? "-.--" : (value / 100).toFixed(2),
      ])
    )
  }, [cartData])

  const priceLoading =
    cartDataLoading ||
    isCreatingCart ||
    isUpdatingCart ||
    isAddingItem ||
    isRemovingItem ||
    isUpdatingItem

  return {
    cartId,
    cartStatus: cartData.status,
    methods,
    fields,
    addItemToCart,
    removeItemFromCart,
    clearCart,
    updateCart,
    refreshCart,
    updateItem,
    panelIsOpen,
    togglePanelOpen,
    closeAllPanels,
    totals,
    serverContact: cartData.contact,
    lineItems: cartData.lineItems,
    loading: priceLoading,
    soldByUser,
    setSoldByUser,
    contact,
    setContact,
    boat,
    setBoat,
  }
}

export default useServerCart
