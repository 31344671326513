import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryInquiries = (currentPage) => {
  return queryApi(`/account/inquiries?page=${currentPage}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const cancelInquiry = ({ id, cancellationReason }) => {
  return mutateApi({
    url: `/account/inquiries/${id}`,
    method: "DELETE",
    data: {
      cancellation_reason: cancellationReason,
    },
  })
}
