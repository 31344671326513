import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useRef, useState } from "react"

import ActiveStorageDirectUpload from "src/utils/form/active_storage_direct_upload"

const FormDirectUploadFileInput = forwardRef(
  (
    {
      accept,
      disabled,
      hasErrors,
      id,
      name,
      onChange,
      onBlur,
      onFocus,
      required,
      uploadUrl,
      className,
      multiple,
      onUploadSuccess,
      onUploadError,
      onFileRemove,
      placeholder,
      size,
      fileName: externalFileName,
    },
    ref
  ) => {
    // Use a local ref if an external one is not provided
    const internalRef = useRef(null)
    const inputRef = ref || internalRef

    const [internalFileName, setInternalFileName] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [uploadedBlobId, setUploadedBlobId] = useState(null)

    // Derive the displayed fileName from either the external prop or internal state
    const fileName = externalFileName || internalFileName

    // Update internal state when external fileName changes
    useEffect(() => {
      if (externalFileName) {
        // We have an external fileName which means we already have an uploaded file
        // No need to change the internal state, the external one takes precedence
      } else if (internalFileName && !externalFileName) {
        // We have an internal filename but no external one, this can happen when removing files
        setInternalFileName("")
      }
    }, [externalFileName])

    const handleFileChange = async (event) => {
      const files = event.target.files
      if (!files.length) return

      const file = files[0]
      setInternalFileName(file.name)

      if (onChange) {
        onChange(event)
      }

      // If onUploadSuccess is provided, handle direct upload
      if (onUploadSuccess) {
        setIsUploading(true)
        try {
          const uploader = new ActiveStorageDirectUpload(file, uploadUrl)
          uploader.uploadFile({
            successCallback: (blob) => {
              setUploadedBlobId(blob.signed_id)
              onUploadSuccess(blob)
              setIsUploading(false)
            },
            errorCallback: (error) => {
              if (onUploadError) {
                onUploadError(error)
              } else {
                console.error("Upload failed:", error)
              }
              setIsUploading(false)
            },
          })
        } catch (error) {
          if (onUploadError) {
            onUploadError(error)
          } else {
            console.error("Upload failed:", error)
          }
          setIsUploading(false)
        }
      }
    }

    const handleRemoveFile = (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (disabled || isUploading) return

      // Reset file input by clearing the file name
      setInternalFileName("")
      setUploadedBlobId(null)

      // Reset the input value to allow selecting the same file again
      if (inputRef && inputRef.current) {
        inputRef.current.value = ""
      }

      // Notify parent component
      if (onFileRemove) {
        onFileRemove(uploadedBlobId)
      }
    }

    return (
      <div className={classNames("relative w-full", className)}>
        <div
          className={classNames(
            "relative flex overflow-hidden rounded border outline-none",
            {
              "border-red-600": hasErrors,
              "cursor-not-allowed bg-gray-100 text-gray-500":
                disabled || isUploading,
              "border-dashed": !fileName,
              "h-10": size !== "large",
              "h-12": size === "large",
            }
          )}
        >
          <input
            className="absolute inset-0 z-10 cursor-pointer opacity-0"
            type="file"
            id={id}
            name={name}
            ref={inputRef}
            accept={accept}
            disabled={disabled || isUploading}
            required={required}
            multiple={multiple}
            onChange={handleFileChange}
            onBlur={onBlur}
            onFocus={onFocus}
            data-testid={id}
            data-design-system="FormDirectUploadFileInput"
            style={{
              // Hide the input from the remove button area
              right: fileName && !isUploading ? "40px" : "0",
            }}
          />

          {/* Display area - now just for visual presentation */}
          <div
            className={classNames("flex h-full w-full items-center", {
              "cursor-not-allowed": disabled || isUploading,
              "cursor-pointer": !disabled && !isUploading,
            })}
          >
            <div
              className={classNames(
                "flex w-full items-center justify-between",
                // Add right padding when remove button is showing to prevent text overlap
                { "pr-10": fileName && !isUploading }
              )}
            >
              <span className="my-auto ml-3 truncate">
                {isUploading
                  ? "Uploading..."
                  : fileName || placeholder || "Choose file"}
              </span>

              <div className="ml-2 flex items-center self-center pr-3">
                <span
                  className={classNames("flex items-center justify-center", {
                    "text-blue-600": !disabled && !isUploading,
                    "text-gray-400": disabled || isUploading,
                  })}
                >
                  {fileName ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* Vertical remove button positioned at the far right */}
          {fileName && !isUploading && (
            <button
              type="button"
              onClick={handleRemoveFile}
              disabled={disabled || isUploading}
              className={classNames(
                "bg-gray-50 absolute bottom-0 right-0 top-0 z-20 flex h-full items-center justify-center border-l px-3 transition-colors",
                {
                  "cursor-not-allowed": disabled || isUploading,
                  "cursor-pointer hover:bg-red-50 hover:text-red-600":
                    !disabled && !isUploading,
                  "text-gray-500": !disabled && !isUploading,
                  "border-red-600": hasErrors,
                  "border-gray-300": !hasErrors,
                }
              )}
              aria-label="Remove file"
              title="Remove file"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>
        {isUploading && (
          <div className="mt-1 h-1 w-full overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-full animate-pulse bg-blue-600"
              style={{ width: "100%" }}
            ></div>
          </div>
        )}
      </div>
    )
  }
)

FormDirectUploadFileInput.displayName = "FormDirectUploadFileInput"

FormDirectUploadFileInput.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  uploadUrl: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  onUploadSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
  onFileRemove: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["default", "large"]),
  fileName: PropTypes.string,
}

FormDirectUploadFileInput.defaultProps = {
  uploadUrl: "/rails/active_storage/direct_uploads",
  multiple: false,
  size: "default",
}

export default FormDirectUploadFileInput
