import PropTypes from "prop-types"
import React from "react"
import ContactBoatCard from "src/main/Chat/Sidebar/ContactBoatCard"
import ContactBoatDimension from "src/main/Chat/Sidebar/ContactBoatDimension"
import ContactCard from "src/main/Chat/Sidebar/ContactCard"
import HorizontalRule from "src/main/Chat/Sidebar/HorizontalRule"
import Notice from "src/main/Chat/Sidebar/Notice"
import SectionHeader from "src/main/Chat/Sidebar/SectionHeader"

import Details from "./Details"

const Item = ({ type, ...passThroughProps }) => {
  const determineType = (type) => {
    switch (type) {
      case "detail":
        return Details
      case "horizontal_rule":
        return HorizontalRule
      case "notice":
        return Notice
      case "contact_card":
        return ContactCard
      case "section_header":
        return SectionHeader
      case "contact_boat_card":
        return ContactBoatCard
      case "contact_boat_dimension":
        return ContactBoatDimension
      default:
        return HorizontalRule
    }
  }

  const ItemComponent = determineType(type)

  return <ItemComponent {...passThroughProps} />
}

Item.propTypes = {
  type: PropTypes.oneOf([
    "detail",
    "horizontal_rule",
    "contact_card",
    "contact_boat_card",
    "contact_boat_dimension",
    "section_header",
    "notice",
  ]).isRequired,
  label: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  link: PropTypes.string,
}

export default Item
