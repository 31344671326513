import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { updateAmenities } from "src/api/Onboarding/Claim"

import { useTracker } from "src/hooks/use_tracker"

import { deepCamelCaseToSnakeKeys } from "src/utils/object_helpers"
import { capitalize } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const AmenitiesStep = ({
  onNext,
  primaryAmenities,
  assetCounts,
  marinaName,
}) => {
  const [showFuelSubStep, setShowFuelSubStep] = useState(false)
  const [showAssetCountSubStep, setShowAssetCountSubStep] = useState(false)
  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()

  // Effect to scroll to bottom of page when asset counts section appears
  useEffect(() => {
    if (showAssetCountSubStep) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        })
      }, 200)
    }
  }, [showAssetCountSubStep])

  // Effect to scroll to bottom of page when fuel prices section appears
  useEffect(() => {
    if (showFuelSubStep) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        })
      }, 200)
    }
  }, [showFuelSubStep])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      amenities: primaryAmenities,
      fuelPrices: {
        dieselPrice: "",
        gasRegularPrice: "",
        gasSuperPrice: "",
        gasPremiumPrice: "",
      },
      assetCounts: assetCounts,
    },
  })

  const watchedAmenities = watch("amenities")
  const hasDiesel = !!watchedAmenities?.fuel?.fuel_diesel?.value
  const hasGas = !!watchedAmenities?.fuel?.fuel_gas?.value

  const validateDecimalPrecision = (value) => {
    const regex = /^-?\d+(\.\d{1,4})?$/
    if (value && !regex.test(value)) {
      return "Max precision is 4 decimals"
    }
    return true
  }

  const validatePositiveNumber = (value) => {
    if (value && !/^[0-9]+$/.test(value)) {
      return "Must be zero or a positive number"
    }
    return true
  }

  const { mutate, isLoading } = useMutation(
    (data) => {
      data.amenities = Object.fromEntries(
        Object.entries(data.amenities).flatMap(([_, amenities]) =>
          Object.values(amenities).map(({ key, value }) => [key, value])
        )
      )
      return updateAmenities(marinaSlug, deepCamelCaseToSnakeKeys(data))
    },
    {
      onSuccess: () => {
        onNext()
      },
    }
  )

  const handleAmenityChange = (group, key) => {
    const amenity = watchedAmenities[group][key]

    if (amenity) {
      const updatedAmenity = {
        ...amenity,
        value: !amenity.value,
      }

      setValue(`amenities.${group}.${key}`, updatedAmenity, {
        shouldDirty: true,
      })
    }
  }

  const onSubmit = () => {
    if (!showAssetCountSubStep) {
      tracker.trackEvent("onboarding:claim:asset_counts_pressed")
      setShowAssetCountSubStep(true)
    } else if ((hasDiesel || hasGas) && !showFuelSubStep) {
      tracker.trackEvent("onboarding:claim:fuel_prices_pressed")
      setShowFuelSubStep(true)
    } else {
      tracker.trackEvent("onboarding:claim:amenities_continue_pressed")

      if (isDirty) {
        handleSubmit(mutate)()
      } else {
        // Skip API call if form hasn't changed
        onNext()
      }
    }
  }

  const renderFuelSubStep = () => {
    return (
      <Transition
        show={showFuelSubStep}
        enter="transition-all duration-500 ease-in-out"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all duration-400 ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-6"
      >
        <div className="mb-4 flex flex-col gap-2">
          <h2 className="text-center text-2xl font-bold">
            What are your fuel prices?
          </h2>
          <p className="mb-4 text-center text-gray-600">
            Please provide the price per gallon for each fuel type you offer.
          </p>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {hasGas && (
              <>
                <div>
                  <Form.Label htmlFor="regular-gas-input">
                    Regular Gasoline
                  </Form.Label>
                  <Form.IconTextField
                    id="gas-regular-price-input"
                    icon="$"
                    type="text"
                    placeholder="0.00"
                    hasErrors={!!errors.fuelPrices?.gasRegularPrice}
                    {...register("fuelPrices.gasRegularPrice", {
                      validate: validateDecimalPrecision,
                    })}
                  />
                  {errors.fuelPrices?.gasRegularPrice && (
                    <p className="mt-1 text-xs text-red-500">
                      {errors.fuelPrices?.gasRegularPrice?.message}
                    </p>
                  )}
                </div>
                <div>
                  <Form.Label htmlFor="super-gas-input">
                    Super Gasoline
                  </Form.Label>
                  <Form.IconTextField
                    id="gas-super-price-input"
                    icon="$"
                    type="text"
                    placeholder="0.00"
                    hasErrors={!!errors.fuelPrices?.gasSuperPrice}
                    {...register("fuelPrices.gasSuperPrice", {
                      validate: validateDecimalPrecision,
                    })}
                  />
                  {errors.fuelPrices?.gasSuperPrice && (
                    <p className="mt-1 text-xs text-red-500">
                      {errors.fuelPrices?.gasSuperPrice?.message}
                    </p>
                  )}
                </div>
                <div>
                  <Form.Label htmlFor="premium-gas-input">
                    Premium Gasoline
                  </Form.Label>
                  <Form.IconTextField
                    id="premium-gas-input"
                    icon="$"
                    type="text"
                    placeholder="0.00"
                    hasErrors={!!errors.fuelPrices?.gasPremiumPrice}
                    {...register("fuelPrices.gasPremiumPrice", {
                      validate: validateDecimalPrecision,
                    })}
                  />
                  {errors.fuelPrices?.gasPremiumPrice && (
                    <p className="mt-1 text-xs text-red-500">
                      {errors.fuelPrices?.gasPremiumPrice?.message}
                    </p>
                  )}
                </div>
              </>
            )}
            {hasDiesel && (
              <div>
                <Form.Label htmlFor="diesel-price-input">
                  Diesel Price
                </Form.Label>
                <Form.IconTextField
                  id="diesel-price-input"
                  icon="$"
                  type="text"
                  placeholder="0.00"
                  hasErrors={!!errors.fuelPrices?.dieselPrice}
                  {...register("fuelPrices.dieselPrice", {
                    validate: validateDecimalPrecision,
                  })}
                />
                {errors.fuelPrices?.dieselPrice && (
                  <p className="mt-1 text-xs text-red-500">
                    {errors.fuelPrices?.dieselPrice?.message}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </Transition>
    )
  }

  const renderAssetCountSubStep = () => {
    return (
      <Transition
        show={showAssetCountSubStep}
        enter="transition-all duration-500 ease-in-out"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all duration-400 ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-6"
      >
        <div className="mb-4 flex flex-col gap-2">
          <h2 className="text-center text-2xl font-bold">
            What is your capacity?
          </h2>
          <p className="mb-4 text-center text-gray-600">
            Please provide the number of slips, moorings, linear dockage, dry
            stack spaces, and land storage you have.
          </p>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <Form.Label htmlFor="slips-input">Number of Slips</Form.Label>
              <Form.TextField
                id="slips-input"
                type="number"
                placeholder="0"
                hasErrors={!!errors.assetCounts?.slip_count}
                {...register("assetCounts.slip_count", {
                  validate: validatePositiveNumber,
                })}
              />
              {errors.assetCounts?.slip_count && (
                <p className="mt-1 text-xs text-red-500">
                  {errors.assetCounts?.slip_count?.message}
                </p>
              )}
            </div>
            <div>
              <Form.Label htmlFor="moorings-input">
                Number of Moorings
              </Form.Label>
              <Form.TextField
                id="moorings-input"
                type="number"
                placeholder="0"
                hasErrors={!!errors.assetCounts?.mooring_count}
                {...register("assetCounts.mooring_count", {
                  validate: validatePositiveNumber,
                })}
              />
              {errors.assetCounts?.mooring_count && (
                <p className="mt-1 text-xs text-red-500">
                  {errors.assetCounts?.mooring_count?.message}
                </p>
              )}
            </div>
            <div>
              <Form.Label htmlFor="linear-dockage-input">
                Linear Dockage
              </Form.Label>
              <Form.IconTextField
                id="linear-dockage-input"
                icon={assetCounts?.length_display_units}
                position="right"
                type="number"
                placeholder="0"
                hasErrors={!!errors.assetCounts?.linear_dockage}
                {...register("assetCounts.linear_dockage", {
                  validate: validatePositiveNumber,
                })}
              />
              {errors.assetCounts?.linear_dockage && (
                <p className="mt-1 text-xs text-red-500">
                  {errors.assetCounts?.linear_dockage?.message}
                </p>
              )}
            </div>
            <div>
              <Form.Label htmlFor="dry-stack-input">
                Dry Stack Capacity
              </Form.Label>
              <Form.TextField
                id="dry-stack-input"
                type="number"
                placeholder="0"
                hasErrors={!!errors.assetCounts?.dry_storage_capacity}
                {...register("assetCounts.dry_storage_capacity", {
                  validate: validatePositiveNumber,
                })}
              />
              {errors.assetCounts?.dry_storage_capacity && (
                <p className="mt-1 text-xs text-red-500">
                  {errors.assetCounts?.dry_storage_capacity?.message}
                </p>
              )}
            </div>
            <div>
              <Form.Label htmlFor="land-storage-input">
                Land Storage Capacity
              </Form.Label>
              <Form.TextField
                id="land-storage-input"
                type="number"
                placeholder="0"
                hasErrors={!!errors.assetCounts?.winter_storage_capacity}
                {...register("assetCounts.winter_storage_capacity", {
                  validate: validatePositiveNumber,
                })}
              />
              {errors.assetCounts?.winter_storage_capacity && (
                <p className="mt-1 text-xs text-red-500">
                  {errors.assetCounts?.winter_storage_capacity?.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </Transition>
    )
  }

  return (
    <div className="flex min-h-full w-full flex-col items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center px-2 text-center lg:w-1/3">
        <h2 className="text-2xl font-bold">
          Which of these amenities does {marinaName} offer?
        </h2>
        <p className="text-sm text-gray-500">
          This will help us tailor your marina profile to potential customers.
          You&apos;ll be able to add more amenities later, let&apos;s start with
          the most important ones.
        </p>
      </div>
      <div className="mb-4 flex w-full flex-col items-center justify-center px-2 lg:w-1/2">
        {Object.entries(watchedAmenities || {}).map(
          ([group, groupAmenities]) => {
            return (
              <>
                <h5 className="mb-1 text-lg font-semibold">
                  {capitalize(group)}
                </h5>
                <div
                  key={group}
                  className="my-2 grid w-full grid-cols-3 gap-2 lg:w-3/4"
                >
                  {Object.values(groupAmenities).map(
                    ({ key, label, value }) => (
                      <label
                        key={key}
                        className="flex cursor-pointer space-x-2 text-left"
                      >
                        <input
                          type="checkbox"
                          checked={value}
                          name={key}
                          onChange={() => handleAmenityChange(group, key)}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <span className="text-gray-700">{label}</span>
                      </label>
                    )
                  )}
                </div>
              </>
            )
          }
        )}
      </div>
      <div className="flex w-full flex-col justify-center px-2 lg:w-1/3">
        {renderAssetCountSubStep()}
      </div>
      <div className="flex w-full flex-col justify-center px-2 lg:w-1/3">
        {renderFuelSubStep()}
      </div>
      <div className="my-4">
        <Button variant="primary" onClick={onSubmit} isLoading={isLoading}>
          Continue
        </Button>
      </div>
    </div>
  )
}

AmenitiesStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  primaryAmenities: PropTypes.object.isRequired,
  assetCounts: PropTypes.object.isRequired,
  marinaName: PropTypes.string.isRequired,
}

export default AmenitiesStep
