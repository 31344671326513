import PropTypes from "prop-types"
import React from "react"
import DockwaPlusFuelDeals from "src/main/MarinaProfile/DockwaPlusDeals/DockwaPlusFuelDeals"
import DockwaPlusReservationDeals from "src/main/MarinaProfile/DockwaPlusDeals/DockwaPlusReservationDeals"

import CardWithGradient from "src/components/Card/CardWithGradient"
import { DockwaPlusDealsLogoBlack } from "src/components/DockwaPlus/Logos"

const DockwaPlusDeals = ({
  dockwaPlusReservationDeals,
  hasDockwaPlusFuelDeals,
  hasAnyFuelPumpPrices,
  fuelDiscountsEnabled,
}) => {
  if (
    !dockwaPlusReservationDeals.length &&
    (!hasDockwaPlusFuelDeals || !fuelDiscountsEnabled)
  ) {
    return null
  }
  return (
    <div className="mb-6">
      <CardWithGradient>
        <div className="mb-4 pt-1">
          <DockwaPlusDealsLogoBlack />
          <span>
            As a Dockwa+ member, you have access to the following deals.
            Discounts will automatically apply when entering applicable dates
          </span>
        </div>
        <DockwaPlusReservationDeals
          dockwaPlusReservationDeals={dockwaPlusReservationDeals}
        />
        {dockwaPlusReservationDeals.length > 0 &&
          hasDockwaPlusFuelDeals &&
          fuelDiscountsEnabled && <div className="my-4 w-full border-b" />}
        {hasAnyFuelPumpPrices &&
          hasDockwaPlusFuelDeals &&
          fuelDiscountsEnabled && <DockwaPlusFuelDeals />}
      </CardWithGradient>
    </div>
  )
}

DockwaPlusDeals.propTypes = {
  dockwaPlusReservationDeals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired,
      expiresAt: PropTypes.string,
      validRangeReadable: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      readableDiscount: PropTypes.string.isRequired,
      expiresSoon: PropTypes.bool.isRequired,
    })
  ),
  hasDockwaPlusFuelDeals: PropTypes.bool.isRequired,
  hasAnyFuelPumpPrices: PropTypes.bool.isRequired,
  fuelDiscountsEnabled: PropTypes.bool.isRequired,
}

export default DockwaPlusDeals
