import Decimal from "decimal.js"

export const inchesToFeet = (inches) => {
  return Math.ceil(inches / 12)
}

export const inchesToFeetAndInches = (totalInches) => {
  const inches = totalInches % 12
  const feet = (totalInches - inches) / 12
  return { feet, inches }
}

export const inchesToSquareFeet = (inches) => {
  return Math.ceil(inches / 144)
}

export const feetToInches = (feet) => {
  return feet * 12
}

export const formattedFeet = (feet) => {
  return `${feet}'`
}

export const formattedDollars = (dollars, precision = 2) => {
  let prefix = ""

  if (dollars < 0) {
    prefix = "-"
  }
  return (
    prefix +
    new Decimal(dollars).abs().toNumber().toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: precision,
      style: "currency",
      currency: "USD",
    })
  )
}

export const formattedCentsToDollars = (cents, precision = 2) => {
  return formattedDollars(new Decimal(cents).div(100).toNumber(), precision)
}

export const formattedHundredthsCentsToDollars = (
  hundredthsCents,
  precision = 4
) => {
  return formattedDollars(
    new Decimal(hundredthsCents).div(10000).toNumber(),
    precision
  )
}

export const formattedDecimalToTax = (taxDecimal) => {
  try {
    return `${new Decimal(taxDecimal).mul(100).toNumber()}%`
  } catch (e) {
    return null
  }
}

export const centsToDollars = (cents) => {
  try {
    return new Decimal(cents).div(100).toFixed(2)
  } catch (e) {
    return null
  }
}

export const percentToDecimal = (percent) => {
  try {
    return new Decimal(percent).div(100).toFixed(2)
  } catch (e) {
    return null
  }
}
