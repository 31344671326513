import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React, { useContext, useEffect } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import { PaymentFlowContext } from "../../PaymentModal"
import StripeWrapper from "./StripeWrapper"
import { createPaymentMethod } from "./helpers"

const DetailCollectionScreen = () => {
  const {
    cartController: {
      totals: { total: contextTotal },
    },
  } = useContext(POSContext)

  return (
    <StripeWrapper total={contextTotal}>
      <div className="h-full w-full p-2">
        <StripeForm />
      </div>
    </StripeWrapper>
  )
}

/*
  useStripe and useElements must be called from within a child component of StripeWrapper,
  which is why StripeForm is defined outside of DetailCollectionScreen
 */
const StripeForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const {
    checkoutDetails: { setPaymentMethod },
    flowDetails: { clearErrors },
  } = useContext(PaymentFlowContext)

  useEffect(() => {
    setPaymentMethod({})
  }, [setPaymentMethod])

  const handlePaymentElementChange = async (event) => {
    if (event.complete) {
      clearErrors()
      const result = await createPaymentMethod({ stripe, elements })
      setPaymentMethod(result)
    }
  }

  return (
    <>
      <PaymentElement
        options={{ terms: { card: "never" } }}
        onChange={handlePaymentElementChange}
      />
    </>
  )
}

DetailCollectionScreen.modalTitle = "Enter payment details"
DetailCollectionScreen.confirmButtonLabel = "Continue"

export default DetailCollectionScreen
