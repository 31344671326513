import PropTypes from "prop-types"
import React from "react"

import AddFullStayDiscountModal from "./AddFullStayDiscountModal"
import AddMonthlyDiscountModal from "./AddMonthlyDiscountModal"

const AddDiscountModal = ({
  onClose,
  onSubmit,
  monthlyDiscountModal = false,
}) => {
  if (monthlyDiscountModal) {
    return <AddMonthlyDiscountModal onClose={onClose} onSubmit={onSubmit} />
  }

  return <AddFullStayDiscountModal onClose={onClose} onSubmit={onSubmit} />
}

AddDiscountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  monthlyDiscountModal: PropTypes.bool.isRequired,
}

export default AddDiscountModal
