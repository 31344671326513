import PropTypes from "prop-types"
import React from "react"

const Error = ({ name }) => {
  const errorText = () => {
    if (name) {
      return `Error loading ${name}.`
    } else {
      return "Error loading data."
    }
  }
  return (
    <div data-design-system="Error" className="p-5 text-center text-red-600">
      <h3 className="mb-5 text-lg font-semibold">
        {errorText()} Please try again. If the problem persists, please contact{" "}
        <a
          href="mailto:mayday@dockwa.com"
          className="font-semibold text-blue-700"
        >
          mayday@dockwa.com
        </a>
        .
      </h3>
    </div>
  )
}

Error.propTypes = {
  name: PropTypes.string,
}

export default Error
