import Decimal from "decimal.js"

export const PERCENT_DISCOUNT_TYPE = "Billing::PercentProductSaleDiscount"
export const FIXED_DISCOUNT_TYPE = "Billing::FixedProductSaleDiscount"

export const calculateDiscountsByOrder = (originalAmount) => {
  let currentTotalDiscounts = 0
  return (discount, index) => {
    let typeSpecificParams = {}
    const remainingAmount = new Decimal(originalAmount)
      .minus(currentTotalDiscounts)
      .toNumber()
    if (discount.percentage) {
      const amount = new Decimal(discount.percentage)
        .mul(remainingAmount)
        .toFixed(0)
      currentTotalDiscounts += Number(amount)
      typeSpecificParams = {
        amount: Number(amount),
        type: PERCENT_DISCOUNT_TYPE,
        percent_amount: discount.percentage,
      }
    } else {
      const amount = Math.min(remainingAmount, Number(discount.amount))
      currentTotalDiscounts += Number(amount)
      typeSpecificParams = {
        amount: Number(amount),
        type: FIXED_DISCOUNT_TYPE,
      }
    }
    return {
      name: discount.name,
      apply_order: index,
      billing_applied_discount_template_id:
        discount.billingAppliedDiscountTemplateId,
      ...typeSpecificParams,
    }
  }
}
