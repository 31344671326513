import React, { useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../WizardContextProvider"
import DatePickerInput from "./DatePickerInput"

const DepartureInput = () => {
  const { handleDepartureChange } = useContext(WizardContext)
  const {
    watch,
    formState: { errors },
  } = useFormContext()
  const arrival = watch("arrival")

  return (
    <div>
      <Form.Label htmlFor="trw-departure">Departure</Form.Label>
      <Controller
        name="departure"
        render={({ field: { value } }) => (
          <Form.DatePicker
            id="trw-departure"
            minDate={arrival}
            hasErrors={Boolean(errors?.departure)}
            renderCustomHeader={(props) => (
              <Form.DatePicker.QuickNavHeader {...props} />
            )}
            value={value}
            onChange={handleDepartureChange}
            customInput={
              <DatePickerInput
                errorMessage={
                  errors?.departure ? errors.departure.message : null
                }
              />
            }
          />
        )}
      />
    </div>
  )
}

export default DepartureInput
