import React, { useContext, useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"

import Badge from "src/components/Badge"
import Form from "src/components/Form"

import { WizardContext } from "../../WizardContextProvider"
import { NO_COUPON_CODE_CHOSEN_VALUE } from "../../constants"
import {
  getCouponCodeBadgeAttributes,
  getCouponCodeOptionText,
  getOrderedCouponCodeOptions,
} from "../../helpers"

const CouponCodeSelector = () => {
  const {
    couponCodes,
    defaultCouponCodeId,
    promoCodesPath,
    isMonthlyBilling,
    isCustomRate,
  } = useContext(WizardContext)
  const couponCodeUnsupported = isMonthlyBilling || isCustomRate
  const orderedCouponCodeOptions = useMemo(
    () => getOrderedCouponCodeOptions(couponCodes),
    [couponCodes]
  )
  const { register, watch, setValue } = useFormContext()
  const [promoCodeOrDiscountTemplateId] = watch([
    "promo_code_or_discount_template_id",
  ])
  const selectedCouponCode = couponCodes.find(
    (coupon) => coupon.id.toString() === promoCodeOrDiscountTemplateId
  )
  const defaultCouponCode = couponCodes.find(
    (coupon) => coupon.id === defaultCouponCodeId
  )

  useEffect(() => {
    // if the coupon code is no longer valid (ie not present in couponCodes array),
    // reset the form value to "none"
    if (
      promoCodeOrDiscountTemplateId !== NO_COUPON_CODE_CHOSEN_VALUE &&
      !selectedCouponCode
    ) {
      setValue(
        "promo_code_or_discount_template_id",
        NO_COUPON_CODE_CHOSEN_VALUE
      )
      setValue("promo_code_or_discount_template_type", null)
    }
  }, [setValue, selectedCouponCode, promoCodeOrDiscountTemplateId])

  useEffect(() => {
    if (defaultCouponCode) {
      setValue(
        "promo_code_or_discount_template_id",
        defaultCouponCode.id.toString()
      )
      setValue("promo_code_or_discount_template_type", defaultCouponCode.type)
    }
  }, [couponCodes, defaultCouponCode])

  const handleCouponCodeSelection = (id) => {
    const couponCode = couponCodes.find(
      (couponCode) => couponCode.id.toString() === id
    )

    setValue("promo_code_or_discount_template_id", id)
    setValue(
      "promo_code_or_discount_template_type",
      id === NO_COUPON_CODE_CHOSEN_VALUE || !couponCode ? null : couponCode.type
    )
  }

  const renderSelectedCouponCodeBadge = () => {
    if (selectedCouponCode) {
      const [badgeIcon, badgeText] =
        getCouponCodeBadgeAttributes(selectedCouponCode)

      return (
        <Badge
          color="blue"
          icon={badgeIcon}
          text={badgeText}
          onDismiss={() =>
            handleCouponCodeSelection(NO_COUPON_CODE_CHOSEN_VALUE)
          }
        />
      )
    }
    return null
  }

  return (
    <div data-testid="trw-coupon-code-section">
      <Form.Label htmlFor="trw-coupon-code">
        Coupon Code{" "}
        <a
          href={promoCodesPath}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer no-underline"
        >
          (settings)
        </a>
      </Form.Label>
      <Form.Select
        id="trw-coupon-code"
        disabled={couponCodes.length === 0 || couponCodeUnsupported}
        {...register("promo_code_or_discount_template_id")}
        onChange={({ target: { value } }) => handleCouponCodeSelection(value)}
      >
        <option value={NO_COUPON_CODE_CHOSEN_VALUE}>None</option>
        {orderedCouponCodeOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {getCouponCodeOptionText(option)}
          </option>
        ))}
      </Form.Select>
      {couponCodeUnsupported ? (
        <p className="pt-2 text-gray-500">
          Coupon codes not available for monthly billing or custom rates.
        </p>
      ) : null}
      <div className="pt-2">{renderSelectedCouponCodeBadge()}</div>
    </div>
  )
}

export default CouponCodeSelector
