import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { POSContext } from "src/main/PointOfSale/Layout"

import Button from "src/components/Button"

export default function Expired() {
  const {
    cartController: { clearCart },
  } = useContext(POSContext)

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="mt-[15%] font-semibold">Expired</h1>
      <p>This cart has expired</p>
      <Link to="/" onClick={async () => await clearCart()}>
        <Button variant="primary" fullWidth>
          Start a New Sale
        </Button>
      </Link>
    </div>
  )
}
