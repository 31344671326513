import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"

import Form from "src/components/Form"

import useDebounce from "src/hooks/use_debounce"

const NumericInput = ({
  value,
  onChange,
  onBlur,
  disabled,
  hasErrors,
  precision = 2,
  valueMultiplier = 1,
  smartFormat = false,
  icon,
  iconPosition = "right",
  inputMode = "decimal",
  ...props
}) => {
  // Track if a key is being held down
  const [keyInterval, setKeyInterval] = useState(null)

  // Formatting function to display numbers smartly or with fixed precision
  const formatNumber = useCallback((number, precision, smart = false) => {
    const num = parseFloat(number)
    if (smart) {
      if (Number.isInteger(num)) {
        return num.toString()
      } else {
        return num.toFixed(precision).replace(/\.?0+$/, "")
      }
    }
    return num.toFixed(precision)
  }, [])

  // Initialize the display value based on the provided value
  const [displayValue, setDisplayValue] = useState(() => {
    const displayNum = value / valueMultiplier
    return formatNumber(displayNum, precision, smartFormat)
  })

  // Updated adjustValue: determine step from the current display
  const adjustValue = (direction) => {
    const currentDecimal = new Decimal(displayValue || "0")

    // If the display shows a decimal, use the number of digits after the decimal as the determinant
    let step
    if (displayValue.includes(".")) {
      const decimals = displayValue.split(".")[1].length
      step = new Decimal(1).div(Math.pow(10, decimals))
    } else {
      step = new Decimal(1)
    }

    const newValue =
      direction === "up"
        ? currentDecimal.plus(step)
        : currentDecimal.minus(step)

    setDisplayValue(formatNumber(newValue.toNumber(), precision, smartFormat))

    // Calculate storage value using Decimal to avoid floating point errors
    const storageValue = newValue.mul(valueMultiplier).toNumber()
    onChange(storageValue)
  }

  // Arrow key handlers
  const handleKeyDown = (e) => {
    if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return
    e.preventDefault()

    if (keyInterval) {
      clearInterval(keyInterval)
    }

    // Adjust immediately
    adjustValue(e.key === "ArrowUp" ? "up" : "down")

    // Then continuously adjust while key is held
    const newInterval = setTimeout(() => {
      const repeatInterval = setInterval(() => {
        adjustValue(e.key === "ArrowUp" ? "up" : "down")
      }, 50)
      setKeyInterval(repeatInterval)
    }, 400)

    setKeyInterval(newInterval)
  }

  const handleKeyUp = (e) => {
    if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return
    if (keyInterval) {
      clearInterval(keyInterval)
      setKeyInterval(null)
    }
  }

  // Update debounced handler to use Decimal
  const [debouncedUpdateValue] = useDebounce((newValue, onChange) => {
    if (newValue.trim() === "") {
      return
    }
    const decimalValue = new Decimal(newValue)
    const storageValue = decimalValue.mul(valueMultiplier).toNumber()
    onChange(storageValue)
  }, 500)

  const handleChange = useCallback(
    (e) => {
      const input = e.target.value
      const sanitizedInput = input.replace(/[^\d.]/g, "")
      const parts = sanitizedInput.split(".")
      const cleanValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "")
      setDisplayValue(cleanValue)
      if (cleanValue.trim() !== "") {
        debouncedUpdateValue(cleanValue, onChange)
      }
    },
    [onChange, debouncedUpdateValue]
  )

  const handleBlur = useCallback(
    (e) => {
      if (displayValue.trim() === "") {
        setDisplayValue("0")
        onChange(0)
      } else {
        const numericValue = parseFloat(displayValue)
        const formattedValue = formatNumber(
          numericValue,
          precision,
          smartFormat
        )
        setDisplayValue(formattedValue)
      }
      if (onBlur) {
        onBlur(e)
      }
    },
    [displayValue, precision, smartFormat, formatNumber, onBlur, onChange]
  )

  return (
    <Form.IconTextField
      type="text"
      inputMode={inputMode}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      icon={icon}
      position={iconPosition}
      disabled={disabled}
      hasErrors={hasErrors}
      {...props}
    />
  )
}

NumericInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  precision: PropTypes.number,
  valueMultiplier: PropTypes.number,
  smartFormat: PropTypes.bool,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  inputMode: PropTypes.oneOf(["text", "decimal", "numeric"]),
  id: PropTypes.string,
}

export default NumericInput
