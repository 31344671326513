import PropTypes from "prop-types"
import React from "react"

const variantClasses = {
  reservation: {
    backgroundLeft: "bg-blue-600",
    backgroundRight: "bg-blue-500",
  },
  fuel: {
    backgroundLeft: "bg-supplementary-orange-400",
    backgroundRight: "bg-supplementary-orange-600",
  },
}

const DealTicket = ({ discount, variant = "reservation" }) => {
  const { backgroundLeft, backgroundRight } = variantClasses[variant]
  return (
    <div className="flex select-none text-lg font-bold text-white">
      <div
        className={`relative w-4 overflow-hidden rounded-l ${backgroundLeft}`}
      >
        <div className="absolute inset-y-0 left-0 my-auto -ml-4 h-2 w-5 rounded-full bg-white" />
      </div>
      <div className={`rounded-r ${backgroundRight} py-1 pl-1 pr-2`}>
        <span>{discount}</span>
      </div>
    </div>
  )
}

DealTicket.propTypes = {
  discount: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["reservation", "fuel"]),
}

export default DealTicket
