import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useState } from "react"
import { Controller, useFormContext, useFormState } from "react-hook-form"
import NumericInput from "src/main/PointOfSale/CurrentSalePanel/NumericInput"
import { POSContext } from "src/main/PointOfSale/Layout"

import Form from "src/components/Form"

import useDebounce from "src/hooks/use_debounce"
import { useTracker } from "src/hooks/use_tracker"

import {
  CART_ITEM_DISCOUNT_EDITED,
  CART_ITEM_NOTE_EDITED,
  CART_ITEM_PRICE_EDITED,
  CART_ITEM_QTY_EDITED,
  CART_ITEM_TAX_EDITED,
} from "../amplitude_events"
import { validatePrecision } from "./validators"

const ServerCartItemForm = ({ item, index, disabled = false }) => {
  const {
    cartController: { updateItem },
  } = useContext(POSContext)

  const { control, trigger } = useFormContext()

  const { errors, isValidating } = useFormState({
    control,
    name: `cart[${index}]`,
  })

  const tracker = useTracker()

  const formId = `cart[${index}]`
  const errorsForItem = errors?.cart?.[index]

  const [note, setNote] = useState(item.note)

  const validateAndUpdate = useCallback(
    async (update) => {
      const isValid = await trigger(`cart[${index}]`)

      if (isValid && !isValidating) {
        updateItem({
          item: {
            ...update,
            id: item.id,
          },
        })
      }
    },
    [trigger, index, item, updateItem, isValidating]
  )

  const [debouncedUpdateServer] = useDebounce((fieldName, newValue) => {
    validateAndUpdate({ [fieldName]: newValue })
  }, 100)

  const [debouncedUpdateNote] = useDebounce((newNote, onChange) => {
    onChange(newNote)
    validateAndUpdate({
      note: newNote,
    })
    tracker.trackEvent(CART_ITEM_NOTE_EDITED)
  }, 500)

  const handleNoteChange = useCallback(
    (event, onChange) => {
      const newNote = event.target.value
      setNote(newNote)
      debouncedUpdateNote(newNote, onChange)
    },
    [debouncedUpdateNote]
  )

  const renderQuantityInput = () => {
    const inputName = `${formId}.quantity`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Quantity
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.quantity}
          rules={{
            required: "Quantity is required",
            validate: validatePrecision({ name: "Quantity", precision: 5 }),
            min: { value: 1, message: "Must be greater than 0" },
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <NumericInput
                id={inputName}
                value={value}
                onChange={(updatedValue) => {
                  onChange(updatedValue)
                  debouncedUpdateServer("quantity", updatedValue)
                  tracker.trackEvent(CART_ITEM_QTY_EDITED)
                }}
                onBlur={onBlur}
                precision={5}
                smartFormat={true}
                disabled={disabled}
                hasErrors={Boolean(errorsForItem?.quantity)}
              />
            )
          }}
        />
      </div>
    )
  }

  const renderPriceInput = () => {
    const inputName = `${formId}.price`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Price
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.price}
          rules={{
            required: "Price is required",
            validate: validatePrecision({
              name: "Price",
              precision: item.pricePrecision,
            }),
            min: { value: 0, message: "Price cannot be less than $0.00" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value}
              onChange={(updatedValue) => {
                onChange(updatedValue)
                debouncedUpdateServer("price", updatedValue)
                tracker.trackEvent(CART_ITEM_PRICE_EDITED)
              }}
              precision={item.pricePrecision === "cents" ? 2 : 4}
              valueMultiplier={item.pricePrecision === "cents" ? 100 : 10000}
              icon="$"
              iconPosition="left"
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.price)}
            />
          )}
        />
      </div>
    )
  }

  const renderTaxInput = () => {
    const inputName = `${formId}.taxRate`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Tax
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.taxRate}
          rules={{
            required: "Tax is required",
            validate: (value) => {
              const [, decimals] = String(value).split(".")

              if (decimals?.length > 8) {
                return "Tax can only be specified up to 6 decimal places"
              }
            },
            min: { value: 0, message: "Tax cannot be less than 0%" },
            max: { value: 100, message: "Tax cannot be more than 100%" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value * 100} // display as percent
              onChange={(updatedValue) => {
                const value = new Decimal(updatedValue).div(100).toNumber()
                onChange(value)
                debouncedUpdateServer("taxRate", value)
                tracker.trackEvent(CART_ITEM_TAX_EDITED)
              }}
              precision={6}
              icon="%"
              smartFormat={true}
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.taxRate)}
            />
          )}
        />
      </div>
    )
  }

  const renderDiscountInput = () => {
    const inputName = `${formId}.discount`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Discount
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.discount}
          rules={{
            required: "Discount is required",
            validate: validatePrecision({
              name: "Discount",
              precision: 0,
              message: "Discount must be a whole number",
            }),
            min: { value: 0, message: "Discount must be greater than 0" },
            max: { value: 100, message: "Discount must be less than 100" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value}
              onChange={(updatedValue) => {
                onChange(updatedValue)
                debouncedUpdateServer("discount", updatedValue)
                tracker.trackEvent(CART_ITEM_DISCOUNT_EDITED)
              }}
              precision={0}
              icon="%"
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.discount)}
            />
          )}
        />
      </div>
    )
  }

  const renderNoteInput = () => {
    const inputName = `${formId}.note`
    return (
      <div className="w-full">
        <Form.Label small htmlFor={inputName}>
          Item Note
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={note}
          render={({ field: { onChange } }) => (
            <Form.Textarea
              rows={1}
              id={inputName}
              value={note}
              onChange={(e) => {
                handleNoteChange(e, onChange)
              }}
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.note)}
            />
          )}
        />
        <div className="text-muted italic">
          Visible on customer invoice/receipt
        </div>
      </div>
    )
  }

  const renderDiscountTemplateText = () => {
    if (item.discountTemplate) {
      return (
        <div className="flex flex-row items-center space-x-1 text-teal-600">
          <i className="icon icon-tag-outline mt-0.5 text-lg font-normal" />
          <span className="text-sm font-semibold">
            {`${item.discountTemplate.label} automatically added (${item.discountTemplate.description})`}
          </span>
        </div>
      )
    }
  }

  return (
    <div className="flex w-full flex-col gap-4 pb-1">
      <div className="grid h-full grid-cols-2 gap-x-3 gap-y-2 pt-2">
        {renderQuantityInput()}
        {renderPriceInput()}
        {renderTaxInput()}
        {renderDiscountInput()}
        <div className="col-span-2">{renderNoteInput()}</div>
      </div>
      <div className="flex w-full flex-col">
        {renderDiscountTemplateText()}
        {errorsForItem && (
          <div className="flex w-full flex-col pt-2 text-xs">
            <Form.Error>{errorsForItem.quantity?.message}</Form.Error>
            <Form.Error>{errorsForItem.price?.message}</Form.Error>
            <Form.Error>{errorsForItem.taxRate?.message}</Form.Error>
            <Form.Error>{errorsForItem.discount?.message}</Form.Error>
            <Form.Error>{errorsForItem.note?.message}</Form.Error>
          </div>
        )}
      </div>
    </div>
  )
}

ServerCartItemForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    pricePrecision: PropTypes.oneOf(["cents", "hundredths_of_cents"])
      .isRequired,
    taxRate: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    note: PropTypes.string,
    discountTemplate: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      source: PropTypes.oneOf([
        "dockwa_plus",
        "boatus",
        "safe_harbor",
        "no_membership",
      ]).isRequired,
      description: PropTypes.string.isRequired,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default ServerCartItemForm
