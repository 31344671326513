import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"

import { inchesToFeet, inchesToSquareFeet } from "src/utils/UnitConversion"

const AvailabilityCalendar = (props) => {
  const shouldRenderExtraRow = (firstDayOfWeek, daysInMonth) => {
    const minimumDateIndex = 36

    return firstDayOfWeek + daysInMonth < minimumDateIndex
  }

  const isTypeLOA = () =>
    ["SpaceGroup::Linear", "SpaceGroup::LinearV2"].includes(props.type)

  const isTypeSquareFoot = () => props.type === "SpaceGroup::SquareFootage"

  const datesForQuery = () => _.map(props.nights, "night")

  const isDateForQuery = (date) =>
    datesForQuery().includes(date.format("YYYY-MM-DD"))

  const dataForDate = (date) =>
    _.find(props.nights, ["night", date.format("YYYY-MM-DD")])

  const convertedOccupancyForDate = (date) => {
    const nightData = dataForDate(date)
    if (isTypeLOA()) {
      return inchesToFeet(nightData.loa)
    } else if (isTypeSquareFoot()) {
      return inchesToSquareFeet(nightData.square_inches)
    } else {
      return nightData.count
    }
  }

  const convertedTotal = () => {
    if (isTypeLOA()) {
      return inchesToFeet(props.total)
    } else if (isTypeSquareFoot()) {
      return inchesToSquareFeet(props.total)
    } else {
      return props.total
    }
  }

  const convertedAvailabilityForDate = (date) =>
    convertedTotal() - convertedOccupancyForDate(date)

  const displayUnit = () => {
    if (isTypeLOA()) {
      return "'"
    } else if (isTypeSquareFoot()) {
      return "sqft"
    } else {
      return ""
    }
  }

  const calcColor = (date) => {
    if (isDateForQuery(date)) {
      const occupancy = convertedOccupancyForDate(date)
      if (occupancy >= convertedTotal()) {
        return "bg-red-600 cursor-pointer"
      } else if (
        occupancy >= convertedTotal() * 0.9 ||
        convertedTotal() - occupancy === 1
      ) {
        return "bg-yellow-500 cursor-pointer"
      } else {
        return "bg-supplementary-green-600 cursor-pointer"
      }
    } else {
      return "bg-gray-400"
    }
  }

  const popoverData = (date) => {
    if (isDateForQuery(date)) {
      return `
        <div class="font-semibold">
          <span class="text-gray-600">DATE</span>
          <span class="ml-2">${date.format("L")}</span>
        </div>
        <div class="font-semibold">
          <span class="text-gray-600">AVAILABLE</span>
          <span class="ml-2">
            ${`${convertedAvailabilityForDate(date)}${displayUnit()}`}
          </span>
        </div>
      `
    }
  }

  const dateData = (date) => {
    if (isDateForQuery(date)) {
      const available = convertedAvailabilityForDate(date)

      if (available > 99) {
        if (props.isShortTimeFrame) {
          return `99${displayUnit()}+`
        } else {
          return "+"
        }
      } else {
        return `${available}${displayUnit()}`
      }
    }
  }

  const renderDate = (date) => {
    return (
      <div
        key={`${date.format("YYYY-MM-DD")}-availability-date`}
        className={`col-start-${Number(date.format("d")) + 1} ${calcColor(
          date
        )} flex items-center justify-center rounded px-1 font-semibold text-white hover:scale-150 hover:shadow-md`}
        style={{
          width: props.isShortTimeFrame ? 35 : 20,
          height: 20,
          fontSize: 12,
        }}
        data-content={popoverData(date)}
        data-container="body"
        data-toggle="popover"
        data-placement="top"
        data-animation="false"
        data-html="true"
      >
        <span>{dateData(date)}</span>
      </div>
    )
  }

  const renderCalendar = () => {
    // eslint-disable-next-line no-undef
    let date = moment(props.nights[0].night, "YYYY-MM-DD")
    if (props.isShortTimeFrame) {
      const daysInTimeFrame = props.nights.length
      return (
        <div className="flex items-center space-x-2 overflow-x-auto px-3">
          {_.map(_.times(daysInTimeFrame), () => {
            const html = renderDate(date)
            date = date.clone().add(1, "days")
            return html
          })}
        </div>
      )
    } else {
      date = date.startOf("month")
      const daysInMonth = date.daysInMonth()
      const firstDayOfWeek = Number(date.format("d"))
      return (
        <div
          className="my-3 mr-4 w-3/4 bg-gray-200 px-2 py-3 lg:w-1/3"
          style={{ width: "190" }}
        >
          <p className="text-center font-semibold">
            {date.format("MMMM YYYY")}
          </p>
          <div className="grid grid-cols-7 justify-items-center gap-y-2">
            <div className="font-size-10">S</div>
            <div className="font-size-10">M</div>
            <div className="font-size-10">T</div>
            <div className="font-size-10">W</div>
            <div className="font-size-10">T</div>
            <div className="font-size-10">F</div>
            <div className="font-size-10">S</div>
            {_.map(_.times(daysInMonth), () => {
              const html = renderDate(date)
              date = date.clone().add(1, "days")
              return html
            })}
            {shouldRenderExtraRow(firstDayOfWeek, daysInMonth) && (
              <div className="col-start-1" style={{ height: "20px" }} />
            )}
          </div>
        </div>
      )
    }
  }

  if (props.nights) {
    return renderCalendar()
  } else {
    return null
  }
}

AvailabilityCalendar.propTypes = {
  type: PropTypes.oneOf([
    "SpaceGroup::Linear",
    "SpaceGroup::LinearV2",
    "SpaceGroup::SquareFootage",
    "SpaceGroup::Count",
  ]),
  nights: PropTypes.arrayOf(
    PropTypes.shape({
      night: PropTypes.string,
      occupancies: PropTypes.object,
      overages: PropTypes.arrayOf(
        PropTypes.shape({
          reservation_encoded_id: PropTypes.string,
          loa: PropTypes.number,
          square_inches: PropTypes.number,
        })
      ),
      nightly_overages: PropTypes.object,
      space_groupless: PropTypes.arrayOf(
        PropTypes.shape({
          reservation_encoded_id: PropTypes.string,
          loa: PropTypes.number,
          square_inches: PropTypes.number,
          id: PropTypes.string,
        })
      ),
      has_space_groups: PropTypes.bool,
    })
  ),
  total: PropTypes.number,
  isShortTimeFrame: PropTypes.bool,
}
export default AvailabilityCalendar
