import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import Badge from "src/components/Badge"
import DataTable from "src/components/DataTable"
import Error from "src/components/Error"
import Loader from "src/components/Loader"

import { getInvoices } from "src/api/Account"
import { queryCombinedInvoices } from "src/api/Account/Reservations"

import { statusBadgeColor } from "src/utils/status_badge_color_helper"
import { snakecaseToTitlecase } from "src/utils/string_helpers"

const renderStatusBadge = (status) => {
  return <Badge color={statusBadgeColor(status)} text={status} />
}

const renderActions = (data) => {
  if (data.settledAt) {
    return (
      <div className="flex justify-end">
        <a
          href={data.detailsPath}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-tertiary"
        >
          Receipt
        </a>
      </div>
    )
  } else {
    return (
      <div className="flex justify-end space-x-4">
        {data.boaterPayable && (
          <a
            href={`/account/invoice_payment/${data.displayId}`}
            className="btn btn-secondary"
          >
            Pay
          </a>
        )}
        <a
          href={data.detailsPath}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-tertiary"
        >
          Invoice
        </a>
      </div>
    )
  }
}

const COLUMN_DEFINITIONS = [
  {
    key: "settledStatus",
    header: "Status",
    render: (data) =>
      renderStatusBadge(snakecaseToTitlecase(data.settledStatus)),
  },
  {
    key: "dueDate",
    header: "Date",
  },
  {
    key: "totalAmountDollars",
    header: "Amount",
  },
  {
    key: "actions",
    header: "",
    render: (data) => renderActions(data),
  },
]

const parentTypes = {
  reservation: "reservation",
  point_of_sale_sale: "point_of_sale_sale",
}

const BillingDetails = ({ invoices = [], parentObjectId, parentType }) => {
  const queryMethod = ({ parentObjectId }) => {
    if (parentType === parentTypes.reservation) {
      return queryCombinedInvoices({ reservationId: parentObjectId })
    } else if (parentType === parentTypes.point_of_sale_sale) {
      return getInvoices({ pointOfSaleSaleId: parentObjectId })
    } else {
      throw new Error(`Unsupported parentType: ${parentType}`)
    }
  }

  const {
    data: invoiceData,
    isLoading,
    isError,
  } = useQuery(
    ["account", "combinedInvoices", { parentObjectId, parentType }],
    () => queryMethod({ parentObjectId }),
    {
      initialData: invoices,
      keepPreviousData: true,
      retry: false,
      refetchIntervalh: 1000 * 60 * 5,
    }
  )

  if (isLoading) {
    return <Loader name="billing details" />
  } else if (isError) {
    return <Error name="billing details" />
  } else {
    return (
      <DataTable
        name="billingDetails"
        rowData={invoiceData}
        colDefs={COLUMN_DEFINITIONS}
        hideSettings
        hideActions
        autoColumnWidth
      />
    )
  }
}

BillingDetails.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      boaterPayable: PropTypes.bool.isRequired,
      displayId: PropTypes.string.isRequired,
      dueDate: PropTypes.string.isRequired,
      totalAmount: PropTypes.string.isRequired,
      settledStatus: PropTypes.string.isRequired,
      settledAt: PropTypes.string,
      detailsPath: PropTypes.string.isRequired,
    })
  ).isRequired,
  parentObjectId: PropTypes.string.isRequired,
  parentType: PropTypes.oneOf(["reservation", "point_of_sale_sale"]).isRequired,
}

export default BillingDetails
