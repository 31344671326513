import PropTypes from "prop-types"
import React from "react"

import {
  DockwaPlusDealLogoWhiteLarge,
  PlusLogo,
} from "src/components/DockwaPlus/Logos"

const DockwaPlusDealsBanner = ({
  currentUserFirstName,
  hasActiveDockwaPlusSubscription,
}) => (
  <div className="relative flex max-h-60 min-h-48 w-full flex-col justify-between bg-dockwa-plus-radial-gradient p-8 lg:rounded">
    <div className="absolute right-0 top-0">
      <PlusLogo />
    </div>
    <div className="flex flex-col space-y-4">
      <div className="flex justify-center lg:justify-start">
        <DockwaPlusDealLogoWhiteLarge />
      </div>
      <div className="text-center text-base text-white lg:text-start">
        {hasActiveDockwaPlusSubscription
          ? `${
              currentUserFirstName ? `Hi, ${currentUserFirstName}!` : ""
            } As a Dockwa+ member, you have access to great deals on dockage, moorings, and more. Act fast to redeem deals before they are gone.`
          : "Explore deals on dockage, moorings, and more. Act fast to redeem the deals before they are gone. Access to redeem Dockwa+ Deals is available exclusively to Dockwa+ members."}
      </div>
    </div>
    {!hasActiveDockwaPlusSubscription && (
      <div className="mb-2 mt-2 flex justify-center md:mb-0 md:mt-4 lg:justify-start">
        <a className="btn btn-primary" href="/account/settings/memberships">
          Become a Dockwa+ Member Today
        </a>
      </div>
    )}
  </div>
)

DockwaPlusDealsBanner.propTypes = {
  currentUserFirstName: PropTypes.string,
  hasActiveDockwaPlusSubscription: PropTypes.bool.isRequired,
}

export default DockwaPlusDealsBanner
