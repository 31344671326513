import React, { useState } from "react"

import detectBarcodeScan from "src/utils/detectBarcodeScan"

const BarcodeScannerTest = () => {
  const [scannedCode, setScannedCode] = useState(false)
  detectBarcodeScan(setScannedCode)

  return (
    <div>
      {scannedCode ? (
        <div className="flex items-center justify-between rounded-lg bg-teal-100 px-4 py-2 text-teal-800">
          <span>Scanned code successfully ✅</span>
          <span
            className="cursor-pointer font-semibold text-teal-700 underline"
            onClick={() => setScannedCode(false)}
          >
            Reset
          </span>
        </div>
      ) : (
        <div className="rounded-lg bg-yellow-100/75 px-4 py-2 font-semibold text-black/65">
          Waiting for barcode scan...
        </div>
      )}
    </div>
  )
}

export default BarcodeScannerTest
