import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import {
  expireContractQuote,
  markContractQuoteDeclined,
  retryContractQuote,
} from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"

import ContractActionsModal from "./ContractActionsModal"
import { ContractGroupContext } from "./index"

const ContractActions = ({
  contract,
  statusPending,
  menuDisabled,
  setMenuDisabled,
  setCurrentStatus,
  tab,
}) => {
  const { canUpdate, group } = useContext(ContractGroupContext)
  const [isContractActionsModalOpen, setIsContractActionsModalOpen] =
    useState(false)
  const [currentContract, setCurrentContract] = useState({})
  const [currentAction, setCurrentAction] = useState("")
  const showToast = useToast()
  const queryClient = useQueryClient()

  const handleDisableActions = () => {
    setCurrentContract(contract)
    setMenuDisabled(true)
  }

  const handleEnableActions = () => {
    setCurrentContract({})
    setMenuDisabled(false)
  }

  const { mutate: expireContract } = useMutation(expireContractQuote, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        "contractGroups",
        group.encodedId,
        "contractQuotes",
      ])
      showToast(data.message, { type: "success" })
    },
    onMutate: async ({ quoteId, groupId }) => {
      await queryClient.cancelQueries([
        "contractGroups",
        groupId,
        "contractQuotes",
      ])

      const previousData = queryClient.getQueryData([
        "contractGroups",
        groupId,
        "contractQuotes",
      ])

      queryClient.setQueryData(
        ["contractGroups", groupId, "contractQuotes"],
        (oldData) => {
          if (!oldData) return oldData

          const { unsent, outstanding, completed } = oldData

          let contract = unsent.find((item) => item.encodedId === quoteId)
          if (!contract) {
            contract = outstanding.find((item) => item.encodedId === quoteId)
          }

          return {
            unsent: unsent.filter((item) => item.encodedId !== quoteId),
            outstanding: outstanding.filter(
              (item) => item.encodedId !== quoteId
            ),
            completed: [{ ...contract, status: "Voided" }, ...completed],
          }
        }
      )

      return { previousData }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ["contractGroups", group.encodedId, "contractQuotes"],
        context.previousData
      )
      handleEnableActions()
      showToast(error.message, { type: "error" })
    },
  })

  const { mutate: retryContract } = useMutation(retryContractQuote, {
    onSuccess: (data) => {
      handleEnableActions()
      queryClient.invalidateQueries([
        "contractGroups",
        group.encodedId,
        "contractQuotes",
      ])
      setCurrentStatus("Processing")
      showToast(data.message, { type: "success" })
    },
    onError: (error) => {
      handleEnableActions()
      showToast(error.message, { type: "error" })
    },
  })

  const { mutate: markContractDeclined } = useMutation(
    markContractQuoteDeclined,
    {
      onSuccess: (data) => {
        handleEnableActions()
        queryClient.invalidateQueries([
          "contractGroups",
          group.encodedId,
          "contractQuotes",
        ])
        showToast(data.message, { type: "success" })
      },
      onMutate: async ({ quoteId, groupId }) => {
        await queryClient.cancelQueries([
          "contractGroups",
          groupId,
          "contractQuotes",
        ])

        const previousData = queryClient.getQueryData([
          "contractGroups",
          groupId,
          "contractQuotes",
        ])

        queryClient.setQueryData(
          ["contractGroups", groupId, "contractQuotes"],
          (oldData) => {
            if (!oldData) return oldData

            const { unsent, outstanding, completed } = oldData

            let contract = unsent.find((item) => item.encodedId === quoteId)
            if (!contract) {
              contract = outstanding.find((item) => item.encodedId === quoteId)
            }

            return {
              unsent: unsent.filter((item) => item.encodedId !== quoteId),
              outstanding: outstanding.filter(
                (item) => item.encodedId !== quoteId
              ),
              completed: [
                { ...contract, status: "Boater Declined" },
                ...completed,
              ],
            }
          }
        )

        return { previousData }
      },
      onError: (error) => {
        handleEnableActions()
        showToast(error.message, { type: "error" })
      },
    }
  )

  const handleExpireContract = () => {
    handleDisableActions()
    expireContract({
      quoteId: contract.encodedId,
      groupId: group.encodedId,
    })
  }

  const handleRetryContract = () => {
    handleDisableActions()
    retryContract({
      quoteId: contract.encodedId,
      groupId: group.encodedId,
    })
  }

  const handleMarkContractDeclined = () => {
    handleDisableActions()
    markContractDeclined({
      quoteId: contract.encodedId,
      groupId: group.encodedId,
    })
  }

  const isCompleted = () => {
    return ["Signed", "Completed In House", "Canceled"].includes(
      contract.status
    )
  }

  const isDeclined = () => {
    return ["Voided", "Boater Declined"].includes(contract.status)
  }

  const renderActionButton = () => {
    if (tab === "unsent" || tab === "outstanding") {
      return (
        <OverflowMenu
          menuButtonLabel="Actions"
          menuButtonVariant="ghost"
          disabled={
            (menuDisabled &&
              currentContract.encodedId === contract.encodedId) ||
            statusPending
          }
        >
          {contract.status === "Failed" ? (
            <>
              <OverflowMenu.Item label="Retry" onClick={handleRetryContract} />
              {canUpdate && (
                <>
                  <OverflowMenu.Item
                    label="Edit"
                    variant="link"
                    href={contract.editUrl}
                  />
                  <OverflowMenu.Item
                    label="Void"
                    tooltipText="Invalidates the contract so that it can't be signed."
                    tooltipVariant="dark"
                    onClick={handleExpireContract}
                  />
                </>
              )}
            </>
          ) : (
            canUpdate && (
              <>
                <OverflowMenu.Item
                  label="Edit"
                  variant="link"
                  href={contract.editUrl}
                />
                {contract.sendable && (
                  <OverflowMenu.Item
                    label={tab === "unsent" ? "Send" : "Resend"}
                    onClick={() => {
                      setCurrentAction("send")
                      setIsContractActionsModalOpen(true)
                    }}
                  />
                )}
                <OverflowMenu.Item
                  label="Contract PDF"
                  variant="link"
                  href={contract.generatePdfEndpoint}
                />
                <OverflowMenu.Item
                  label="Complete in house"
                  variant="link"
                  href={contract.completeInHouseUrl}
                />
                {tab === "outstanding" && (
                  <OverflowMenu.Item
                    label="Mark as declined"
                    onClick={handleMarkContractDeclined}
                  />
                )}
                <OverflowMenu.Item
                  label="Void"
                  tooltipText="Invalidates the contract so that it can't be signed."
                  tooltipVariant="dark"
                  onClick={handleExpireContract}
                />
              </>
            )
          )}
        </OverflowMenu>
      )
    } else {
      if (isCompleted()) {
        return (
          <a
            className="btn btn-ghost px-3 py-1 no-underline hover:bg-blue-50 hover:text-blue-700"
            href={contract.contractUrl}
          >
            Contract
          </a>
        )
      } else if (isDeclined()) {
        return (
          <Button
            variant="ghost"
            onClick={() => {
              setCurrentAction("delete")
              setIsContractActionsModalOpen(true)
            }}
            disabled={
              menuDisabled && currentContract.encodedId === contract.encodedId
            }
          >
            Delete
          </Button>
        )
      }
    }
  }

  return (
    <div>
      {statusPending ? (
        <Tooltip
          text="Please wait while the contract is prepared"
          variant="dark"
          placement="top"
        >
          {renderActionButton()}
        </Tooltip>
      ) : (
        renderActionButton()
      )}
      <ContractActionsModal
        action={currentAction}
        contract={contract}
        handleDisableActions={handleDisableActions}
        handleEnableActions={handleEnableActions}
        isOpen={isContractActionsModalOpen}
        setIsOpen={setIsContractActionsModalOpen}
        tab={tab}
      />
    </div>
  )
}

ContractActions.propTypes = {
  contract: PropTypes.shape({
    completeInHouseUrl: PropTypes.string.isRequired,
    contractUrl: PropTypes.string,
    editUrl: PropTypes.string.isRequired,
    encodedId: PropTypes.string.isRequired,
    generatePdfEndpoint: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sendable: PropTypes.bool.isRequired,
  }).isRequired,
  statusPending: PropTypes.bool.isRequired,
  menuDisabled: PropTypes.bool.isRequired,
  setMenuDisabled: PropTypes.func.isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
}

export default ContractActions
