import React, { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"

import { restoreContractGroup } from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"

import {
  INITIAL_TAB_ORDER,
  INITIAL_TAB_ORDER_WITH_TEMPLATES,
  TABS,
} from "../constants"
import Actions from "./Actions"
import {
  AddItemsTab,
  DocumentsTab,
  InstallmentsTab,
  ItineraryTab,
  PaymentMethodTab,
  RateTab,
  TemplatesTab,
} from "./contract_group_tabs"
import { ContractGroupContext } from "./index"

const ContractGroupTabLayout = () => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { canUpdate, group } = useContext(ContractGroupContext)
  const initialTabs = group.withTemplate
    ? INITIAL_TAB_ORDER_WITH_TEMPLATES
    : INITIAL_TAB_ORDER

  const getTab = (tab) => {
    switch (tab) {
      case TABS.installments:
        return <InstallmentsTab />
      case TABS.itinerary:
        return <ItineraryTab />
      case TABS.addItems:
        return <AddItemsTab />
      case TABS.rate:
        return <RateTab />
      case TABS.paymentMethod:
        return <PaymentMethodTab />
      case TABS.templatesDocuments:
        return <TemplatesTab />
      case TABS.documents:
      case TABS.additionalSettings:
        return <DocumentsTab />
    }
  }

  const renderTabs = () => {
    return initialTabs.map((tab) => {
      return <div key={tab}>{getTab(tab)}</div>
    })
  }

  const { mutate: restoreGroup, isLoading } = useMutation(
    (data) => {
      return restoreContractGroup(data)
    },
    {
      onSuccess: () => {
        showToast(`${name} successfully restored!`, { type: "success" })
        queryClient.invalidateQueries("contractGroups")
        window.location.reload()
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const renderActions = () => {
    if (canUpdate) {
      if (group.deletedAt) {
        return (
          <div className="flex pt-2">
            <Button
              onClick={() => {
                restoreGroup(group.encodedId)
              }}
              fullWidth
              isLoading={isLoading}
            >
              Restore
            </Button>
          </div>
        )
      } else {
        return <Actions />
      }
    }
  }

  return (
    <>
      <div className="p-6">
        <h3 className="my-1 font-semibold">{group.name}</h3>
        {renderActions()}
      </div>
      {renderTabs()}
    </>
  )
}

export default ContractGroupTabLayout
