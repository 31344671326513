import { formPostApi, mutateApi } from "src/utils/api/ApiMethods"

export const updateClaimOnboardingStarted = async (marinaSlug) => {
  const url = `/manage/${marinaSlug}/onboarding/update_claim_onboarding_started`
  return mutateApi({ url, method: "POST" })
}

export const updateAmenities = async (marinaSlug, data) => {
  const url = `/manage/${marinaSlug}/onboarding/update_amenities`
  return mutateApi({ url, method: "PATCH", data: data })
}

export const createMarinaUser = async (marinaSlug, data) => {
  const url = `/manage/${marinaSlug}/onboarding/create_marina_user`
  return mutateApi({ url, method: "POST", data: data })
}

export const createPhoto = async (marinaSlug, data) => {
  const url = `/manage/${marinaSlug}/onboarding/create_photo`

  // Create a FormData object for multipart upload
  const formData = new FormData()

  // Append the photo file to the FormData
  if (data.photo) {
    formData.append("photo", data.photo)
  }

  // Append any other data fields
  Object.keys(data).forEach((key) => {
    if (key !== "photo") {
      formData.append(key, data[key])
    }
  })

  return formPostApi({
    url,
    data: formData,
  })
}

export const publishMarina = async (marinaSlug) => {
  const url = `/manage/${marinaSlug}/onboarding/publish_marina`
  return mutateApi({ url, method: "POST" })
}
