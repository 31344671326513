import React, { useContext, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { POSContext } from "src/main/PointOfSale/Layout"

import Button from "src/components/Button"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { openCashDrawer } from "src/api/PointOfSale/sales"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import ChangePrinterModal from "../Checkout/ChangePrinterModal"
import { useSelectedDefaultPrinterId } from "../hooks/useSelectedDefaultPrinterId"

const CashDrawerToggle = () => {
  const { receiptPrinters } = useContext(POSContext)

  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()

  const [showChangePrinterModal, setShowChangePrinterModal] = useState(false)
  const [defaultPrinterId] = useSelectedDefaultPrinterId()

  const { mutate: openDrawer, isLoading } = useMutation({
    mutationFn: (body) => openCashDrawer(body),
    onSuccess: () => {
      showToast("Opened cash drawer", { type: "success" })
    },
    onError: () => {
      showToast("Failed to open cash drawer", { type: "error" })
    },
  })

  const renderButton = ({ disabled = false } = {}) => {
    return (
      <Button
        variant="tertiary"
        onClick={onToggleCashDrawerClick}
        isLoading={isLoading}
        disabled={disabled}
      >
        Open Cash Drawer
      </Button>
    )
  }

  const renderToggleCashDrawer = () => {
    if (receiptPrinters.some((printer) => printer.hasCashDrawer)) {
      return (
        <MaybeRestrictedTooltip
          allowedRoles={[]}
          text="Choose a default printer with a cash drawer enabled"
          placement="bottom"
          restricted={!selectedPrinter?.hasCashDrawer}
        >
          {renderButton({ disabled: !selectedPrinter?.hasCashDrawer })}
        </MaybeRestrictedTooltip>
      )
    } else {
      return null
    }
  }

  const onToggleCashDrawerClick = () => {
    if (selectedPrinter?.hasCashDrawer) {
      openDrawer({ manage_id: marinaSlug, id: selectedPrinter.id })
    }
  }

  const selectedPrinter = useMemo(() => {
    return receiptPrinters.find((printer) => printer.id === defaultPrinterId)
  }, [defaultPrinterId, receiptPrinters])

  return (
    <>
      {renderToggleCashDrawer()}
      {showChangePrinterModal && (
        <ChangePrinterModal onClose={() => setShowChangePrinterModal(false)} />
      )}
    </>
  )
}

export default CashDrawerToggle
