import React, { useContext } from "react"
import { MapProvider } from "react-map-gl/mapbox"
import { BoatDetailContext } from "src/main/Account/Boats/BoatDetails"
import HomeportCard from "src/main/Account/Boats/HomeportCard"
import InsuranceCard from "src/main/Account/Boats/InsuranceCard"
import RegistrationCard from "src/main/Account/Boats/RegistrationCard"
import {
  renderBoatDetails,
  renderMissingDetailsSection,
} from "src/main/Account/Boats/SharedMethods"

import Button from "src/components/Button"
import Card from "src/components/Card"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const DesktopBoatDetailContainer = () => {
  const {
    boat,
    setIsRemoveModalOpen,
    setIsEditDetailsModalOpen,
    setIsInsuranceModalOpen,
    setIsRegistrationModalOpen,
    setIsPhotoModalOpen,
    photoUrl,
  } = useContext(BoatDetailContext)
  const renderActions = () => (
    <div
      className="absolute bottom-2 right-2 flex cursor-pointer items-center justify-center space-x-1 rounded-full border border-gray-600 bg-white px-2 py-1 transition ease-in-out hover:scale-105"
      onClick={() => setIsPhotoModalOpen(true)}
      data-testid="edit-boat-photo"
    >
      <i className="icon icon-camera-inverse text-xl text-gray-600" />
      <span className="font-semibold">Edit</span>
    </div>
  )

  const renderImage = () => {
    if (photoUrl) {
      return (
        <div className="relative h-52 w-full overflow-hidden rounded-t border-l border-r border-t">
          <img
            src={photoUrl}
            alt={boat.name}
            className="h-full w-full rounded-t object-cover"
          />
          {renderActions()}
        </div>
      )
    } else {
      return (
        <div
          className="relative flex h-52 cursor-pointer select-none flex-col items-center justify-center rounded-t border-l border-r border-t bg-blue-50 text-blue-400 hover:brightness-90"
          onClick={() => setIsPhotoModalOpen && setIsPhotoModalOpen(true)}
        >
          <i className="icon icon-md-directions-boat text-5xl" />
          <span className="text-lg font-semibold">Add Photo</span>
        </div>
      )
    }
  }

  const renderBoatCard = () => (
    <Card renderImage={renderImage} withHover={false}>
      <div
        className="flex w-full cursor-pointer flex-col rounded-b border p-4 hover:border-blue-700 hover:bg-gray-100"
        onClick={() => setIsEditDetailsModalOpen(true)}
      >
        {renderBoatDetails(boat, true)}
        {renderMissingDetailsSection(boat.incompleteBoatProfileData)}
      </div>
    </Card>
  )

  return (
    <div className="container m-0 w-full px-2 xl:mx-auto xl:max-w-screen-xl">
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12">
          <a
            href="/account/boats"
            className="btn btn-ghost -ml-4 flex w-min items-center space-x-2 no-underline"
          >
            <i className="icon icon-arrow-left text-xs" />
            <span className="text-sm">Back to all boats</span>
          </a>
        </div>
        <div className="col-span-12 flex items-center justify-between">
          <HeadingPageTitle>Boat Details</HeadingPageTitle>
          <Button onClick={() => setIsRemoveModalOpen(true)}>Delete</Button>
        </div>
        <div className="col-span-4 h-min">{renderBoatCard()}</div>
        <div className="col-span-8 grid h-min grid-cols-12 gap-8">
          <div className="col-span-6 m-0 h-full">
            <InsuranceCard
              insurance={boat.insurance}
              setIsInsuranceModalOpen={setIsInsuranceModalOpen}
              boatId={boat.encodedId}
            />
          </div>
          <div className="col-span-6 h-full">
            <RegistrationCard
              registration={boat.registration}
              setIsRegistrationModalOpen={setIsRegistrationModalOpen}
              boatId={boat.encodedId}
            />
          </div>
          <div className="col-span-12">
            <MapProvider>
              <HomeportCard boatId={boat.encodedId} />
            </MapProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopBoatDetailContainer
