import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Badge from "src/components/Badge"

import { WizardContext } from "../../../WizardContextProvider"
import { getDiscountBadgeAttributes } from "./helpers"

const FullStayDiscountsTable = () => {
  const { handleRemoveDiscount } = useContext(WizardContext)
  const { watch } = useFormContext()

  const [discounts] = watch(["discounts"])

  const renderDiscount = (discount) => {
    const [badgeIcon, badgeText] = getDiscountBadgeAttributes(discount)

    return (
      <Badge
        color="teal"
        icon={badgeIcon}
        text={badgeText}
        onDismiss={() => handleRemoveDiscount(discount)}
      />
    )
  }

  return (
    <div className="flex flex-wrap gap-2 pt-2">
      {discounts.map(renderDiscount)}
    </div>
  )
}

export default FullStayDiscountsTable
