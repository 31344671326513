import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import InvoiceSettled from "src/main/Account/PaymentPortal/InvoiceSettled"

import Button from "src/components/Button"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"
import SelectPaymentMethod from "src/components/SelectPaymentMethod/SelectPaymentMethod"

import { getInvoice, payInvoice } from "src/api/Account/PaymentPortal"

import { useToast } from "src/hooks/use_toast"

const PayInvoice = ({
  combinedInvoice,
  paymentMethods: initialPaymentMethods,
  acceptedPaymentMethods,
}) => {
  const {
    displayId,
    totalAmount,
    totalAmountDollars,
    settledStatus,
    detailsPath,
    marinaName,
    reservationId,
    parentRecordType,
    saleId,
    returnPath,
  } = combinedInvoice

  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods)
  const [paymentMethod, setPaymentMethod] = useState("")
  const [invoiceSettled, setInvoiceSettled] = useState(
    settledStatus === "settled"
  )
  const [invoiceProcessing, setInvoiceProcessing] = useState(
    settledStatus === "processing"
  )
  const showToast = useToast()

  useQuery(
    ["invoiceStatus", displayId],
    () => getInvoice({ invoiceId: displayId }),
    {
      enabled: invoiceProcessing,
      refetchInterval: 3000,
      onSuccess: (data) => {
        if (data.settledStatus === "settled") {
          setInvoiceProcessing(false)
          setInvoiceSettled(true)
        }
      },
    }
  )

  const { mutate: makePayment, isLoading: makingPayment } = useMutation(
    () =>
      payInvoice({
        invoiceId: displayId,
        paymentMethod,
        reservationId: reservationId,
        saleId: saleId,
        amount: totalAmount,
      }),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        setInvoiceProcessing(true)
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
        setInvoiceProcessing(false)
      },
    }
  )

  const renderInvoiceDetails = () => (
    <div className="card flex flex-col">
      <span className="font-semibold">{marinaName}</span>
      <span>
        {parentRecordType} #{saleId || reservationId}
      </span>
      <div className="mt-4 flex flex-col">
        <a href={detailsPath} className="font-semibold">
          View Invoice
        </a>
        <span className="text-gray-600">Reference: {displayId}</span>
      </div>
    </div>
  )

  const renderInvoiceAmount = () => (
    <div className="card flex flex-col space-y-4 font-semibold">
      <span className="text-base">Amount</span>
      <span className="text-lg">{totalAmountDollars}</span>
    </div>
  )

  const renderPaymentMethodSection = () => (
    <div className="card">
      <SelectPaymentMethod
        marinaName={marinaName}
        acceptedPaymentMethods={acceptedPaymentMethods}
        paymentMethods={paymentMethods}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        setPaymentMethods={setPaymentMethods}
      />
    </div>
  )

  const renderButtons = () => (
    <div className="flex flex-col space-y-2">
      <Button
        variant="primary"
        disabled={!paymentMethod || paymentMethod === "add"}
        onClick={makePayment}
        isLoading={makingPayment}
      >
        {makingPayment ? "Paying" : "Pay Now"}
      </Button>
      <a href={returnPath} className="btn btn-secondary">
        Cancel
      </a>
    </div>
  )

  if (invoiceSettled || invoiceProcessing) {
    return (
      <InvoiceSettled
        combinedInvoice={combinedInvoice}
        paymentMethods={paymentMethods}
        paymentMethod={paymentMethod}
        invoiceProcessing={invoiceProcessing}
      />
    )
  } else {
    return (
      <div className="flex flex-col space-y-4 py-10">
        <HeadingPageTitle>Make a payment</HeadingPageTitle>
        {renderInvoiceDetails()}
        {renderInvoiceAmount()}
        {renderPaymentMethodSection()}
        {renderButtons()}
      </div>
    )
  }
}

PayInvoice.propTypes = {
  combinedInvoice: PropTypes.shape({
    displayId: PropTypes.string.isRequired,
    totalAmount: PropTypes.string.isRequired,
    totalAmountDollars: PropTypes.string.isRequired,
    settledStatus: PropTypes.string.isRequired,
    detailsPath: PropTypes.string.isRequired,
    marinaName: PropTypes.string.isRequired,
    reservationId: PropTypes.string,
    parentRecordType: PropTypes.string.isRequired,
    saleId: PropTypes.string,
    returnPath: PropTypes.string.isRequired,
  }).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stripePaymentMethodId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired
  ),
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"]).isRequired
  ).isRequired,
}

export default PayInvoice
