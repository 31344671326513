import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { createStop, updateStop } from "src/api/CruisePlan/mutateStop"

import { useTracker } from "src/hooks/use_tracker"

import { titlecase } from "src/utils/string_helpers"

import ReservationStopSelector from "./ReservationStopSelector"
import TravelPlans from "./TravelPlans"

const ReservationStopForm = ({ cruiseStop, upcomingReservations }) => {
  const [reservation, setReservation] = useState(cruiseStop.reservation)
  const tracker = useTracker()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: cruiseStop.note,
      nauticalMiles: cruiseStop.nauticalMiles,
      knots: cruiseStop.knots,
    },
  })

  const { mutate } = useMutation({
    mutationFn: (data) => {
      data.reservation_encoded_id = reservation.encodedId
      data.nautical_miles = data.nauticalMiles
      if (cruiseStop.id) {
        return updateStop({
          type: cruiseStop.type,
          cruisePlanId: cruiseStop.cruisePlanId,
          id: cruiseStop.id,
          data: data,
        })
      } else {
        return createStop({
          type: cruiseStop.type,
          cruisePlanId: cruiseStop.cruisePlanId,
          data: data,
        })
      }
    },
    onSuccess: (reponse) => {
      tracker.trackEvent("cruise_plans:reservation_stops:saved", {
        experience_name: "cruise_plans",
      })
      window.location.href = reponse.redirect_url
    },
    onError: (reponse) => {
      alert(reponse.errors)
    },
  })

  const onSubmit = (data) => {
    mutate(data)
  }

  const renderForm = () => {
    return (
      <>
        <div className="relative mb-4 flex gap-4 rounded border p-4">
          <button
            className="btn-sm absolute right-2 top-0 cursor-pointer bg-white p-2 hover:bg-gray-100"
            onClick={() => setReservation(null)}
          >
            x
          </button>
          <img
            alt={reservation.marinaName}
            className="size-24 rounded"
            src={reservation.marinaImageUrl}
          />
          <div className="flex flex-col items-start">
            <h2 className="m-0 text-lg font-semibold">
              {reservation.marinaName}
            </h2>
            <span className="rounded bg-blue-100 px-2 py-0.5 font-bold text-blue-900">
              {titlecase(reservation.status)}
            </span>
            <div>{reservation.readableDateRange}</div>
            <span>{reservation.readableBoatName}</span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div>
              <Form.Label htmlFor="note">Note</Form.Label>
              <Form.Textarea
                id="note"
                {...register(`note`)}
                hasErrors={!!errors?.note}
              />
              {errors?.note && <Form.Error>{errors?.note?.message}</Form.Error>}
            </div>
          </div>

          <TravelPlans
            fromCruiseStopName={cruiseStop.fromCruiseStopName}
            fromCruiseStopEndDate={cruiseStop.fromCruiseStopEndDate}
            register={register}
            errors={errors}
            watch={watch}
          />

          <div className="mt-4">
            <Button variant="primary" type="submit">
              {cruiseStop.id
                ? "Update Reservation Stop"
                : "Add Reservation Stop"}
            </Button>
          </div>
        </form>
      </>
    )
  }

  const renderReservationSelectorForm = () => {
    return (
      <ReservationStopSelector
        upcomingReservations={upcomingReservations}
        setReservation={setReservation}
      />
    )
  }

  return (
    <div className="container max-w-5xl">
      <a
        href={`/account/cruise_plans/${cruiseStop.cruisePlanId}`}
        className="text-link"
      >
        ← Back to cruise plan
      </a>
      <div className="card mt-4">
        <h1 className="my-0 text-xl font-bold">Add a Reservation</h1>
        <p className="mb-4">Select a reservation along your cruise.</p>
        {!reservation ? renderReservationSelectorForm() : renderForm()}
      </div>
    </div>
  )
}

ReservationStopForm.propTypes = {
  cruiseStop: PropTypes.shape({
    id: PropTypes.string,
    cruisePlanId: PropTypes.string.isRequired,
    reservation: PropTypes.object,
    note: PropTypes.string,
    type: PropTypes.string,
    fromCruiseStopName: PropTypes.string,
    fromCruiseStopEndDate: PropTypes.string,
    nauticalMiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    knots: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  upcomingReservations: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ReservationStopForm
