import { isValidPhoneNumber } from "libphonenumber-js"

import AdditionalInformation from "./AdditionalInformation"
import DocusealSigningForm from "./DocusealSigningForm"
import InsuranceAndRegistration from "./InsuranceAndRegistration"
import SelectPaymentMethod from "./SelectPaymentMethod"
import SignAndComplete from "./SignAndComplete"
import ViewContractDetails from "./ViewContractDetails"

const ReviewStep = {
  title: "Review",
  key: "review",
  validate: (state) => state.contractDetailsSeen,
  component: ViewContractDetails,
}

const PayStep = {
  title: "Pay",
  key: "pay",
  validate: (state) => {
    if (state.paymentMethod === "manual" || state.paymentType === "manual") {
      return state.paymentType === "manual" && state.paymentMethod === "manual"
    }
    return (
      state.paymentMethod !== "" &&
      state.paymentMethod !== "add" &&
      state.paymentType === "credit"
    )
  },
  component: SelectPaymentMethod,
}

const InsuranceAndRegistrationStep = (quote) => ({
  title: "Insurance & Registration",
  key: "required",
  validate: (state) => {
    const {
      requiredInformation: { phone, registration, insurance },
    } = state

    const {
      registrationRequired,
      insuranceRequired,
      phoneInvalid,
      insuranceRequirement,
      registrationRequirement,
    } = quote

    let valid = true

    if (phoneInvalid) {
      valid = valid && isValidPhoneNumber(phone, "US")
    }

    if (registrationRequired) {
      const { registrationNumber, expirationDate } = registration
      valid = valid && registrationNumber !== "" && expirationDate !== null
    }

    if (registrationRequired && registrationRequirement === "info_and_upload") {
      const { document } = registration
      valid = valid && document === true
    }

    if (insuranceRequired) {
      const { companyName, policyNumber, expirationDate } = insurance
      valid =
        valid &&
        companyName !== "" &&
        policyNumber !== "" &&
        expirationDate !== null
    }

    if (insuranceRequired && insuranceRequirement === "info_and_upload") {
      const { document } = insurance
      valid = valid && document === true
    }

    return valid
  },
  component: InsuranceAndRegistration,
})

const AdditionalInformationStep = (quote) => {
  return {
    title: "Additional Information",
    key: "additional",
    validate: (state) => {
      const { additionalInformation } = state
      const { contractSigningEnablements } = quote

      return contractSigningEnablements
        .filter((enablement) => enablement.required)
        .every((enablement) => {
          const { customFieldDefinitionId } = enablement
          return (
            additionalInformation[customFieldDefinitionId] &&
            additionalInformation[customFieldDefinitionId] !== ""
          )
        })
    },
    component: AdditionalInformation,
  }
}

const SignAndCompleteStep = {
  title: "Sign & Complete",
  key: "sign",
  validate: (state) => {
    const { signature, dockwaTermsAccepted, marinaTermsAccepted } = state
    return signature !== "" && dockwaTermsAccepted && marinaTermsAccepted
  },
  component: SignAndComplete,
}

const DocusealSignAgreementStep = {
  title: "Sign Agreement",
  key: "sign",
  validate: (state) => state.docusealFormCompleted || false,
  component: DocusealSigningForm,
}

const shouldShowInsuranceAndRegistrationStep = (quote) => {
  const { registrationRequired, insuranceRequired, phoneInvalid } = quote

  return registrationRequired || insuranceRequired || phoneInvalid
}

const shouldShowAdditionalInformationStep = (quote) => {
  const { contractSigningEnablements } = quote
  return contractSigningEnablements && contractSigningEnablements.length > 0
}

const getSteps = (quote, withTemplate) => {
  const steps = [ReviewStep, PayStep]

  if (shouldShowInsuranceAndRegistrationStep(quote)) {
    steps.push(InsuranceAndRegistrationStep(quote))
  }

  if (shouldShowAdditionalInformationStep(quote)) {
    steps.push(AdditionalInformationStep(quote))
  }

  if (withTemplate) {
    steps.push(DocusealSignAgreementStep)
  } else {
    steps.push(SignAndCompleteStep)
  }

  return steps
}

export default getSteps
