import React, { useContext, useState } from "react"

import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import { useTracker } from "src/hooks/use_tracker"

import ArchiveGroupModal from "./ArchiveGroupModal"
import DuplicateGroupModal from "./DuplicateGroupModal"
import { ContractGroupContext } from "./index"

const Actions = () => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false)
  const tracker = useTracker()

  const {
    archivedGroupsPagePath,
    canUpdate,
    group,
    marinaName,
    templates,
    templatesPath,
  } = useContext(ContractGroupContext)

  const { entry } = group

  const handleDuplicateGroup = () => {
    tracker.trackEvent("contracts_v2:group_duplicated", {
      contract_group_name: group.name,
      from_groups_list: false,
      marina_id: group.marinaId,
      marina_name: marinaName,
    })
    window.location.href = entry?.duplicatePath
  }

  const renderDuplicateActions = () => {
    if (group.withTemplate) {
      return (
        <OverflowMenu.Item
          label="Duplicate"
          onClick={handleDuplicateGroup}
          disabled={!canUpdate || Boolean(entry?.creationForbiddenMessage)}
        />
      )
    } else {
      return (
        <OverflowMenu.Item
          label="Duplicate"
          onClick={() => {
            setIsDuplicateModalOpen(true)
          }}
          disabled={!canUpdate}
        />
      )
    }
  }

  const renderOverflowMenu = () => (
    <OverflowMenu menuButtonLabel="Actions">
      <OverflowMenu.Item
        label="Edit"
        variant="link"
        href={entry?.editPath}
        disabled={!canUpdate || Boolean(entry?.creationForbiddenMessage)}
      />
      {renderDuplicateActions()}
      <OverflowMenu.Item
        label="Archive"
        onClick={() => {
          setIsArchiveModalOpen(true)
        }}
        disabled={!canUpdate}
      />
    </OverflowMenu>
  )

  const renderAddNewLink = () => {
    if (entry?.creationForbiddenMessage) {
      return (
        <Tooltip
          text={entry?.creationForbiddenMessage}
          placement="top"
          variant="dark"
          maxWidth="200px"
        >
          <a
            className="btn btn-primary pointer-events-none cursor-not-allowed opacity-40"
            href={entry?.addNewPath}
          >
            Add New
          </a>
        </Tooltip>
      )
    } else {
      return (
        <a className="btn btn-primary" href={entry?.addNewPath}>
          Add New
        </a>
      )
    }
  }

  return (
    <div className="mt-5 flex justify-between">
      {renderOverflowMenu()}
      {renderAddNewLink()}
      <ArchiveGroupModal
        archivedGroupsPagePath={archivedGroupsPagePath}
        group={group}
        isOpen={isArchiveModalOpen}
        marinaName={marinaName}
        setIsOpen={setIsArchiveModalOpen}
      />
      <DuplicateGroupModal
        duplicateGroupUrl={entry?.duplicatePath}
        group={group}
        isOpen={isDuplicateModalOpen}
        marinaName={marinaName}
        setIsOpen={setIsDuplicateModalOpen}
        templates={templates}
        templatesPath={templatesPath}
      />
    </div>
  )
}

export default Actions
