import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Table from "src/components/Table"

import AmountField from "./AmountField"
import AmountTypeSelect from "./AmountTypeSelect"
import DueDatePicker from "./DueDatePicker"

const InstallmentsTable = ({ fields }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const installmentDistributionType = watch("installmentDistributionType")

  return (
    <Table autoColumnWidth fullHeight>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Cell columnWidth="30%">Due</Table.Head.Cell>
          <Table.Head.Cell columnWidth="30%" />
          <Table.Head.Cell>Amount type</Table.Head.Cell>
          <Table.Head.Cell columnWidth="25%">Amount</Table.Head.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body>
        {fields.map(({ id, ...field }, index) => {
          const shouldShowTooltip =
            installmentDistributionType === "even" &&
            field.amountType !== "dollars" &&
            index !== 0 &&
            fields[0].amountType !== "percent"
          return (
            <Table.Row key={`${id}-${index}`}>
              <Table.Cell>
                {/* Due on Signature VS Date dropdown */}
                <Form.Select
                  id={id}
                  disabled={index !== 0}
                  {...register(`contractInstallments.${index}.dueOnSignature`, {
                    setValueAs: (val) => {
                      // select field values can only be strings, but we want
                      // to maintain boolean values in the form
                      if (typeof val === "string") {
                        return val === "true"
                      }
                      return val
                    },
                  })}
                >
                  <option value={true}>On Signature</option>
                  <option value={false}>Date</option>
                </Form.Select>
                {errors?.contractInstallments &&
                errors?.contractInstallments[index] &&
                errors?.contractInstallments[index]?.dueOnSignature ? (
                  <Form.Error>
                    {errors.contractInstallments[index].dueOnSignature.message}
                  </Form.Error>
                ) : null}
              </Table.Cell>
              <Table.Cell>
                {/* Due Date Picker */}
                <DueDatePicker field={field} index={index} />
              </Table.Cell>
              <Table.Cell>
                {/* Amount Type Dropdown */}
                <AmountTypeSelect
                  index={index}
                  fieldId={id}
                  installmentQuantity={fields.length}
                />
              </Table.Cell>
              <Table.Cell>
                <div className="flex flex-row items-center">
                  {/* Amount Text Field */}
                  <AmountField
                    index={index}
                    fieldId={id}
                    field={field}
                    installmentQuantity={fields.length}
                  />
                  <label
                    className={`font-semibold ${
                      shouldShowTooltip ? "visible" : "hidden"
                    }`}
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-content="% of remainder (after on-signature $ amount)"
                  >
                    <i className="icon icon-md-info text-muted ml-3" />
                  </label>
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

InstallmentsTable.propTypes = {
  fields: PropTypes.array.isRequired,
}

export default InstallmentsTable
