import React from "react"
import { useFormContext } from "react-hook-form"

import {
  CUSTOM_RATE_ID,
  NO_PRICING_STRUCTURE_CHOSEN_VALUE,
} from "../../../constants"

const PriceEstimateHelpText = () => {
  const { watch } = useFormContext()

  const [arrival, departure, rateId, ratePricingStructure] = watch([
    "arrival",
    "departure",
    "rate.id",
    "rate.pricing_structure",
  ])

  const missingDates = !arrival || !departure
  const missingPricingStructure =
    rateId === CUSTOM_RATE_ID &&
    ratePricingStructure === NO_PRICING_STRUCTURE_CHOSEN_VALUE

  const missingDatesText = "Select arrival and departure dates"
  const missingPricingStructureText =
    "Select a pricing structure for your custom rate"

  const renderHelpText = (helpText) => {
    return (
      <div className="flex flex-row items-center space-x-4">
        <i role="icon" className="icon icon-warning-outline text-lg" />
        <span>{helpText}</span>
      </div>
    )
  }
  return missingDates || missingPricingStructure ? (
    <div className="flex w-full grow flex-col space-y-4 text-xs text-gray-700">
      <span>To see your pricing estimate:</span>
      {missingDates ? renderHelpText(missingDatesText) : null}
      {missingPricingStructure
        ? renderHelpText(missingPricingStructureText)
        : null}
    </div>
  ) : null
}

export default PriceEstimateHelpText
