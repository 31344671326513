import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"

import { updateGeneralLedgerCodeMapping } from "src/api/Accounting"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const MappingExternalField = ({ mapping }) => {
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()
  const [selectValue, setSelectValue] = useState(mapping.generalLedgerCodeId)
  const showToast = useToast()

  const handleUpdateError = (error) => {
    showToast(error.toString(), { type: "error", duration: 5 })
  }

  const handleUpdateSuccess = (data, value) => {
    showToast(data.message.toString(), { type: "success", duration: 3 })
  }

  const { mutateAsync } = useMutation((data) =>
    updateGeneralLedgerCodeMapping(marinaSlug, data)
  )

  const handleMappingUpdate = (event) => {
    queryClient.invalidateQueries([
      "accounting",
      "mapping-tab",
      mapping.categoryKey,
    ])
    mutateAsync({
      categories: { [mapping.categoryId]: event.target.value },
      type: mapping.categoryKey,
    })
      .then((data) => handleUpdateSuccess(data, event))
      .catch((error) => handleUpdateError(error))

    setSelectValue(event.target.value)
  }

  return (
    <Form.Select
      value={selectValue || ""}
      name={mapping.displayName}
      onChange={handleMappingUpdate}
    >
      <option value="">No Mapping</option>
      {mapping.codeSelectOptions}
    </Form.Select>
  )
}

MappingExternalField.propTypes = {
  mapping: PropTypes.shape({
    categoryKey: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
    codeSelectOptions: PropTypes.object.isRequired,
    generalLedgerCodeId: PropTypes.number.isRequired,
  }),
}

export default MappingExternalField
