import { format, isToday, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"

import Badge from "src/components/Badge"
import Loader from "src/components/Loader"
import Pagination from "src/components/Pagination"

import { queryInquiries } from "src/api/Account/Inquiries"

import { useTracker } from "src/hooks/use_tracker"

import { humanize } from "src/utils/string_helpers"
import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

const Inquiries = ({ items, page, pageCount, totalCount }) => {
  const tracker = useTracker()
  const [currentPage, setCurrentPage] = useState(page)

  useEffect(() => {
    tracker.trackEvent("inquiry_detail:inquiry_portal_screen_view")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: inquiries, isFetching } = useQuery(
    ["inquiries", currentPage],
    () => queryInquiries(currentPage),
    {
      initialData: { items, page, pageCount, totalCount },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      refetchInterval: 300 * 1000,
      keepPreviousData: true,
    }
  )

  const handlePageChange = (page) => {
    setCurrentPage(page.selected)
    updateUrlQueryParams({ page: page.selected })
  }

  const timeOrDate = (date) => {
    const parsedDate = parseISO(date)
    console.log(parsedDate, isToday(parsedDate), new Date())
    if (isToday(parsedDate)) {
      return format(parsedDate, "h:mm a")
    }
    return format(parsedDate, "MM/dd/yy")
  }

  const badgeForInquiryStatus = (status) => {
    switch (status) {
      case "pending":
        return "yellow"
      case "in_progress":
      case "accepted":
        return "blue"
      case "completed":
        return "teal"
      case "declined":
      case "canceled":
        return "red"
      case "expired":
        return "gray"
      default:
        return "gray"
    }
  }

  const renderEmptyState = () => (
    <div className="flex justify-center p-8">
      <div className="flex max-w-xl flex-col items-center justify-center">
        <i className="icon icon-mail text-5xl leading-none text-blue-700" />
        <div className="mt-0 text-center text-lg font-semibold text-gray-900">
          You haven&apos;t sent any inquiries
        </div>
        <p className="mb-0 mt-1 text-center text-gray-600">
          Make an inquiry to learn more about long-term dockage, storage, and
          other marina services.
        </p>
        <a href="/explore" className="btn btn-primary mt-4">
          Explore Marinas
        </a>
      </div>
    </div>
  )

  const renderInquiries = () => {
    return inquiries.items.map((inquiry, index) => {
      const fontStyleClass =
        inquiry.unreadMessagesForBoaterCount > 0
          ? "font-bold text-gray-900"
          : "text-gray-600"
      return (
        <div key={inquiry.encodedId}>
          <a className="no-underline" href={inquiry.detailPath}>
            <div className="flex h-24 w-full cursor-pointer flex-row justify-between rounded py-2 hover:bg-gray-100">
              <div className="w-1/6 flex-none p-1 md:w-1/12">
                <div className="flex items-center">
                  <div className="h-2 w-2">
                    {inquiry.unreadMessagesForBoaterCount > 0 && (
                      <div className="h-2 w-2 rounded-full bg-blue-600" />
                    )}
                  </div>
                  <img
                    className="ml-2 h-10 w-10 rounded-full"
                    alt={`Thumbnail image for ${inquiry.marina.name}`}
                    src={inquiry.marina.smallImageUrl}
                  />
                </div>
              </div>
              <div className="w-3/6 p-1 md:w-9/12">
                <div
                  className={"flex h-full flex-col items-start justify-between"}
                >
                  <div>
                    <div className={"font-semibold text-gray-900"}>
                      {inquiry.marina.name}
                    </div>
                    <div className={"text-xs text-gray-600"}>
                      {inquiry.marina.formattedAddress}
                    </div>
                  </div>
                  <div className={`w-full truncate ${fontStyleClass}`}>
                    {inquiry.displayText}
                  </div>
                </div>{" "}
              </div>
              <div className="w-2/6 flex-none p-1 md:w-2/12">
                <div
                  className={
                    "flex h-full flex-col items-end justify-between px-1"
                  }
                >
                  <div className={`text-xs ${fontStyleClass}`}>
                    {timeOrDate(inquiry.displayTimestamp)}
                  </div>
                  <Badge
                    color={badgeForInquiryStatus(inquiry.status)}
                    text={humanize(inquiry.status)}
                  />
                </div>
              </div>
            </div>
          </a>
          {index < inquiries.items.length - 1 && (
            <hr className="my-2 w-full border-b" />
          )}
        </div>
      )
    })
  }

  const renderContent = () => {
    if (isFetching) {
      return <Loader name="inquiries" />
    } else if (totalCount === 0) {
      return renderEmptyState()
    } else {
      return renderInquiries()
    }
  }

  return (
    <div className="pb-12">
      <h1 className="text-2xl font-bold">Inquiries</h1>
      <div className="py-8">{renderContent()}</div>
      {pageCount > 1 && (
        <Pagination
          numberOfPages={pageCount}
          onPageChange={handlePageChange}
          page={currentPage}
        />
      )}
    </div>
  )
}

Inquiries.propTypes = {
  items: PropTypes.array,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
}

export default Inquiries
