import Bugsnag from "@bugsnag/js"
import PropTypes from "prop-types"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"

import ErrorFallback from "./ErrorFallback"

const ErrorBoundaryProvider = ({
  children,
  fallbackComponent = ErrorFallback,
}) => {
  const ErrorBoundaryWrapper = window.bugsnagSettings
    ? Bugsnag.getPlugin("react").createErrorBoundary(React)
    : ErrorBoundary

  return (
    <ErrorBoundaryWrapper FallbackComponent={fallbackComponent}>
      {children}
    </ErrorBoundaryWrapper>
  )
}

ErrorBoundaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.node,
}
export default ErrorBoundaryProvider
