import PropTypes from "prop-types"
import React from "react"

import HeadingSectionHeader from "src/components/Heading/HeadingSectionHeader"

const SectionHeader = ({ label }) => {
  return (
    <div className="pb-2 pt-4">
      <HeadingSectionHeader>{label}</HeadingSectionHeader>
    </div>
  )
}

SectionHeader.propTypes = {
  label: PropTypes.string,
}

export default SectionHeader
