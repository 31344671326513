import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { COLOR_TO_BORDER_CLASS_MAP } from "../constants.js"
import { disabledKeyText, findQuickKey, quickKeyEnabled } from "../utils.js"
import DraggableQuickKey from "./DraggableQuickKey"
import DroppableCell from "./DroppableCell"
import MaybeDndContext from "./MaybeDndContext"
import QuickKey from "./QuickKey"

const QuickKeyGrid = ({
  quickKeys,
  onQuickKeyClick,
  onEmptyCellClick,
  EmptyCell,
  onRearrange,
  isNested,
  disableInactiveKeys = false,
  disableAllKeys = false,
  limitedQuickKeys = false,
}) => {
  return (
    <MaybeDndContext onRearrange={onRearrange}>
      <div className="w-full overflow-y-auto @container">
        <div
          className={classNames(
            {
              "ml-16": isNested,
              "mx-auto": !isNested,
            },
            "grid w-fit grid-cols-5 grid-rows-5"
          )}
          data-testid="quick-key-grid"
        >
          {Array.from({ length: 25 }).map((v, i) => {
            const x = i % 5
            const y = Math.floor(i / 5)
            const quickKey = findQuickKey(quickKeys, { x, y })

            const activeItemCount = quickKey
              ? // TODO: update BE to serialize quick keys in a uniform way
                quickKey.items.filter(
                  (item) => item.deletedAt === null || item.deleted_at === null
                ).length
              : 0
            const noActiveItems = quickKey && activeItemCount === 0
            const keyEnabled = quickKeyEnabled({ x, y, limitedQuickKeys })
            const disabled =
              (disableInactiveKeys && noActiveItems) ||
              disableAllKeys ||
              !keyEnabled

            return (
              <div
                className="m-2 @xs:size-18 @xs:text-xs @3xl:size-26 @3xl:text-base"
                key={`qk-${i}`}
              >
                <DroppableCell enabled={!!onRearrange} x={x} y={y}>
                  {({ isUnderDraggable }) =>
                    quickKey ? (
                      <DraggableQuickKey
                        id={`${quickKey.id}-${i}`}
                        x={x}
                        y={y}
                        enabled={!!onRearrange}
                      >
                        {({ isDragging }) => (
                          <QuickKey
                            id={quickKey.id}
                            disabled={disabled}
                            disabledText={disabledKeyText({
                              disableAllKeys,
                              keyEnabled,
                            })}
                            name={quickKey.name}
                            itemCount={activeItemCount}
                            color={quickKey.color}
                            onClick={() => onQuickKeyClick(quickKey)}
                            indicateDragInteraction={
                              isDragging || isUnderDraggable
                            }
                          />
                        )}
                      </DraggableQuickKey>
                    ) : (
                      <EmptyCell
                        onClick={() => onEmptyCellClick({ x, y })}
                        isunderdraggable={isUnderDraggable.toString()}
                        disabled={!keyEnabled}
                        x={x}
                        y={y}
                      />
                    )
                  }
                </DroppableCell>
              </div>
            )
          })}
        </div>
      </div>
    </MaybeDndContext>
  )
}

QuickKeyGrid.propTypes = {
  quickKeys: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      color: PropTypes.oneOf(Object.keys(COLOR_TO_BORDER_CLASS_MAP)).isRequired,
      name: PropTypes.string.isRequired,
      items: PropTypes.array,
    })
  ),
  onQuickKeyClick: PropTypes.func.isRequired,
  EmptyCell: PropTypes.elementType,
  onEmptyCellClick: PropTypes.func,
  onRearrange: PropTypes.func,
  disableInactiveKeys: PropTypes.bool,
  disableAllKeys: PropTypes.bool,
  isNested: PropTypes.bool,
  limitedQuickKeys: PropTypes.bool.isRequired,
}

QuickKeyGrid.defaultProps = {
  disableInactiveKeys: false,
  disableAllKeys: false,
  EmptyCell: "div",
  onEmptyCellClick: () => {},
  isNested: false,
  limitedQuickKeys: false,
}

export default QuickKeyGrid
