import React from "react"

import AlertBanner from "src/components/AlertBanner"

const AvailabilityErrorFallback = () => (
  <AlertBanner type="error">
    Something went wrong fetching availability. If the problem persists, please
    contact support at mayday@dockwa.com.
  </AlertBanner>
)

export default AvailabilityErrorFallback
