import { camelCaseToSnakecase, snakecaseToCamelCase } from "./string_helpers"

export const CAMEL_TO_SNAKE = "camelToSnake"
export const SNAKE_TO_CAMEL = "snakeToCamel"

export const CONVERSION_TYPES = Object.freeze({
  CAMEL_TO_SNAKE,
  SNAKE_TO_CAMEL,
})

const CONVERTERS = {
  [CAMEL_TO_SNAKE]: camelCaseToSnakecase,
  [SNAKE_TO_CAMEL]: snakecaseToCamelCase,
}

/**
 * Converts object keys using the specified conversion type
 * @param {Object|Array} obj - The object or array to convert
 * @param {string} conversionType - The type of conversion to perform (use CONVERSION_TYPES constants)
 * @param {Object} options - Optional configuration options
 * @returns {Object|Array} The converted object or array
 */
export const convertObjectKeys = (obj, conversionType, options = {}) => {
  if (obj === null || typeof obj !== "object") return obj

  const converter = CONVERTERS[conversionType]
  if (!converter) {
    throw new Error(`Unknown conversion type: ${conversionType}`)
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectKeys(item, conversionType, options))
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [
      converter(key),
      convertObjectKeys(val, conversionType, options),
    ])
  )
}

// Maintain backward compatibility
export const camelCaseToSnakeKeys = (obj) =>
  convertObjectKeys(obj, CAMEL_TO_SNAKE)
export const snakecaseToCamelKeys = (obj) =>
  convertObjectKeys(obj, SNAKE_TO_CAMEL)

export const deepCamelCaseToSnakeKeys = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  return Object.keys(obj).reduce((acc, key) => {
    acc[camelCaseToSnakecase(key)] = deepCamelCaseToSnakeKeys(obj[key])
    return acc
  }, {})
}
