import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { acceptToContractGroup } from "src/api/Inquiries"

import { useToast } from "src/hooks/use_toast"

const AcceptToContractGroupModal = ({
  isOpen,
  setIsOpen,
  contractGroups,
  selectedInquiry,
  contractGroup = "",
}) => {
  const { reset, control, setValue, getValues, watch } = useForm({
    defaultValues: { selectedContractGroup: "" },
  })
  const showToast = useToast()
  const queryClient = useQueryClient()
  const handleClose = () => {
    setIsOpen(false)
    reset()
  }

  useEffect(() => {
    if (contractGroup) setValue("selectedContractGroup", contractGroup)
  }, [selectedInquiry, contractGroup, setValue])

  const { mutate: acceptToGroup, isLoading: acceptingToGroup } = useMutation(
    (data) => acceptToContractGroup({ data }),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        queryClient.invalidateQueries("inquirySearch")
        queryClient.invalidateQueries("Lead")
        handleClose()
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const handleAccept = () => {
    acceptToGroup({
      id: selectedInquiry.encodedId,
      contractGroupId: getValues("selectedContractGroup"),
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Accept Lead To Contract Group" />
      <Modal.Body>
        <div className="flex flex-col pb-2">
          <span>
            Create a Contract from this Lead, using the configuration of the
            group chosen below.
          </span>
          <div className="flex flex-col py-4">
            <Controller
              name="selectedContractGroup"
              defaultValue=""
              control={control}
              render={({ field: { onChange } }) => (
                <Form.Select.Custom
                  id="templateSelector"
                  onChange={onChange}
                  value={watch("selectedContractGroup")}
                >
                  <Form.Select.RichOption value="" disabled>
                    Select a contract group
                  </Form.Select.RichOption>
                  {contractGroups.map((group) => (
                    <Form.Select.RichOption
                      key={group.encodedId}
                      value={group.encodedId}
                    >
                      <span className="w-38 overflow-hidden truncate">
                        {group.name}
                      </span>
                    </Form.Select.RichOption>
                  ))}
                </Form.Select.Custom>
              )}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText="Create Contract"
        confirmBtnVariant="primary"
        onSubmit={handleAccept}
        disabled={!watch("selectedContractGroup")}
        cancelBtnText="Cancel"
        confirmBtnLoading={acceptingToGroup}
      />
    </Modal>
  )
}

AcceptToContractGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  contractGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      encodedId: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  contractGroup: PropTypes.string,
  selectedInquiry: PropTypes.object,
}

export default AcceptToContractGroupModal
