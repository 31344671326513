import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"
import ContactSearch from "src/components/ContactSearch/ContactSearch"
import { convertBoatDimensionsToInches } from "src/components/ContactSearch/helpers"
import Form from "src/components/Form"

import { createContactBoat } from "src/api/ContactBoat"

import { VALIDATED_FIELDS_BY_TAB } from "../../../constants"
import PageActions from "../../wizard/PageActions"
import PricingSummary from "./PricingSummary"

const ContactAndBoat = () => {
  const {
    watch,
    setError,
    trigger,
    formState: { errors, defaultValues },
    getValues,
    setValue,
  } = useFormContext()
  const queryClient = useQueryClient()
  const [selectedBoatId, selectedContactId] = watch([
    "contact_boat_id",
    "contact_id",
  ])

  useEffect(() => {
    return () => {
      if (selectedContactId === "addNew" && defaultValues.contact_id) {
        setValue("contact_id", defaultValues.contact_id)
        setValue("contact", defaultValues.contact)
      }
      if (selectedBoatId === "addNew" && defaultValues.contact_boat_id) {
        setValue("contact_boat_id", defaultValues.contact_boat_id, {
          shouldValidate: true,
        })
        setValue("contact_boat", defaultValues.contact_boat)
      }
    }
    // we only want to run this on unmount just in case a user
    // starts an "add new" flow then clicks away without saving
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate: saveContactBoatAndContact, isLoading: saveBoatIsLoading } =
    useMutation(["addNewContactBoat", selectedContactId], createContactBoat, {
      onSuccess: ({ boat, contact }) => {
        setValue("contact_boat_id", boat.id, { shouldValidate: true })
        if (contact) {
          setValue("contact", contact)
          setValue("contact_id", contact.id, { shouldValidate: true })
        }
        queryClient.invalidateQueries([
          "contactSearch-boats",
          selectedContactId,
        ])
      },
      onError: (error) => {
        setError("root.saveBoatError", {
          message: error.message,
          meta: { invalidEmailUrl: error.link },
        })
      },
    })

  const saveNewBoat = async () => {
    if (!(await trigger(["contact_boat", "contact"]))) {
      return
    }
    const boat = getValues("contact_boat")
    const contact = getValues("contact")
    const {
      name,
      make,
      model,
      boat_type: boatType,
      year,
      hailing_port: hailingPort,
      registration_attributes: registrationAttributes,
      ...boatDimensions
    } = boat

    const params = {
      boat: {
        name,
        boat_type: boatType,
        make,
        model,
        year,
        hailing_port: hailingPort,
        registration_attributes: registrationAttributes,
        ...convertBoatDimensionsToInches(boatDimensions),
      },
      contact,
      contactId:
        selectedContactId && selectedContactId !== "addNew"
          ? selectedContactId
          : undefined,
    }
    saveContactBoatAndContact(params)
  }

  const renderRootError = () => {
    if (errors?.root?.saveBoatError) {
      const { message, meta } = errors.root.saveBoatError
      if (meta?.invalidEmailUrl) {
        return (
          <Form.Error>
            {message}{" "}
            <a className="text-link" href={meta.invalidEmailUrl}>
              View existing contacts.
            </a>
          </Form.Error>
        )
      } else {
        return <Form.Error>{message}</Form.Error>
      }
    }
  }

  return (
    <>
      <ContactSearch clearable={false} />
      {selectedBoatId === "addNew" ? (
        <div className="mt-4 flex flex-col gap-4">
          <Button
            variant="primary"
            onClick={saveNewBoat}
            disabled={saveBoatIsLoading}
          >
            Save Boat{selectedContactId === "addNew" ? " and Contact" : ""}
          </Button>
          {renderRootError()}
        </div>
      ) : null}
      {selectedBoatId &&
      selectedBoatId !== "addNew" &&
      selectedContactId &&
      selectedContactId !== "addNew" ? (
        <PricingSummary />
      ) : null}
      {errors?.contact_id ? (
        <Form.Error>{errors.contact_id.message}</Form.Error>
      ) : null}
      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.contactAndBoat)}
      />
    </>
  )
}

export default ContactAndBoat
