import PropTypes from "prop-types"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

const SaleNote = ({ disabled = false }) => {
  const { register, watch } = useFormContext()

  const inputName = "note"
  const checkoutNote = watch(inputName)

  const [inputVisible, setInputVisible] = useState(checkoutNote !== "")

  const handleInputBlur = () => {
    if (checkoutNote === "") {
      setInputVisible(false)
    }
  }

  const renderNoteButton = () => {
    return (
      <Button
        variant="ghost"
        disabled={disabled}
        onClick={() => setInputVisible(!inputVisible)}
      >
        Add Sale Note
      </Button>
    )
  }

  const renderNoteInput = () => {
    return (
      <div className="w-full">
        <Form.Label small htmlFor={inputName}>
          Sale Note
        </Form.Label>
        {disabled ? (
          <p>{checkoutNote}</p>
        ) : (
          <Form.Textarea
            rows={1}
            id={inputName}
            {...register(inputName)}
            onBlur={handleInputBlur}
            autoFocus
          />
        )}
        <div className="text-muted italic">
          Visible on customer invoice/receipt
        </div>
      </div>
    )
  }

  return (
    <div className="mt-6 w-full">
      {!disabled && !inputVisible ? renderNoteButton() : null}
      {inputVisible ? renderNoteInput() : null}
    </div>
  )
}

SaleNote.propTypes = {
  disabled: PropTypes.bool,
}
export default SaleNote
