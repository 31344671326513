import {
  validateEndDate,
  validateStartDateAgainstDiscounts,
} from "src/main/Billing/Items/validators"
import * as yup from "yup"

import {
  contactBoatSchema,
  contactSchema,
} from "src/components/ContactSearch/contactAndBoatSchema"

import { BILLING_CYCLES } from "../../constants"
import { isMonthlyBilling } from "../utils"
import { contractInstallmentsSchema } from "./contractInstallmentsSchema"
import { posItemsDataSchema } from "./posItemsDataSchema"

export const schema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required."),
    startDate: yup
      .date()
      .required("Start date is required.")
      .when("billingCycle", {
        is: (billingCycle) => isMonthlyBilling({ billingCycle }),
        then: () =>
          yup
            .date()
            .required("Start date is required.")
            .test(
              "testing valid start date with discount consideration",
              "Start date is invalid",
              (startDate, context) => {
                const message = validateStartDateAgainstDiscounts({
                  contractStartDate: startDate,
                  feesAndDiscounts: context.parent?.feesAndDiscounts,
                })
                if (message) {
                  return context.createError({
                    message,
                    path: "startDate",
                  })
                }
                return true
              }
            ),
      }),
    billingCycle: yup
      .string()
      .when("rate", {
        is: (rate) =>
          rate?.pricingStructure && !rate.pricingStructure.includes("_month"),
        then: () =>
          yup
            .string()
            .oneOf(
              [BILLING_CYCLES.CUSTOM_INSTALLMENTS],
              "The selected pricing structure is only valid for custom installment contract types."
            ),
      })
      .oneOf([
        BILLING_CYCLES.MONTH_TO_MONTH,
        BILLING_CYCLES.MONTHLY_INSTALLMENTS,
        BILLING_CYCLES.CUSTOM_INSTALLMENTS,
      ])
      .required("Contract type is required."),
    endDate: yup
      .date()
      .nullable()
      .when("billingCycle", {
        is: (billingCycle) => billingCycle !== BILLING_CYCLES.MONTH_TO_MONTH,
        then: () =>
          yup
            .date()
            .required("End date is required.")
            .test(
              "testing valid end date",
              "End date is invalid",
              (endDate, context) => {
                const message = validateEndDate({
                  end: endDate,
                  start: context.parent?.startDate,
                })
                if (message) {
                  return context.createError({
                    message,
                    path: "endDate",
                  })
                }
                return true
              }
            ),
      }),
    rate: yup
      .object()
      .shape({
        amount: yup.lazy((value) =>
          value === ""
            ? yup.string().required("Amount is required.")
            : yup
                .number()
                .min(0, "Amount cannot be negative")
                .required("Amount is required.")
                .test(
                  "no-percent-of-zero",
                  "Amount cannot be zero if applying percent-based items.",
                  (rate, context) => {
                    const [parent] = context.from.slice(-1)
                    const hasPercentBasedItems =
                      parent.value.posItemsData?.some(
                        (item) =>
                          item.pricingStructure ===
                          "percent_of_reservation_sale"
                      )

                    if (hasPercentBasedItems) {
                      return rate !== 0
                    }
                    return true
                  }
                )
        ),
        pricingStructure: yup
          .string()
          .test(
            "testing valid pricing structure",
            "Select a valid pricing structure.",
            (pricingStructure, context) => {
              const [parent] = context.from.slice(-1)
              if (parent.value.billingCycle === BILLING_CYCLES.MONTH_TO_MONTH) {
                return pricingStructure.includes("_month")
              }
              return true
            }
          )
          .required("Pricing structure is required."),
        taxRate: yup.lazy((value) =>
          value === "" ? yup.string().nullable() : yup.number().nullable()
        ),
        longTermStorageProductId: yup
          .string()
          .required("Product type is required."),
        dueDay: yup
          .number()
          .nullable()
          .when("billingCycle", {
            is: (billingCycle) => isMonthlyBilling({ billingCycle }),
            then: () => yup.number().required("Due day is required."),
          }),
      })
      .required(),
    attachments: yup.array(),
    contact_boat: yup.object().when("contact_boat_id", {
      is: (contactBoatId) => contactBoatId === "addNew",
      then: () => contactBoatSchema,
    }),
    contact: yup.object().when("contact_id", {
      is: (contactId) => contactId === "addNew",
      then: () => contactSchema,
    }),
    contact_id: yup.string().when("isGroup", {
      is: (isGroup) => !isGroup,
      then: () =>
        yup
          .string()
          .matches(/^(?!addNew\b)/i, "You must select a contact.")
          .required("You must select a contact."),
    }),
    contact_boat_id: yup.string().when("isGroup", {
      is: (isGroup) => !isGroup,
      then: () => yup.string().required("You must select a boat."),
    }),
    contractInstallments: contractInstallmentsSchema,
    installmentDistributionType: yup.string().when("billingCycle", {
      is: (billingCycle) => !isMonthlyBilling({ billingCycle }),
      then: () => yup.string().oneOf(["custom", "even"]).required(),
    }),
    isGroup: yup.boolean().required(),
    posItemsData: posItemsDataSchema,
    acceptedPaymentMethods: yup
      .array()
      .min(1, "At least one payment method must be selected.")
      .required("Payment method is required."),
    feesAndDiscounts: yup
      .array()
      .of(
        yup.object().shape(
          {
            name: yup.string().required(),
            percentage: yup
              .string()
              .nullable()
              .when("amount", {
                is: (amount) => !!amount,
                then: () =>
                  yup
                    .string()
                    .nullable(true)
                    .test(
                      "must be null",
                      "Amount must be null for percentage-based discounts.",
                      (percentage) => !percentage
                    ),
                otherwise: () => yup.string().required("Amount is required."),
              }),
            amount: yup
              .number()
              .nullable()
              .when("percentage", {
                is: (percentage) => !!percentage,
                then: () =>
                  yup
                    .string()
                    .nullable(true)
                    .test(
                      "must be null",
                      "Percentage must be null for dollar-based discounts",
                      (amount) => !amount
                    ),
                otherwise: () => yup.string().required("Amount is required."),
              }),
          },
          ["percentage", "amount"]
        )
      )
      .min(0),
    withTemplate: yup.boolean().required(),
    selectedTemplateId: yup.string().when("withTemplate", {
      is: (withTemplate) => withTemplate,
      then: () => yup.string().required("Template is required."),
      otherwise: () => yup.string().notRequired(),
    }),
    templateComplete: yup.boolean().when("withTemplate", {
      is: (withTemplate) => withTemplate,
      then: (schema) => schema.oneOf([true], "Template cannot be incomplete."),
      otherwise: (schema) => schema.notRequired(),
    }),
  })
  .required()

export default schema
