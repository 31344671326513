import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"

import { updateClaimOnboardingStarted } from "src/api/Onboarding/Claim"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import ProgressIndicator from "../ProgressIndicator"
import AmenitiesStep from "./AmenitiesStep"
import DockmastersStep from "./DockmastersStep"
import FinalStep from "./FinalStep"
import PhotoStep from "./PhotoStep"

const StepTransition = ({ show, children }) => (
  <Transition
    as={Fragment}
    show={show}
    enter="transition-all duration-700 ease-in-out"
    enterFrom="opacity-0 translate-x-12"
    enterTo="opacity-100 translate-x-0"
    leave="transition-all duration-600 ease-in-out"
    leaveFrom="opacity-100 translate-x-0"
    leaveTo="opacity-0 -translate-x-12"
  >
    <div
      className="flex w-full justify-center"
      style={{ display: show ? "flex" : "none" }}
    >
      {children}
    </div>
  </Transition>
)

const steps = {
  welcome: 0,
  photo: 1,
  amenities: 2,
  dockmasters: 3,
  final: 4,
}

const OnboardingContainer = ({
  marinaName,
  firstName,
  marinaDiagram,
  primaryAmenities,
  assetCounts,
  photo,
  dockmasters,
  dockmasterRoles,
  step = "welcome",
}) => {
  const [currentStep, setCurrentStep] = useState(steps[step])
  const tracker = useTracker()
  const marinaSlug = getCurrentMarinaSlug()

  const { mutate: updateClaimOnboarding } = useMutation(
    () => updateClaimOnboardingStarted(marinaSlug),
    {
      onSuccess: () => {
        setCurrentStep(currentStep + 1)
      },
    }
  )
  return (
    <div className="flex flex-col items-center justify-center space-y-4 pt-10">
      <Transition
        appear
        show={currentStep > 0}
        enter="transition-all duration-300 ease-in-out"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all duration-300 ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-2"
      >
        <ProgressIndicator currentStep={currentStep} />
      </Transition>

      <div className="w-full overflow-hidden">
        <StepTransition show={currentStep === 0}>
          <div className="flex h-full w-full flex-col items-center justify-center">
            <div
              className="relative mb-4 aspect-[2/1] w-full rounded bg-cover bg-top bg-no-repeat lg:w-5/12"
              style={{ backgroundImage: `url(${marinaDiagram})` }}
            >
              <div className="absolute bottom-0 left-0 right-0 h-[5%] bg-gradient-to-t from-white via-white/30 to-transparent"></div>
            </div>
            <div className="mb-4 flex w-full flex-col items-center justify-center px-2 text-center lg:w-1/3">
              <h2 className="text-2xl font-bold">
                Welcome to Dockwa{firstName ? `, ${firstName}` : ""}!
              </h2>
              <p className="text-center text-gray-600">
                By claiming your marina&apos;s profile, you&apos;re unlocking
                the leading boater relationship platform that will help you
                attract more boaters, showcase your amenities, and streamline
                reservations—let&apos;s get your marina&apos;s profile page up
                and running!
              </p>
            </div>
            <Button
              variant="primary"
              onClick={() => {
                tracker.trackEvent("onboarding:claim:get_started_pressed")
                updateClaimOnboarding()
              }}
            >
              Get Started
            </Button>
          </div>
        </StepTransition>

        <StepTransition show={currentStep === 1}>
          <PhotoStep
            onNext={() => setCurrentStep(currentStep + 1)}
            existingPhoto={photo}
          />
        </StepTransition>

        <StepTransition show={currentStep === 2}>
          <AmenitiesStep
            onNext={() => setCurrentStep(currentStep + 1)}
            primaryAmenities={primaryAmenities}
            assetCounts={assetCounts}
            marinaName={marinaName}
          />
        </StepTransition>

        <StepTransition show={currentStep === 3}>
          <DockmastersStep
            onNext={() => setCurrentStep(currentStep + 1)}
            dockmasters={dockmasters}
            dockmasterRoles={dockmasterRoles}
          />
        </StepTransition>

        <StepTransition show={currentStep === 4}>
          <FinalStep firstName={firstName} />
        </StepTransition>
      </div>
    </div>
  )
}

StepTransition.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

OnboardingContainer.propTypes = {
  marinaName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  marinaDiagram: PropTypes.string.isRequired,
  primaryAmenities: PropTypes.object.isRequired,
  assetCounts: PropTypes.object.isRequired,
  photo: PropTypes.string.isRequired,
  dockmasters: PropTypes.array.isRequired,
  dockmasterRoles: PropTypes.array.isRequired,
  step: PropTypes.string,
}

export default OnboardingContainer
