import { validateEndDate } from "src/main/Billing/Items/validators"
import { ADD_NEW_CARD_ID } from "src/main/Billing/constants"
import * as yup from "yup"

import {
  contactBoatSchema,
  contactSchema,
} from "src/components/ContactSearch/contactAndBoatSchema"

import {
  AUTOPAY_DISABLED,
  AUTOPAY_ENABLED,
  DEFAULT_RATE_ID,
  PRICING_STRUCTURES,
} from "./constants"

export const schema = yup
  .object()
  .shape({
    arrival: yup.date().required("Arrival is required."),
    departure: yup
      .date()
      .required("Departure is required.")
      .test(
        "testing valid departure date",
        "Departure date is invalid",
        (departure, context) => {
          const invalid = validateEndDate({
            end: departure,
            start: context.parent?.arrival,
          })
          if (invalid) {
            return context.createError({
              message: "Must be the same as or later than arrival date.",
              path: "departure",
            })
          }
          return true
        }
      ),
    billing_schedule_id: yup.string().required("Billing schedule is required."),
    storage_product_id: yup.string().required("Storage type is required."),
    electric_product_id: yup.string(),
    contact_boat_id: yup.string().required("Boat is required."),
    contact_boat: yup.object().when("contact_boat_id", {
      is: (contactBoatId) => contactBoatId === "addNew",
      then: () => contactBoatSchema,
    }),
    contact_id: yup.string().required("Contact is required."),
    contact: yup.object().when("contact_id", {
      is: (contactId) => contactId === "addNew",
      then: () => contactSchema,
    }),
    payment_type: yup
      .string()
      .oneOf([AUTOPAY_ENABLED, AUTOPAY_DISABLED])
      .required(),
    stripe_card_id: yup.string().when("payment_type", {
      is: (paymentType) => paymentType === AUTOPAY_ENABLED,
      then: () =>
        yup
          .string()
          .notOneOf(
            [ADD_NEW_CARD_ID],
            "Payment method is required when autopay is enabled."
          )
          .required("Payment method is required when autopay is enabled."),
    }),
    rate: yup
      .object()
      .shape({
        id: yup.string().required("Rate is required"),
        pricing_structure: yup.string().when("id", {
          is: (rateId) => rateId !== DEFAULT_RATE_ID,
          then: () =>
            yup
              .string()
              .oneOf(PRICING_STRUCTURES, "Pricing structure is required")
              .required(),
        }),
        amount: yup
          .number()
          .notRequired()
          .when("id", {
            is: (rateId) => rateId !== DEFAULT_RATE_ID,
            then: () =>
              yup
                .number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .min(0, "Amount cannot be negative")
                .required("Amount is required"),
          }),
        tax_rate: yup
          .number()
          .notRequired()
          .when("id", {
            is: (rateId) => rateId !== DEFAULT_RATE_ID,
            then: () =>
              yup
                .number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .min(0, "Tax cannot be negative")
                .notRequired(),
          }),
      })
      .required(),
  })
  .required()
