import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const ModalBody = forwardRef(({ children, controlOverflow = true }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames("mb-2 max-h-full flex-1", {
        "overflow-y-auto overflow-x-hidden": controlOverflow,
      })}
    >
      {children}
    </div>
  )
})

ModalBody.displayName = "ModalBody"

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  controlOverflow: PropTypes.bool,
}

export default ModalBody
