export const occupancyToColor = (occupancy) => {
  if (occupancy <= 0.2) {
    return "bg-teal-50 transition-colors duration-300"
  } else if (occupancy <= 0.3) {
    return "bg-teal-100 transition-colors duration-300"
  } else if (occupancy <= 0.4) {
    return "bg-teal-200 transition-colors duration-300"
  } else if (occupancy <= 0.5) {
    return "bg-teal-300 text-white transition-colors duration-300"
  } else if (occupancy <= 0.6) {
    return "bg-teal-400 text-white transition-colors duration-300"
  } else if (occupancy <= 0.75) {
    return "bg-teal-500 text-white transition-colors duration-300"
  } else if (occupancy <= 0.8) {
    return "bg-yellow-600 text-white transition-colors duration-300"
  } else if (occupancy <= 0.9) {
    return "bg-yellow-700 text-white transition-colors duration-300"
  } else if (occupancy <= 1) {
    return "bg-red-800 text-white transition-colors duration-300"
  } else {
    return "bg-red-900 text-white transition-colors duration-300"
  }
}
