import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Button from "src/components/Button"
import Heading from "src/components/Heading"
import ReloadableWidget from "src/components/ReloadableWidget"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import AddCategoryModal from "./AddCategoryModal"
import DeleteCategoryModal from "./DeleteCategoryModal"
import EditCategoryModal from "./EditCategoryModal"
import ManageCategoriesModal from "./ManageCategoriesModal"
import { ManageItemsContext } from "./ManageItemsContainer"
import { UNCATEGORIZED_CATEGORY } from "./utils/uncategorized"

const CategorySidebar = ({ collapseCategories }) => {
  const {
    canManageItems,
    categories,
    categoriesError,
    categoriesLoading,
    includeInactive,
    selectedCategories,
    setIncludeInactive,
    setMissingBarcode,
    missingBarcode,
    handleCategoryFilterSelection,
    marinaAccess,
  } = useContext(ManageItemsContext)

  const [manageCategoriesModalOpen, setManageCategoriesModalOpen] =
    useState(false)
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false)
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState(null)
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false)
  const [categoryToEdit, setCategoryToEdit] = useState(null)
  const [keepManageCategoriesOpen, setKeepManageCategoriesOpen] =
    useState(false)

  const openAddCategoryModalFromManage = () => {
    setAddCategoryModalOpen(true)
    setManageCategoriesModalOpen(false)
    setKeepManageCategoriesOpen(true)
  }

  const closeAddCategoryModal = () => {
    setAddCategoryModalOpen(false)

    if (keepManageCategoriesOpen) {
      setManageCategoriesModalOpen(true)
      setKeepManageCategoriesOpen(false)
    }
  }

  const openDeleteCategoryModal = (category) => {
    setDeleteCategoryModalOpen(true)
    setCategoryToDelete(category)
    setManageCategoriesModalOpen(false)
  }

  const closeDeleteCategoryModal = () => {
    setDeleteCategoryModalOpen(false)
    setCategoryToDelete(null)
    setManageCategoriesModalOpen(true)
  }

  const openEditCategoryModal = (category) => {
    setEditCategoryModalOpen(true)
    setCategoryToEdit(category)
    setManageCategoriesModalOpen(false)
  }

  const closeEditCategoryModal = () => {
    setEditCategoryModalOpen(false)
    setCategoryToEdit(null)
    setManageCategoriesModalOpen(true)
  }

  const renderCategories = () => {
    return (
      <div className="my-2">
        {categories.concat(UNCATEGORIZED_CATEGORY).map((category) => {
          return (
            <div
              key={category.id}
              className="mb-2 flex w-full flex-row justify-between"
            >
              <label
                htmlFor={category.displayName}
                className="mb-0 font-normal"
              >
                {category.displayName}
              </label>
              <input
                className="m-0"
                type="checkbox"
                onChange={() => handleCategoryFilterSelection(category)}
                name={category.displayName}
                id={category.displayName}
                checked={selectedCategories.some((sc) => sc.id === category.id)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const renderAdvancedFilters = () => {
    return (
      <>
        <div className="mb-4 mt-12">
          <Heading.SectionHeader>Advanced Filters</Heading.SectionHeader>
        </div>
        <div className="mb-10 flex w-full flex-row justify-between md:mb-2">
          <label htmlFor="showInactive" className="mb-0 font-normal">
            Show inactive items
          </label>
          <input
            className="m-0"
            type="checkbox"
            onChange={() => setIncludeInactive(!includeInactive)}
            name="Show inactive items"
            id="showInactive"
            checked={includeInactive}
          />
        </div>
        <div className="mb-10 flex w-full flex-row justify-between md:mb-2">
          <label htmlFor="missingBarcode" className="mb-0 font-normal">
            No Barcode
          </label>
          <input
            className="m-0"
            type="checkbox"
            onChange={() => setMissingBarcode(!missingBarcode)}
            name="No Barcode"
            id="missingBarcode"
            checked={missingBarcode}
          />
        </div>
      </>
    )
  }

  const disableManage = () => !marinaAccess.manageCategories || !canManageItems

  return (
    <>
      <details className="mb-4 h-full" open={!collapseCategories}>
        <summary
          onClick={(e) => (collapseCategories ? null : e.preventDefault())}
          className="mb-0 list-item cursor-pointer text-lg font-semibold leading-6 text-gray-900 manage-items-md:mb-2 manage-items-md:block manage-items-md:cursor-default"
        >
          Categories
        </summary>
        <ReloadableWidget
          isLoading={categoriesLoading}
          isError={categoriesError}
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              {renderCategories()}
              {renderAdvancedFilters()}
            </div>
            <MaybeRestrictedTooltip
              text={
                !marinaAccess.manageCategories
                  ? "The ability to customize categories is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
                  : null
              }
              restricted={disableManage()}
              allowedRoles={["Admin", "Finance"]}
            >
              <div className="md:mb-10">
                <Button
                  fullWidth
                  onClick={() => setManageCategoriesModalOpen(true)}
                  variant="secondary"
                  disabled={disableManage()}
                >
                  Manage Categories
                </Button>
              </div>
            </MaybeRestrictedTooltip>
          </div>
        </ReloadableWidget>
      </details>
      <AddCategoryModal
        closeModal={closeAddCategoryModal}
        isOpen={addCategoryModalOpen}
      />
      <EditCategoryModal
        category={categoryToEdit}
        closeModal={closeEditCategoryModal}
        isOpen={editCategoryModalOpen}
      />
      <DeleteCategoryModal
        category={categoryToDelete}
        closeModal={closeDeleteCategoryModal}
        isOpen={deleteCategoryModalOpen}
      />
      <ManageCategoriesModal
        closeModal={() => setManageCategoriesModalOpen(false)}
        isOpen={manageCategoriesModalOpen}
        openAddCategoryModal={openAddCategoryModalFromManage}
        openDeleteCategoryModal={openDeleteCategoryModal}
        openEditCategoryModal={openEditCategoryModal}
      />
    </>
  )
}

CategorySidebar.propTypes = {
  collapseCategories: PropTypes.bool,
}

export default CategorySidebar
