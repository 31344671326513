import { DocusealForm } from "@docuseal/react"
import React, { useContext, useState } from "react"
import { useMutation } from "react-query"

import { updateDocusealSubmission } from "src/api/Contracts"

import { SignContractWizardContext } from "../SignContractWizardContext"

const DocusealSigningForm = () => {
  const { docusealSubmitterSlug, dispatch, dockwaLogo, quote } = useContext(
    SignContractWizardContext
  )
  const [key, setKey] = useState(Date.now())

  const { mutate } = useMutation((data) =>
    updateDocusealSubmission({ data, quoteId: quote.encodedId })
  )

  const handleComplete = (data) => {
    const { submission_id: submissionId, status } = data
    const { docusealSubmissionExternalId } = quote

    if (status === "completed") {
      dispatch({
        type: "DOCUSEAL_FORM_COMPLETED",
      })
      mutate({
        id: submissionId,
        status: status,
        docuseal_submitter_slug: docusealSubmitterSlug,
        docuseal_submission_external_id: docusealSubmissionExternalId,
      })
      setKey(Date.now())
    }
  }

  return (
    <DocusealForm
      src={`https://docuseal.co/s/${docusealSubmitterSlug}`}
      key={key}
      logo={dockwaLogo}
      onComplete={handleComplete}
      sendCopyEmail={false}
      withTitle={false}
      withSendCopyButton={false}
      allowToResubmit={false}
      expand={false}
      withDownloadButton={false}
      goToLast={false}
      i18n={{
        form_has_been_submitted_already: " ",
        "form_has_been_archived.":
          "There have been changes made to this agreement, please refresh the page and try signing again.",
      }}
    />
  )
}

export default DocusealSigningForm
