import { feetToInches } from "src/utils/UnitConversion"

export const convertBoatDimensionsToInches = ({
  length_overall_feet: lengthOverallFeet,
  length_overall_inches: lengthOverallInches,
  height_feet: heightFeet,
  height_inches: heightInches,
  draw_feet: drawFeet,
  draw_inches: drawInches,
  beam_feet: beamFeet,
  beam_inches: beamInches,
}) => {
  // Per form validations, lengthOverallFeet should always be present.
  const lengthOverallPresent =
    Boolean(lengthOverallFeet) || Boolean(lengthOverallInches)
  const lengthOverallFeetToInches = lengthOverallFeet
    ? feetToInches(Number(lengthOverallFeet))
    : 0
  const lengthOverall = lengthOverallPresent
    ? lengthOverallFeetToInches + Number(lengthOverallInches)
    : null

  const heightPresent = Boolean(heightFeet) || Boolean(heightInches)
  const heightFeetToInches = heightFeet ? feetToInches(Number(heightFeet)) : 0
  const height = heightPresent
    ? heightFeetToInches + Number(heightInches)
    : null

  const drawPresent = Boolean(drawFeet) || Boolean(drawInches)
  const drawFeetToInches = drawFeet ? feetToInches(Number(drawFeet)) : 0
  const draw = drawPresent ? drawFeetToInches + Number(drawInches) : null

  // Per form validations, beamFeet should always be present.
  const beamPresent = Boolean(beamFeet) || Boolean(beamInches)
  const beamFeetToInches = beamFeet ? feetToInches(Number(beamFeet)) : 0
  const beam = beamPresent ? beamFeetToInches + Number(beamInches) : null

  return { length_overall: lengthOverall, height, draw, beam }
}
