import { format } from "date-fns"
import Decimal from "decimal.js"
import { parseDateValue } from "src/main/Billing/helpers"

export const formatDiscountData = ({ name, amountType, amount }) => {
  if (amountType === "percent") {
    return {
      name,
      percentage: new Decimal(amount).div(100).toString(),
    }
  }
  return {
    name,
    amount: new Decimal(amount).mul(100).toString(),
  }
}

export const formatIncomingDiscountData = ({
  name,
  amountType,
  amount,
  discountType,
  discountMonth,
  discountStartMonth,
  discountEndMonth,
}) => {
  const baseDiscount = formatDiscountData({ name, amountType, amount })
  if (!discountType) {
    return baseDiscount
  }

  if (discountType === "every") {
    return { discountType, ...baseDiscount }
  } else if (discountType === "month") {
    return { discountType, discountMonth, ...baseDiscount }
  } else {
    return {
      discountType,
      discountStartMonth,
      discountEndMonth,
      ...baseDiscount,
    }
  }
}

export const parseSelectedDiscount = (discount) => {
  if (!discount) {
    return
  }

  const {
    name,
    percentage,
    amount,
    discountType,
    discountMonth,
    discountStartMonth,
    discountEndMonth,
  } = discount

  const baseDiscount = {
    name,
    discountType,
    discountMonth,
    discountStartMonth,
    discountEndMonth,
  }

  if (percentage) {
    return {
      ...baseDiscount,
      amountType: "percent",
      amount: new Decimal(percentage).mul(100).toString(),
    }
  }
  return {
    ...baseDiscount,
    amountType: "dollars",
    amount: new Decimal(amount).div(100).toString(),
  }
}

export const parseSavedDiscount = (discount) => {
  const {
    name,
    percent_amount: percentAmount,
    amount,
    billing_applied_discount_template_id: billingAppliedDiscountTemplateId,
  } = discount
  if (percentAmount) {
    return {
      name,
      billingAppliedDiscountTemplateId,
      percentage: percentAmount,
    }
  }
  return {
    name,
    billingAppliedDiscountTemplateId,
    amount: amount,
  }
}

export const getAmountDisplay = (field) => {
  const percentage = field.percentage
  if (percentage) {
    return `${new Decimal(percentage).mul(100).toString()}%`
  }
  return `$${new Decimal(field.amount).div(100).toFixed(2)}`
}

export const getDisplayName = (field) => {
  if (field.discountType === "every") {
    return `${field.name} (monthly)`
  } else if (field.discountType === "month" && field.discountMonth) {
    return `${field.name} (${format(
      parseDateValue(field.discountMonth),
      "MMMM yyyy"
    )})`
  } else if (
    field.discountType === "range" &&
    field.discountStartMonth &&
    field.discountEndMonth
  ) {
    return `${field.name} (${format(
      parseDateValue(field.discountStartMonth),
      "MMMM yyyy"
    )} - ${format(parseDateValue(field.discountEndMonth), "MMMM yyyy")})`
  } else {
    return field.name
  }
}
