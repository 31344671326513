import { formattedCentsToDollars } from "src/utils/UnitConversion"

import {
  DISCOUNT_APPLY_TO_ELECTRIC,
  DISCOUNT_APPLY_TO_STORAGE,
  DISCOUNT_TYPE_DOLLAR,
  DISCOUNT_TYPE_PERCENT,
} from "./constants"

const getDiscountAmountText = (discountType, amount) => {
  const validAmount = isNaN(parseInt(amount)) ? "0" : amount

  switch (discountType) {
    case DISCOUNT_TYPE_DOLLAR:
      return formattedCentsToDollars(validAmount)
    case DISCOUNT_TYPE_PERCENT:
      return `${validAmount * 100}%`
    default:
      return "0"
  }
}

const getDiscountIcon = (applyTo) => {
  switch (applyTo) {
    case DISCOUNT_APPLY_TO_STORAGE:
      return "boat"
    case DISCOUNT_APPLY_TO_ELECTRIC:
      return "lightning"
    default:
      return null
  }
}

export const getDiscountBadgeAttributes = ({
  name,
  discountType,
  amount,
  applyTo,
}) => {
  const badgeIcon = getDiscountIcon(applyTo)
  const discountName = name || "Discount"
  const amountText = getDiscountAmountText(discountType, amount || 0)
  const badgeText = `${discountName}: ${amountText}`

  return [badgeIcon, badgeText]
}
