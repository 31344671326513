import classNames from "classnames"
import { formatDistance } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

const TransactionCard = ({ transaction, onClick }) => {
  const wasProcessed = Boolean(transaction.processed_at)

  return (
    <div
      onClick={onClick}
      className={classNames(
        "hover:bg-gray-50 flex cursor-pointer flex-col space-y-2 rounded-lg border border-gray-200 p-4 transition-colors",
        {
          "bg-gray-50": wasProcessed,
          "bg-white": !wasProcessed,
        }
      )}
    >
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="font-medium text-gray-900">
            {transaction.product_name}
          </div>
          <div className="text-sm text-gray-500">
            {transaction.product_kind}
          </div>
          {transaction.site_name || transaction.pump_name ? (
            <div className="text-sm text-gray-500">
              {[transaction.site_name, transaction.pump_name]
                .filter(Boolean)
                .join(" - ")}
            </div>
          ) : null}
        </div>
        <div className="text-lg font-semibold text-gray-900">
          {transaction.volume} gals
        </div>
      </div>

      <div className="space-y-0.5 text-sm text-gray-500">
        <div className="flex items-center space-x-2">
          <i className="icon icon-clock-far" />
          <span>
            {formatDistance(new Date(transaction.transacted_at), new Date(), {
              addSuffix: true,
            })}
          </span>
        </div>

        {wasProcessed && (
          <div className="flex items-center space-x-2 text-gray-400">
            <i className="icon icon-cart-outline" />
            <span>
              Added to cart{" "}
              {formatDistance(new Date(transaction.processed_at), new Date(), {
                addSuffix: true,
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

TransactionCard.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    processed_at: PropTypes.string,
    product_name: PropTypes.string.isRequired,
    product_kind: PropTypes.string.isRequired,
    volume: PropTypes.number.isRequired,
    transacted_at: PropTypes.string.isRequired,
    pump_name: PropTypes.string.optional,
    site_name: PropTypes.string.optional,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TransactionCard
