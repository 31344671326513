import PropTypes from "prop-types"
import React, { Fragment, useContext, useEffect, useState } from "react"

import Form from "../../../../components/Form"
import { SignContractWizardContext } from "../SignContractWizardContext"

const AdditionalInformation = ({ form }) => {
  const {
    dispatch,
    state,
    quote: { contractSigningEnablements },
    customFieldDefinitions,
  } = useContext(SignContractWizardContext)

  // Track display file names for fields with uploads
  const [displayFileNames, setDisplayFileNames] = useState({})

  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    unregister,
  } = form

  // This effect initializes display file names for existing uploads
  useEffect(() => {
    const fileFields = contractSigningEnablements
      .map(
        ({ customFieldDefinitionId }) =>
          customFieldDefinitions[customFieldDefinitionId]
      )
      .filter(({ inputType }) => inputType === "file")

    const newDisplayFileNames = { ...displayFileNames }
    let updated = false

    fileFields.forEach(({ id, key }) => {
      const value = state.additionalInformation[id]
      if (value && value !== "" && !displayFileNames[key]) {
        // If we have a value but no display name, set a generic name
        newDisplayFileNames[key] = "File uploaded"
        updated = true
      }
    })

    if (updated) {
      setDisplayFileNames(newDisplayFileNames)
    }
  }, [
    state.additionalInformation,
    contractSigningEnablements,
    customFieldDefinitions,
  ])

  // This effect ensures file inputs are properly registered with React Hook Form
  useEffect(() => {
    // Get all file-type fields
    const fileFields = contractSigningEnablements
      .map(
        ({ customFieldDefinitionId }) =>
          customFieldDefinitions[customFieldDefinitionId]
      )
      .filter(({ inputType }) => inputType === "file")
      .map(({ key, id, title, required }) => ({
        key,
        id,
        label: title,
        required,
        value: state.additionalInformation[id] || "",
      }))

    // Register each file field with the proper validation
    fileFields.forEach(({ key, required, label, value }) => {
      // We need to unregister first to avoid duplicate registrations
      unregister(key)

      // Then register with the appropriate validation
      register(key, {
        required: required ? `${label} is required.` : false,
        validate: (fieldValue) => {
          if (required && (!fieldValue || fieldValue === "")) {
            return `${label} is required.`
          }
          return true
        },
      })

      // Set the initial value if there is one
      if (value) {
        setValue(key, value, { shouldValidate: true })
      }
    })

    // Clean up on unmount
    return () => {
      fileFields.forEach(({ key }) => unregister(key))
    }
  }, [
    register,
    unregister,
    setValue,
    contractSigningEnablements,
    customFieldDefinitions,
    state.additionalInformation,
  ])

  const handleChange = (eventHandler, id) => (event) => {
    eventHandler(event)

    const payload = { [id]: event.target.value }

    dispatch({
      type: "ADDITIONAL_INFORMATION_CHANGED",
      payload,
    })
  }

  const handleFileUploadSuccess = (id, inputKey) => (blob) => {
    // Update the Redux-like state
    const payload = { [id]: blob.signed_id }

    dispatch({
      type: "ADDITIONAL_INFORMATION_CHANGED",
      payload,
    })

    // Also update React Hook Form's state to clear the required validation error
    setValue(inputKey, blob.signed_id, {
      shouldValidate: true,
      shouldDirty: true,
    })

    // Store the file name for display
    setDisplayFileNames({
      ...displayFileNames,
      [inputKey]: blob.filename || "File uploaded",
    })

    // Trigger validation to immediately clear any errors
    trigger(inputKey)
  }

  const handleFileRemove = (id, inputKey) => () => {
    // Clear the Redux-like state
    const payload = { [id]: "" }

    dispatch({
      type: "ADDITIONAL_INFORMATION_CHANGED",
      payload,
    })

    // Also update React Hook Form's state
    setValue(inputKey, "", {
      shouldValidate: true,
      shouldDirty: true,
    })

    // Remove the file name from display
    const newDisplayFileNames = { ...displayFileNames }
    delete newDisplayFileNames[inputKey]
    setDisplayFileNames(newDisplayFileNames)

    // Trigger validation to show error again if field is required
    trigger(inputKey)
  }

  return (
    <div className="flex flex-col space-y-4">
      {contractSigningEnablements
        .map(({ customFieldDefinitionId }) => {
          return customFieldDefinitions[customFieldDefinitionId]
        })
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(({ key: inputKey, title: label, id, required, inputType }) => {
          const value = state.additionalInformation[id] || ""
          const hasUploadedFile = inputType === "file" && value !== ""
          const fileName = displayFileNames[inputKey] || ""

          const errorsForKey = errors[inputKey]

          // Only set up register for non-file inputs
          // File inputs are registered in the useEffect
          let registerProps = {}
          if (inputType !== "file") {
            const inputOptions = {
              value: value,
            }

            if (required) {
              inputOptions.required = `${label} is required.`
            }

            registerProps = register(inputKey, inputOptions)
          } else {
            // For file inputs, we still need name and ref, but not onChange which will be handled by the component
            registerProps = {
              name: inputKey,
              ref: register(inputKey).ref,
              onBlur: register(inputKey).onBlur,
            }
          }

          return (
            <Fragment key={inputKey}>
              <Form.Label
                htmlFor={inputKey}
                required={required}
                optional={!required}
              >
                {label}
              </Form.Label>
              <div className="flex flex-col space-y-2">
                {inputType === "file" ? (
                  <Form.DirectUploadFileInput
                    id={inputKey}
                    name={registerProps.name}
                    ref={registerProps.ref}
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                    required={required}
                    hasErrors={Boolean(errorsForKey)}
                    onBlur={registerProps.onBlur}
                    onUploadSuccess={handleFileUploadSuccess(id, inputKey)}
                    onFileRemove={handleFileRemove(id, inputKey)}
                    placeholder={`Upload ${label}`}
                    fileName={hasUploadedFile ? fileName : ""}
                  />
                ) : (
                  <Form.TextField
                    id={inputKey}
                    onChange={handleChange(registerProps.onChange, id)}
                    onBlur={registerProps.onBlur}
                    name={registerProps.name}
                    ref={registerProps.ref}
                    hasErrors={Boolean(errorsForKey)}
                  />
                )}
                <div className="font-semibold text-red-600">
                  {errorsForKey ? errorsForKey.message : <span>&nbsp;</span>}
                </div>
              </div>
            </Fragment>
          )
        })}
    </div>
  )
}

AdditionalInformation.propTypes = {
  form: PropTypes.object.isRequired,
}

export default AdditionalInformation
