import classNames from "classnames"
import PropTypes from "prop-types"
import React, { Fragment, useCallback, useEffect, useMemo } from "react"
import { useInfiniteQuery } from "react-query"
import DealSwimlane from "src/main/DockwaPlusDeals/Marketing/DealSwimlane"
import DealsList from "src/main/DockwaPlusDeals/Marketing/DealsList"
import DockwaPlusDealsBanner from "src/main/DockwaPlusDeals/Marketing/DockwaPlusDealsBanner"
import { renderStatus } from "src/main/DockwaPlusDeals/Marketing/shared_methods"

import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"

import {
  queryAllDeals,
  queryFavorites,
  queryFuelDeals,
} from "src/api/DockwaPlusDeals/Marketing"

import { useLocalStorageState } from "src/hooks/use_local_storage_state"
import useWindowSize from "src/hooks/use_window_size"

const DockwaPlusDealsMarketing = ({
  currentUserFirstName,
  hasActiveDockwaPlusSubscription,
  isSignedIn,
  fuelDealsEnabled,
}) => {
  const { isLargeScreen } = useWindowSize()
  const [seenFuelDealsCount, setSeenFuelDealsCount] = useLocalStorageState(
    "dockwaPlusDeals",
    "seenFuelDealsCount"
  )

  useEffect(
    function fuelDealsSeenCounter() {
      if (seenFuelDealsCount) {
        setSeenFuelDealsCount(parseInt(seenFuelDealsCount) + 1)
      } else {
        setSeenFuelDealsCount(1)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const {
    isFetching,
    isError,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: ["allDockwaPlusDeals"],
    queryFn: ({ pageParam = 1 }) => queryAllDeals({ page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
  })

  const handleScroll = useCallback(() => {
    const triggerAt = 200
    const scrolledToTriggerPoint =
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - triggerAt

    if (scrolledToTriggerPoint) {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  const allDeals = useMemo(() => data?.pages?.flat?.(), [data])

  const renderDeals = () => {
    return (
      <div className="flex flex-col space-y-6 pl-4 pt-6 lg:pl-0">
        {isSignedIn && (
          <Fragment>
            <DealSwimlane
              hasActiveDockwaPlusSubscription={hasActiveDockwaPlusSubscription}
              title="Deals at your saved marinas"
              subtitle="Marinas you’ve saved or booked at in the past"
              primaryCta={{
                href: "/dockwa_deals/favorite_marinas",
                text: "View all",
              }}
              query={queryFavorites}
              queryKey="dealsAtFavorites"
            />
            {fuelDealsEnabled && (
              <DealSwimlane
                hasActiveDockwaPlusSubscription={
                  hasActiveDockwaPlusSubscription
                }
                title="Fuel Discounts"
                subtitle="Check out these great discounts on fuel"
                primaryCta={{
                  href: "/dockwa_plus/fuel_discounts",
                  text: "View all",
                }}
                secondaryCta={{
                  href: "/search?dockwa_plus_fuel_discount",
                  text: "View all on map",
                }}
                query={queryFuelDeals}
                queryKey="allFuelDeals"
                variant="fuel"
                eventBadgeProps={{
                  mode: "time",
                  hideAt: new Date("11/1/2025"),
                }}
              />
            )}
          </Fragment>
        )}
        <div className="flex flex-col space-y-4 pr-4 lg:pr-0">
          <div className="flex w-full flex-col space-y-2 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
            <div className="flex flex-col space-y-2">
              <HeadingModalTitle>All deals</HeadingModalTitle>
              <span className="text-gray-600">
                All active and upcoming dockwa+ deals
              </span>
            </div>
            <a
              href="/search?dockwa_plus_deal"
              className={classNames({
                "font-semibold text-blue-700": !isLargeScreen,
                "btn btn-tertiary": isLargeScreen,
              })}
            >
              View all on map
            </a>
          </div>
          {isLoading || isError ? (
            renderStatus({ isLoading, isError, error })
          ) : (
            <DealsList
              deals={allDeals}
              hasActiveDockwaPlusSubscription={hasActiveDockwaPlusSubscription}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
            />
          )}
        </div>
      </div>
    )
  }
  return (
    <div className="-mt-6 lg:m-auto lg:max-w-[1248px] lg:p-4">
      <DockwaPlusDealsBanner
        currentUserFirstName={currentUserFirstName}
        hasActiveDockwaPlusSubscription={hasActiveDockwaPlusSubscription}
      />
      {renderDeals()}
    </div>
  )
}

DockwaPlusDealsMarketing.propTypes = {
  currentUserFirstName: PropTypes.string,
  hasActiveDockwaPlusSubscription: PropTypes.bool.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  fuelDealsEnabled: PropTypes.bool.isRequired,
}

export default DockwaPlusDealsMarketing
