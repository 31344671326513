import React, { useContext } from "react"

import { AvailabilityStep, ContactStep, SummaryStep } from "./Steps"
import { WizardContext } from "./WizardContextProvider"
import { STEPS } from "./constants"

const ReservationStep = () => {
  const { currentStep } = useContext(WizardContext)

  switch (currentStep) {
    case STEPS.availability:
      return <AvailabilityStep />
    case STEPS.contact:
      return <ContactStep />
    case STEPS.summary:
      return <SummaryStep />
  }
}

export default ReservationStep
