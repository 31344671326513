import { queryApi } from "src/utils/api/ApiMethods"

export const getReservations = () => {
  return queryApi("/account/trips/reservations", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const getContracts = () => {
  return queryApi("/account/trips/contracts", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const getSales = () => {
  return queryApi("/account/sales", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
