import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import ServerCartItemForm from "src/main/PointOfSale/CurrentSalePanel/ServerCartItemForm"
import { POSContext } from "src/main/PointOfSale/Layout"

import Heading from "src/components/Heading"

const formatMoney = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount / 100)
}

const formatQuantity = (quantity) => {
  if (quantity === null || quantity === undefined) return ""
  const num = parseFloat(quantity)
  return Number.isInteger(num) ? `(${num})` : `(${parseFloat(num.toFixed(2))})`
}

const ServerCartItem = ({ item, index, disabled = false }) => {
  const {
    cartController: { removeItemFromCart, panelIsOpen, togglePanelOpen },
  } = useContext(POSContext)

  const {
    formState: { errors },
  } = useFormContext()
  const errorsForItem = errors.cart?.[index]

  const renderChevron = () => (
    <div
      className="flex w-5 justify-center"
      role="button"
      aria-label="Toggle Item Panel"
      onClick={() => togglePanelOpen(item)}
    >
      <i
        role="img"
        className={classNames(
          "icon icon-chevron text-xl text-gray-400 transition duration-100",
          { "icon-rotate-90": panelIsOpen(item) }
        )}
      />
    </div>
  )

  const renderPriceWithDiscount = () => {
    const { taxAmount, discountAmount, subtotal, total } = item

    const hasDiscount = discountAmount > 0
    const formattedSubtotal = formatMoney(subtotal)
    const formattedTotal = formatMoney(total - taxAmount)

    if (hasDiscount) {
      return (
        <div className="flex flex-row items-center space-x-2">
          <i className="icon icon-tag-outline mt-0.5 text-lg font-normal text-teal-600" />
          <span className="text-sm text-gray-400 line-through">
            {formattedSubtotal}
          </span>
          <span className="text-teal-600">{formattedTotal}</span>
        </div>
      )
    } else {
      return <span>{formattedTotal}</span>
    }
  }

  return (
    <div>
      <div className="flex flex-row items-center space-x-2">
        {renderChevron()}
        <div className="flex w-full flex-row justify-between font-semibold text-gray-700">
          <div className="flex w-full flex-1 flex-row flex-wrap items-center gap-x-1">
            {errorsForItem && (
              <i className="icon icon-exclamation-circle text-red-600" />
            )}
            <div
              role="button"
              className="max-w-60 break-words"
              onClick={() => togglePanelOpen(item)}
            >
              <Heading.SubSectionHeader>
                {item.name}{" "}
                <span className="text-gray-500">
                  {formatQuantity(item.quantity)}
                </span>
              </Heading.SubSectionHeader>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 text-lg">
            {renderPriceWithDiscount()}
            {!disabled && (
              <div
                role="button"
                aria-label="Remove Item"
                onClick={() => removeItemFromCart(item.id)}
              >
                <i className="icon icon-sf-trashcan text-gray-600" role="img" />
              </div>
            )}
          </div>
        </div>
      </div>
      {panelIsOpen(item) && (
        <div className="flex h-auto flex-row items-stretch space-x-1">
          <div className="w-5 flex-1 justify-center p-2">
            <div className="h-full w-0.5 bg-gray-300" />
          </div>
          <ServerCartItemForm item={item} index={index} disabled={disabled} />
        </div>
      )}
    </div>
  )
}

ServerCartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    pricePrecision: PropTypes.oneOf(["cents", "hundredths_of_cents"])
      .isRequired,
    taxRate: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    discountAmount: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    taxAmount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default ServerCartItem
