import React, { useContext, useState } from "react"
import { PaymentFlowContext } from "src/main/PointOfSale/Checkout/PaymentModal/index"

import CustomerAndBoatSelector from "./CustomerAndBoatSelector"
import CustomerCreatorModal from "./CustomerCreatorModal"

const CustomerSelectionScreen = () => {
  const [isCreating, setIsCreating] = useState(false)
  const {
    flowDetails: { customerIdentityRequired },
  } = useContext(PaymentFlowContext)

  return (
    <div className="mb-4">
      <CustomerAndBoatSelector
        onAddNewCustomerClicked={() => setIsCreating(true)}
        customerIdentityRequired={customerIdentityRequired}
      />
      {isCreating ? (
        <CustomerCreatorModal onClose={() => setIsCreating(false)} />
      ) : null}
    </div>
  )
}

export default CustomerSelectionScreen
