import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import MaxPaymentAmountDisplay from "src/main/Billing/MaxPaymentAmountDisplay"
import PaymentMethodDropdown from "src/main/Billing/PaymentMethodDropdown"
import RefundPaymentMethodDropdown, {
  formatPaymentMethods,
} from "src/main/Billing/RefundPaymentMethodDropdown"
import { SharedBillingContext } from "src/main/Billing/SharedBillingContext"
import { useGetRefundablePaymentMethods } from "src/main/Billing/hooks"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { createInvoiceTxn } from "src/api/Billing/Payments"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { capitalize } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { getRequestPaymentMethodId } from "../helpers"
import { OFFLINE_PAYMENT_METHODS, getTxnAttributes } from "./helpers"
import { useUpdateInvoice } from "./hooks"

const GENERIC_SERVER_ERROR = {
  type: "server",
  message:
    "Something went wrong settling this payment. Please try again or contact support.",
}

const renderManualPaymentMetadataFields = (paymentType, register) => {
  switch (paymentType) {
    case "check":
      return (
        <>
          <Form.Label htmlFor="check-number">Check number</Form.Label>
          <Form.TextField
            id="check-number"
            {...register("txn.offline_payment_attributes.check_number")}
          />
        </>
      )
    case "manual_ach":
      return (
        <>
          <Form.Label htmlFor="ach-confirmation">ACH confirmation</Form.Label>
          <Form.TextField
            id="ach-confirmation"
            {...register("txn.offline_payment_attributes.ach_confirmation")}
          />
        </>
      )
    case "external_txn":
      return (
        <>
          <Form.Label htmlFor="note">Note</Form.Label>
          <Form.TextField
            id="note"
            {...register("txn.offline_payment_attributes.notes")}
          />
        </>
      )
    default:
      return null
  }
}

const SettlePaymentModal = ({
  payment,
  onPaymentSettle,
  isOpen,
  onClose,
  afterLeave,
  paymentMethods,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const partialSettlementCheckboxRef = useRef()
  const [isPartialSettlement, setIsPartialSettlement] = useState(false)
  const { reservationId } = useContext(SharedBillingContext) || {}

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      txn: {
        offline_payment_attributes: {
          payment_type: "cash",
        },
      },
    },
    mode: "all",
  })

  const [paymentType, partialPayment, paymentMethodId] = watch([
    "txn.offline_payment_attributes.payment_type",
    "partialPayment",
    "invoice.payment_method_id",
  ])

  const onResetModal = () => {
    if (afterLeave) {
      afterLeave()
    }
    reset()
    setIsPartialSettlement(false)
  }

  const invoiceId = payment?.id
  const balanceInCents = payment?.balance ?? 0
  const absBalanceInCents = new Decimal(balanceInCents).abs().toNumber()
  const formattedAbsBalance = formattedCentsToDollars(absBalanceInCents)
  const settleType = new Decimal(balanceInCents).lt(0) ? "refund" : "payment"
  const isRefund = settleType === "refund"
  const isReservationRefund = isRefund && reservationId
  const { cards: paymentCards } = paymentMethods
  const partialPaymentInCents = new Decimal(partialPayment || 0)
    .mul(100)
    .toNumber()
  const existingPaymentMethodId = payment?.payment_method?.id || "manual"
  const existingPaymentMethod = isReservationRefund
    ? String(existingPaymentMethodId)
    : existingPaymentMethodId

  const { mutate, isLoading } = useMutation({
    queryKey: ["invoiceTxn", invoiceId],
    mutationFn: (data) => createInvoiceTxn({ invoiceId, marinaSlug, data }),
    onSuccess: () => {
      onPaymentSettle()
      reset()
    },
    onError: () => {
      setError("root", GENERIC_SERVER_ERROR)
    },
  })

  // Fetching Payment Methods
  const {
    data: paymentMethodResponse,
    isFetching: isLoadingRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    reservationId,
    isSettling: true, // include all methods, ignoring scheduled refunds
    options: {
      onSuccess: ({
        refundablePaymentMethods: data,
        isMultiplePaymentRequired,
      }) => {
        if (isMultiplePaymentRequired && !isPartialSettlement) {
          setIsPartialSettlement(true)
        }

        const paymentMethod = data.find(
          (pm) => pm.payment_method_id === String(paymentMethodId)
        )

        // If the current payment method is not refundable, select the first refundable method
        const refundablePaymentMethodId = paymentMethod
          ? paymentMethod.payment_method_id
          : data[0]?.payment_method_id

        if (String(refundablePaymentMethodId) !== String(paymentMethodId)) {
          setValue("invoice.payment_method_id", refundablePaymentMethodId)
        }
      },
      select: (data) => ({
        refundablePaymentMethods: formatPaymentMethods(data),
        isMultiplePaymentRequired: data.every(
          (paymentMethod) => paymentMethod.refundable_amount < absBalanceInCents
        ),
      }),
      onError: (error) => {
        console.log("queryRefundablePaymentMethods error", { error })
      },
      enabled: Boolean(isReservationRefund),
    },
  })

  const { refundablePaymentMethods, isMultiplePaymentRequired } =
    paymentMethodResponse ?? {
      refundablePaymentMethods: [],
      isMultiplePaymentRequired: null,
    }

  const refundableAmount = useMemo(() => {
    if (!isRefund || refundablePaymentMethods.length === 0) {
      return 0
    }

    const refundablePaymentMethod = refundablePaymentMethods.find(
      (paymentMethod) => paymentMethod.payment_method_id === paymentMethodId
    )

    return refundablePaymentMethod
      ? refundablePaymentMethod.refundable_amount
      : 0
  }, [isRefund, refundablePaymentMethods, paymentMethodId])

  const formattedRefundableAmount = formattedCentsToDollars(refundableAmount)

  useEffect(() => {
    setValue("invoice.payment_method_id", existingPaymentMethod)
  }, [payment, setValue, existingPaymentMethod])

  const createInvoiceTransaction = (data, currentPayment) => {
    const requestData = {
      txn: getTxnAttributes({ data, payment: currentPayment }),
    }
    mutate(requestData)
  }

  // invoked for refund creations if the payment method has changed
  // to first update the invoice before creating the transaction
  const { submit: updateInvoice, isLoading: isLoadingInvoice } =
    useUpdateInvoice({
      paymentId: payment?.id,
      onSuccess: (payment) =>
        handleSubmit((data) => createInvoiceTransaction(data, payment))(),
      onError: () => {
        setError("root", GENERIC_SERVER_ERROR)
      },
    })

  const onSubmit = (data) => {
    if (String(existingPaymentMethod) !== String(paymentMethodId)) {
      if (isRefund) {
        updateInvoice(data)
      } else {
        createInvoiceTransaction(data, {
          ...payment,
          payment_method: {
            ...(payment?.payment_method ? payment?.payment_method : {}),
            id: getRequestPaymentMethodId(paymentMethodId),
          },
        })
      }
    } else {
      createInvoiceTransaction(data, payment)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxSize="medium"
      afterLeave={onResetModal}
    >
      <Modal.Header title="Settle now" />
      <Modal.Body>
        <div className="mb-5">
          {isReservationRefund && isMultiplePaymentRequired && (
            <AlertBanner type="info">
              There is a total of
              <strong>{` ${formattedAbsBalance} `}</strong>
              owed to the customer. You can refund up to
              <strong>{` ${formattedRefundableAmount} `}</strong>
              on this payment method. Please settle the partial amount and
              refund the rest separately
            </AlertBanner>
          )}
        </div>
        <div className="mb-5 w-3/4">
          {isReservationRefund ? (
            <RefundPaymentMethodDropdown
              id="refund-payment-method"
              name="invoice.payment_method_id"
              control={control}
              isLoading={isLoadingRefundablePaymentMethods}
              paymentMethods={refundablePaymentMethods}
              registerOptions={{
                validate: (val) => (val ? null : "Refund method is required."),
              }}
              errors={errors?.invoice?.payment_method_id}
            />
          ) : (
            <PaymentMethodDropdown
              name="invoice.payment_method_id"
              onlineMethods={paymentCards}
              control={control}
              includeExpirationDates
              disabled={isRefund}
            />
          )}
        </div>
        {paymentMethodId === "manual" && (
          <div className="mb-5 flex w-full flex-row gap-4">
            <div className="flex w-1/2 flex-col">
              <Form.Label htmlFor="manual-payment-type">
                Manual payment type
              </Form.Label>
              <Form.Select
                id="manual-payment-type"
                {...register("txn.offline_payment_attributes.payment_type")}
              >
                {Object.entries(OFFLINE_PAYMENT_METHODS).map(
                  ([value, displayName]) => (
                    <option key={value} value={value}>
                      {displayName}
                    </option>
                  )
                )}
              </Form.Select>
            </div>
            <div className="flex w-1/2 flex-col">
              {renderManualPaymentMetadataFields(paymentType, register)}
            </div>
          </div>
        )}
        {!isPartialSettlement ? (
          <div className="flex flex-row gap-4">
            <div className="mb-5 flex w-1/2 flex-col">
              <Form.Label htmlFor="full-payment-amount">
                {capitalize(settleType)} amount
              </Form.Label>
              <Form.IconTextField
                id="full-payment-amount"
                position="left"
                icon="$"
                type="number"
                disabled
                value={new Decimal(
                  isRefund ? balanceInCents : absBalanceInCents
                )
                  .div(100)
                  .toFixed(2)}
              />
            </div>
            <div className="mb-5 w-1/2"></div>
          </div>
        ) : (
          <div className="mb-5 flex flex-row gap-4">
            <div className="flex w-full flex-col">
              <Form.Label htmlFor="partial-payment-amount">
                {isRefund ? "Partial refund amount" : "Partial payment amount"}
              </Form.Label>
              <div className="-mt-2 mb-2">
                <MaxPaymentAmountDisplay
                  amount={isRefund ? refundableAmount : absBalanceInCents}
                  displayZero
                />
              </div>
              <Form.IconTextField
                id="partial-payment-amount"
                position="left"
                icon="$"
                type="number"
                {...register("partialPayment", {
                  validate: (val) => {
                    const amount = new Decimal(val || 0).mul(100).toNumber()
                    if (!amount || amount < 0) {
                      return "Partial payment amount must be greater than 0."
                    }
                    if (isRefund && amount > refundableAmount) {
                      return `Partial ${settleType} cannot exceed ${settleType} amount of ${formattedRefundableAmount}.`
                    }
                    if (amount > absBalanceInCents) {
                      return `Partial payment cannot exceed ${settleType} amount of ${formattedAbsBalance}.`
                    }
                  },
                })}
                hasErrors={!!errors.partialPayment}
              />
              {errors.partialPayment && (
                <Form.Error>{errors.partialPayment.message}</Form.Error>
              )}
            </div>
            <div className="flex w-full flex-col">
              <Form.Label htmlFor="remainder-payment-amount">
                Remaining amount
              </Form.Label>
              <div className="text-muted -mt-2 mb-2">
                (scheduled separately)
              </div>
              <Form.IconTextField
                id="remainder-payment-amount"
                position="left"
                icon="$"
                type="number"
                disabled
                value={
                  errors.partialPayment
                    ? ""
                    : new Decimal(absBalanceInCents)
                        .minus(partialPaymentInCents)
                        .div(100)
                        .toFixed(2)
                }
              />
            </div>
          </div>
        )}
        <div className="mb-5">
          <Form.Checkbox
            name="partial-settlement"
            ref={partialSettlementCheckboxRef}
            onChange={() => {
              setIsPartialSettlement(!isPartialSettlement)
            }}
            checked={isPartialSettlement}
            disabled={isMultiplePaymentRequired}
            compact
            label="Settle partial amount"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-col justify-end gap-4">
          <div className="flex justify-end">
            <div className="mr-5">
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <Button
              type="submit"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={
                isLoading ||
                isLoadingInvoice ||
                isLoadingRefundablePaymentMethods
              }
            >
              {isPartialSettlement ? "Settle partial amount" : "Settle now"}
            </Button>
          </div>
          {errors.root ? <Form.Error>{errors.root.message}</Form.Error> : null}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

SettlePaymentModal.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    payment_method: PropTypes.object,
  }),
  onPaymentSettle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  afterLeave: PropTypes.func,
  paymentMethods: PropTypes.shape({
    cards: PropTypes.array.isRequired,
  }),
}

export default SettlePaymentModal
