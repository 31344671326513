import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"

import { queryInvoiceTxns } from "src/api/Billing/Payments"

import PaymentModal from "./PaymentModal"
import PaymentsTable from "./PaymentsTable"
import SettlePaymentModal from "./SettlePaymentModal"

const Payments = ({
  payments,
  refreshItems,
  refreshPayments,
  marinaSlug,
  paymentMethods,
  isLoading,
  reservationId,
  isError,
  editable = true,
  editInvoiceDueDates,
}) => {
  const [isEditingPayment, setIsEditingPayment] = useState(false)
  const [isSettlingPayment, setIsSettlingPayment] = useState(false)
  const [editablePayment, setEditablePayment] = useState(null)
  const [paymentToSettle, setPaymentToSettle] = useState(null)

  const queryClient = useQueryClient()
  const paymentId = editablePayment?.id

  const {
    isFetching: isLoadingInvoiceTxns,
    isError: isErrorInvoiceTxns,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["invoice_txns", paymentId, marinaSlug],
    queryFn: ({ pageParam = 1 }) =>
      queryInvoiceTxns({ invoiceId: paymentId, marinaSlug, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    enabled: Boolean(paymentId),
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  useEffect(() => {
    if (window.location.hash === "#billing") {
      const element = document.getElementById("payments-section")
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [])

  const invoiceTxns = useMemo(
    () => (data?.pages || []).flatMap((page) => page),
    [data]
  )
  const resetInvoiceTxns = () => {
    queryClient.resetQueries({
      queryKey: ["invoice_txns", paymentId, marinaSlug],
    })
  }

  // This function is curried by the payment rows and used when a user clicks
  // "Open" for a payment
  const onEditPayment = (payment) => () => {
    setIsEditingPayment(true)
    setEditablePayment(payment)
  }

  const onEditPaymentClose = () => {
    setIsEditingPayment(false)
  }

  const afterEditPaymentLeave = () => {
    resetInvoiceTxns()
    setEditablePayment(null)
  }

  const onSettlePaymentClick = (payment) => {
    setPaymentToSettle(payment)
    setIsSettlingPayment(true)
  }

  const onSettleClose = () => {
    setIsSettlingPayment(false)
  }

  const onSettlePaymentModalSubmit = () => {
    onSettleClose()
    resetInvoiceTxns()
    refreshItems()
    refreshPayments()
  }

  return (
    <div id="payments-section" className="pb-5">
      <h4 className="text-base font-semibold">Payments</h4>
      <div className="overflow-x-auto sm:overflow-visible">
        <PaymentsTable
          payments={payments}
          editPayment={onEditPayment}
          settlePayment={onSettlePaymentClick}
          isLoading={isLoading}
          isError={isError}
          editable={editable}
        />
      </div>
      <PaymentModal
        payment={editablePayment}
        invoiceTxns={invoiceTxns}
        setEditablePayment={setEditablePayment}
        refreshPayments={refreshPayments}
        refreshItems={refreshItems}
        paymentMethods={paymentMethods}
        isOpen={isEditingPayment}
        onClose={onEditPaymentClose}
        isLoadingInvoiceTxns={isLoadingInvoiceTxns || hasNextPage !== false}
        isErrorInvoiceTxns={isErrorInvoiceTxns}
        afterLeave={afterEditPaymentLeave}
        reservationId={reservationId}
        editInvoiceDueDates={editInvoiceDueDates}
      />
      {/*
        See PaymentModal.jsx for the comment that explains why we
        render the SettlePaymentModal both here and in the PaymentModal
        component
      */}
      <SettlePaymentModal
        payment={paymentToSettle}
        paymentMethods={paymentMethods}
        onPaymentSettle={onSettlePaymentModalSubmit}
        isOpen={isSettlingPayment}
        onClose={onSettleClose}
        afterLeave={() => setPaymentToSettle(null)}
      />
    </div>
  )
}

Payments.propTypes = {
  reservationId: PropTypes.number,
  payments: PropTypes.array,
  refreshItems: PropTypes.func.isRequired,
  refreshPayments: PropTypes.func.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  editInvoiceDueDates: PropTypes.bool.isRequired,
}

export default Payments
