import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../../WizardContextProvider"
import {
  CUSTOM_RATE_ID,
  NO_PRICING_STRUCTURE_CHOSEN_VALUE,
} from "../../../constants"
import { friendlyRatePricingStructureName } from "../../../helpers"

const PricingStructureSelector = () => {
  const { pricingStructures, isMonthlyBilling } = useContext(WizardContext)
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const [rateId] = watch(["rate.id"])

  const optionIsDisabled = (option) => {
    if (isMonthlyBilling) {
      return !option.includes("month")
    }

    return false
  }

  return (
    <div>
      <Form.Label htmlFor="trw-rate-pricing-structure">
        Pricing Structure
      </Form.Label>
      <Form.Select
        id="trw-rate-pricing-structure"
        disabled={rateId !== CUSTOM_RATE_ID}
        {...register("rate.pricing_structure")}
      >
        <option value={NO_PRICING_STRUCTURE_CHOSEN_VALUE}>Select...</option>
        {pricingStructures.map((option) => (
          <option
            key={option}
            value={option}
            disabled={optionIsDisabled(option)}
          >
            {friendlyRatePricingStructureName(option)}
          </option>
        ))}
      </Form.Select>
      {errors?.rate?.pricing_structure?.message && (
        <Form.Error>{errors.rate.pricing_structure.message}</Form.Error>
      )}
    </div>
  )
}

export default PricingStructureSelector
