import { Menu, Transition } from "@headlessui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { Fragment } from "react"

import Button from "../Button"
import Tooltip from "../Tooltip"
import MenuButton from "./MenuButton"
import MenuItem from "./MenuItem"
import VerticalDotsIcon from "./VerticalDotsIcon"

const OVERFLOW_MENU_VARIANTS = {
  default: {
    button: "",
    buttonHover: "",
  },
  transparent: {
    button: "!bg-transparent",
    buttonHover: "",
  },
  tableRow: {
    button: "!bg-transparent",
    buttonHover: "group-hover:bg-gray-100",
  },
}

// if we upgrade to headlessui v2 we get positioning utilities
// and can skip manual positioning
const MENU_POSITIONS = {
  topLeft: "top-0 transform -translate-y-full right-0 origin-top-right",
  topRight: "top-0 transform -translate-y-full left-0 origin-top-right",
  bottomLeft: "right-0", // default
  bottomRight: "left-0",
}

const getMenuPosition = (menuPosition) => {
  return MENU_POSITIONS[menuPosition]
}

const renderTooltipWithButton = (cta) => {
  if (cta.tooltip) {
    return (
      <Tooltip
        text={cta.tooltip}
        placement="top"
        variant="dark"
        maxWidth="200px"
      >
        {renderButton(cta)}
      </Tooltip>
    )
  } else {
    return renderButton(cta)
  }
}

const renderButton = (cta) => {
  return (
    <Button
      variant="tertiary"
      fullWidth={cta.fullWidth ?? true}
      onClick={(e) => {
        e.stopPropagation()
        cta.onClick()
      }}
      disabled={cta.disabled}
      isLoading={cta.isLoading}
    >
      {cta.label}
    </Button>
  )
}

const OverflowMenu = ({
  children,
  cta,
  disabled = false,
  loading = false,
  manualClose = false,
  menuButtonFullWidth = false,
  menuButtonLabel,
  menuButtonVariant,
  menuItemWidth,
  menuOpen,
  setMenuOpen,
  variant,
  alignEnd = false,
  customMenuButton,
  icon,
  menuPosition,
}) => {
  const variantClasses = OVERFLOW_MENU_VARIANTS[variant]
  const ctaVisible = Boolean(cta)

  const getMenuButtonContent = (open) => {
    if (customMenuButton) {
      return customMenuButton({
        open,
      })
    }
    if (menuButtonLabel) {
      return (
        <MenuButton
          open={manualClose ? menuOpen : open}
          disabled={loading || disabled}
          label={menuButtonLabel}
          variant={menuButtonVariant}
          fullWidth={menuButtonFullWidth}
          onClick={() => manualClose && setMenuOpen(!menuOpen)}
        />
      )
    }
    if (icon) {
      return (
        <i
          className={classNames(
            `icon icon-${icon} rounded-sm bg-transparent transition-colors hover:bg-gray-200 hover:text-gray-800 hover:ease-in-out`,
            {
              "bg-gray-200 text-gray-800": open,
              "text-gray-600": !open,
            }
          )}
        />
      )
    }
    return (
      <VerticalDotsIcon
        open={open}
        transparentVariant={variant === "transparent"}
      />
    )
  }
  return (
    <div
      className={`flex w-full flex-row items-center whitespace-nowrap ${
        alignEnd ? "justify-end" : ""
      }`}
      data-design-system="OverflowMenu"
    >
      {ctaVisible && renderTooltipWithButton(cta)}
      <Menu
        as="div"
        className={classNames("relative inline-block", {
          "ml-2": ctaVisible,
          "w-full": menuButtonFullWidth,
        })}
      >
        {({ open }) => (
          <>
            <Menu.Button
              as={menuButtonLabel ? "div" : "button"}
              className={`z-0 mx-1 flex justify-center p-0 ${variantClasses.buttonHover} ${variantClasses.button}`}
              disabled={disabled || loading}
              onClick={(e) => {
                if (!manualClose) {
                  e.stopPropagation()
                }
              }}
            >
              {getMenuButtonContent(open)}
            </Menu.Button>
            <Transition
              as={Fragment}
              show={manualClose ? menuOpen : open}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items
                as="div"
                onClick={(e) => {
                  if (!manualClose) {
                    e.stopPropagation()
                  }
                }}
                className={`absolute z-10 min-w-44 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${getMenuPosition(
                  menuPosition
                )}`}
                style={{ width: menuItemWidth }}
                static={manualClose}
              >
                {manualClose && (
                  <i
                    className="icon icon-sf-x absolute right-2 top-2 cursor-pointer text-xxs"
                    onClick={() => setMenuOpen(false)}
                  />
                )}
                {children}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

OverflowMenu.Item = MenuItem

export const overflowMenuProps = {
  children: PropTypes.node.isRequired,
  cta: PropTypes.shape({
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
    fullWidth: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  manualClose: PropTypes.bool,
  menuButtonFullWidth: PropTypes.bool,
  menuButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  menuButtonVariant: PropTypes.oneOf([
    "secondary",
    "tertiary",
    "primary",
    "danger",
    "ghost",
  ]),
  menuItemWidth: PropTypes.string,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  variant: PropTypes.oneOf(Object.keys(OVERFLOW_MENU_VARIANTS)),
  alignEnd: PropTypes.bool,
  customMenuButton: PropTypes.func,
  icon: PropTypes.string,
  menuPosition: PropTypes.oneOf([
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
  ]),
}

OverflowMenu.propTypes = overflowMenuProps

OverflowMenu.defaultProps = {
  variant: "default",
  menuPosition: "bottomLeft",
}

export default OverflowMenu
