import PropTypes from "prop-types"
import React from "react"

import AlertBanner from "src/components/AlertBanner"

import { useLocalStorageState } from "src/hooks/use_local_storage_state"

const DismissibleAnnouncementBanner = ({
  bannerType = "info",
  closeable = false,
  copy,
  cta = {},
  localStorageKey,
}) => {
  const canRenderCta = !!cta.type && !!cta.text && !!cta.destination
  const [bannerDismissed, setBannerDismissed] = useLocalStorageState(
    "bannerDismissed",
    localStorageKey
  )

  // Key will appear as `dockwa::bannerDismissed::{localStorageKey}` in local storage.
  // It is recommended you set a localStorageKey value that is specific to the particular
  // announcement and contains the user's encoded id.
  // Eg. dockwa::bannerDismissed::MbmTransientReservationsAnnouncement::GVPZV
  // where MbmTransientReservationsAnnouncement::GVPZV is the localStorageKey
  const handleBannerDismissal = () => {
    if (localStorageKey) {
      setBannerDismissed(true)
    }
  }

  return (
    <>
      {bannerDismissed ? null : (
        <div className="px-10 pb-8 pt-4">
          <AlertBanner
            type={bannerType}
            cta={
              canRenderCta
                ? {
                    type: cta.type,
                    text: cta.text,
                    onClick: () =>
                      window.open(cta.destination, "_blank", "noreferrer"),
                  }
                : null
            }
            onClose={handleBannerDismissal}
            closeable={closeable}
          >
            {copy}
          </AlertBanner>
        </div>
      )}
    </>
  )
}

DismissibleAnnouncementBanner.propTypes = {
  bannerType: PropTypes.oneOf([
    "info",
    "warning",
    "error",
    "success",
    "neutral",
    "announcement",
    "recommendation",
  ]),
  closeable: PropTypes.bool,
  copy: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    type: PropTypes.oneOf(["primary", "secondary"]),
    text: PropTypes.string,
    destination: PropTypes.string,
  }),
  localStorageKey: PropTypes.string,
}

export default DismissibleAnnouncementBanner
