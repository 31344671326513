import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const BoatBeamInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div>
      <Form.Label htmlFor="trw-new-boat-beam">Beam</Form.Label>
      <Form.IconTextField
        icon="ft"
        position="right"
        id="trw-new-boat-beam"
        hasErrors={!!errors?.newBoat?.beamFeet}
        type="number"
        inputMode="numeric"
        {...register("newBoat.beamFeet")}
      />
      <div className="absolute bottom-0">
        {errors?.newBoat?.beamFeet?.message && (
          <Form.Error>{errors.newBoat.beamFeet.message}</Form.Error>
        )}
      </div>
    </div>
  )
}

export default BoatBeamInput
