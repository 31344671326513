import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

const CreatePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      user: {
        password: "",
        password_confirmation: "",
      },
    },
  })

  const tracker = useTracker()

  const onSubmit = (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("invitation:create_password_button_pressed", {
        experience_name: "invitation",
      })
      event.target.submit()
    }
  }
  return (
    <div className="flex-fill flex h-full justify-center pt-48">
      <div className="w-full max-w-3xl">
        <h3 className="mb-4 font-bold">Create Password</h3>
        <p className="mb-4">
          Finish setting up your account by creating a password
        </p>
        <Form action="/setup" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 flex flex-col space-y-4">
            <div className="flex flex-col space-y-1">
              <input
                type="hidden"
                name="authenticity_token"
                value={
                  document.querySelector('meta[name="csrf-token"]')?.content
                }
              />
              <input
                type="hidden"
                name="invitation_token"
                value={new URLSearchParams(window.location.search).get(
                  "invitation_token"
                )}
              />
              <input
                type="hidden"
                name="redirect_to"
                value={new URLSearchParams(window.location.search).get(
                  "redirect_to"
                )}
              />
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.TextField
                type="password"
                id="password"
                placeholder="Enter password"
                {...register("user[password]", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
                hasErrors={!!errors?.user?.password}
              />
              {errors?.user?.password ? (
                <Form.Error>{errors?.user?.password?.message}</Form.Error>
              ) : (
                <Form.Subtext>6 characters minimum</Form.Subtext>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="password_confirmation">
                Password Confirmation
              </Form.Label>
              <Form.TextField
                type="password"
                id="password_confirmation"
                placeholder="Re-enter password"
                {...register("user[password_confirmation]", {
                  required: "Password confirmation is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                  validate: (value) => {
                    return (
                      value === getValues("user[password]") ||
                      "Passwords do not match"
                    )
                  },
                })}
                hasErrors={!!errors?.user?.password_confirmation}
              />
              {errors?.user?.password_confirmation && (
                <Form.Error>
                  {errors?.user?.password_confirmation?.message}
                </Form.Error>
              )}
            </div>
          </div>
          <Button type="submit" fullWidth variant="primary">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default CreatePassword
