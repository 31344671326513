import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const saleStyles = {
  past_due: {
    className: "flex items-center space-x-1 font-semibold text-red-700",
    icon: "icon icon-exclamation-circle",
  },
  failed: {
    className: "flex items-center space-x-1 font-semibold text-red-700",
    icon: "icon icon-exclamation-circle",
  },
  settled: {
    className: "flex items-center space-x-1 font-semibold text-teal-700",
    icon: "icon icon-check text-xs",
  },
  refund: {
    className: "flex items-center space-x-1 font-semibold text-teal-700",
    icon: "icon icon-replay",
  },
  processing_refund: {
    className: "flex items-center space-x-1 font-semibold text-yellow-700",
    icon: "icon icon-schedule text-base -mt-1",
  },
  scheduled: {
    className: "flex items-center space-x-1 font-semibold text-yellow-700",
    icon: "icon icon-schedule text-base -mt-1",
  },
}

const Sale = ({
  encodedId,
  balance,
  marina,
  dueDate,
  settledAt,
  type,
  totalPaid,
  refundTotal,
  detailsLink,
}) => {
  const { className, icon } = saleStyles[type] || {}
  const settled = type === "settled"
  const refund = type === "refund"
  const processingRefund = type === "processing_refund"
  const refundTotalAbsoluteValue = Math.abs(refundTotal)

  const renderStatusCopy = () => {
    switch (type) {
      case "past_due":
        return <span>Overdue</span>
      case "failed":
        return <span>Payment Failed</span>
      case "settled":
        return <span>Paid</span>
      case "refund":
        return <span>Refunded</span>
      case "processing_refund":
        return <span>Processing refund</span>
      case "scheduled":
        return <span>Scheduled</span>
      default:
        return null
    }
  }

  const renderDate = () => {
    if (processingRefund) {
      return null
    }

    if (settledAt) {
      return <span>Settled {settledAt}</span>
    } else {
      return <span>Due {dueDate}</span>
    }
  }

  const determineAmount = () => {
    if (refund || processingRefund) {
      return refundTotalAbsoluteValue
    } else if (settled) {
      return totalPaid
    } else {
      return balance
    }
  }

  const renderAmount = () => (
    <div className={className || ""}>
      <span>{formattedCentsToDollars(determineAmount())}</span>
      {icon && <i className={icon} />}
      {renderStatusCopy()}
    </div>
  )

  const renderPartialRefundAmount = () => (
    <div className="flex items-center space-x-1 font-semibold text-teal-700">
      <span>{formattedCentsToDollars(refundTotalAbsoluteValue)}</span>
      <i className={saleStyles.refund.icon} />
      <span>Refunded</span>
    </div>
  )

  const renderAmounts = () => {
    if (settled && refundTotal && totalPaid) {
      return (
        <div className="flex flex-col space-y-2">
          {renderPartialRefundAmount()}
          <div className="w-full border-b" />
          {renderAmount()}
        </div>
      )
    } else {
      return renderAmount()
    }
  }

  return (
    <a
      href={detailsLink}
      className="m-2 flex items-center justify-between rounded-lg p-4 text-gray-900 no-underline hover:bg-gray-100"
    >
      <div className="flex flex-col space-y-2">
        <span className="font-semibold">
          {marina} - Sale #{encodedId}
        </span>
        <span>{renderDate()}</span>
        <div className={className || ""}>{renderAmounts()}</div>
      </div>
      <i className="icon icon-chevron-right text-xs text-gray-600" />
    </a>
  )
}

Sale.propTypes = {
  encodedId: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  marina: PropTypes.string.isRequired,
  dueDate: PropTypes.string,
  settledAt: PropTypes.string,
  type: PropTypes.string.isRequired,
  totalPaid: PropTypes.number,
  refundTotal: PropTypes.number,
  detailsLink: PropTypes.string.isRequired,
}

export default Sale
