import React, { useContext } from "react"

import ErrorBoundaryProvider from "src/components/ErrorBoundaryProvider"
import Form from "src/components/Form"

import { WizardContext } from "../../../WizardContextProvider"
import AvailabilityBySpaceGroup from "./AvailabilityBySpaceGroup"
import AvailabilityErrorFallback from "./AvailabilityErrorFallback"

const Availability = () => {
  const {
    availabilityData,
    isLoadingAvailabilityData,
    getAvailabilityDataError,
    marinaSlug,
  } = useContext(WizardContext)

  const renderEmptyState = () => {
    if (
      !availabilityData &&
      !isLoadingAvailabilityData &&
      !getAvailabilityDataError
    ) {
      return (
        <div data-testid="trw-availability-not-fetched">
          Set <strong>Arrival</strong>, <strong>Departure</strong>, and{" "}
          <strong>Type</strong> to view availability.
        </div>
      )
    }

    return null
  }

  const renderErrorState = () => {
    if (getAvailabilityDataError && !isLoadingAvailabilityData) {
      return (
        <Form.Error>
          An error occurred while trying to calculate availability.
        </Form.Error>
      )
    }
    return null
  }

  return (
    <div className="mt-5">
      <ErrorBoundaryProvider fallbackComponent={AvailabilityErrorFallback}>
        <AvailabilityBySpaceGroup
          marinaSlug={marinaSlug}
          data={availabilityData}
          loading={isLoadingAvailabilityData}
        />
        {renderEmptyState()}
        {renderErrorState()}
      </ErrorBoundaryProvider>
    </div>
  )
}

export default Availability
