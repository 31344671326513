import PropTypes from "prop-types"
import React from "react"

const ContactBoatDimension = ({ data }) => {
  return (
    <div className="flex space-x-8">
      {data.map((item, index) => (
        <div
          key={`boat-dimension - ${index}`}
          className="col-span-3 flex flex-col"
        >
          <span className="text-base font-semibold">{item.dimension}</span>
          <span className="text-gray-600">{item.label}</span>
        </div>
      ))}
    </div>
  )
}

ContactBoatDimension.propTypes = {
  data: PropTypes.array.isRequired,
}

export default ContactBoatDimension
