import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import EmptyStateImage from "src/main/DockwaPlusDeals/Manage/EmptyStateImage"

import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

const EmptyState = ({ newPath, canCreateDeal }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-4 pt-16">
      <EmptyStateImage />
      <span className="mb-4 text-lg font-bold">No fuel deals yet</span>
      <span className="text-muted text-center">
        Dockwa Deals lets you design custom promotions to attract boaters to
        your marina and are
        <br />
        published to the{" "}
        <a className="font-semibold" href="https://dockwa.com/dockwa_deals">
          Dockwa Deals listing page
        </a>
        . Marinas with Dockwa Deals see 8x more traffic
        <br />
        to their pages than those without. Run your first Dockwa Deal today!
      </span>
      <MaybeRestrictedTooltip
        restricted={!canCreateDeal}
        allowedRoles={["Admin", "Dockmaster"]}
      >
        <a
          href={newPath}
          className={classNames("btn btn-primary w-min", {
            "pointer-events-none cursor-not-allowed opacity-40": !canCreateDeal,
          })}
        >
          Create new fuel deal
        </a>
      </MaybeRestrictedTooltip>
    </div>
  )
}

EmptyState.propTypes = {
  newPath: PropTypes.string,
  canCreateDeal: PropTypes.bool.isRequired,
}

export default EmptyState
