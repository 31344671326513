import PropTypes from "prop-types"
import React, { useContext, useRef, useState } from "react"

import { ManageContractsContext } from ".."
import DropdownButton from "./DropdownButton"

const DropdownSelect = ({
  children,
  label,
  onChange,
  onClose,
  options,
  selected,
}) => {
  const { archivedGroupsPagePath } = useContext(ManageContractsContext)

  const [isOpen, setIsOpen] = useState(false)
  let blurTimeout = useRef()

  const handleBlur = (event) => {
    blurTimeout = setTimeout(() => {
      setIsOpen(false)
      onClose && onClose()
    })
  }

  // clear blurTimeout if a child element received focus
  const handleFocus = (event) => {
    clearTimeout(blurTimeout)
  }

  const handleInputChange = (event) => {
    // Safari does not give focus to input on click
    event.target.focus()
    onChange(event)
  }

  const renderOption = (option) => {
    const isChecked = Boolean(
      selected.includes(option.id) || selected.includes(option.name)
    )

    return (
      <div className="checkbox" key={option.id}>
        <label>
          <input
            type="checkbox"
            name={option.name}
            id={option.id}
            checked={isChecked}
            onChange={handleInputChange}
            className="cursor-pointer"
          />
          <span tabIndex="-1">{option.name}</span>
        </label>
      </div>
    )
  }

  const toggleOpen = () => {
    if (isOpen && onClose) {
      onClose()
    }

    setIsOpen(!isOpen)
  }

  return (
    <div onBlur={handleBlur}>
      <DropdownButton
        label={label}
        isOpen={isOpen}
        onFocus={handleFocus}
        toggleOpen={toggleOpen}
      >
        {children}
        <div className="px-5">
          {options.map((option) => renderOption(option))}
          {label === "Contract Group" && (
            <div className="flex w-80 flex-col">
              <div className="mb-2 w-full border-b" />
              <span className="text-gray-600">
                Archived groups aren&apos;t shown here. Access them on the{" "}
                <a className="font-semibold" href={archivedGroupsPagePath}>
                  archived groups page
                </a>
              </span>
            </div>
          )}
        </div>
      </DropdownButton>
    </div>
  )
}

DropdownSelect.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
}

export default DropdownSelect
