import { queryApi } from "src/utils/api/ApiMethods"

export const getInvoices = ({ pointOfSaleSaleId }) => {
  return queryApi(`/account/sales/${pointOfSaleSaleId}/invoices`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
