import React, { useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../WizardContextProvider"
import DatePickerInput from "./DatePickerInput"

const ArrivalInput = () => {
  const { handleArrivalChange } = useContext(WizardContext)
  const {
    watch,
    formState: { errors },
  } = useFormContext()
  const departure = watch("departure")

  return (
    <div>
      <Form.Label htmlFor="trw-arrival">Arrival</Form.Label>
      <Controller
        name="arrival"
        render={({ field: { value } }) => (
          <Form.DatePicker
            id="trw-arrival"
            maxDate={departure}
            hasErrors={Boolean(errors?.arrival)}
            renderCustomHeader={(props) => (
              <Form.DatePicker.QuickNavHeader {...props} />
            )}
            value={value}
            onChange={handleArrivalChange}
            customInput={
              <DatePickerInput
                errorMessage={errors?.arrival ? errors.arrival.message : null}
              />
            }
          />
        )}
      />
    </div>
  )
}

export default ArrivalInput
