import PropTypes from "prop-types"
import React, { createContext } from "react"

import Modal from "src/components/Modal"

import usePaymentFlow, { PAYMENT_PHASES } from "../../hooks/usePaymentFlow"
import CustomerInfo from "./CustomerInfo"

export const PaymentFlowContext = createContext()

const PaymentModal = ({ onClose, paymentFlow }) => {
  const { checkoutDetails, flowDetails } = usePaymentFlow({
    exitPaymentFlow: onClose,
    paymentFlow,
  })

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header title={flowDetails.title} />
      <Modal.Body controlOverflow={false}>
        <PaymentFlowContext.Provider
          value={{
            checkoutDetails,
            flowDetails,
          }}
        >
          {[
            PAYMENT_PHASES.detail_collection,
            PAYMENT_PHASES.confirmation,
            PAYMENT_PHASES.processing,
          ].includes(flowDetails.paymentPhase) ? (
            <CustomerInfo />
          ) : null}
          {flowDetails.body}
          {flowDetails.errors ? (
            <ul className="mt-4 list-none pl-0 text-red-600">
              {flowDetails.errors.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          ) : null}
        </PaymentFlowContext.Provider>
      </Modal.Body>
      {flowDetails.hideFooter ? null : (
        <Modal.Footer
          onClose={flowDetails.onCancel}
          confirmBtnText={flowDetails.confirmButtonLabel}
          cancelBtnText={flowDetails.cancelButtonLabel}
          disabled={flowDetails.paymentPhase === PAYMENT_PHASES.processing}
          disableCancelBtn={
            flowDetails.paymentPhase === PAYMENT_PHASES.processing
          }
          onSubmit={flowDetails.goToNextPhase}
        />
      )}
    </Modal>
  )
}

PaymentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  paymentFlow: PropTypes.shape({
    DetailCollectionScreen: PropTypes.func,
    ConfirmationScreenDetails: PropTypes.func.isRequired,
    ProcessingScreen: PropTypes.func,
    processSale: PropTypes.func.isRequired,
    validatePaymentMethod: PropTypes.func,
    customerSelection: PropTypes.oneOf(["optional", "required"]).isRequired,
    customerIdentityRequired: PropTypes.bool,
    showTipInput: PropTypes.bool,
  }).isRequired,
}

export default PaymentModal
