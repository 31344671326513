import React, { useContext, useEffect, useState } from "react"

import Tabs from "src/components/Tabs"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import { AccountingContext } from "./AccountingView"
import MappingTable from "./MappingTable"

const MappingTab = () => {
  const { isMbm, selectedAccountingService } = useContext(AccountingContext)
  const [currentMappingTab, setCurrentMappingTab] = useState(0)
  const urlParams = new URLSearchParams(window.location.search)
  const activeMappingTab = urlParams.get("mapping_tab")
  const page = urlParams.get("page")
  const [currentAccountingPage, setCurrentAccountingPage] = useState(
    page && activeMappingTab === "accounting" ? parseInt(page) : 1
  )
  const [currentRevenuePage, setCurrentRevenuePage] = useState(
    page && activeMappingTab === "revenue" ? parseInt(page) : 1
  )
  const [currentItemsPage, setCurrentItemsPage] = useState(
    page && (activeMappingTab === "items" || activeMappingTab === "pos")
      ? parseInt(page)
      : 1
  )
  const [currentOtherPage, setCurrentOtherPage] = useState(
    page && activeMappingTab === "other" ? parseInt(page) : 1
  )

  const tabName = (tabNumber) => {
    switch (tabNumber) {
      case 0:
        return "accounting"
      case 1:
        return "revenue"
      case 2:
        return isMbm ? "items" : "pos"
      case 3:
        return "other"
      default:
        return ""
    }
  }

  const itemsTabTitle = () => {
    if (isMbm) {
      return "Items"
    } else {
      return "POS Items"
    }
  }

  const itemsCategoryKey = () => {
    if (isMbm) {
      return "items"
    } else {
      return "pos"
    }
  }

  const handleTabChange = (tab) => {
    setCurrentMappingTab(tab)
    updateUrlQueryParams({ mapping_tab: tabName(tab) })
  }

  useEffect(
    function handleTabChangeEffect() {
      if (activeMappingTab === "accounting") {
        setCurrentMappingTab(0)
        updateUrlQueryParams({
          mapping_tab: "accounting",
          page: currentAccountingPage,
        })
      } else if (activeMappingTab === "revenue") {
        setCurrentMappingTab(1)
        updateUrlQueryParams({
          mapping_tab: "revenue",
          page: currentRevenuePage,
        })
      } else if (activeMappingTab === "items" || activeMappingTab === "pos") {
        setCurrentMappingTab(2)
        updateUrlQueryParams({
          mapping_tab: isMbm ? "items" : "pos",
          page: currentItemsPage,
        })
      } else {
        setCurrentMappingTab(3)
        updateUrlQueryParams({
          mapping_tab: "other",
          page: currentOtherPage,
        })
      }
    },
    [
      isMbm,
      currentMappingTab,
      currentAccountingPage,
      currentRevenuePage,
      currentItemsPage,
      currentOtherPage,
      activeMappingTab,
    ]
  )

  return (
    <div>
      <h4 className="mb-1 pt-6 font-semibold text-gray-900">Mapping</h4>
      <div className="mb-8">
        <span className="text-gray-800">
          Map your {selectedAccountingService?.name || "Accounting System"}{" "}
          Accounts to Dockwa standard revenue fields.
        </span>
      </div>
      <Tabs selectedIndex={currentMappingTab} onChange={handleTabChange}>
        <div className="mt-4 flex w-full flex-col justify-between bg-white lg:flex-row">
          <div className="order-2 flex justify-center border-b lg:order-1 lg:border-none">
            <Tabs.TabList>
              <Tabs.Tab title="Accounting Fields" />
              <Tabs.Tab title="Boat Storage" />
              <Tabs.Tab title={itemsTabTitle()} />
              <Tabs.Tab title="Other" />
            </Tabs.TabList>
          </div>
        </div>
        <div className="px-0">
          <Tabs.Panels>
            <Tabs.Panel>
              <MappingTable
                categoryKey="accounting"
                setPage={setCurrentAccountingPage}
                page={currentAccountingPage}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <MappingTable
                categoryKey="revenue"
                setPage={setCurrentRevenuePage}
                page={currentRevenuePage}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <MappingTable
                categoryKey={itemsCategoryKey()}
                setPage={setCurrentItemsPage}
                page={currentItemsPage}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <MappingTable
                categoryKey="other"
                setPage={setCurrentOtherPage}
                page={currentOtherPage}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </div>
      </Tabs>
    </div>
  )
}

export default MappingTab
