export const TABS = {
  itinerary: "itinerary",
  rate: "rate",
  installments: "installments",
  addItems: "items",
  documents: "documents",
  contactAndBoat: "contact_and_boat",
  paymentMethod: "payment_methods",
  additionalSettings: "additional_settings",
  templatesDocuments: "templates_documents",
}

export const VALIDATED_FIELDS_BY_TAB = {
  itinerary: ["name", "startDate", "billingCycle", "endDate", "rate.dueDay"],
  installments: ["contractInstallments"],
  rate: [
    "rate.amount",
    "rate.taxRate",
    "rate.pricingStructure",
    "rate.longTermStorageProductId",
  ],
  contactAndBoat: ["contact_id", "contact_boat_id"],
  addItems: ["posItemsData"],
  documents: [],
  paymentMethod: ["acceptedPaymentMethods"],
  additionalSettings: [],
  templatesDocuments: ["selectedTemplateId", "templateComplete"],
}

export const INITIAL_TAB_ORDER = [
  TABS.itinerary,
  TABS.rate,
  TABS.paymentMethod,
  TABS.installments,
  TABS.addItems,
  TABS.documents,
]

export const INITIAL_TAB_ORDER_WITH_TEMPLATES = [
  TABS.itinerary,
  TABS.rate,
  TABS.paymentMethod,
  TABS.installments,
  TABS.addItems,
  TABS.templatesDocuments,
  TABS.additionalSettings,
]

export const PAYMENT_METHODS = {
  bank: "us_bank_account",
  card: "card",
}

export const INITIAL_DISCOUNT_DISTRIBUTION_OPTIONS = [
  {
    value: "evenly",
    text: "Apply evenly across all installments",
  },
  {
    value: "reduce_early_to_late",
    text: "Apply to first installment(s) until fully used",
  },
  {
    value: "reduce_late_to_early",
    text: "Apply to last installment(s) until fully used",
  },
]

export const BILLING_CYCLES = {
  MONTH_TO_MONTH: "month_to_month",
  MONTHLY_INSTALLMENTS: "monthly_installments",
  CUSTOM_INSTALLMENTS: "installments", // TODO: TB-1927 rename installments -> custom_installments
}

export const BILLING_CYCLE_DISPLAY_NAMES = {
  [BILLING_CYCLES.MONTH_TO_MONTH]: "Month to Month",
  [BILLING_CYCLES.MONTHLY_INSTALLMENTS]: "Monthly Installments",
  [BILLING_CYCLES.CUSTOM_INSTALLMENTS]: "Custom Installments",
}
