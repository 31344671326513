import React from "react"
import { useQuery } from "react-query"
import SalesCollections from "src/main/Account/Trips/Sales/SaleCollections"

import Error from "src/components/Error"
import Loader from "src/components/Loader"

import { getSales } from "src/api/Account/Trips"

const Sales = () => {
  const {
    data: salesCollections,
    isLoading,
    isError,
  } = useQuery(["account", "trips", "sales"], getSales, {
    retry: false,
  })

  if (isLoading) {
    return <Loader name="sales" />
  } else if (isError) {
    return <Error name={"sales"} />
  } else if (salesCollections?.length > 0) {
    return <SalesCollections salesCollections={salesCollections} />
  } else {
    return (
      <div className="card flex justify-center p-8">
        <div className="flex max-w-xl flex-col items-center justify-center">
          <i className="icon icon-md-shopping-cart text-5xl leading-none text-blue-700" />
          <div className="mt-0 text-lg font-semibold text-black">
            You don&apos;t have any sales
          </div>
        </div>
      </div>
    )
  }
}

export default Sales
