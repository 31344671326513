const detectBarcodeScan = (callback, threshold = 100, minimumLength = 4) => {
  let inputBuffer = ""
  let lastTime = Date.now()

  const handleKeypress = (event) => {
    const currentTime = Date.now()
    const timeDifference = currentTime - lastTime
    lastTime = currentTime

    /// Assume last character the scanner sends after the barcode is "Enter"
    if (event.key === "Enter") {
      if (inputBuffer.length >= minimumLength) {
        callback(inputBuffer)
        event.preventDefault()
      }
      inputBuffer = ""
    } else {
      if (timeDifference < threshold) {
        // Handle 2nd and subsequent characters
        inputBuffer += event.key
      } else {
        // Handle first character
        inputBuffer = event.key
      }
    }
  }

  document.addEventListener("keypress", handleKeypress)
}

export default detectBarcodeScan
