import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const SpecialRequestInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div>
      <Form.Label htmlFor="special-request">Special Request</Form.Label>
      <Form.Textarea
        id="special-request"
        {...register("special_request")}
        hasErrors={Boolean(errors?.special_request)}
        rows={2}
      />
      {errors?.special_request && (
        <Form.Error>{errors?.specialRequest?.message}</Form.Error>
      )}
    </div>
  )
}

export default SpecialRequestInput
