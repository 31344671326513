import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useInfiniteQuery } from "react-query"
import DealCard from "src/main/DockwaPlusDeals/Marketing/DealCard"
import { renderStatus } from "src/main/DockwaPlusDeals/Marketing/shared_methods"

import EventBadge from "src/components/Badge/EventBadge"
import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"
import Loader from "src/components/Loader"
import MultiCarousel from "src/components/MultiCarousel"

import useWindowSize from "src/hooks/use_window_size"

const DealSwimlane = ({
  hasActiveDockwaPlusSubscription,
  title,
  subtitle,
  query,
  queryKey,
  variant,
  eventBadgeProps,
  primaryCta,
  secondaryCta,
}) => {
  const { isLargeScreen } = useWindowSize()
  const buttonGroupCta = {
    href: primaryCta.href,
    text: primaryCta.text,
  }

  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => query({ page: pageParam }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length) {
          return pages.length + 1
        }
      },
    }
  )

  const deals = useMemo(() => data?.pages?.flat?.(), [data])

  const renderDeals = () => (
    <MultiCarousel
      sliderClass="flex space-x-2 -ml-2"
      buttonGroupCta={buttonGroupCta}
      showArrows={isLargeScreen}
      beforeChange={() => {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage()
        }
      }}
      hasNextPage={hasNextPage}
      totalItems={deals?.length}
    >
      {deals.map((deal) => (
        <div className="h-full" key={deal.id}>
          <DealCard
            deal={deal}
            hasActiveDockwaPlusSubscription={hasActiveDockwaPlusSubscription}
            variant={variant}
          />
        </div>
      ))}
      {isFetchingNextPage && hasNextPage && (
        <div className="col-span-12 flex justify-center">
          <Loader name="Dockwa+ Deals" />
        </div>
      )}
    </MultiCarousel>
  )

  if (deals?.length > 0 || isLoading || isError) {
    return (
      <div className="relative flex flex-col space-y-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col space-y-2">
            <HeadingModalTitle>
              <div className="flex items-center space-x-2">
                <span>{title}</span>
                {eventBadgeProps && (
                  <EventBadge
                    text="New"
                    variant="small"
                    color="blue"
                    mode={eventBadgeProps.mode}
                    maxViews={eventBadgeProps.maxViews}
                    hideAt={eventBadgeProps.hideAt}
                  />
                )}
              </div>
            </HeadingModalTitle>
            <span className="text-gray-600">{subtitle}</span>
            {primaryCta && !isLargeScreen && (
              <a href={primaryCta.href} className="font-semibold text-blue-700">
                {primaryCta.text}
              </a>
            )}
            {secondaryCta && (
              <a
                href={secondaryCta.href}
                className="font-semibold text-blue-700"
              >
                {secondaryCta.text}
              </a>
            )}
          </div>
        </div>
        {isLoading || isError
          ? renderStatus({ isLoading, isError, error })
          : renderDeals()}
      </div>
    )
  }
}

DealSwimlane.propTypes = {
  hasActiveDockwaPlusSubscription: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  primaryCta: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  secondaryCta: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  query: PropTypes.func.isRequired,
  queryKey: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["reservation", "fuel"]),
  eventBadgeProps: PropTypes.shape({
    mode: PropTypes.oneOf(["time", "count"]),
    hideAt: PropTypes.instanceOf(Date),
    maxViews: PropTypes.number,
  }),
}

export default DealSwimlane
