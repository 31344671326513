import Decimal from "decimal.js"
import * as yup from "yup"

export const contactBoatSchema = yup
  .object()
  .shape({
    name: yup.string().required("Boat name is required."),
    boat_type: yup.string().required("Boat type is required."),
    make: yup.string(),
    model: yup.string(),
    year: yup.string(),
    registration_attributes: yup.object().shape({
      number: yup.string(),
    }),
    hailing_port: yup.string(),
    length_overall_feet: yup
      .string()
      .required("Length (feet) is required.")
      .test(
        "testing contact_boat.length_overall_feet",
        "Length (feet) is invalid.",
        (lengthOverall, context) => {
          if (lengthOverall) {
            const lengthOverallNumber = new Decimal(lengthOverall)
            if (lengthOverallNumber.lte(0)) {
              return context.createError({
                message: "Length (feet) must be greater than 0.",
                path: "contact_boat.length_overall_feet",
              })
            } else if (!lengthOverallNumber.isInteger()) {
              return context.createError({
                message: "Length (feet) must be a whole number.",
                path: "contact_boat.length_overall_feet",
              })
            }
            return true
          }
          return true
        }
      ),
    length_overall_inches: yup
      .string()
      .test(
        "testing contact_boat.length_overall_inches",
        "Length (inches) is invalid.",
        (lengthOverall, context) => {
          if (lengthOverall) {
            const lengthOverallNumber = new Decimal(lengthOverall)
            if (lengthOverallNumber.lt(0)) {
              return context.createError({
                message: "Length (inches) cannot be negative.",
                path: "contact_boat.length_overall_inches",
              })
            } else if (lengthOverallNumber.gte(12)) {
              return context.createError({
                message: "Length (inches) must be less than 12.",
                path: "contact_boat.length_overall_inches",
              })
            } else if (!lengthOverallNumber.isInteger()) {
              return context.createError({
                message: "Length (inches) must be a whole number.",
                path: "contact_boat.length_overall_inches",
              })
            }
            return true
          }
          return true
        }
      ),
    beam_feet: yup
      .string()
      .required("Beam (feet) is required.")
      .test(
        "testing contact_boat.beam_feet",
        "Beam (feet) is invalid.",
        (beam, context) => {
          if (beam) {
            const beamNumber = new Decimal(beam)
            if (beamNumber.lte(0)) {
              return context.createError({
                message: "Beam (feet) must be greater than 0.",
                path: "contact_boat.beam_feet",
              })
            } else if (!beamNumber.isInteger()) {
              return context.createError({
                message: "Beam (feet) must be a whole number.",
                path: "contact_boat.beam_feet",
              })
            }
            return true
          }
          return true
        }
      ),
    beam_inches: yup
      .string()
      .test(
        "testing contact_boat.beam_inches",
        "Beam (inches) is invalid.",
        (beam, context) => {
          if (beam) {
            const beamNumber = new Decimal(beam)
            if (beamNumber.lt(0)) {
              return context.createError({
                message: "Beam (inches) cannot be negative.",
                path: "contact_boat.beam_inches",
              })
            } else if (beamNumber.gte(12)) {
              return context.createError({
                message: "Beam (inches) must be less than 12.",
                path: "contact_boat.beam_inches",
              })
            } else if (!beamNumber.isInteger()) {
              return context.createError({
                message: "Beam (inches) must be a whole number.",
                path: "contact_boat.beam_inches",
              })
            }
            return true
          }
          return true
        }
      ),
    height_feet: yup
      .string()
      .test(
        "testing contact_boat.height_feet",
        "Height (feet) is invalid.",
        (height, context) => {
          const heightInches = context.parent.height_inches
          if (height) {
            const heightNumber = new Decimal(height)
            if (heightNumber.lte(0)) {
              return context.createError({
                message: "Height (feet) must be greater than 0.",
                path: "contact_boat.height_feet",
              })
            } else if (!heightNumber.isInteger()) {
              return context.createError({
                message: "Height (feet) must be a whole number.",
                path: "contact_boat.height_feet",
              })
            }
            return true
          } else if (heightInches) {
            return context.createError({
              message: "Height, if present, must be minimum 1 foot.",
              path: "contact_boat.height_feet",
            })
          }
          return true
        }
      ),
    height_inches: yup
      .string()
      .test(
        "testing contact_boat.height_inches",
        "Height (inches) is invalid.",
        (height, context) => {
          if (height) {
            const heightNumber = new Decimal(height)
            if (heightNumber.lt(0)) {
              return context.createError({
                message: "Height (inches) cannot be negative.",
                path: "contact_boat.height_inches",
              })
            } else if (heightNumber.gte(12)) {
              return context.createError({
                message: "Height (inches) must be less than 12.",
                path: "contact_boat.height_inches",
              })
            } else if (!heightNumber.isInteger()) {
              return context.createError({
                message: "Height (inches) must be a whole number.",
                path: "contact_boat.height_inches",
              })
            }
            return true
          }
          return true
        }
      ),
    draw_feet: yup
      .string()
      .test(
        "testing contact_boat.draw_feet",
        "Draft (feet) is invalid.",
        (draw, context) => {
          const drawInches = context.parent.draw_inches
          if (draw) {
            const drawNumber = new Decimal(draw)
            if (drawNumber.lte(0)) {
              return context.createError({
                message: "Draft (feet) must be greater than 0.",
                path: "contact_boat.draw_feet",
              })
            } else if (!drawNumber.isInteger()) {
              return context.createError({
                message: "Draft (feet) must be a whole number.",
                path: "contact_boat.draw_feet",
              })
            }
            return true
          } else if (drawInches) {
            return context.createError({
              message: "Draft, if present, must be minimum 1 foot.",
              path: "contact_boat.draw_feet",
            })
          }
          return true
        }
      ),
    draw_inches: yup
      .string()
      .test(
        "testing contact_boat.draw_inches",
        "Draft (inches) is invalid.",
        (draw, context) => {
          if (draw) {
            const drawNumber = new Decimal(draw)
            if (drawNumber.lt(0)) {
              return context.createError({
                message: "Draft (inches) cannot be negative.",
                path: "contact_boat.draw_inches",
              })
            } else if (drawNumber.gte(12)) {
              return context.createError({
                message: "Draft (inches) must be less than 12.",
                path: "contact_boat.draw_inches",
              })
            } else if (!drawNumber.isInteger()) {
              return context.createError({
                message: "Draft (inches) must be a whole number.",
                path: "contact_boat.draw_inches",
              })
            }
            return true
          }
          return true
        }
      ),
  })
  .required()

export const contactSchema = yup
  .object()
  .shape({
    name: yup.string().required("Contact name is required."),
    email: yup.string().required("Email is required."),
  })
  .required()
