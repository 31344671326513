import { useMutation, useQuery } from "react-query"

import {
  queryRefundablePaymentMethods,
  querySaleRefundablePaymentMethods,
} from "src/api/Billing/Payments"
import { reversePayment } from "src/api/Payments"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const useGetRefundablePaymentMethods = ({
  reservationId,
  saleId,
  isSettling = false,
  options,
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const queryKey = [
    "refundablePaymentMethods",
    { saleId, reservationId, isSettling },
    marinaSlug,
  ]

  const queryFn = reservationId
    ? () =>
        queryRefundablePaymentMethods({ reservationId, marinaSlug, isSettling })
    : () => querySaleRefundablePaymentMethods({ saleId, marinaSlug })

  const { data, isFetching, isError } = useQuery({
    queryKey,
    queryFn,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    ...options,
  })

  return { data, isFetching, isError }
}

export const useReversePayment = ({
  reservationId,
  saleId,
  paymentId,
  options,
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const queryKey = ["reversePayment", { saleId, reservationId }, marinaSlug]

  const { mutate, isLoading } = useMutation({
    queryKey,
    mutationFn: () =>
      reversePayment({
        marinaSlug,
        invoiceId: paymentId,
      }),
    ...options,
  })
  return { mutate, isLoading }
}
