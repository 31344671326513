import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const createCart = async ({ marinaSlug, soldById }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/carts`,
    method: "POST",
    data: { cart: { soldById } },
  })
}

export const getCart = async ({ marinaSlug, cartId }) => {
  return queryApi(`/manage/${marinaSlug}/point_of_sale/carts/${cartId}`)
}

export const updateCart = async ({ marinaSlug, cartId, cart }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/carts/${cartId}`,
    method: "PATCH",
    data: { cart },
  })
}

export const addCartItem = async ({ marinaSlug, cartId, item }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/carts/${cartId}/add_item`,
    method: "POST",
    data: { item },
  })
}

export const updateCartItem = async ({ marinaSlug, cartId, itemId, item }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/carts/${cartId}/items/${itemId}`,
    method: "PATCH",
    data: { item },
  })
}

export const removeCartItem = async ({ marinaSlug, cartId, itemId }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/carts/${cartId}/items/${itemId}`,
    method: "DELETE",
  })
}

export const deleteCart = async ({ marinaSlug, cartId }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/carts/${cartId}`,
    method: "DELETE",
  })
}
