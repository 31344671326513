import PropTypes from "prop-types"
import React, { useState } from "react"
import CustomerAndBoatSelector from "src/main/PointOfSale/Checkout/PaymentModal/CustomerAndBoatSelector"
import CustomerCreatorModal from "src/main/PointOfSale/Checkout/PaymentModal/CustomerCreatorModal"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

export default function SelectCustomerModal({ isOpen, onClose }) {
  const [isCreating, setIsCreating] = useState(false)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="mediumFixed">
      <Modal.Header title={"Select Customer"} />
      <Modal.Body controlOverflow={false}>
        <div>
          {isCreating ? (
            <CustomerCreatorModal onClose={() => setIsCreating(false)} />
          ) : (
            <CustomerAndBoatSelector
              onAddNewCustomerClicked={() => setIsCreating(true)}
              customerIdentityRequired={false}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end">
          <Button variant="primary" type="button" onClick={onClose}>
            Continue
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

SelectCustomerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
