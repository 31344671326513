import PropTypes from "prop-types"
import React, { useRef } from "react"

import Button from "src/components/Button"
import Tooltip from "src/components/Tooltip"

import useIsTruncated from "src/hooks/use_is_truncated"
import { useToast } from "src/hooks/use_toast"

const Details = ({ label, data, link, copyable }) => {
  const showToast = useToast()
  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  const renderDetails = () => {
    if (link) {
      return (
        <div
          ref={textRef}
          className="line-clamp-2 w-full overflow-hidden text-gray-600"
        >
          <a href={link} className="font-semibold text-blue-700">
            {data}
          </a>
        </div>
      )
    } else {
      return (
        <div
          ref={textRef}
          className="line-clamp-2 w-full overflow-hidden text-gray-600"
        >
          {data}
        </div>
      )
    }
  }

  return (
    <div className="flex items-center justify-between">
      <div className="line-clamp-2 flex flex-col space-y-2">
        <span className="font-semibold">{label}</span>
        {isTruncated ? (
          <Tooltip text={data} variant="dark" maxWidth="300px" placement="top">
            {renderDetails()}
          </Tooltip>
        ) : (
          renderDetails()
        )}
      </div>
      {copyable && (
        <div className="mr-1">
          <Button
            variant="ghost"
            onClick={() => {
              navigator.clipboard.writeText(data)
              showToast(`${label} copied to clipboard!`, { type: "success" })
            }}
            small
          >
            Copy
          </Button>
        </div>
      )}
    </div>
  )
}

Details.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.string,
  link: PropTypes.string,
  copyable: PropTypes.bool,
}

export default Details
