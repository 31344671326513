import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"

import { publishMarina } from "src/api/Onboarding/Claim"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const FinalStep = ({ firstName }) => {
  const tracker = useTracker()
  const marinaSlug = getCurrentMarinaSlug()
  const [isRedirecting, setIsRedirecting] = useState(false)

  const { mutate: publish, isLoading } = useMutation(
    () => publishMarina(marinaSlug),
    {
      onSuccess: (data) => {
        setIsRedirecting(true)
        window.location.href = data.redirectUrl
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="mb-4 flex w-full flex-col items-center justify-center px-2 text-center lg:w-1/3">
        <h2 className="text-2xl font-bold">
          That&apos;s everything we need for now
          {firstName ? `, ${firstName}` : ""}!
        </h2>
        <p className="text-center text-gray-600">
          Your marina profile is now ready to accept leads. You can continue to
          add more information to your profile anytime in your marina profile.
        </p>
      </div>
      <Button
        variant="primary"
        onClick={() => {
          tracker.trackEvent("onboarding:claim:start_accepting_leads_pressed")
          publish()
        }}
        isLoading={isLoading}
        disabled={isRedirecting}
      >
        Start Accepting Leads
      </Button>
    </div>
  )
}

FinalStep.propTypes = {
  firstName: PropTypes.string.isRequired,
}

export default FinalStep
