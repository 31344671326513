import PropTypes from "prop-types"
import React from "react"

const variantClasses = {
  fuel: {
    backgroundLeft: "bg-supplementary-orange-400",
    backgroundRight: "bg-supplementary-orange-600",
    circleBackground: "bg-supplementary-orange-300",
    icon: "icon icon-fuel",
  },
}

const DealTicketWithIcon = ({ variant = "fuel" }) => {
  const { backgroundLeft, backgroundRight, icon, circleBackground } =
    variantClasses[variant]
  return (
    <div className="relative flex items-center">
      <div className={`absolute h-13 w-13 rounded-full ${circleBackground}`} />
      <div className="flex rotate-[-15deg] transform select-none text-lg font-bold text-white">
        <div
          className={`relative w-4 overflow-hidden rounded-l ${backgroundLeft}`}
        >
          <div
            className={`absolute inset-y-0 left-0 my-auto -ml-4 h-2 w-5 rounded-full ${circleBackground}`}
          />
        </div>
        <div className={`rounded-r ${backgroundRight} z-10 px-2 py-1`}>
          <i className={icon} />
        </div>
      </div>
    </div>
  )
}

DealTicketWithIcon.propTypes = {
  variant: PropTypes.oneOf(["fuel"]),
}

export default DealTicketWithIcon
