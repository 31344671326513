import { addYears, format, isEqual, subYears } from "date-fns"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { centsToDollars } from "src/main/Billing/Items/helpers"
import { ReservationDatesContext } from "src/main/Billing/ReservationDatesContext"
import { PERCENT_OF_STORAGE_PRICING_STRUCTURE } from "src/main/Billing/constants"
import { getFirstOfNextMonth } from "src/main/Billing/helpers"
import DiscountTable from "src/main/Contracts/ContractsForm/shared/DiscountTable"
import { formatFeesAndDiscountsData } from "src/main/Contracts/ContractsForm/utils"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"

import {
  extendRecurringProductSaleInvoicing,
  queryExtendInvoicingDateOptions,
  queryNamedRates,
  updateRecurringProductSale,
  updateReservationDates,
} from "src/api/Billing/Items"

import { safePercentageDecimal } from "src/utils/number_helpers"
import { snakeCaseToHumanize } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { BILLING_CYCLES } from "../../../Contracts/constants"
import { parseDateValue } from "../../helpers"
import { parseSavedRecurringDiscount } from "../helpers"

const EditRecurringProductSaleModal = ({
  recurringProductSale,
  pricingUrl,
  monthlyRateOptions,
  contactBoat,
  onClose,
  defaultEndDate,
}) => {
  const [selectedAction, setSelectedAction] = useState("editDates")
  const [selectedEndDateOption, setSelectedEndDateOption] = useState(
    recurringProductSale.end_date ? "onDate" : "never"
  )
  const isEditingDates = selectedAction === "editDates"
  const isEditingRate = selectedAction === "editRate"
  const isExtendingInvoicing = selectedAction === "extendInvoicing"
  const isEditingDiscounts = selectedAction === "editDiscounts"
  const marinaSlug = getCurrentMarinaSlug()
  const canExtendInvoicing = recurringProductSale.can_extend_invoicing
  const productSaleStartDate = parseDateValue(recurringProductSale.start_date)
  const productSaleEndDate =
    recurringProductSale.end_date &&
    parseDateValue(recurringProductSale.end_date)
  const oneYearAgo = subYears(new Date(), 1)
  const oneYearFromNow = addYears(new Date(), 1)
  const {
    reservationCheckInDate,
    reservationCheckOutDate,
    setReservationDates,
  } = useContext(ReservationDatesContext)
  const isPercentOfReservationSale =
    recurringProductSale.pricing_structure ===
    PERCENT_OF_STORAGE_PRICING_STRUCTURE
  const isMonthlyTransient =
    productSaleEndDate && !isEqual(productSaleEndDate, defaultEndDate)
  const {
    isLoading: isLoadingRates,
    isError: isErrorRates,
    data: namedRates,
  } = useQuery(["rates", marinaSlug], () => queryNamedRates({ marinaSlug }), {
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled:
      recurringProductSale.reservation_sale && selectedAction === "editRate",
  })

  const {
    isLoading: isLoadingInvoicingDates,
    isError: isErrorInvoicingDates,
    data: extendInvoicingDateOptions,
  } = useQuery(
    ["extend_invoicing_dates", marinaSlug],
    () =>
      queryExtendInvoicingDateOptions({
        marinaSlug,
        id: recurringProductSale.id,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: selectedAction === "extendInvoicing",
    }
  )

  const methods = useForm({
    defaultValues: {
      namedRateId: "custom",
      effectiveDate: getFirstOfNextMonth(),
      rate: {
        pricingStructure: recurringProductSale.pricing_structure || null,
      },
      feesAndDiscounts:
        recurringProductSale.discounts
          ?.map((discount) => parseSavedRecurringDiscount(discount))
          ?.sort((a, b) => a.applyOrder - b.applyOrder) || [],
      startDate: productSaleStartDate,
      endDate: productSaleEndDate
        ? recurringProductSale.reservation_sale
          ? defaultEndDate
          : productSaleEndDate
        : null,
    },
  })
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = methods

  const [selectedRateId, selectedStartDate, selectedEndDate] = watch([
    "namedRateId",
    "startDate",
    "endDate",
  ])
  const isNamedRate = selectedRateId !== "custom"

  const allInvoiceDatesDisabled = () => {
    if (!isExtendingInvoicing) {
      return false
    }
    if (!extendInvoicingDateOptions) {
      return true
    }

    const enabledOptions = extendInvoicingDateOptions.filter(
      (option) => !option.disabled
    )

    return enabledOptions.length === 0
  }

  const updateAndValidate = (name, value) => {
    setValue(name, value, {
      shouldValidate: true,
    })
  }

  const validateEffectiveDate = (effectiveDate) => {
    const dateMin = Math.max(productSaleStartDate, oneYearAgo)
    const dateMax = productSaleEndDate
      ? Math.min(productSaleEndDate, oneYearFromNow)
      : oneYearFromNow

    const minValidationText =
      productSaleStartDate > oneYearAgo
        ? `the initial ${format(productSaleStartDate, "M/d/yyyy")} start date`
        : "1 year ago"
    const maxValidationText =
      productSaleEndDate && productSaleEndDate < oneYearFromNow
        ? `the end date of ${format(productSaleEndDate, "M/d/yyyy")}`
        : "1 year from now"

    return (
      (effectiveDate >= dateMin && effectiveDate <= dateMax) ||
      `New rate cannot take effect earlier than ${minValidationText} or later than ${maxValidationText}`
    )
  }

  const validatePricingStructure = (pricingStructure) => {
    const canApplyMonthlyLengthRate =
      pricingStructure === "by_foot_per_month" && !!contactBoat.lengthOverall
    const canApplyMonthlySqFtRate =
      pricingStructure === "sqft_per_month" &&
      !!contactBoat.lengthOverall &&
      !!contactBoat.beam
    const canApplyRate =
      pricingStructure === "per_month" ||
      canApplyMonthlyLengthRate ||
      canApplyMonthlySqFtRate
    return (
      canApplyRate ||
      "The customer's boat is missing a value for length overall or beam, so a dimension-based rate cannot be accurately applied."
    )
  }

  const validateServiceStartDate = () => {
    if (
      isPercentOfReservationSale &&
      selectedStartDate < parseDateValue(reservationCheckInDate)
    ) {
      return "Percent-based item cannot begin earlier than the check-in date."
    }
    if (selectedStartDate > oneYearFromNow) {
      return `Start date must be on or before ${format(
        oneYearFromNow,
        "M/d/yyyy"
      )}.`
    }

    return true
  }

  const validateServiceEndDate = () => {
    if (
      isPercentOfReservationSale &&
      selectedEndDate > parseDateValue(reservationCheckOutDate)
    ) {
      return "Percent-based item cannot end later than the check-out date."
    }
    if (selectedEndDate >= selectedStartDate) {
      return true
    }

    return `End date must be ${format(selectedStartDate, "M/d/yyyy")} or later.`
  }

  const handleActionSelection = (action) => {
    clearErrors()
    setSelectedAction(action)
  }

  const handleEndDateOptionSelection = (option) => {
    setSelectedEndDateOption(option)
    if (option === "never") {
      setValue("endDate", null)
    } else {
      setValue("endDate", defaultEndDate)
    }
  }

  const handleRateSelected = (id) => {
    if (id === "custom") {
      setValue("rate.amount", null)
      setValue("rate.taxRate", null)
      setValue("rate.pricingStructure", null)
      setValue("namedRateId", id)
    } else {
      const parsedId = parseInt(id, 10)
      const namedRate = namedRates.find((rate) => rate.id === parsedId)
      updateAndValidate("namedRateId", parsedId)
      updateAndValidate("rate.amount", centsToDollars(namedRate.amount))
      updateAndValidate(
        "rate.taxRate",
        safePercentageDecimal(namedRate.tax_rate)
      )
      updateAndValidate("rate.pricingStructure", namedRate.pricing_structure)
    }
  }

  const updatedAttrForErrorMsg = (selectedAction) => {
    if (selectedAction === "editRate") {
      return "rate"
    } else if (selectedAction === "editDiscounts") {
      return "discounts"
    } else {
      return "end date"
    }
  }

  const {
    mutate: updateRecurringProductSaleMutation,
    isLoading: isLoadingUpdate,
  } = useMutation(updateRecurringProductSale, {
    onSuccess: () => onClose(),
    onError: () => {
      setError("root.serverError", {
        message: `There was a problem updating the ${updatedAttrForErrorMsg(
          selectedAction
        )}.
        Please try again or contact Dockwa support at mayday@dockwa.com`,
      })
    },
  })

  const {
    mutate: updateReservationEndDateMutation,
    isLoading: isLoadingReservationUpdate,
  } = useMutation(
    (data) =>
      updateReservationDates({
        marinaSlug,
        reservationId: recurringProductSale.reservation_id,
        data,
      }),
    {
      onSuccess: (
        _data,
        { check_in_date: checkIn, check_out_date: checkOut }
      ) => {
        setReservationDates({
          reservationCheckInDate: checkIn,
          reservationCheckOutDate: checkOut,
        })
        onClose()
      },
      onError: () => {
        setError("root.serverError", {
          message:
            "There was a problem updating the end date. Please try again or contact Dockwa support at mayday@dockwa.com",
        })
      },
    }
  )

  const {
    mutate: extendInvoicingMutation,
    isLoading: isLoadingExtendInvoicing,
  } = useMutation(extendRecurringProductSaleInvoicing, {
    onSuccess: () => onClose(),
    onError: () => {
      setError("root.serverError", {
        message:
          "There was a problem creating additional invoices. Please try again or contact Dockwa support at mayday@dockwa.com",
      })
    },
  })

  // the values payload we send to the back end should mirror those generated by
  // ContractQuotes::CreateBilling#create_month_to_month_dockage_recurring_product_sale!
  const onSubmit = (formData) => {
    if (
      selectedAction === "editDates" &&
      selectedEndDateOption === "never" &&
      format(productSaleStartDate, "yyyy-MM-dd") ===
        format(formData.startDate, "yyyy-MM-dd")
    ) {
      // Nothing changed, just close
      return onClose(false)
    }

    const recurringProductSaleId = recurringProductSale.id
    let data

    if (isExtendingInvoicing) {
      data = {
        manage_id: marinaSlug,
        invoice_through: formData.invoiceThroughDate,
      }
    } else if (selectedAction === "editRate") {
      const taxRate = formData.rate.taxRate || 0
      const amountPerUnitCents = new Decimal(formData.rate.amount)
        .mul(100)
        .toNumber()
      const decimalTaxRate = new Decimal(taxRate).div(100).toNumber()

      let quantity

      // ContractQuote#dockage_amount_per_period => https://github.com/dockwa/dockwa-rails/blob/c14f0afe2970aa8bc8478819a03a7aaf24384fa8/app/models/contract_quote.rb#L464-L472
      // ContractQuote#boat_dimension_multiplier => https://github.com/dockwa/dockwa-rails/blob/c14f0afe2970aa8bc8478819a03a7aaf24384fa8/app/models/contract_quote.rb#L919-L931

      if (formData.rate.pricingStructure === "per_month") {
        quantity = 1
      } else if (formData.rate.pricingStructure === "by_foot_per_month") {
        quantity = Math.ceil(contactBoat.lengthOverall / 12)
      } else if (formData.rate.pricingStructure === "sqft_per_month") {
        quantity = Math.ceil(contactBoat.squareFeet)
      }

      // ContractQuote::CreateBilling#tax_amount_per_period => https://github.com/dockwa/dockwa-rails/blob/c14f0afe2970aa8bc8478819a03a7aaf24384fa8/app/services/contract_quotes/create_billing.rb#L189-L193
      const originalAmountCents = amountPerUnitCents * quantity

      data = {
        manage_id: marinaSlug,
        new_amount_first_service_start_date: format(
          formData.effectiveDate,
          "yyyy-MM-dd"
        ),
        recurring_product_sale: {
          original_amount: originalAmountCents,
          tax_rate: decimalTaxRate,
          quantity: quantity,
          price_per_unit: amountPerUnitCents,
          pricing_structure: formData.rate.pricingStructure,
        },
      }
    } else if (selectedAction === "editDiscounts") {
      data = {
        manage_id: marinaSlug,
        recurring_product_sale: {
          discounts: JSON.stringify(
            formatFeesAndDiscountsData(formData.feesAndDiscounts)
          ),
        },
      }
    } else if (recurringProductSale.reservation_sale) {
      data = {
        check_in_date: format(formData.startDate, "yyyy-MM-dd"),
        check_out_date:
          formData.endDate && format(formData.endDate, "yyyy-MM-dd"),
      }
    } else {
      data = {
        manage_id: marinaSlug,
        recurring_product_sale: {
          start_date: format(formData.startDate, "yyyy-MM-dd"),
          end_date: formData.endDate && format(formData.endDate, "yyyy-MM-dd"),
        },
      }
    }

    if (isExtendingInvoicing) {
      extendInvoicingMutation({ id: recurringProductSaleId, data })
    } else if (recurringProductSale.reservation_sale && isEditingDates) {
      updateReservationEndDateMutation(data)
    } else {
      updateRecurringProductSaleMutation({ recurringProductSaleId, data })
    }
  }

  const renderActionSelect = () => {
    return (
      <div>
        <Form.Label htmlFor="action">Action</Form.Label>
        <Form.Select
          id="action"
          value={selectedAction}
          onChange={(e) => handleActionSelection(e.target.value)}
        >
          {recurringProductSale.reservation_sale && (
            <>
              <option key="editRate" value="editRate">
                Edit rate
              </option>
              <option key="editDiscounts" value="editDiscounts">
                Edit discounts
              </option>
            </>
          )}
          <option key="editDates" value="editDates">
            Edit dates
          </option>
          {canExtendInvoicing && (
            <option key="extendInvoicing" value="extendInvoicing">
              Create future invoices
            </option>
          )}
        </Form.Select>
      </div>
    )
  }

  const renderDateFields = () => {
    return (
      <>
        <div className="flex w-full flex-row items-end justify-start gap-4">
          <div className="w-1/3">
            <Form.Label htmlFor="startDate">Starts on</Form.Label>
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: "You must choose a start date",
                validate: validateServiceStartDate,
              }}
              render={({ field: { onChange, value } }) => (
                <Form.DatePicker
                  id="startDate"
                  {...{ onChange, value }}
                  hasErrors={Boolean(errors?.startDate)}
                  customInput={<input data-testid="start-date" type="text" />}
                />
              )}
            />
          </div>
          <div className="w-1/3">
            <Form.Label htmlFor="selectedEndDateOption">Ends</Form.Label>
            <Form.Select
              id="selectedEndDateOption"
              value={selectedEndDateOption}
              onChange={(e) => handleEndDateOptionSelection(e.target.value)}
            >
              <option key="never" value="never" disabled={productSaleEndDate}>
                Never
              </option>
              <option key="onDate" value="onDate">
                On date
              </option>
            </Form.Select>
          </div>
          <div className="w-1/3">
            {selectedEndDateOption === "onDate" && (
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: "You must choose an end date",
                  validate: validateServiceEndDate,
                }}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    id="endDate"
                    {...{ onChange, value }}
                    hasErrors={Boolean(errors?.endDate)}
                    customInput={<input data-testid="end-date" type="text" />}
                  />
                )}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  const renderDiscountFields = () => {
    return (
      <>
        <DiscountTable
          canEdit={false}
          billingCycle={BILLING_CYCLES.MONTH_TO_MONTH}
          contractStartDate={productSaleStartDate}
          contractEndDate={productSaleEndDate}
          hidePlaceholders
          contractCompleted
        />
      </>
    )
  }

  const renderRateFields = () => {
    return (
      <>
        <div>
          <Form.Label htmlFor="rate">New Rate</Form.Label>
          <div className="text-muted mb-2">
            Visit the{" "}
            <a target="_blank" href={pricingUrl} rel="noreferrer">
              Pricing
            </a>{" "}
            settings to create and update installment rates.
          </div>
          <Form.Select
            id="rate"
            value={selectedRateId}
            onChange={(e) => handleRateSelected(e.target.value)}
          >
            <optgroup label="Installment Rate">
              {namedRates.map((rate) => (
                <option key={rate.id} value={rate.id}>
                  {rate.name}
                </option>
              ))}
            </optgroup>
            <optgroup label="Custom Rate">
              <option value="custom">Custom rate</option>
            </optgroup>
          </Form.Select>
        </div>
        <div className="flex w-full flex-row justify-between gap-4">
          <div className="w-1/2">
            <Form.Label htmlFor="pricing-structure">
              Pricing Structure
            </Form.Label>
            <Form.Select
              id="pricing-structure"
              disabled={isNamedRate}
              {...register("rate.pricingStructure", {
                required: "Pricing structure is required",
                validate: validatePricingStructure,
              })}
              hasErrors={Boolean(errors?.rate?.pricingStructure)}
            >
              <option disabled value="">
                Select a pricing structure
              </option>
              {monthlyRateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {snakeCaseToHumanize(option.name)}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="w-1/4">
            <Form.Label htmlFor="amount">Amount</Form.Label>
            <Form.IconTextField
              icon="$"
              id="amount"
              disabled={isNamedRate}
              {...register("rate.amount", {
                required: "Amount is required",
                min: 0,
              })}
              type="number"
              hasErrors={Boolean(errors?.rate?.amount)}
            />
          </div>
          <div className="w-1/4">
            <Form.Label htmlFor="tax-rate">Tax</Form.Label>
            <Form.IconTextField
              icon="%"
              position="right"
              id="tax-rate"
              disabled={isNamedRate}
              {...register("rate.taxRate", {
                min: {
                  value: 0,
                  message: "Tax rate must be 0 or more",
                },
              })}
              type="number"
              hasErrors={Boolean(errors?.rate?.taxRate)}
            />
          </div>
        </div>
        <div className="flex w-full flex-row justify-between gap-4">
          <div className="w-1/3">
            <Form.Label htmlFor="effective-date">New rate begins on</Form.Label>
            <Controller
              name="effectiveDate"
              control={control}
              rules={{
                required:
                  "You must choose a date for the new rate to go into effect",
                validate: validateEffectiveDate,
              }}
              render={({ field: { onChange, value } }) => (
                <Form.DatePicker
                  id="effective-date"
                  {...{ onChange, value }}
                  hasErrors={Boolean(errors?.effectiveDate)}
                />
              )}
            />
          </div>
        </div>
      </>
    )
  }

  const renderInvoiceFields = () => {
    return (
      <div>
        <Form.Label htmlFor="invoiceThroughDate">
          Create invoices through
        </Form.Label>
        <Form.Select
          id="invoiceThroughDate"
          {...register("invoiceThroughDate")}
        >
          {extendInvoicingDateOptions.map((option) => {
            return (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.displayName}
              </option>
            )
          })}
        </Form.Select>
      </div>
    )
  }

  const renderFormErrors = () => {
    return (
      <Form.Error>
        {errors?.root?.serverError && (
          <div>{errors.root.serverError.message}</div>
        )}
        {errors?.rate?.pricingStructure && (
          <div>{errors.rate.pricingStructure.message}</div>
        )}
        {errors?.rate?.amount && <div>{errors.rate.amount.message}</div>}
        {errors?.rate?.taxRate && <div>{errors.rate.taxRate.message}</div>}
        {errors?.effectiveDate && <div>{errors.effectiveDate.message}</div>}
        {errors?.startDate && <div>{errors.startDate.message}</div>}
        {errors?.endDate && <div>{errors.endDate.message}</div>}
      </Form.Error>
    )
  }

  const renderFormBody = () => {
    return (
      <ReloadableWidget
        isLoading={isLoadingRates || isLoadingInvoicingDates}
        isError={isErrorRates || isErrorInvoicingDates}
      >
        {renderActionSelect()}
        {isEditingRate && namedRates ? renderRateFields() : null}
        {isEditingDates ? renderDateFields() : null}
        {isEditingDiscounts ? renderDiscountFields() : null}
        {isExtendingInvoicing && extendInvoicingDateOptions
          ? renderInvoiceFields()
          : null}
      </ReloadableWidget>
    )
  }

  return (
    <Modal
      isOpen={Boolean(recurringProductSale)}
      onClose={() => onClose(false)}
      maxSize="medium"
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={
              recurringProductSale.reservation_sale ? "Edit stay" : "Edit item"
            }
          />
          <Modal.Body>
            <div className="mb-6">
              <div className="flex flex-col gap-4">
                {renderFormBody()}
                {renderFormErrors()}
                {!recurringProductSale.reservation_sale &&
                selectedAction === "editDates" &&
                isPercentOfReservationSale ? (
                  <div className="flex flex-row items-center space-x-2">
                    <i className="icon icon-info-circle text-lg text-yellow-700" />
                    <span>
                      Once the dates of a percent-based item have been changed,
                      its dates will no longer automatically update when the
                      storage sale’s dates are changed.
                    </span>
                  </div>
                ) : null}
                {recurringProductSale.reservation_sale &&
                isMonthlyTransient &&
                selectedAction === "editDates" ? (
                  <div className="flex flex-row items-center space-x-2">
                    <i className="icon icon-info-circle text-lg text-yellow-700" />
                    <span>
                      {
                        "Note: the end date of the customer's stay is representative of the day they depart, not their last night."
                      }
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button variant="tertiary" onClick={() => onClose(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                isLoading={
                  isLoadingUpdate ||
                  isLoadingReservationUpdate ||
                  isLoadingExtendInvoicing
                }
                disabled={
                  isLoadingRates ||
                  isLoadingExtendInvoicing ||
                  allInvoiceDatesDisabled()
                }
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  )
}

EditRecurringProductSaleModal.propTypes = {
  recurringProductSale: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    product: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }),
    reservation_id: PropTypes.number.isRequired,
    pricing_structure: PropTypes.string,
    reservation_sale: PropTypes.bool.isRequired,
    discounts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        apply_order: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        discount_type: PropTypes.string.isRequired,
        discount_month: PropTypes.string,
        discount_end_month: PropTypes.string,
        discount_start_month: PropTypes.string,
      })
    ),
    can_extend_invoicing: PropTypes.bool.isRequired,
  }).isRequired,
  pricingUrl: PropTypes.string.isRequired,
  monthlyRateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  contactBoat: PropTypes.shape({
    lengthOverall: PropTypes.number.isRequired,
    beam: PropTypes.number.isRequired,
    squareFeet: PropTypes.number.isRequired,
  }),
  defaultEndDate: PropTypes.instanceOf(Date).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditRecurringProductSaleModal
