import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const EmailConfirmationCheckbox = () => {
  const { register } = useFormContext()

  return (
    <Form.Checkbox
      {...register("confirmation_email_enabled")}
      label="Send confirmation email to boater"
    />
  )
}

export default EmailConfirmationCheckbox
