import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const Total = ({ label, amount, loading }) => {
  return (
    <div className="flex items-center justify-between text-gray-900">
      <span className="font-semibold">{label}</span>
      <div className="h-6">
        {loading ? (
          <i className="icon icon-spinner icon-spin h-full" />
        ) : (
          <span className="text-lg font-semibold">
            {formattedCentsToDollars(amount)}
          </span>
        )}
      </div>
    </div>
  )
}

Total.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

export default Total
