import PropTypes from "prop-types"
import React from "react"

import DealTicket from "src/components/DockwaPlus/DealTicket"

const ListedPumpPrices = ({
  fuel,
  lastUpdatedAt,
  hasActiveDockwaPlusSubscription,
  hasDockwaPlusFuelDeals,
  highestDiscountTemplate,
  fuelDiscountsEnabled,
}) => {
  if (fuel.length === 0) return null
  const renderFuelDealCta = () => (
    <div className="mb-6 flex items-center space-x-4">
      {highestDiscountTemplate && (
        <DealTicket
          discount={`Up to ${highestDiscountTemplate.readableDiscount}`}
          variant="fuel"
        />
      )}
      <span className="text-sm">
        Deals available for Dockwa+ members.
        {!hasActiveDockwaPlusSubscription && (
          <a
            href="/account/settings/memberships"
            className="ml-1 cursor-pointer font-semibold text-blue-700"
          >
            Learn more and sign up
          </a>
        )}
      </span>
    </div>
  )

  const renderFuelPrices = () => (
    <div className="grid grid-cols-2 gap-4">
      {fuel.map((fuelItem) => (
        <div key={fuelItem.id} className="col-span-1 flex flex-col space-y-1">
          <span className="text-sm font-semibold">{fuelItem.name}</span>
          <span className="text-base font-bold">
            {fuelItem.priceDollars}/gal
          </span>
          {fuelItem.discounted && fuelDiscountsEnabled && (
            <div className="flex items-center space-x-1 text-sm font-semibold text-supplementary-orange-600">
              <i className="icon icon-tag-outline text-lg" />
              <span>
                {fuelItem.effectivePriceDollars}/gal (
                {fuelItem.readableDiscount})
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  )

  return (
    <div className="flex flex-col" id="listed-pump-prices">
      <div className="mb-2 flex items-center justify-between">
        <h2 className="mb-0 text-lg font-bold">Listed Pump Prices</h2>
        <span className="text-gray-600">Last updated: {lastUpdatedAt}</span>
      </div>
      {hasDockwaPlusFuelDeals && fuelDiscountsEnabled && renderFuelDealCta()}
      {renderFuelPrices()}
      <div className="my-6 flex w-full border-b" />
    </div>
  )
}

ListedPumpPrices.propTypes = {
  fuel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      priceDollars: PropTypes.string.isRequired,
      effectivePriceDollars: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      discounted: PropTypes.bool.isRequired,
      readableDiscount: PropTypes.string,
    })
  ),
  lastUpdatedAt: PropTypes.string,
  hasActiveDockwaPlusSubscription: PropTypes.bool.isRequired,
  hasDockwaPlusFuelDeals: PropTypes.bool.isRequired,
  highestDiscountTemplate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    readableDiscount: PropTypes.string.isRequired,
    percentAmount: PropTypes.string,
    amount: PropTypes.number,
  }),
  fuelDiscountsEnabled: PropTypes.bool.isRequired,
}

export default ListedPumpPrices
