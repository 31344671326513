import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const BasicPrice = ({ label, amount }) => {
  return (
    <div className="flex items-center justify-between">
      <span>{label}</span>
      <span className="text-lg font-semibold text-gray-600">
        {formattedCentsToDollars(amount)}
      </span>
    </div>
  )
}

BasicPrice.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
}

export default BasicPrice
