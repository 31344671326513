import PropTypes from "prop-types"
import React, { useContext, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { POSContext } from "src/main/PointOfSale/Layout"
import { GUEST_DISPLAY_NAME } from "src/main/PointOfSale/constants"

import AutocompleteField from "src/components/Autocomplete"
import Button from "src/components/Button"
import Form from "src/components/Form"

import {
  searchContactBoats,
  searchContacts,
} from "src/api/PointOfSale/checkout"

import useDebounce from "src/hooks/use_debounce"
import { useDisplayContactOptions } from "src/hooks/use_display_contact_options"
import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { CUSTOMER_BOAT_SELECTED } from "../../amplitude_events"

const CustomerAndBoatSelector = ({
  onAddNewCustomerClicked,
  customerIdentityRequired,
}) => {
  const { contact, setContact, boat, setBoat } = useContext(POSContext)

  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()

  const [contactSearch, setContactSearch] = useState("")

  const [debouncedSetContactSearch, isContactSearchDebouncing] =
    useDebounce(setContactSearch)

  const contactQuery = useQuery({
    queryKey: ["payment-flow-contact-search", contactSearch],
    queryFn: () => searchContacts({ marinaSlug, q: contactSearch }),
    enabled: contactSearch.length >= 3,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const onContactSelected = (contact) => {
    const newContact = contact ?? {}
    setBoatSearch("")
    setBoat({})

    if (!hasArchivedContacts || hasContacts || guestContact) {
      setContact(newContact)
    }
  }

  const [boatSearch, setBoatSearch] = useState("")
  const [debouncedSetBoatSearch, isBoatSearchDebouncing] =
    useDebounce(setBoatSearch)
  const boatQuery = useQuery({
    queryKey: ["payment-flow-boat-search", contact?.id, boatSearch],
    queryFn: () =>
      searchContactBoats({
        marinaSlug,
        contactId: contact?.id,
        q: boatSearch.trim(),
      }),
    enabled: boatSearch.length >= 2,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const onBoatSelected = (boat) => {
    if (boat) tracker.trackEvent(CUSTOMER_BOAT_SELECTED)

    const selectedBoat = boat ?? {}
    const selectedBoatContact = boat ? boat.contact : {}

    setContact(selectedBoatContact)
    setBoat(selectedBoat)
  }

  const buildGuestContact = () => {
    if (customerIdentityRequired || !contactQuery.data?.activeContacts) {
      return null
    } else {
      return {
        id: null,
        name: GUEST_DISPLAY_NAME,
        paymentMethods: [],
      }
    }
  }

  const guestContact = useMemo(buildGuestContact, [
    customerIdentityRequired,
    contactQuery,
  ])

  const getOption = ({ name, email }) => (
    <div>
      <span>{name}</span>
      {email && <span className="ml-2">({email})</span>}
    </div>
  )

  const {
    getOptions,
    getRenderOption,
    showNoResultsText,
    hasContacts,
    hasArchivedContacts,
  } = useDisplayContactOptions({
    data: contactQuery.data,
    query: contactSearch,
    renderOption: getOption,
    guestContact,
  })

  return (
    <div className="space-y-4">
      <div>
        <div className="mb-2 flex items-center justify-between">
          <Form.Label htmlFor="saved-payment-contact-search" noMargin>
            Customer
          </Form.Label>
          <Button variant="ghost" small onClick={onAddNewCustomerClicked}>
            (add new customer)
          </Button>
        </div>
        <AutocompleteField
          id="saved-payment-contact-search"
          placeholder="Search by first name, last name, or email"
          leadingIcon={<i className="icon icon-search" />}
          showNoResultsText={showNoResultsText}
          clearable
          selectedItem={contact}
          onInputChange={(v) => debouncedSetContactSearch(v)}
          onSelect={onContactSelected}
          options={getOptions}
          isLoading={contactQuery.isFetching || isContactSearchDebouncing}
          renderOption={({ option }) => getRenderOption(option)}
        />
      </div>
      <div>
        <Form.Label htmlFor="saved-payment-boat-search" optional>
          Boat name
        </Form.Label>
        <AutocompleteField
          id="saved-payment-boat-search"
          placeholder="Search by boat name"
          leadingIcon={<i className="icon icon-search" />}
          showNoResultsText
          clearable
          selectedItem={boat}
          onInputChange={(v) => debouncedSetBoatSearch(v)}
          onSelect={onBoatSelected}
          options={boatQuery.data}
          renderOption={({ option }) =>
            `${option.name} (${option.contact.name || option.contact.email})`
          }
          isLoading={boatQuery.isFetching || isBoatSearchDebouncing}
        />
      </div>
    </div>
  )
}

CustomerAndBoatSelector.propTypes = {
  onAddNewCustomerClicked: PropTypes.func.isRequired,
  customerIdentityRequired: PropTypes.bool.isRequired,
}

export default CustomerAndBoatSelector
