import { queryApi } from "src/utils/api/ApiMethods"

export const queryAllDeals = ({ page = 1 }) => {
  return queryApi(`/dockwa_deals?page=${page}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const queryFavorites = ({ page = 1 }) => {
  return queryApi(`/dockwa_deals/favorite_marinas?page=${page}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const queryFuelDeals = ({ page = 1 }) => {
  return queryApi(`/dockwa_plus/fuel_discounts?page=${page}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
