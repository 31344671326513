import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Badge from "src/components/Badge"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import { WizardContext } from "../../WizardContextProvider"
import { friendlyInstallmentScheduleName } from "../../helpers"

const BillingScheduleSelector = () => {
  const { billingSchedules, reservationSettingsPath } =
    useContext(WizardContext)
  const { register } = useFormContext()
  const tooltipText =
    "Check out our expanded flexible deposits feature and customize your own deposit schedule"

  const renderLinkAndTooltip = () => {
    return (
      <>
        <span>
          (
          <a
            href={reservationSettingsPath}
            className="text-blue-600"
            rel="noreferrer noopener"
            target="_blank"
          >
            edit
          </a>
          )
        </span>
        <Tooltip
          text={tooltipText}
          placement="top"
          variant="dark"
          maxWidth="275px"
          textCenter
        >
          <Badge color="teal" text="New" />
        </Tooltip>
      </>
    )
  }

  return (
    <div data-testid="trw-billing-schedule-select">
      <div className="flex flex-row items-baseline gap-1">
        <Form.Label htmlFor="billing-schedule">Billing Schedule</Form.Label>
        {renderLinkAndTooltip()}
      </div>
      <Form.Select id="billing-schedule" {...register("billing_schedule_id")}>
        {billingSchedules.map((option) => (
          <option key={option.id} value={option.id}>
            {friendlyInstallmentScheduleName(option.schedule)}
          </option>
        ))}
      </Form.Select>
    </div>
  )
}

export default BillingScheduleSelector
