import PropTypes from "prop-types"
import React from "react"
import { formatItemForCart } from "src/main/PointOfSale/utils"

const PosItemCard = ({ item, transaction, onClick }) => {
  const formattedItem = formatItemForCart(item)

  return (
    <div
      onClick={onClick}
      className="hover:bg-gray-50 flex cursor-pointer items-center justify-between rounded-lg border border-gray-200 p-4 transition-colors"
    >
      <div className="space-y-1">
        <div className="font-medium text-gray-900">{item.name}</div>
        <div className="text-sm text-gray-500">{item.description}</div>
      </div>
      <div className="text-lg font-semibold text-gray-900">
        ${formattedItem?.price} / gal | $
        {(transaction?.volume * formattedItem?.price).toFixed(2)}
      </div>
    </div>
  )
}

PosItemCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  transaction: PropTypes.shape({
    volume: PropTypes.number.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PosItemCard
