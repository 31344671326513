import { DirectUpload } from "@rails/activestorage"

class ActiveStorageDirectUpload {
  constructor(file, url = "/rails/active_storage/direct_uploads") {
    this.upload = new DirectUpload(file, url, this)
  }

  uploadFile({ successCallback, errorCallback }) {
    this.upload.create((error, blob) => {
      if (error) {
        errorCallback(error)
      } else {
        successCallback(blob)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) =>
      this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    console.log(event)
  }
}

export default ActiveStorageDirectUpload
