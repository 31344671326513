import React, { useContext } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import { PaymentFlowContext } from "../../PaymentModal"
import { changeDue } from "./helpers"

const ConfirmationScreenDetails = () => {
  const {
    checkoutDetails: { paymentMethod },
  } = useContext(PaymentFlowContext)
  const {
    cartController: {
      totals: { total },
    },
  } = useContext(POSContext)

  return (
    <div className="flex justify-between text-2xl font-semibold text-teal-600">
      <span>Change Due</span>
      <span>${changeDue(paymentMethod.cashCollected, total)}</span>
    </div>
  )
}

ConfirmationScreenDetails.confirmButtonLabel = "Complete Transaction"

export default ConfirmationScreenDetails
