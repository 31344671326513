import PropTypes from "prop-types"
import React from "react"

const StepContent = ({ children }) => {
  return (
    <div className="flex w-full flex-col space-y-4 lg:flex-3">
      <div className="rounded border bg-white p-5">{children}</div>
    </div>
  )
}

StepContent.propTypes = {
  children: PropTypes.node.isRequired,
}
export default StepContent
