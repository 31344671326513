import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { createStop, updateStop } from "src/api/CruisePlan/mutateStop"

import { useTracker } from "src/hooks/use_tracker"

import DateTimeInput, { dateToLoad, dateToSave } from "./DateTimeInput"
import TravelPlans from "./TravelPlans"

const NoteStopForm = ({ cruiseStop }) => {
  const tracker = useTracker()

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: cruiseStop.name,
      note: cruiseStop.note,
      startDate: cruiseStop.startDate ? dateToLoad(cruiseStop.startDate) : null,
      endDate: cruiseStop.endDate ? dateToLoad(cruiseStop.endDate) : null,
      nauticalMiles: cruiseStop.nauticalMiles,
      knots: cruiseStop.knots,
    },
  })

  const { mutate } = useMutation({
    mutationFn: (data) => {
      data.start_date = data.startDate ? dateToSave(data.startDate) : null
      data.end_date = data.endDate ? dateToSave(data.endDate) : null
      data.nautical_miles = data.nauticalMiles
      if (cruiseStop.id) {
        return updateStop({
          type: cruiseStop.type,
          cruisePlanId: cruiseStop.cruisePlanId,
          id: cruiseStop.id,
          data: data,
        })
      } else {
        return createStop({
          type: cruiseStop.type,
          cruisePlanId: cruiseStop.cruisePlanId,
          data: data,
        })
      }
    },
    onSuccess: (reponse) => {
      tracker.trackEvent("cruise_plans:note_stops:saved", {
        experience_name: "cruise_plans",
      })
      window.location.href = reponse.redirect_url
    },
    onError: (reponse) => {
      alert(reponse.errors)
    },
  })

  const onSubmit = (data) => {
    mutate(data)
  }

  return (
    <div className="container max-w-5xl">
      <a
        href={`/account/cruise_plans/${cruiseStop.cruisePlanId}`}
        className="text-link"
      >
        ← Back to cruise plan
      </a>
      <div className="card mt-4">
        <h1 className="my-0 text-xl font-bold">Add a Note</h1>
        <p className="mb-4">
          Create a note to jot down reminders, navigation aides, or places you
          want to visit.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div>
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.TextField
                id="title"
                {...register(`title`, { required: "Title is required" })}
                hasErrors={!!errors?.title}
              />
              {errors?.title && (
                <Form.Error>{errors?.title?.message}</Form.Error>
              )}
            </div>

            <div>
              <Form.Label htmlFor="note">Note</Form.Label>
              <Form.Textarea
                id="note"
                {...register(`note`)}
                hasErrors={!!errors?.note}
              />
              {errors?.note && <Form.Error>{errors?.note?.message}</Form.Error>}
            </div>

            <DateTimeInput
              title="Arriving at"
              name="startDate"
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />

            <DateTimeInput
              title="Departing at"
              name="endDate"
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          </div>

          <TravelPlans
            fromCruiseStopName={cruiseStop.fromCruiseStopName}
            fromCruiseStopEndDate={cruiseStop.fromCruiseStopEndDate}
            register={register}
            errors={errors}
            watch={watch}
          />

          <div className="mt-4">
            <Button variant="primary" type="submit">
              {cruiseStop.id ? "Update Note" : "Add Note"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

NoteStopForm.propTypes = {
  cruiseStop: PropTypes.shape({
    id: PropTypes.string,
    cruisePlanId: PropTypes.string.isRequired,
    name: PropTypes.string,
    note: PropTypes.string,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    type: PropTypes.string,
    nauticalMiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    knots: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fromCruiseStopName: PropTypes.string,
    fromCruiseStopEndDate: PropTypes.string,
  }).isRequired,
}

export default NoteStopForm
