import React, { useContext, useEffect, useState } from "react"

import Form from "src/components/Form"

import useDebounce from "src/hooks/use_debounce"

import detectBarcodeScan from "src/utils/detectBarcodeScan"

import { ManageItemsContext } from "./ManageItemsContainer"

const ManageItemsSearch = () => {
  const { searchTerm, handleSearch, marinaAccess } =
    useContext(ManageItemsContext)
  const [debouncedSearch] = useDebounce(handleSearch) // handleSearch updates the query key, which triggers a fetch, so we debounce
  const [searchInput, setSearchInput] = useState(searchTerm)
  const [textFieldFocused, setTextFieldFocused] = useState(false)

  useEffect(() => {
    if (searchTerm.length === 0) {
      setSearchInput("")
    }
  }, [searchTerm])

  const handleBarcodeScan = (barcode) => {
    if (textFieldFocused) return
    if (marinaAccess.barcodeScanner) {
      setSearchInput(barcode)
      debouncedSearch(barcode)
    }
  }

  detectBarcodeScan(handleBarcodeScan)

  const handleSearchInput = (e) => {
    const search = e.target.value
    setSearchInput(search)
    debouncedSearch(search)
  }

  return (
    <Form.IconTextField
      id="search-items"
      icon={<i className="icon icon-search" />}
      value={searchInput}
      onChange={handleSearchInput}
      placeholder="Search for item, or scan barcode"
      onFocus={() => setTextFieldFocused(true)}
      onBlur={() => setTextFieldFocused(false)}
    />
  )
}

export default ManageItemsSearch
