import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../../WizardContextProvider"
import { CUSTOM_RATE_ID, DEFAULT_RATE_ID } from "../../../constants"

const RateSelector = () => {
  const { rateOptions, isMonthlyBilling } = useContext(WizardContext)
  const { register } = useFormContext()

  const optionIsDisabled = (option) => {
    if (isMonthlyBilling) {
      return !option.mbmTranslatedPricingStructure.includes("month")
    }

    return false
  }

  return (
    <div>
      <Form.Label htmlFor="trw-rate">Rate</Form.Label>
      <Form.Select id="trw-rate" {...register("rate.id")}>
        <option value={DEFAULT_RATE_ID} disabled={isMonthlyBilling}>
          Default rates
        </option>
        <option value={CUSTOM_RATE_ID}>Custom rate</option>
        {rateOptions.length > 0 ? (
          <optgroup label="Installment Rates">
            {rateOptions.map((option) => (
              <option
                key={option.id}
                value={option.id}
                disabled={optionIsDisabled(option)}
              >
                {option.name}
              </option>
            ))}
          </optgroup>
        ) : null}
      </Form.Select>
    </div>
  )
}

export default RateSelector
