import PropTypes from "prop-types"
import React from "react"

import Card from "src/components/Card"
import ProfileImage from "src/components/ProfileImage"

const ContactBoatCard = ({ link, data }) => {
  return (
    <Card href={link} withShadow={false}>
      <div className="flex items-center justify-between p-4">
        <div className="flex space-x-4">
          <ProfileImage
            imageUrl={data.thumbnail_url}
            name={data.name[0]}
            backgroundColor={data.avatar_background_color}
          />
          <div className="flex flex-col">
            <span className="font-semibold">{data.name}</span>
            <span>
              {data.make} {data.model} {data.year}
            </span>
          </div>
        </div>
        <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
      </div>
    </Card>
  )
}

ContactBoatCard.propTypes = {
  link: PropTypes.string.isRequired,
  data: PropTypes.shape({
    thumbnail_url: PropTypes.string,
    avatar_background_color: PropTypes.string,
    name: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.string,
  }).isRequired,
}

export default ContactBoatCard
