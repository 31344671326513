import PropTypes from "prop-types"
import React from "react"

import ReloadableWidget from "src/components/ReloadableWidget"
import Table from "src/components/Table"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { sortItems } from "../helpers"
import PaymentItemRow from "./PaymentItemRow"
import {
  collapseReservationItems,
  getTotalBalance,
  groupRelatedPaymentItemRows,
} from "./helpers"

const PaymentItemsTable = ({ items, isPaymentSettled, isLoading, isError }) => {
  const amountColumnName = isPaymentSettled
    ? "Transaction Amount"
    : "Scheduled Amount"
  const totalBalance = getTotalBalance(items)

  const collapsedReservationItems = sortItems(collapseReservationItems(items))
  const nonZeroCollapsedReservationItems = collapsedReservationItems?.filter(
    (item) => item.amount !== 0
  )

  const groupedRelatedProducts = Object.entries(
    groupRelatedPaymentItemRows(nonZeroCollapsedReservationItems)
  )

  const renderPaymentItems = () => {
    return groupedRelatedProducts.length ? (
      groupedRelatedProducts.map(([id, item]) => (
        <PaymentItemRow key={id} item={item} />
      ))
    ) : (
      <Table.Row>
        <td className="px-4 text-xs" colSpan="4" align="center">
          No products / services to display
        </td>
      </Table.Row>
    )
  }

  return (
    <div className="w-full overflow-y-auto">
      <Table autoColumnWidth>
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">Name</span>
            </Table.Head.Cell>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">Dates</span>
            </Table.Head.Cell>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">Subtotal</span>
            </Table.Head.Cell>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">Discount</span>
            </Table.Head.Cell>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">Tax</span>
            </Table.Head.Cell>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">Total Price</span>
            </Table.Head.Cell>
            <Table.Head.Cell>
              <span className="whitespace-nowrap">{amountColumnName}</span>
            </Table.Head.Cell>
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {isLoading || isError ? (
            <Table.Row>
              <td className="px-4" colSpan="2">
                <ReloadableWidget
                  isLoading={isLoading}
                  isError={isError}
                  border={false}
                />
              </td>
            </Table.Row>
          ) : (
            renderPaymentItems()
          )}
        </Table.Body>
        <Table.Foot>
          <Table.Foot.Row>
            <Table.Foot.Cell>
              <div className="py-2.5">Total</div>
            </Table.Foot.Cell>
            <Table.Foot.Cell />
            <Table.Foot.Cell />
            <Table.Foot.Cell />
            <Table.Foot.Cell />
            <Table.Foot.Cell />
            <Table.Foot.Cell>
              <div className="py-2.5">
                {formattedCentsToDollars(totalBalance)}
              </div>
            </Table.Foot.Cell>
          </Table.Foot.Row>
        </Table.Foot>
      </Table>
    </div>
  )
}

PaymentItemsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      txn: PropTypes.shape({
        amount: PropTypes.number.isRequired,
      }),
    })
  ).isRequired,
  isPaymentSettled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default PaymentItemsTable
