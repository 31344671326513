import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryProductCategories = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/product_categories`)
}

export const createProductCategory = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/product_categories`,
    method: "POST",
    data,
  })
}

export const updateProductCategory = ({ marinaSlug, id, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/product_categories/${id}`,
    method: "PATCH",
    data,
  })
}

export const deleteProductCategory = ({ marinaSlug, id }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/product_categories/${id}`,
    method: "DELETE",
  })
}

export const reorderProductCategories = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/product_categories/reorder`,
    method: "PATCH",
    data,
  })
}

export const createProduct = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/products`,
    method: "POST",
    data,
  })
}

export const updateProduct = ({ marinaSlug, productId, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/products/${productId}`,
    method: "PUT",
    data,
  })
}

export const updateProductStatus = ({ marinaSlug, id, status }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/products/${id}/status?status=${status}`,
    method: "PATCH",
  })
}

export const queryProduct = ({ marinaSlug, productId }) => {
  return queryApi(`/manage/${marinaSlug}/products/${productId}`)
}

export const getItemByBarcode = ({ marinaSlug, barcode }) => {
  return queryApi(
    `/manage/${marinaSlug}/products/barcode?barcode=${encodeURIComponent(
      barcode
    )}`
  )
}

export const createProductQuantityAdjustment = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/product_quantity_adjustments`,
    method: "POST",
    data,
  })
}
