import PropTypes from "prop-types"
import React from "react"

const Contact = ({ data, link }) => {
  return (
    <div className="flex flex-1 items-center">
      <a href={link} className="mr-2 mt-1 text-gray-600 no-underline lg:hidden">
        <i className="icon icon-sf-chevron-left" />
      </a>
      <div className="text-xl font-bold leading-8 text-gray-900">{data}</div>
    </div>
  )
}

Contact.propTypes = {
  data: PropTypes.string,
  link: PropTypes.string,
}

export default Contact
