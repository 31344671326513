import PropTypes from "prop-types"
import React, { useContext } from "react"
import ActiveToggle from "src/main/DockwaPlusDeals/Manage/Fuel/ActiveToggle"
import { ManageDockwaPlusDealsContext } from "src/main/DockwaPlusDeals/Manage/index"

import DataTable from "src/components/DataTable"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const DiscountsTable = ({ discounts, totalPages }) => {
  const { updateParams, queryPage, canUpdateDeal } = useContext(
    ManageDockwaPlusDealsContext
  )

  const DEFAULT_COLUMN_DEFINITIONS = [
    {
      key: "label",
      header: "Label",
    },
    {
      key: "readableAmount",
      header: "Discount",
    },
    {
      key: "applicabilities",
      header: "Applies To",
    },
    {
      key: "active",
      header: "Active",
      render: (data) => (
        <ActiveToggle data={data} canUpdateDeal={canUpdateDeal} />
      ),
    },
  ]

  const handlePageChange = (selectedPage) => {
    updateParams({
      page: selectedPage.selected,
      tab: 1,
    })
  }

  const rowActions = (data) => {
    if (canUpdateDeal) {
      return [
        {
          action: "Edit Deal",
          variant: "link",
          href: `/manage/${getCurrentMarinaSlug()}/fuel_discount_templates/${
            data.id
          }/edit`,
        },
      ]
    }
  }

  const rowConfig = {
    actions: (data) => rowActions(data),
  }

  return (
    <div className="bg-white pb-16 pt-2">
      <DataTable
        colDefs={DEFAULT_COLUMN_DEFINITIONS}
        name="fuelDiscounts"
        rowData={discounts}
        rowConfig={rowConfig}
        hideSettings
        pagination
        page={queryPage}
        onPageChange={handlePageChange}
        numberOfPages={totalPages}
      />
    </div>
  )
}

DiscountsTable.propTypes = {
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      status: PropTypes.string,
      discount: PropTypes.string,
      appliesTo: PropTypes.string,
      created: PropTypes.string,
    })
  ),
  page: PropTypes.number,
  totalPages: PropTypes.number,
}

export default DiscountsTable
