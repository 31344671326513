import classNames from "classnames"
import React, { useContext } from "react"
import { useQuery } from "react-query"
import DiscountsTable from "src/main/DockwaPlusDeals/Manage/Fuel/DiscountsTable"
import EmptyState from "src/main/DockwaPlusDeals/Manage/Fuel/EmptyState"
import { ManageDockwaPlusDealsContext } from "src/main/DockwaPlusDeals/Manage/index"

import Loader from "src/components/Loader"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { queryFuelDiscounts } from "src/api/DockwaPlusDeals/Manage/Fuel"

const Fuel = () => {
  const { queryPage, canCreateDeal } = useContext(ManageDockwaPlusDealsContext)
  const { data: fuelDiscountsData, isLoading: isLoadingFuelDiscountData } =
    useQuery(
      ["fuelDiscounts", queryPage],
      () => queryFuelDiscounts({ page: queryPage }),
      {
        refetchOnMount: false,
        refetchInterval: 60 * 5000, // 5 minutes
      }
    )

  if (isLoadingFuelDiscountData) {
    return <Loader name={"Fuel Deals"} />
  } else if (fuelDiscountsData?.discounts?.length > 0) {
    return (
      <div className="flex flex-col">
        <div className="mb-2 flex w-full justify-end">
          <MaybeRestrictedTooltip
            restricted={!canCreateDeal}
            allowedRoles={["Admin", "Dockmaster"]}
          >
            <a
              href={fuelDiscountsData.newPath}
              className={classNames("btn btn-primary", {
                "pointer-events-none cursor-not-allowed opacity-40":
                  !canCreateDeal,
              })}
            >
              Create new fuel deal
            </a>
          </MaybeRestrictedTooltip>
        </div>
        <DiscountsTable {...fuelDiscountsData} />
      </div>
    )
  } else {
    return (
      <EmptyState
        newPath={fuelDiscountsData?.newPath}
        canCreateDeal={canCreateDeal}
      />
    )
  }
}

export default Fuel
