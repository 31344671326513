import React, { useContext, useState } from "react"

import Button from "src/components/Button"

import { WizardContext } from "../../../WizardContextProvider"
import PreservedRateModal from "./PreservedRateModal"

const PreservedRateModalButton = () => {
  const {
    isLoadingPriceEstimate,
    priceEstimateData,
    getPriceEstimateError,
    telescopeEnabled,
    telescopeCtaPath,
  } = useContext(WizardContext)
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen)
  }
  return (
    <>
      <Button
        fullWidth
        onClick={() => toggleModalOpen()}
        disabled={
          isLoadingPriceEstimate ||
          getPriceEstimateError ||
          !priceEstimateData?.preserved_rates ||
          priceEstimateData?.preserved_rates.length === 0
        }
      >
        View Rates
      </Button>
      {modalOpen ? (
        <PreservedRateModal
          onClose={() => toggleModalOpen()}
          preservedRates={priceEstimateData.preserved_rates}
          telescopeEnabled={telescopeEnabled}
          telescopeCtaPath={telescopeCtaPath}
        />
      ) : null}
    </>
  )
}

export default PreservedRateModalButton
