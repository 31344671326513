export const STEPS = {
  availability: 1,
  contact: 2,
  summary: 3,
}

export const MONTHLY_BILLING_SCHEDULE = "monthly"

export const ELECTRIC_MONTHLY_BILLING_STRUCTURE = "per_month"
export const NO_ELECTRIC_CHOSEN_VALUE = "none"

export const AUTOPAY_ENABLED = "credit"
export const AUTOPAY_DISABLED = "manual"

export const ADD_NEW_CONTACT = "addNew"
export const ADD_NEW_CONTACT_BOAT = "addNew"

export const DISCOUNT_TEMPLATE = "discount_template"
export const NO_COUPON_CODE_CHOSEN_VALUE = "none"
export const PROMO_CODE = "promo_code"

export const DEFAULT_RATE_ID = "nightly"
export const CUSTOM_RATE_ID = "custom_installment"
export const NO_PRICING_STRUCTURE_CHOSEN_VALUE = "none"

export const PRICING_STRUCTURES = [
  "per_day",
  "by_foot_per_day",
  "sqft_per_day",
  "per_month",
  "by_foot_per_month",
  "sqft_per_month",
  "per_stay",
  "by_foot_per_stay",
  "sqft_per_stay",
]

// memberships
export const BOAT_US = "boatus"
export const DOCKWA_PLUS = "dockwa_plus"
export const SAFE_HARBOR = "safe_harbor"
