import PropTypes from "prop-types"
import React from "react"

import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"
import Tooltip from "src/components/Tooltip"

const InvoiceSettled = ({
  combinedInvoice,
  paymentMethods,
  paymentMethod,
  invoiceProcessing,
}) => {
  const {
    marinaName,
    parentRecordType,
    saleId,
    reservationId,
    totalAmountDollars,
    settledAt,
    displayId,
    returnPath,
  } = combinedInvoice

  const findPaymentMethod = () => {
    return paymentMethods.find(
      (method) => method.stripePaymentMethodId === paymentMethod
    )
  }

  return (
    <div className="flex flex-col justify-center space-y-4 py-10 text-center">
      <i className="icon icon-contract-circle-check text-5xl text-teal-500" />
      <HeadingPageTitle>Payment submitted</HeadingPageTitle>
      <span className="text-gray-600">
        You&apos;re all set! You will receive a confirmation email soon with
        your payment details.
      </span>
      <div className="flex flex-col space-y-4 rounded bg-gray-100 p-4">
        <div className="flex flex-col">
          <span className="font-semibold">Will be paid to {marinaName}</span>
          <span>
            {parentRecordType} #{saleId || reservationId}
          </span>
        </div>
        <span className="text-4xl font-semibold">{totalAmountDollars}</span>
        <div className="flex flex-col">
          <span>{findPaymentMethod()?.title}</span>
          <span>{settledAt}</span>
        </div>
        <div>
          <span className="text-gray-600">Invoice reference: {displayId}</span>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        {invoiceProcessing ? (
          <Tooltip
            placement="top"
            variant="dark"
            text="Your receipt is still being generated"
          >
            <a
              href="#"
              className="btn btn-secondary pointer-events-none w-full cursor-not-allowed opacity-40"
            >
              Download Receipt
            </a>
          </Tooltip>
        ) : (
          <a
            href={`/account/invoice_payment/${displayId}/download_receipt`}
            className="btn btn-secondary"
          >
            Download Receipt
          </a>
        )}

        <a href={returnPath} className="btn btn-primary">
          Return to {parentRecordType} details
        </a>
      </div>
    </div>
  )
}

InvoiceSettled.propTypes = {
  combinedInvoice: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  invoiceProcessing: PropTypes.bool.isRequired,
}

export default InvoiceSettled
