import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const expireDeal = async (marinaSlug, id) => {
  return await mutateApi({
    url: `/manage/${marinaSlug}/dockwa_deals/${id}`,
    method: "DELETE",
  })
}

export const queryDockwaPlusDeals = () => {
  const marinaSlug = getCurrentMarinaSlug()
  return queryApi(`/manage/${marinaSlug}/dockwa_deals`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
