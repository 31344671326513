import React from "react"
import { useFormContext } from "react-hook-form"

import ComplexPriceEstimate from "./ComplexPriceEstimate"
import SimplePriceEstimate from "./SimplePriceEstimate"

const PriceSection = () => {
  const { watch } = useFormContext()
  const [contact] = watch(["contact"])

  return (
    <div className="rounded bg-gray-100 p-4">
      {contact?.encodedId ? <ComplexPriceEstimate /> : <SimplePriceEstimate />}
    </div>
  )
}

export default PriceSection
