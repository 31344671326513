import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"

const Intake = () => {
  const { productTypes, isEdit, isListedPumpPrice, marinaAccess } =
    useContext(ItemFormContext)
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const [selectedType, inventoryProductId] = watch([
    "type",
    "inventoryProductId",
  ])

  const showSKUAndBarcode = ["standard", "fuel"].includes(selectedType)

  return (
    <FormSection>
      <Form.Label htmlFor="item-type">Item type</Form.Label>
      <Form.Select
        id="item-type"
        disabled={isEdit}
        {...register("type", { required: true })}
      >
        {productTypes.map(({ displayName, value }) => (
          <option key={value} value={value}>
            {displayName}
          </option>
        ))}
      </Form.Select>
      <Form.Error>{errors.type?.message}</Form.Error>

      <Form.Label htmlFor="item-name">Name</Form.Label>
      <Form.TextField
        id="item-name"
        disabled={isListedPumpPrice}
        {...register("name", {
          required: "The name field is required",
        })}
        hasErrors={!!errors.name}
      />
      <Form.Error>{errors.name?.message}</Form.Error>

      {showSKUAndBarcode ? (
        <>
          <Form.Label htmlFor="item-sku">SKU</Form.Label>
          <MaybeRestrictedTooltip
            text={
              !marinaAccess.useSkus
                ? "To use SKUs, please upgrade your plan to include the POS module.\nContact your Dockwa representative or our support team to learn more.!"
                : null
            }
            restricted={!marinaAccess.useSkus}
            allowedRoles={["Admin", "Finance"]}
          >
            <Form.TextField
              id="item-sku"
              {...register("sku")}
              disabled={!marinaAccess?.useSkus || inventoryProductId}
              hasErrors={!!errors.sku}
            />
          </MaybeRestrictedTooltip>
          <Form.Error>{errors.sku?.message}</Form.Error>

          <Form.Label htmlFor="item-barcode">Barcode</Form.Label>
          <MaybeRestrictedTooltip
            text={
              !marinaAccess.barcodeScanner
                ? "To use barcodes, please upgrade your plan to include the POS module.\nContact your Dockwa representative or our support team to learn more!"
                : null
            }
            restricted={!marinaAccess.barcodeScanner}
            allowedRoles={["Admin", "Finance"]}
          >
            <Form.TextField
              id="item-barcode"
              {...register("barcode")}
              hasErrors={!!errors.barcode}
              disabled={!marinaAccess.barcodeScanner}
            />
            <Form.Error>{errors.barcode?.message}</Form.Error>
            {marinaAccess.barcodeScanner && (
              <div className="text-sm text-gray-600">
                You can use a barcode scanner to automatically fill in the
                barcode field. Simply scan the barcode and the value will be
                populated. You can use regular UPC barcodes or QR codes.
              </div>
            )}
          </MaybeRestrictedTooltip>
        </>
      ) : null}
    </FormSection>
  )
}

Intake.propTypes = {
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  isEdit: PropTypes.bool,
  isListedPumpPrice: PropTypes.bool,
  marinaAccess: PropTypes.shape({
    useSkus: PropTypes.bool,
    barcodeScanner: PropTypes.bool,
  }),
}

export default Intake
