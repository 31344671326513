import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useLocalStorageState } from "src/hooks/use_local_storage_state"

import Badge from "./"

const EventBadge = ({ mode, hideAt, maxViews, storageKey, ...badgeProps }) => {
  const [views, setViews] = useLocalStorageState("eventBadge", storageKey)
  const now = new Date()

  useEffect(function viewCounter() {
    if (views) {
      setViews(views + 1)
    } else {
      setViews(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isNew = now < hideAt || views < maxViews

  if (!isNew) return null

  return <Badge {...badgeProps} />
}

EventBadge.propTypes = {
  mode: PropTypes.oneOf(["time", "count"]).isRequired,
  hideAt: (props, propName, componentName) => {
    if (props.mode === "time") {
      if (props[propName] == null) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It is required when \`mode\` is "count".`
        )
      }
      if (!(props[propName] instanceof Date)) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected a Date object when \`mode\` is "time".`
        )
      }
    }
    return null
  },
  maxViews: (props, propName, componentName) => {
    if (props.mode === "count") {
      if (props[propName] == null) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It is required when \`mode\` is "count".`
        )
      }
      if (typeof props[propName] !== "number") {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected a number when \`mode\` is "count".`
        )
      }
    }
    return null
  },
  storageKey: (props, propName, componentName) => {
    if (props.mode === "count") {
      if (props[propName] == null) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It is required when \`mode\` is "count".`
        )
      }
      if (typeof props[propName] !== "string") {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected a string when \`mode\` is "count".`
        )
      }
    }
    return null
  },
  icon: PropTypes.string,
  iconPlacement: PropTypes.oneOf(["left", "right"]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onDismiss: PropTypes.func,
  color: PropTypes.oneOf([
    "blue",
    "error",
    "teal",
    "yellow",
    "red",
    "gray",
    "notification",
    "orange",
    "blue-inverted",
    "transparent",
  ]).isRequired,
  variant: PropTypes.oneOf(["default", "small"]),
}

export default EventBadge
