import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useQuery } from "react-query"
import MappingExternalField from "src/main/Accounting/MappingExternalField"

import DataTable from "src/components/DataTable"
import Loader from "src/components/Loader"

import { queryMappings } from "src/api/Accounting"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import { AccountingContext } from "./AccountingView"

const selectOptions = (codeOptions) => {
  return (
    <>
      {codeOptions.map((codeOption) => {
        return (
          <option key={codeOption[1]} value={codeOption[1]}>
            {codeOption[0]}
          </option>
        )
      })}
    </>
  )
}

const COLUMNDEFINITIONS = [
  {
    key: "displayName",
    header: "Dockwa Field",
  },
  {
    key: "externalField",
    header: "External Field",
    width: "280px",
    render: (data) => <MappingExternalField mapping={data} />,
  },
]

const MappingTable = ({ categoryKey, setPage, page }) => {
  const { marinaSlug } = useContext(AccountingContext)
  const { data, error, isError, isLoading } = useQuery(
    ["accounting", "mapping-tab", categoryKey, page],
    () =>
      queryMappings({
        marinaSlug: marinaSlug,
        collectionType: categoryKey,
        page: page,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected)
    updateUrlQueryParams({
      mapping_tab: categoryKey,
      page: selectedPage.selected,
    })
  }

  if (isLoading) {
    return (
      <div className="bg-white">
        <Loader name="mappings" />
      </div>
    )
  } else if (isError) {
    return (
      <div className="text-muted bg-white p-5 text-center">
        <h3 className="mb-5">{error.message}</h3>
      </div>
    )
  } else if (data) {
    if (data.collection.length === 0) {
      return (
        <div className="text-muted bg-white p-5 text-center">
          <h3 className="mb-5">No mappings to display</h3>
        </div>
      )
    } else {
      const codeSelectOptions = selectOptions(data.codeOptions)

      return (
        <div className="h-full w-full pb-8 pt-2">
          <DataTable
            isLoading={isLoading}
            name="glcodes.mappings"
            colDefs={COLUMNDEFINITIONS}
            rowData={data.collection.map((mapping) => ({
              ...mapping,
              codeSelectOptions,
              categoryKey,
            }))}
            pagination
            page={page}
            numberOfPages={data.totalPages}
            onPageChange={handlePageChange}
            hideActions
            hideSettings
          />
        </div>
      )
    }
  }
}

MappingTable.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
}

export default MappingTable
