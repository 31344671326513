import PropTypes from "prop-types"
import React from "react"

import { DockwaPlusLogoBlack } from "src/components/DockwaPlus/Logos"
import Modal from "src/components/Modal"

const DetailsModal = ({
  isOpen,
  setIsOpen,
  dockwaPlusDeal,
  marinaSlug,
  hasActiveDockwaPlusSubscription,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header title={dockwaPlusDeal.readableDiscount} />
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          {dockwaPlusDeal.validRangeReadable && (
            <div className="flex flex-col space-y-1">
              <span className="font-semibold">Discount applies to</span>
              <span>{dockwaPlusDeal.validRangeReadable}</span>
            </div>
          )}
          <div className="flex flex-col space-y-1">
            <span className="font-semibold">Description</span>
            <span>{dockwaPlusDeal.description}</span>
          </div>
          {dockwaPlusDeal.expiresAt && (
            <div className="flex flex-col space-y-1">
              <span className="font-semibold">Expiration</span>
              <span>Book before {dockwaPlusDeal.expiresAt}</span>
            </div>
          )}
          {marinaSlug && (
            <>
              <div>
                <a
                  href={`/explore/destination/${marinaSlug}`}
                  className="btn btn-primary"
                >
                  Visit marina profile to book
                </a>
              </div>
              {!hasActiveDockwaPlusSubscription && (
                <div className="flex flex-col space-y-4">
                  <div className="w-full border-b" />
                  <div className="flex items-center space-x-4">
                    <DockwaPlusLogoBlack />
                    <div className="flex flex-col space-y-1">
                      <span>
                        This deal and more available exclusively for Dockwa+
                        members.
                      </span>
                      <a
                        href="/account/settings/memberships"
                        className="cursor-pointer font-semibold text-blue-700"
                      >
                        Learn more and sign up today
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

DetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  marinaSlug: PropTypes.string,
  hasActiveDockwaPlusSubscription: PropTypes.bool,
  dockwaPlusDeal: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    discount: PropTypes.number,
    category: PropTypes.string,
    expiresAt: PropTypes.string,
    validRangeReadable: PropTypes.string,
    description: PropTypes.string.isRequired,
    readableDiscount: PropTypes.string.isRequired,
    expiresSoon: PropTypes.bool,
  }),
}

export default DetailsModal
