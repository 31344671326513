import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { cancelBoaterLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import { useTracker } from "src/hooks/use_tracker"

const CancelWaitlistEntryModal = ({
  closeModal,
  isOpen,
  entryId,
  setEntry,
  tracking = {},
}) => {
  const tracker = useTracker()
  const [error, setError] = useState("")

  const onClose = () => {
    closeModal()
    setError("")
  }

  const onSuccess = (response) => {
    if (setEntry) {
      setEntry(response.entry)
    }
    closeModal()
  }

  const onError = () => {
    setError(
      "There was an error canceling your waitlist entry. Please contact mayday@dockwa.com."
    )
  }

  const { mutate, isLoading, isSuccess } = useMutation(
    () => cancelBoaterLongtermWaitlistEntry(entryId),
    { onSuccess, onError }
  )

  const onSubmit = () => {
    tracker.trackEvent(tracking.submit, tracking.eventProperties)
    mutate()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Are you sure you want to cancel?" />
      <Modal.Body>
        Canceling your entry will remove you from the waitlist permanently. This
        action cannot be undone.
        {error && <Form.Error>{error}</Form.Error>}
      </Modal.Body>
      <Modal.Footer
        cancelBtnText="Close"
        onClose={onClose}
        confirmBtnText="Cancel"
        confirmBtnVariant="danger"
        confirmBtnLoading={isLoading || isSuccess}
        onSubmit={onSubmit}
        disabled={isLoading || isSuccess}
      />
    </Modal>
  )
}

CancelWaitlistEntryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setEntry: PropTypes.func,
  entryId: PropTypes.string.isRequired,
  tracking: PropTypes.shape({
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
    }).isRequired,
    submit: PropTypes.string.isRequired,
  }),
}

export default CancelWaitlistEntryModal
