import PropTypes from "prop-types"
import React, { useContext } from "react"
import { ChatContext } from "src/main/Chat/ChatContainer"
import Item from "src/main/Chat/Header/Item"

import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"

const Header = ({ details, actions }) => {
  const { setShowSidebar, showSidebar } = useContext(ChatContext)

  return (
    <div className="sticky top-0 z-50 flex flex-wrap gap-2 border-b border-t bg-white pb-3 pl-3 pr-4 pt-4">
      {details.map((item, index) => (
        <Item type={item.type} key={`header-${item.type}-${index}`} {...item} />
      ))}
      {actions.length > 0 && (
        <div className="w-full lg:w-44">
          <OverflowMenu menuButtonLabel="Actions" menuButtonFullWidth>
            {actions.map((item, index) => (
              <Item
                type={item.type}
                key={`header-${item.type}-${index}`}
                {...item}
              />
            ))}
          </OverflowMenu>
        </div>
      )}
      <div className="flex w-full flex-col lg:w-auto lg:flex-row lg:items-center lg:justify-end">
        <div className="mb-2 flex space-x-2 lg:mb-0">
          <div className="w-full lg:w-min">
            <Button
              onClick={() => {
                setShowSidebar(!showSidebar)
              }}
              fullWidth
            >
              {showSidebar ? "Hide Details" : "Details"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["contact", "status"]).isRequired,
      label: PropTypes.string,
      data: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["action"]).isRequired,
      label: PropTypes.string,
      data: PropTypes.object,
      link: PropTypes.string,
    })
  ),
}

export default Header
