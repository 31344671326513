import Decimal from "decimal.js"
import React, { useContext, useEffect, useState } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import Form from "src/components/Form"

import { PaymentFlowContext } from "../../PaymentModal"

const DetailCollectionScreen = () => {
  const {
    checkoutDetails: { setPaymentMethod },
  } = useContext(PaymentFlowContext)
  const {
    cartController: {
      totals: { total },
    },
  } = useContext(POSContext)

  const [checkNumber, setCheckNumber] = useState("")

  const onCheckNumberChange = ({ target: { value } }) => {
    const trimmedValue = value.trim()
    setCheckNumber(trimmedValue)
  }

  useEffect(() => {
    setPaymentMethod({
      checkNumber: checkNumber,
    })
  }, [checkNumber, setPaymentMethod])

  return (
    <div className="space-y-4 rounded bg-gray-100 p-4">
      <div className="flex items-center justify-between text-xl font-semibold">
        <span className="flex-1">Sale Total</span>
        <span>${new Decimal(total).toFixed(2)}</span>
      </div>
      <div className="flex items-center justify-between text-base">
        <span className="flex-1">Check Number</span>
        <div>
          <Form.TextField
            value={checkNumber}
            onChange={onCheckNumberChange}
            type="number"
            inputMode="numeric"
            rightAlign
          />
        </div>
      </div>
    </div>
  )
}

DetailCollectionScreen.modalTitle = "Add Check Number"
DetailCollectionScreen.confirmButtonLabel = "Continue to Review"

export default DetailCollectionScreen
