import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { arrayToUrlQueryString } from "src/utils/api/query_param_helpers"

export const createTransientReservation = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations`,
    method: "POST",
    data: params,
  })
}

export const calculatePrice = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations/calculate_price`,
    method: "POST",
    data: params,
  })
}

// Note: 'get' is misleading here, as this is a POST request.
// But, POSTing to this endpoint does not create any resources; it is functionally a GET.
// A good future task would be to update this endpoint to be a GET request.
export const getAvailability = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reservations/availability_v2`,
    method: "POST",
    data: params,
  })
}

export const getCouponCodes = ({
  marinaSlug,
  arrival,
  departure,
  productIds,
  contactId = null,
}) => {
  const queryParams = new URLSearchParams({
    contact_id: contactId,
  })

  if (arrival) {
    queryParams.append("arrival", arrival)
  }
  if (departure) {
    queryParams.append("departure", departure)
  }

  const productIdParams = arrayToUrlQueryString({
    queryParam: "product_ids",
    values: productIds,
  })

  return queryApi(
    `/manage/${marinaSlug}/reservations/discounts_v2?${queryParams.toString()}${productIdParams}`
  )
}
