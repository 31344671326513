import PropTypes from "prop-types"
import React from "react"

const AvailabilityDonut = ({ warningPercent, dangerPercent }) => {
  const warningDashArray = () => {
    const leftOverPercent = 100 - warningPercent
    return `${warningPercent} ${leftOverPercent}`
  }

  const dangerDashArray = () => {
    const leftOverPercent = 100 - dangerPercent
    return `${dangerPercent} ${leftOverPercent}`
  }

  const dangerDashOffset = () => {
    // Circumference − All preceding segments’ total length + First segment’s offset = Current segment offset
    return 100 - warningPercent
  }

  const fullyAvailable = () =>
    parseInt(warningPercent) === 0 && parseInt(dangerPercent) === 0

  const renderCheckMark = () => (
    <div style={{ position: "absolute", left: "14px", lineHeight: "12px" }}>
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
        <path
          d="M1.03906 4.29887L4.61244 7.36177L10.9935 0.725494"
          stroke="#7DC37C"
          strokeWidth="2"
        />
      </svg>
    </div>
  )

  return (
    <div className="relative flex items-center">
      <svg width="40px" height="40px" viewBox="0 0 42 42" className="donut">
        <circle
          className="donut-hole"
          cx="50%"
          cy="50%"
          r="15.91549430918954"
          fill="#fff"
        />
        <circle
          className="donut-segment stroke-supplementary-green-600"
          cx="50%"
          cy="50%"
          r="15.91549430918954"
          fill="transparent"
          style={{
            strokeWidth: 7,
          }}
        />

        {!fullyAvailable() && (
          <circle
            className="donut-segment stroke-yellow-500"
            cx="50%"
            cy="50%"
            r="15.91549430918954"
            fill="transparent"
            style={{
              strokeWidth: 7,
              strokeDasharray: warningDashArray(),
              strokeDashoffset: 0,
            }}
          />
        )}
        {!fullyAvailable() && (
          <circle
            className="donut-segment stroke-red-600"
            cx="50%"
            cy="50%"
            r="15.91549430918954"
            fill="transparent"
            style={{
              strokeWidth: 7,
              strokeDasharray: dangerDashArray(),
              strokeDashoffset: dangerDashOffset(),
            }}
          />
        )}
        {fullyAvailable() && renderCheckMark()}
      </svg>
    </div>
  )
}

AvailabilityDonut.propTypes = {
  warningPercent: PropTypes.string.isRequired,
  dangerPercent: PropTypes.string.isRequired,
}
export default AvailabilityDonut
