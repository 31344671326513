import React, { useContext } from "react"

import Tooltip from "src/components/Tooltip"

import { WizardContext } from "../../../WizardContextProvider"

const SpaceGroupsSettingsLink = () => {
  const { marinaSlug } = useContext(WizardContext)
  return (
    <Tooltip
      text="Configure space group settings"
      placement="top"
      variant="dark"
    >
      <a
        data-testid="trw-space-groups-settings-link"
        className="flex items-start text-2xl text-gray-600 no-underline hover:text-gray-700"
        href={`/manage/${marinaSlug}/settings/space_groups`}
      >
        <i className="icon icon-cog" />
      </a>
    </Tooltip>
  )
}

export default SpaceGroupsSettingsLink
