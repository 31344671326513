import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Heading from "src/components/Heading"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { WizardContext } from "../../../WizardContextProvider"

const PaymentDueSection = () => {
  const {
    isLoadingPriceEstimate,
    priceEstimateData,
    getPriceEstimateError,
    isMonthlyBilling,
  } = useContext(WizardContext)
  const { watch } = useFormContext()

  const [contact] = watch(["contact", "departure"])

  const renderLoadingSkeleton = () => (
    <div className="grid w-full gap-y-2" data-testid="trw-payments-due-loading">
      <div className="grid grid-cols-2">
        <div className="col-span-3 h-10 animate-pulse rounded bg-gray-200" />
      </div>
    </div>
  )

  const renderPaymentsDue = () => {
    const showPaymentsDue =
      !isLoadingPriceEstimate &&
      !getPriceEstimateError &&
      Boolean(priceEstimateData)

    if (isLoadingPriceEstimate) {
      return renderLoadingSkeleton()
    } else if (showPaymentsDue) {
      return (
        <div className="grid w-full gap-y-2">
          {priceEstimateData.payments_due.map((payment) => (
            <div key={payment.label} className="grid grid-cols-2">
              <div>{payment.label}</div>
              <div className="text-right">
                {formattedCentsToDollars(payment.amount)}
              </div>
            </div>
          ))}
          {isMonthlyBilling && (
            <div className="grid grid-cols-2">
              <div className="text-sm font-bold">Total</div>
              <div className="text-right text-sm font-bold">
                {formattedCentsToDollars(
                  priceEstimateData.payments_due.reduce(
                    (acc, payment) => acc + payment.amount,
                    0
                  )
                )}
              </div>
            </div>
          )}
        </div>
      )
    }

    return null
  }

  if (contact?.encodedId) {
    return (
      <div className="min-h-24 rounded bg-gray-100 p-4">
        <div className="flex w-full flex-col items-start space-y-4">
          <Heading.SectionHeader>Payments Due</Heading.SectionHeader>
          {renderPaymentsDue()}
        </div>
      </div>
    )
  }
  return null
}

export default PaymentDueSection
