import React, { useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import AddItemModal from "src/main/Billing/Items/AddItemModal"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Table from "src/components/Table"

import { BILLING_CYCLES, VALIDATED_FIELDS_BY_TAB } from "../../../constants"
import PageActions from "../../wizard/PageActions"
import {
  calculateTotalPriceDisplay,
  formatModalData,
  parseDateString,
  parseSelectedItem,
} from "./utils"

export const getRecurringDisplay = (data) => {
  const hasEndDate = !!data.endDate
  return (
    <div className="flex flex-col">
      <span className="text-muted text-sm">
        {hasEndDate ? "Monthly, from" : "Month-to-month, starting"}
      </span>
      <span>
        {parseDateString(data.startDate)}
        {hasEndDate ? `  to ${parseDateString(data.endDate)}` : ""}
      </span>
    </div>
  )
}

const AddItems = () => {
  const {
    getValues,
    formState: { errors },
    trigger,
    clearErrors,
  } = useFormContext()
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState(undefined)
  const { fields, append, remove, update } = useFieldArray({
    name: "posItemsData",
  })

  const billingCycle = getValues("billingCycle")
  const startDate = getValues("startDate")
  const endDate = getValues("endDate")
  const rate = getValues("rate")
  const feesAndDiscounts = getValues("feesAndDiscounts")

  const onSubmit = (data) => {
    const formattedData = formatModalData(data)
    if (selectedItemIndex !== undefined) {
      update(selectedItemIndex, formattedData)
      clearErrors(`posItemsData.${selectedItemIndex}`)
    } else {
      append(formattedData)
    }
    setSelectedItemIndex(undefined)
  }

  const endDateError =
    errors?.posItemsData && errors?.posItemsData.length
      ? errors.posItemsData.find((error) => error?.endDate)
      : null
  const hasPricePerUnitError =
    errors?.posItemsData &&
    errors.posItemsData.some((error) => error?.pricePerUnit)

  const displayDueDate = (field) => {
    if (field.dueDay) {
      return getRecurringDisplay(field)
    } else if (field.dueOnSignature) {
      return "On signature"
    } else if (field.addToInstallments) {
      return "Add to installments"
    } else {
      return parseDateString(field.dueDate || field.startDate)
    }
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <Table autoColumnWidth fullHeight>
          <Table.Head>
            <Table.Head.Row>
              <Table.Head.Cell>Name</Table.Head.Cell>
              <Table.Head.Cell>Due date</Table.Head.Cell>
              <Table.Head.Cell>Price</Table.Head.Cell>
              {/* edit/delete */}
              <Table.Head.Cell />
            </Table.Head.Row>
          </Table.Head>
          <Table.Body>
            {fields.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <div className="text-muted w-full text-center">
                    No items added
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {fields.map((field, i) => {
              const hasEndDateError =
                field.dueDay &&
                errors?.posItemsData &&
                errors?.posItemsData[i] &&
                errors?.posItemsData[i]?.endDate
              return (
                <Table.Row key={field.id}>
                  <Table.Cell>{field.name}</Table.Cell>
                  <Table.Cell>
                    <div className="flex flex-row gap-2">
                      {displayDueDate(field)}
                      {hasEndDateError ? (
                        <span className="font-semibold text-red-600">*</span>
                      ) : null}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{calculateTotalPriceDisplay(field)}</Table.Cell>
                  <Table.Cell>
                    <div className="flex flex-row gap-4">
                      <a
                        className="cursor-pointer font-semibold"
                        onClick={() => {
                          setSelectedItemIndex(i)
                          setIsAddItemModalOpen(true)
                        }}
                      >
                        Edit
                      </a>
                      <a
                        className="cursor-pointer font-semibold"
                        onClick={() => remove(i)}
                      >
                        Delete
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        {endDateError?.endDate ? (
          <Form.Error>* {endDateError.endDate.message}</Form.Error>
        ) : null}
        {hasPricePerUnitError ? (
          <Form.Error>
            * Please review your added items: The price may not be negative.
          </Form.Error>
        ) : null}
        <div className="flex w-full justify-center">
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              setSelectedItemIndex(undefined)
              setIsAddItemModalOpen(true)
            }}
          >
            Add Item
          </Button>
        </div>
      </div>
      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.addItems)}
      />
      {isAddItemModalOpen ? (
        <AddItemModal
          onSubmit={onSubmit}
          isMonthToMonth={billingCycle === BILLING_CYCLES.MONTH_TO_MONTH}
          isMonthlyInstallments={
            billingCycle === BILLING_CYCLES.MONTHLY_INSTALLMENTS
          }
          isOpen
          initialValues={parseSelectedItem(fields[selectedItemIndex])}
          onClose={() => {
            setIsAddItemModalOpen(false)
          }}
          contractStartDate={startDate}
          contractEndDate={endDate}
          percentBasedDisabled={
            rate.amount === "0" ||
            feesAndDiscounts.some(
              (discount) =>
                discount.percentage === "1" &&
                (discount.discountType === "every" || !discount.discountType)
            )
          }
        />
      ) : null}
    </>
  )
}

export default AddItems
