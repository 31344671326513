import classNames from "classnames"
import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { DockwaPlusMembershipCardSmall } from "src/main/Account/Settings/DockwaPlusMembershipCard"

import Form from "src/components/Form"
import LineItems from "src/components/LineItems/LineItems"
import Modal from "src/components/Modal"
import SelectPaymentMethod from "src/components/SelectPaymentMethod/SelectPaymentMethod"

import { createDockwaPlusMembership } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const ACCEPTED_PAYMENT_METHODS = ["card"]

const DockwaPlusMembershipSignupModal = ({
  isOpen,
  setIsOpen,
  setUser,
  dockwaPlusProductInformation,
  productInformationLoading,
  paymentMethods,
  setPaymentMethods,
  zipCode,
  setZipCode,
}) => {
  const tracker = useTracker()
  const [paymentMethod, setPaymentMethod] = useState("")
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false)

  const creditCards = useMemo(() => {
    if (!paymentMethods) return []
    return paymentMethods.filter(
      (method) =>
        method.type === "PaymentMethod::Card" && method.deleted !== true
    )
  }, [paymentMethods])

  const showToast = useToast()
  const {
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { zipCode },
    mode: "onChange",
    revalidateMode: "onChange",
  })

  useEffect(() => {
    const method = creditCards.find(
      (method) => method.stripePaymentMethodId === paymentMethod
    )

    setZipCode(method?.metadata?.zip || "")
  }, [creditCards, paymentMethod, setZipCode])

  useEffect(() => {
    setValue("zipCode", zipCode, { shouldValidate: zipCode !== "" })
  }, [setValue, zipCode])

  const { mutate: createMembership, isLoading: creatingMembership } =
    useMutation((data) => createDockwaPlusMembership({ data }), {
      onSuccess: (data) => {
        showToast(
          `You’ve successfully signed up for Dockwa+! You’ll retain access to your benefits through ${data.dockwaPlusSubscription?.readableCurrentPeriodEnd}`,
          { type: "success" }
        )
        setUser(data)
        const { dockwa_plus_external_id: dockwaPlusExternalId } =
          dockwaPlusProductInformation

        tracker.trackEvent("dockwa_plus:membership_purchased", {
          dockwa_plus_external_id: dockwaPlusExternalId,
        })
        handleClose({ onSuccess: true })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })

        const { dockwa_plus_external_id: dockwaPlusExternalId } =
          dockwaPlusProductInformation

        tracker.trackEvent("dockwa_plus:membership_purchase_failed", {
          dockwa_plus_external_id: dockwaPlusExternalId,
          error: error.message,
        })
      },
    })

  const submitButtonText = () => {
    if (creatingMembership) {
      return "Purchasing"
    } else {
      return "Purchase Dockwa+"
    }
  }

  const handleClose = ({ onSuccess } = { onSuccess: false }) => {
    if (!onSuccess && dockwaPlusProductInformation?.dockwa_plus_external_id) {
      const { dockwa_plus_external_id: dockwaPlusExternalId } =
        dockwaPlusProductInformation

      tracker.trackEvent("dockwa_plus:cancel_purchase_pressed", {
        dockwa_plus_external_id: dockwaPlusExternalId,
      })
    }

    setIsOpen(false)
    reset()
    setZipCode("")
    setPaymentMethod("")
  }

  const handleCreateMembership = (data) => {
    const { dockwa_plus_external_id: dockwaPlusExternalId } =
      dockwaPlusProductInformation

    tracker.trackEvent("dockwa_plus:purchase_pressed", {
      dockwa_plus_external_id: dockwaPlusExternalId,
    })

    createMembership({
      paymentMethodId: paymentMethod,
      postalCode: zipCode || data.zipCode,
      dockwaPlusVersion: dockwaPlusProductInformation.dockwa_plus_version,
    })
  }

  const renderMembershipInformation = () => (
    <div className="pb-4">
      <div className="flex space-x-4">
        <DockwaPlusMembershipCardSmall />
        <div className="flex flex-col space-y-2">
          <span className="font-bold">Dockwa+ Membership</span>
          <span className="text-gray-600">
            Renews on{" "}
            {dockwaPlusProductInformation &&
              format(
                new Date(dockwaPlusProductInformation.renewal_date),
                "MMM d, yyyy"
              )}
          </span>
          <span className="text-gray-600">Discounts apply automatically</span>
        </div>
      </div>
    </div>
  )

  const renderPaymentMethodForm = () => {
    const paymentMethodObject = creditCards.find(
      (card) => card.stripePaymentMethodId === paymentMethod
    )

    return (
      <div className="flex flex-col space-y-2">
        <div className="mt-2 flex space-x-4">
          <div
            className={classNames("w-full", {
              "px-1": paymentMethod === "add",
            })}
          >
            <SelectPaymentMethod
              acceptedPaymentMethods={ACCEPTED_PAYMENT_METHODS}
              paymentMethods={creditCards}
              setPaymentMethods={setPaymentMethods}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </div>
          {paymentMethod !== "add" && (
            <Form
              id="dockwa-plus-membership"
              onSubmit={handleSubmit(handleCreateMembership)}
            >
              <div className="flex h-full flex-col justify-end self-end">
                <Form.Label htmlFor="zipCode">Zip Code</Form.Label>
                <Form.TextField
                  id="zipCode"
                  {...register("zipCode", {
                    required: "Zip code is required",
                    onChange: (e) => setZipCode(e.target.value),
                  })}
                  value={zipCode}
                  disabled={
                    paymentMethod === "" || !!paymentMethodObject?.metadata?.zip
                  }
                  hasErrors={!!errors?.zipCode}
                />
              </div>
              {errors.zipCode && (
                <Form.Error>{errors.zipCode.message}</Form.Error>
              )}
            </Form>
          )}
        </div>
      </div>
    )
  }

  const renderLineItems = () => (
    <div className="mb-4">
      <LineItems
        lineItems={dockwaPlusProductInformation?.line_items}
        loading={productInformationLoading}
        loadingItems={4}
      />
    </div>
  )

  const renderLinks = () => (
    <>
      <div className="mt-6 flex items-center gap-4 text-xs text-gray-600">
        <input
          className="m-0"
          type="checkbox"
          id="termsAndConditionsAccepted"
          name="termsAndConditionsAccepted"
          checked={termsAndConditionsAccepted}
          onChange={() =>
            setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
          }
        />
        <label className="m-0" htmlFor="termsAndConditionsAccepted">
          I agree to{" "}
          <a
            href="https://ahoy.dockwa.com/about-us/terms-of-service"
            className="font-semibold text-blue-600"
            rel="noreferrer noopener"
            target="_blank"
          >
            Dockwa&apos;s Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            href="https://ahoy.dockwa.com/privacy"
            className="font-semibold text-blue-600"
            rel="noreferrer noopener"
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          and consent to being charged annually, with automatic renewals until I
          cancel in my account settings. I understand that pricing may change,
          and no refunds will be provided for unused subscription periods.
        </label>
      </div>
    </>
  )

  const disablePurchase =
    !paymentMethod ||
    !termsAndConditionsAccepted ||
    !!errors.zipCode ||
    !dockwaPlusProductInformation

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="mediumFixed">
      <Modal.Header title="Sign up for Dockwa+" />
      <Modal.Body>
        <div className="pb-4">
          {renderMembershipInformation()}
          {renderPaymentMethodForm()}
          <div className="my-8 w-full" />
          {renderLineItems()}
          {renderLinks()}
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnForm="dockwa-plus-membership"
        confirmBtnVariant="primary"
        confirmBtnLoading={creatingMembership || productInformationLoading}
        confirmBtnType="submit"
        disabled={disablePurchase}
        onSubmit={() => {}}
        cancelBtnText="Cancel"
        disableCancelBtn={creatingMembership}
      />
    </Modal>
  )
}

DockwaPlusMembershipSignupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  dockwaPlusProductInformation: PropTypes.shape({
    dockwa_plus_external_id: PropTypes.string.isRequired,
    dockwa_plus_version: PropTypes.number.isRequired,
    line_items: PropTypes.array.isRequired,
    renewal_date: PropTypes.string.isRequired,
  }),
  paymentMethods: PropTypes.array,
  setPaymentMethods: PropTypes.func,
  zipCode: PropTypes.string.isRequired,
  setZipCode: PropTypes.func.isRequired,
  productInformationLoading: PropTypes.bool.isRequired,
}

export default DockwaPlusMembershipSignupModal
