import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const AdditionalInformationSection = ({ customFieldDefinitions }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  const renderInput = (customField) => {
    switch (customField.inputType) {
      case "file": {
        const fieldName = `customFields.${customField.key}`
        const hasUploadError = Boolean(errors.customFields?.[customField.key])

        return (
          <>
            <Form.DirectUploadFileInput
              id={customField.key}
              name={fieldName}
              accept="image/jpg, image/jpeg, image/png, application/pdf"
              placeholder={`Upload ${customField.title}`}
              hasErrors={hasUploadError}
              onUploadSuccess={(blob) => {
                setValue(fieldName, blob.signed_id, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }}
              onUploadError={(error) => {
                alert("Upload failed, please try again: " + error)
              }}
              onFileRemove={(blobId) => {
                setValue(fieldName, null, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }}
            />
            {hasUploadError && (
              <Form.Error>
                {errors.customFields?.[customField.key]?.message}
              </Form.Error>
            )}
          </>
        )
      }
      case "text": {
        const textFieldName = `customFields.${customField.key}`
        const hasTextError = Boolean(errors.customFields?.[customField.key])

        return (
          <>
            <Form.TextField
              id={customField.key}
              hasErrors={hasTextError}
              {...register(textFieldName)}
            />
            {hasTextError && (
              <Form.Error>
                {errors.customFields?.[customField.key]?.message}
              </Form.Error>
            )}
          </>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      {customFieldDefinitions.map((customField) => {
        return (
          <section key={customField.key}>
            <Form.Label htmlFor={customField.key}>
              {customField.title}
            </Form.Label>
            {renderInput(customField)}
          </section>
        )
      })}
    </div>
  )
}

AdditionalInformationSection.propTypes = {
  customFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      inputType: PropTypes.string,
    })
  ),
}

export default AdditionalInformationSection
