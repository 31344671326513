import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const queryChat = async (viewAs, marinaSlug, chatObject) => {
  const url =
    viewAs === "boater" ? "/account/messages" : `/manage/${marinaSlug}/messages`

  return queryApi(`${url}/${chatObject.id}?type=${chatObject.type}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const sendMessage = (marinaSlug, data, source) => {
  const url =
    source === "boater" ? "/account/messages" : `/manage/${marinaSlug}/messages`

  return mutateApi({
    url,
    method: "POST",
    data,
  })
}

export const queryChatObject = async ({ id, type, viewAs }) => {
  if (viewAs === "boater") {
    return queryApi(`/account/chat_objects/${id}?type=${type}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
  } else {
    const marinaSlug = getCurrentMarinaSlug()
    return queryApi(`/manage/${marinaSlug}/chat_objects/${id}?type=${type}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
  }
}
