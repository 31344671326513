import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { centsToDollars } from "src/main/Billing/Items/helpers"
import { POSContext } from "src/main/PointOfSale/Layout"

import { itemSubtotalDollars } from "../../CurrentSalePanel/helpers"
import ConfirmationTotals from "./ConfirmationTotals"

const ItemRow = ({ item, index }) => {
  const { serverCartFeatureEnabled } = useContext(POSContext)
  const { watch } = useFormContext()

  const { quantity, price } = watch(`cart[${index}]`)

  function renderServerCartItem(item) {
    const {
      subtotal,
      total,
      discountAmount,
      taxAmount,
      name,
      quantity: itemQuantity,
    } = item

    const hasDiscount = discountAmount > 0

    const subtotalDollars = centsToDollars(subtotal).toFixed(2)
    const totalDollars = centsToDollars(total - taxAmount).toFixed(2)

    const priceText = hasDiscount ? (
      <div className="flex space-x-2">
        <span className="text-gray-600 line-through">${subtotalDollars}</span>
        <span className="font-semibold text-teal-600">${totalDollars}</span>
      </div>
    ) : (
      <span>${subtotalDollars}</span>
    )

    return (
      <div className="flex justify-between space-y-2">
        <div className="font-semibold">
          <span className="text-gray-800">{name}</span>{" "}
          <span className="text-gray-600">({itemQuantity})</span>
        </div>
        {priceText}
      </div>
    )
  }

  function renderClientCartItem(item) {
    return (
      <div className="flex justify-between space-y-2">
        <div className="font-semibold">
          <span className="text-gray-800">{item.name}</span>{" "}
          <span className="text-gray-600">({quantity})</span>
        </div>
        <span>
          $
          {itemSubtotalDollars({
            quantity,
            price,
          })}
        </span>
      </div>
    )
  }

  return serverCartFeatureEnabled
    ? renderServerCartItem(item)
    : renderClientCartItem(item)
}

ItemRow.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    pricePrecision: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

const ConfirmationScreen = ({ details }) => {
  const { cartController } = useContext(POSContext)
  return (
    <div>
      <div>
        {cartController.fields.map((item, index) => (
          <ItemRow item={item} index={index} key={item.id} />
        ))}
      </div>
      <hr />
      <ConfirmationTotals />
      <div className="mb-4">{details}</div>
    </div>
  )
}

ConfirmationScreen.propTypes = {
  details: PropTypes.node,
}

export default ConfirmationScreen
