import format from "date-fns/format"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { Fragment } from "react"
import DetailItem from "src/main/Account/Trips/Sales/Details/DetailItem"
import { salePropType } from "src/main/Account/Trips/Sales/Details/index"

import Badge from "src/components/Badge"

import { statusBadgeColor } from "src/utils/status_badge_color_helper"
import { snakecaseToTitlecase } from "src/utils/string_helpers"

const SaleInfo = ({ sale }) => {
  const { marina } = sale
  return (
    <Fragment>
      <div className="col-span-5 hidden px-4 md:col-span-1 lg:block lg:px-0">
        <Badge
          color={statusBadgeColor(snakecaseToTitlecase(sale.settledStatus))}
          text={snakecaseToTitlecase(sale.settledStatus)}
        />
      </div>
      <div className="col-span-5 flex flex-col space-y-2 px-4 lg:col-span-4 lg:px-0">
        <DetailItem label="Sale">
          <div className="flex flex-col items-start space-y-2">
            <div className="block lg:hidden">
              <Badge
                color={statusBadgeColor(
                  snakecaseToTitlecase(sale.settledStatus)
                )}
                text={snakecaseToTitlecase(sale.settledStatus)}
              />
            </div>
            <span>#{sale.encodedId}</span>
          </div>
        </DetailItem>
        <DetailItem label="Sale Created at">
          <span>{format(new Date(sale.createdAt), "MMMM d, yyyy")}</span>
        </DetailItem>
        {sale.contactBoatNames.length > 0 && (
          <DetailItem label={pluralize("Boat", sale.contactBoatNames.length)}>
            <span>{sale.contactBoatNames.join(", ")}</span>
          </DetailItem>
        )}
        <div className="flex items-center space-x-2">
          <i className="icon icon-message text-blue-600" />
          <span>
            Thank you for choosing {marina.name}! If you have any questions
            regarding this sale, please{" "}
            {marina.displayPhone
              ? `contact us at ${marina.displayPhone}.`
              : "contact us directly."}
          </span>
        </div>
      </div>
    </Fragment>
  )
}

SaleInfo.propTypes = {
  sale: PropTypes.shape(salePropType).isRequired,
}

export default SaleInfo
