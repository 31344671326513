import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"
import Table from "src/components/Table"
import Tooltip from "src/components/Tooltip"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { statusBadgeColor } from "src/utils/status_badge_color_helper"

const PaymentRow = ({ payment }) => {
  const {
    amount,
    id,
    paymentFailureMessage,
    paymentMethod,
    paymentMethodLogo,
    paymentMethodTitle,
    paymentType,
    processedAt,
    status,
  } = payment

  const renderPaymentMethod = () => {
    const tooltipText = "ACH payments take 4 business days to settle"
    if (paymentMethod || (paymentMethodTitle && paymentMethodLogo)) {
      const title = paymentMethodTitle || paymentMethod.title

      const paymentMethodElements = (
        <div className="flex flex-row items-center space-x-1">
          <img className="h-5" src={paymentMethodLogo} alt={title} />
          <span>{title}</span>
        </div>
      )

      if (paymentMethod?.type?.includes("Bank")) {
        return (
          <Tooltip text={tooltipText} placement="top" variant="dark">
            {paymentMethodElements}
          </Tooltip>
        )
      } else {
        return paymentMethodElements
      }
    } else {
      if (paymentType.includes("ACH")) {
        return (
          <div className="w-min whitespace-nowrap">
            <Tooltip text={tooltipText} placement="top" variant="dark">
              {paymentType}
            </Tooltip>
          </div>
        )
      } else {
        return paymentType
      }
    }
  }

  const renderStatusBadge = () => {
    if (paymentFailureMessage) {
      return (
        <div className="w-min">
          <Tooltip text={paymentFailureMessage} placement="top" variant="dark">
            <Badge text={status} color={statusBadgeColor(status)} />
          </Tooltip>
        </div>
      )
    } else {
      return <Badge text={status} color={statusBadgeColor(status)} />
    }
  }

  return (
    <Table.Row key={id}>
      <Table.Cell>
        <div />
      </Table.Cell>
      <Table.Cell title={renderStatusBadge()} />
      <Table.Cell />
      <Table.Cell title={processedAt} />
      <Table.Cell>{renderPaymentMethod()}</Table.Cell>
      <Table.Cell title={formattedCentsToDollars(amount)} />
    </Table.Row>
  )
}

PaymentRow.propTypes = {
  payment: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    paymentMethod: PropTypes.shape({
      title: PropTypes.string,
      brand: PropTypes.string,
      last4: PropTypes.string,
      type: PropTypes.string,
    }),
    paymentFailureMessage: PropTypes.string,
    paymentMethodLogo: PropTypes.string,
    paymentMethodTitle: PropTypes.string,
    paymentType: PropTypes.string,
    processedAt: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default PaymentRow
