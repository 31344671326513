import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { CUSTOM_RATE_ID } from "../../../constants"

const RateAmountInput = () => {
  const {
    register,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext()
  const [rateId] = watch(["rate.id"])

  return (
    <div>
      <Form.Label htmlFor="trw-rate-amount">Amount</Form.Label>
      <Form.IconTextField
        icon="$"
        position="left"
        id="trw-rate-amount"
        type="text"
        inputMode="decimal"
        disabled={rateId !== CUSTOM_RATE_ID}
        {...register("rate.amount")}
        hasErrors={Boolean(errors?.rate?.amount)}
        onBlur={() => trigger("rate.amount")}
      />
      {errors?.rate?.amount?.message && (
        <Form.Error>{errors.rate.amount.message}</Form.Error>
      )}
    </div>
  )
}

export default RateAmountInput
