import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"
import Card from "src/components/Card"

import { statusBadgeColor } from "src/utils/status_badge_color_helper"
import { snakecaseToTitlecase } from "src/utils/string_helpers"

const Notice = ({ link, data }) => {
  return (
    <div className="card flex flex-col space-y-2 border-teal-600 bg-teal-50">
      <span className="text-base font-semibold">{data.header}</span>
      <Card href={link} withShadow={false}>
        <div className="flex items-center justify-between p-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col">
              <span className="font-semibold">{data.card.label}</span>
              <span>{data.card.dates}</span>
            </div>
            <div>
              <Badge
                color={statusBadgeColor(snakecaseToTitlecase(data.card.status))}
                text={snakecaseToTitlecase(data.card.status)}
              />
            </div>
          </div>
          <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
        </div>
      </Card>
      {data.associated_record_message_thread_link && (
        <span>
          {data.associated_record_message_thread_copy}{" "}
          <a
            className="font-semibold text-blue-700"
            href={data.associated_record_message_thread_link}
          >
            message thread
          </a>
        </span>
      )}
    </div>
  )
}

Notice.propTypes = {
  link: PropTypes.string.isRequired,
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    card: PropTypes.shape({
      dates: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    associated_record_message_thread_link: PropTypes.string,
    associated_record_message_thread_copy: PropTypes.string,
  }).isRequired,
}

export default Notice
