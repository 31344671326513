import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import { IMaskInput } from "react-imask"

import Form from "src/components/Form"

// This is a copy-paste of client/src/main/Contracts/ContractsForm/shared/DatePickerInput.jsx
// A good future task would be to create a shared DatePickerInput and use in both spots
const DatePickerInput = forwardRef(({ errorMessage, ...props }, ref) => (
  <>
    <div className="relative w-full">
      <span className="absolute bottom-0 left-3 top-0 flex items-center text-gray-600">
        <i className="icon icon-calendar" />
      </span>
      <IMaskInput
        {...props}
        className={classNames(
          "h-10 w-full rounded border py-3 pl-10 pr-2 outline-none focus:border-blue-600",
          {
            "border-red-600": !!errorMessage,
          }
        )}
        maxLength={10}
        mask={Date}
        pattern="m{/}`d{/}`YY"
        unmask={true}
        ref={ref}
      />
    </div>
    {errorMessage ? <Form.Error>{errorMessage}</Form.Error> : null}
  </>
))

DatePickerInput.displayName = "DatePickerInput"

DatePickerInput.propTypes = {
  id: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default DatePickerInput
