import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"

import Modal from "src/components/Modal"

import { archiveContractQuote, sendContractQuote } from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { ContractGroupContext } from "./index"

const ContractActionsModal = ({
  action,
  contract,
  handleDisableActions,
  handleEnableActions,
  isOpen,
  setIsOpen,
  tab,
}) => {
  const { group } = useContext(ContractGroupContext)
  const showToast = useToast()
  const tracker = useTracker()
  const queryClient = useQueryClient()

  const handleClose = () => {
    setIsOpen(false)
    handleEnableActions()
  }
  const handleTrackEvent = () => {
    const attributes = {
      contract_quote_id: contract.encodedId,
    }
    if (contract.status === "Sent") {
      attributes.resend = true
    }
    tracker.trackEvent("contracts:send", attributes)
  }

  const handleSubmit = () => {
    handleDisableActions()
    if (action === "send") {
      sendContract({ quoteId: contract.encodedId, groupId: group.encodedId })
    } else {
      archiveContract({
        quoteId: contract.encodedId,
        groupId: group.encodedId,
      })
    }
  }

  const { mutate: sendContract, isLoading: sendIsLoading } = useMutation(
    (data) => sendContractQuote(data),
    {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries([
          "contractGroups",
          group.encodedId,
          "contractQuotes",
        ])
        showToast(
          "Contracts are sending! Once it has been sent, the status will automatically update.",
          { type: "success" }
        )
        handleTrackEvent()
      },
      onMutate: async ({ quoteId, groupId }) => {
        await queryClient.cancelQueries([
          "contractGroups",
          groupId,
          "contractQuotes",
        ])

        const previousData = queryClient.getQueryData([
          "contractGroups",
          groupId,
          "contractQuotes",
        ])

        queryClient.setQueryData(
          ["contractGroups", groupId, "contractQuotes"],
          (oldData) => {
            if (!oldData) return oldData

            const { unsent, outstanding, completed } = oldData

            const unsentContract = unsent.find(
              (item) => item.encodedId === quoteId
            )
            const outstandingContract = outstanding.find(
              (item) => item.encodedId === quoteId
            )

            const outstandingContracts = outstandingContract
              ? outstanding
              : [{ ...unsentContract, status: "Sent" }, ...outstanding]

            return {
              unsent: unsent.filter((item) => item.encodedId !== quoteId),
              outstanding: outstandingContracts,
              completed,
            }
          }
        )

        return { previousData }
      },
      onError: (data, variables, context) => {
        queryClient.setQueryData(
          ["contractGroups", group.encodedId, "contractQuotes"],
          context.previousData
        )
        handleClose()
        showToast(`Contract failed to send. ${data.response}`, {
          type: "error",
        })
      },
    }
  )

  const { mutate: archiveContract, isLoading: archiveIsLoading } = useMutation(
    archiveContractQuote,
    {
      onSuccess: (data) => {
        handleClose()
        showToast(data.message, { type: "success" })
      },
      onMutate: async ({ quoteId, groupId }) => {
        await queryClient.cancelQueries([
          "contractGroups",
          groupId,
          "contractQuotes",
        ])

        const previousData = queryClient.getQueryData([
          "contractGroups",
          groupId,
          "contractQuotes",
        ])

        queryClient.setQueryData(
          ["contractGroups", groupId, "contractQuotes"],
          (oldData) => {
            if (!oldData) return oldData

            const { completed, ...rest } = oldData
            return {
              ...rest,
              completed: completed.filter(
                (quote) => quote.encodedId !== quoteId
              ),
            }
          }
        )

        return { previousData }
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData(
          ["contractGroups", group.encodedId, "contractQuotes"],
          context.previousData
        )
        handleClose()
        showToast(error.message, { type: "error" })
      },
    }
  )

  const title = () => {
    if (action === "send") {
      return "Are you sure you want to send this quote?"
    } else {
      return "Are you sure you want to delete this contract?"
    }
  }

  const body = () => {
    if (action === "send") {
      return "This will send the quote to the boater so they can sign and pay. This cannot be undone."
    } else {
      return "This will delete the contract. This cannot be undone."
    }
  }

  const submitButtonText = () => {
    if (action === "send") {
      return sendIsLoading ? "Sending" : "Send"
    } else {
      return archiveIsLoading ? "Deleting" : "Delete"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title={title()} />
      <Modal.Body>
        <div className="pb-2">{body()}</div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnVariant="primary"
        confirmBtnLoading={sendIsLoading || archiveIsLoading}
        onSubmit={handleSubmit}
        cancelBtnText="Cancel"
        disableCancelBtn={sendIsLoading || archiveIsLoading}
      />
    </Modal>
  )
}

ContractActionsModal.propTypes = {
  action: PropTypes.string.isRequired,
  contract: PropTypes.shape({
    encodedId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  handleEnableActions: PropTypes.func.isRequired,
  handleDisableActions: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
}

export default ContractActionsModal
