import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"

import { createPhoto } from "src/api/Onboarding/Claim"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const PhotoStep = ({ onNext, existingPhoto }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(null)
  const [photo, setPhoto] = useState(null)
  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()
  const { mutate: uploadPhoto } = useMutation(
    (photoFile) => {
      setError(null)
      return createPhoto(marinaSlug, { photo: photoFile })
    },
    {
      onSuccess: (data) => {
        setIsUploading(false)
        // Use the returned photo URL from the API or fall back to existingPhoto
        setPhoto(data?.photoUrl || existingPhoto)
      },
      onError: (error) => {
        setIsUploading(false)
        setError(error.message)
      },
    }
  )

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      tracker.trackEvent("onboarding:claim:upload_photo_pressed")
      setIsUploading(true)
      uploadPhoto(file)
    }
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Transition
        className="flex h-full w-full flex-col items-center justify-center text-center"
        appear
        show={!!photo}
        enter="transition-all duration-300 ease-in-out"
        enterFrom="opacity-0 -translate-y-4"
        enterTo="opacity-100 translate-y-0"
      >
        <>
          <div
            className="mb-4 h-64 w-full rounded bg-cover bg-center bg-no-repeat lg:w-1/2"
            style={{ backgroundImage: `url(${photo})` }}
          />
          <div className="px-2 lg:w-1/3">
            <h2 className="text-2xl font-bold">Looking good!</h2>
            <p className="text-sm text-gray-500">
              You will be able to upload more photos later. We recommend
              uploading a few photos to help potential customers get a better
              look at your marina.
            </p>
            <Button
              variant="primary"
              onClick={() => {
                tracker.trackEvent("onboarding:claim:photo_continue_pressed")
                onNext()
              }}
            >
              Continue
            </Button>
          </div>
        </>
      </Transition>
      {!photo && (
        <div className="flex h-full w-full flex-col items-center justify-center px-2 text-center lg:w-1/3">
          <h2 className="text-2xl font-bold">
            {existingPhoto ? "Upload a new photo" : "Upload your first photo"}
          </h2>
          <p className="text-sm text-gray-500">
            Show off what makes your marina unique by uploading a great photo! A
            high-quality image helps attract more boaters and gives them a
            preview of the experience they can expect at your marina.
            <br />
            <br />
            Photos must be under 20MB and in jpeg, png, tiff, or gif format.
          </p>
          <div className="mt-4 flex gap-4">
            {isUploading ? (
              <Button variant="secondary" isLoading>
                Upload Photo
              </Button>
            ) : (
              <label className="btn btn-secondary">
                Upload Photo
                <input
                  type="file"
                  className="hidden"
                  onChange={handlePhotoUpload}
                  accept="image/jpeg, image/png, image/tiff, image/gif"
                />
              </label>
            )}
            {existingPhoto && (
              <Button
                variant="ghost-tertiary"
                onClick={() => {
                  tracker.trackEvent(
                    "onboarding:claim:photo_skip_continue_pressed"
                  )
                  onNext()
                }}
              >
                Skip for now
              </Button>
            )}
          </div>
          {error && <div className="mt-2 text-red-500">{error}</div>}
        </div>
      )}
    </div>
  )
}

PhotoStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  existingPhoto: PropTypes.string,
}

export default PhotoStep
