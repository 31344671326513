import Decimal from "decimal.js"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import Form from "src/components/Form"

import { PaymentFlowContext } from "../../PaymentModal"
import { changeDue } from "./helpers"

const DetailCollectionScreen = () => {
  const {
    checkoutDetails: { paymentMethod, setPaymentMethod },
  } = useContext(PaymentFlowContext)
  const {
    cartController: {
      totals: { total },
    },
  } = useContext(POSContext)

  const [cashCollected, setCashCollected] = useState("0")
  const cashCollectedDisplayValue = useMemo(() => {
    return `$${cashCollected}`
  }, [cashCollected])

  const onCashCollectedChange = ({ target: { value } }) => {
    const strippedValue = value.replaceAll(/[^\d.]/g, "")
    setCashCollected(strippedValue)
  }

  useEffect(() => {
    setPaymentMethod({
      cashCollected: Number(cashCollected),
      total: new Decimal(total).toNumber(),
    })
  }, [cashCollected, setPaymentMethod, total])

  const onFocus = (e) => {
    const { target } = e

    if (cashCollected === "0") {
      setCashCollected("")
    }

    setTimeout(() => {
      target.setSelectionRange(
        cashCollectedDisplayValue.length,
        cashCollectedDisplayValue.length
      )
    }, 0)
  }

  return (
    <div className="space-y-4 rounded bg-gray-100 p-4">
      <div className="flex items-center justify-between">
        <span className="flex-1">Sale total</span>
        <span>${new Decimal(total).toFixed(2)}</span>
      </div>
      <div className="flex items-center justify-between">
        <span className="flex-1">Cash collected</span>
        <div>
          <Form.TextField
            inputMode="decimal"
            value={cashCollectedDisplayValue}
            onChange={onCashCollectedChange}
            rightAlign
            onFocus={onFocus}
          />
        </div>
      </div>
      {paymentMethod?.cashCollected >= total ? (
        <div className="flex justify-between text-2xl font-semibold">
          <span>Change Due</span>
          <span>${changeDue(paymentMethod.cashCollected, total)}</span>
        </div>
      ) : null}
    </div>
  )
}

DetailCollectionScreen.modalTitle = "Receive cash"
DetailCollectionScreen.confirmButtonLabel = "Continue"

export default DetailCollectionScreen
