import PropTypes from "prop-types"
import React from "react"
import { renderStatusBadge } from "src/main/Inquiries/InquiriesTable"

import { snakecaseToTitlecase } from "src/utils/string_helpers"

const Status = ({ data }) => {
  return (
    <div className="flex items-center justify-end lg:w-auto lg:flex-none">
      {data && renderStatusBadge(snakecaseToTitlecase(data))}
    </div>
  )
}

Status.propTypes = {
  data: PropTypes.string,
}

export default Status
