import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const getRecentFuelCloudTransactions = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/integrations/fuel_cloud/transactions`)
}

export const markFuelCloudTransactionProcessed = ({
  marinaSlug,
  transactionId,
}) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/integrations/fuel_cloud/transactions/${transactionId}/mark_processed`,
    method: "POST",
  })
}
