import PropTypes from "prop-types"
import React from "react"

import LineItem from "./LineItem"

const LineItems = ({ lineItems = [], loading = false, loadingItems = 4 }) => {
  const skeletons = lineItems.length === 0 ? loadingItems : lineItems.length

  return loading ? (
    <div className="my-2 flex animate-pulse flex-col gap-2">
      {Array.from({ length: skeletons }, (_, index) => (
        <div className="flex h-5 items-center justify-between" key={index}>
          <div className="h-4 w-20 rounded-full bg-gray-200 dark:bg-gray-700" />
          <div className="h-4 w-14 rounded-full bg-gray-200 dark:bg-gray-700" />
        </div>
      ))}
    </div>
  ) : (
    <div className="my-2 flex flex-col gap-2">
      {lineItems.map((lineItem, index) => (
        <LineItem
          key={`${lineItem.type}:${index}`}
          loading={loading}
          {...lineItem}
        />
      ))}
    </div>
  )
}

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string,
      amount: PropTypes.number,
      source: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  loadingItems: PropTypes.number,
}

export default LineItems
