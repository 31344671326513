import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useQuery } from "react-query"

import Form from "src/components/Form"

import { getContactBoatsByContactId } from "src/api/Waitlist/contacts"

import NewBoatSection from "./NewBoatSection"

const buildOptions = (data) => {
  return [
    ...data.map((boat) => {
      return (
        <Form.Select.RichOption key={boat.id} value={boat.id}>
          {boat.displayName}
        </Form.Select.RichOption>
      )
    }),
    <Form.Select.RichOption key="addNew" value="addNew">
      + Add New Boat
    </Form.Select.RichOption>,
  ]
}

const ContactSearchBoatSelector = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const selectedBoatId = watch("contact_boat_id")
  const selectedContactId = watch("contact_id")

  const { isFetching, data } = useQuery(
    ["contactSearch-boats", selectedContactId],
    () => getContactBoatsByContactId(selectedContactId),
    {
      enabled: Boolean(selectedContactId) && selectedContactId !== "addNew",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!selectedBoatId) {
          if (data.length) {
            setValue("contact_boat_id", data[0].id, { shouldValidate: true })
            setValue("contact_boat", data[0], { shouldValidate: true })
          } else {
            setValue("contact_boat_id", "addNew")
          }
        }
      },
    }
  )

  if (isFetching) {
    return (
      <div className="flex w-full justify-center">
        <span
          data-testid="loading-spinner"
          className="icon icon-spinner icon-spin"
        />
      </div>
    )
  }

  if (!data) {
    return <NewBoatSection />
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Form.Label htmlFor="waitlist-boatSelection" required>
          Boat
        </Form.Label>
        <Controller
          name="contact_boat_id"
          defaultValue=""
          rules={{ required: "Please select a boat." }}
          render={({ field: { onChange, value } }) => (
            <Form.Select.Custom
              id="waitlist-boatSelection"
              onChange={(id) => {
                if (id !== "addNew") {
                  setValue(
                    "contact_boat",
                    data.find((boat) => boat.id === id)
                  )
                }
                onChange(id)
              }}
              value={value}
              hasErrors={!!errors?.contact_boat_id}
            >
              {buildOptions(data)}
            </Form.Select.Custom>
          )}
        />
        {errors?.contact_boat_id ? (
          <Form.Error>{errors.contact_boat_id.message}</Form.Error>
        ) : null}
      </div>
      {selectedBoatId === "addNew" && <NewBoatSection />}
    </div>
  )
}

export default ContactSearchBoatSelector
