import { capitalize } from "lodash"
import PropTypes from "prop-types"
import React from "react"

import VerticalNav from "src/components/VerticalNav"

import useActiveHash from "src/hooks/use_active_hash"

import IntegrationCard from "./IntegrationCard"

const ids = ["accounting", "marketing", "platform", "new"]

const Integrations = ({ accounting, marketing, utilities }) => {
  const activeIntegrations = [
    ...accounting.filter((integration) => integration.active),
    ...marketing.filter((integration) => integration.active),
    ...utilities.filter((integration) => integration.active),
  ]

  const accountingIntegrations = accounting.filter(
    (integration) => !integration.active
  )
  const marketingIntegrations = marketing.filter(
    (integration) => !integration.active
  )
  const utilitiesIntegrations = utilities.filter(
    (integration) => !integration.active
  )

  const { isActive } = useActiveHash(
    activeIntegrations.length > 0 ? ["active", ...ids] : ids
  )

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-2 hidden p-4 xl:block">
        <VerticalNav>
          {activeIntegrations.length > 0 && (
            <VerticalNav.Link
              href="#active"
              active={isActive("active")}
              label="Active"
            />
          )}
          {ids.map((id) => (
            <VerticalNav.Link
              key={id}
              href={`#${id}`}
              active={isActive(id)}
              label={capitalize(id)}
            />
          ))}
        </VerticalNav>
      </div>
      <div className="col-span-12 space-y-13 p-4 xl:col-span-10">
        {activeIntegrations.length > 0 && (
          <div className="grid grid-cols-12 gap-6" id="active">
            <span className="col-span-12 text-xl font-bold">
              Active Integrations
            </span>
            {activeIntegrations.map((integration) => (
              <div
                key={integration.name}
                className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3"
              >
                <IntegrationCard service={integration} />
              </div>
            ))}
          </div>
        )}
        <div className="grid grid-cols-12 gap-6" id="accounting">
          <span className="col-span-12 text-xl font-bold">
            Accounting Integrations
          </span>
          {accountingIntegrations.map((integration) => {
            return (
              <div
                key={integration.name}
                className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3"
              >
                <IntegrationCard service={integration} />
              </div>
            )
          })}
        </div>
        <div className="grid grid-cols-12 gap-6" id="platform">
          <span className="col-span-12 text-xl font-bold">
            Platform Integrations
          </span>
          {utilitiesIntegrations.map((integration) => {
            return (
              <div
                key={integration.name}
                className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3"
              >
                <IntegrationCard service={integration} />
              </div>
            )
          })}
        </div>
        <div className="grid grid-cols-12 gap-6" id="marketing">
          <span className="col-span-12 text-xl font-bold">
            Marketing Integrations
          </span>
          {marketingIntegrations.map((integration) => {
            return (
              <div
                key={integration.name}
                className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3"
              >
                <IntegrationCard service={integration} />
              </div>
            )
          })}
        </div>
        <div className="w-full border-b" />
        <div className="flex flex-col space-y-4" id="new">
          <span className="font-semibold">
            Can&apos;t find what you&apos;re looking for? Let us know.
          </span>
          <div>
            <a
              className="btn btn-secondary"
              href="https://marinas.dockwa.com/request-an-integration"
            >
              Request New
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Integrations.propTypes = {
  accounting: PropTypes.arrayOf(PropTypes.object).isRequired,
  marketing: PropTypes.arrayOf(PropTypes.object).isRequired,
  utilities: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Integrations
