import PropTypes from "prop-types"
import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Expired from "src/main/PointOfSale/Expired"
import Layout from "src/main/PointOfSale/Layout"

import { marinaAccessProps } from "src/hooks/module_gate_hooks/use_pos_access"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PointOfSale from "."
import Checkout from "./Checkout"

const Router = (props) => {
  const marinaSlug = getCurrentMarinaSlug()
  const { enableBluetoothCardReader, enableTips, ...remainingProps } = props

  const router = createBrowserRouter(
    [
      {
        element: <Layout {...remainingProps} />,
        children: [
          {
            index: true,
            element: <PointOfSale />,
          },
          {
            path: "expired",
            element: <Expired />,
          },
          {
            path: "checkout",
            element: (
              <Checkout
                enableBluetoothCardReader={enableBluetoothCardReader}
                enableTips={enableTips}
              />
            ),
          },
        ],
      },
    ],
    {
      basename: `/manage/${marinaSlug}/point_of_sale`,
    }
  )

  return <RouterProvider router={router} />
}

Router.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  cardReaders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  canManagePOS: PropTypes.bool,
  marinaUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  enableBluetoothCardReader: PropTypes.bool.isRequired,
  tipItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  enableTips: PropTypes.bool.isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
  currentUserEncodedId: PropTypes.string.isRequired,
  serverCartFeatureEnabled: PropTypes.bool.isRequired,
}

export default Router
