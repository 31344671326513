import PropTypes from "prop-types"
import React from "react"
import Actions from "src/main/Chat/Header/Actions"
import Contact from "src/main/Chat/Header/Contact"
import Status from "src/main/Chat/Header/Status"

const Item = ({ type, ...passThroughProps }) => {
  const determineType = (type) => {
    switch (type) {
      case "status":
        return Status
      case "contact":
        return Contact
      case "action":
        return Actions
      default:
        return Contact
    }
  }

  const ItemComponent = determineType(type)

  return <ItemComponent {...passThroughProps} />
}

Item.propTypes = {
  type: PropTypes.oneOf(["status", "contact", "action"]).isRequired,
  label: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.string,
}

export default Item
