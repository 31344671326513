import { useMutation, useQueryClient } from "react-query"

import { updateInquiryStatus } from "src/api/Inquiries"

import { useToast } from "src/hooks/use_toast"

export const useUpdateInquiryStatus = ({ setShowDeclineModal }) => {
  const queryClient = useQueryClient()
  const showToast = useToast()

  const { mutate: updateStatus, isLoading: updatingInquiryStatus } =
    useMutation((data) => updateInquiryStatus({ data }), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("Lead")
        showToast(data.message, { type: "success" })
        setShowDeclineModal(false)
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    })

  return { updateStatus, updatingInquiryStatus }
}
