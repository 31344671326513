import React, { useContext } from "react"
import DockwaPlusMemberBadge from "src/main/PointOfSale/Checkout/PaymentModal/DockwaPlusMemberBadge"
import { POSContext } from "src/main/PointOfSale/Layout"

const CustomerInfo = () => {
  const { contact, boat, serverCartFeatureEnabled } = useContext(POSContext)

  if (!contact?.name && !boat?.id) return null

  function renderContact(contact) {
    if (!contact) return null

    if (contact.activeDockwaPlusSubscription && serverCartFeatureEnabled) {
      return (
        <div className="flex items-center justify-between">
          <div aria-label="Customer Name" className="flex items-center">
            <i className="icon icon-person mr-2 rounded-full bg-gray-200 p-2" />
            <span className="font-semibold text-gray-800">{contact.name}</span>
          </div>
          <DockwaPlusMemberBadge />
        </div>
      )
    } else {
      return (
        <div aria-label="Customer Name">
          <i className="icon icon-person mr-2 rounded-full bg-gray-200 p-2" />
          <span className="font-semibold text-gray-800">{contact.name}</span>
        </div>
      )
    }
  }

  return (
    <>
      <div
        aria-label="Customer Information"
        className="flex flex-col space-y-4"
      >
        {renderContact(contact)}
        {boat?.id ? (
          <div aria-label="Boat Name">
            <i className="icon icon-boat mr-2 rounded-full bg-gray-200 p-2" />
            <span className="font-semibold text-gray-800">{boat.name}</span>
          </div>
        ) : null}
      </div>
      <hr />
    </>
  )
}

export default CustomerInfo
