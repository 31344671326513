import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const NewBoatSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      {/* Name, Type, Make, Model */}
      <div className="grid grid-cols-2 gap-3 gap-x-6 md:grid-cols-4">
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-boatName" required>
            Boat Name
          </Form.Label>
          <Form.TextField
            id="contactSearch-newBoat-boatName"
            {...register("contact_boat.name", {
              required: "Boat name is required.",
            })}
            hasErrors={!!errors?.contact_boat?.name}
          />
          {errors?.contact_boat?.name && (
            <Form.Error>{errors.contact_boat.name.message}</Form.Error>
          )}
        </div>
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-boatType" required>
            Boat Type
          </Form.Label>
          <Controller
            name="contact_boat.boat_type"
            defaultValue=""
            rules={{ required: "Boat type is required." }}
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                id="contactSearch-newBoat-boatType"
                onChange={onChange}
                value={value}
                hasErrors={!!errors?.contact_boat?.boat_type}
              >
                <Form.Select.RichOption value="power">
                  Power Boat
                </Form.Select.RichOption>
                <Form.Select.RichOption value="sail">
                  Sailboat
                </Form.Select.RichOption>
              </Form.Select.Custom>
            )}
          />
          {errors?.contact_boat?.boat_type && (
            <Form.Error>{errors.contact_boat.boat_type.message}</Form.Error>
          )}
        </div>
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-boatMake">Make</Form.Label>
          <Form.TextField
            id="contactSearch-newBoat-boatMake"
            {...register("contact_boat.make")}
            hasErrors={!!errors?.contact_boat?.make}
          />
          {errors?.contact_boat?.make && (
            <Form.Error>{errors.contact_boat.make.message}</Form.Error>
          )}
        </div>
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-boatModel">
            Model
          </Form.Label>
          <Form.TextField
            id="contactSearch-newBoat-boatModel"
            {...register("contact_boat.model")}
            hasErrors={!!errors?.contact_boat?.model}
          />
          {errors?.contact_boat?.model && (
            <Form.Error>{errors.contact_boat.model.message}</Form.Error>
          )}
        </div>
      </div>

      {/* Year, Registration Number, Hailing Port */}
      <div className="grid grid-cols-3 gap-3 gap-x-6">
        <div className="col-span-3 md:col-span-1">
          <Form.Label htmlFor="contactSearch-newBoat-year">Year</Form.Label>
          <Form.TextField
            id="contactSearch-newBoat-year"
            {...register("contact_boat.year")}
            hasErrors={!!errors?.contact_boat?.year}
          />
          {errors?.contact_boat?.year && (
            <Form.Error>{errors.contact_boat.year.message}</Form.Error>
          )}
        </div>

        <div className="col-span-3 md:col-span-1">
          <Form.Label htmlFor="contactSearch-newBoat-registrationNumber">
            Registration Number
          </Form.Label>
          <Form.TextField
            id="contactSearch-newBoat-registrationNumber"
            {...register("contact_boat.registration_attributes.number")}
            hasErrors={!!errors?.contact_boat?.registration_attributes?.number}
          />
          {errors?.contact_boat?.registration_attributes?.number && (
            <Form.Error>
              {errors.contact_boat.registration_attributes.number.message}
            </Form.Error>
          )}
        </div>

        <div className="col-span-3 md:col-span-1">
          <Form.Label htmlFor="contactSearch-newBoat-hailingPort">
            Hailing Port
          </Form.Label>
          <Form.TextField
            id="contactSearch-newBoat-hailingPort"
            {...register("contact_boat.hailing_port")}
            hasErrors={!!errors?.contact_boat?.hailing_port}
          />
          {errors?.contact_boat?.hailing_port && (
            <Form.Error>{errors.contact_boat.hailing_port.message}</Form.Error>
          )}
        </div>
      </div>

      {/* Dimensions */}
      <div className="grid grid-cols-2 gap-3 gap-x-6 md:grid-cols-4">
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-length" required>
            Length Overall
          </Form.Label>
          <div className="flex w-full flex-row space-x-3">
            <div className="w-full">
              <Form.IconTextField
                position="right"
                icon="ft"
                id="contactSearch-newBoat-length"
                {...register("contact_boat.length_overall_feet", {
                  required: "Length (feet) is required.",
                })}
                type="number"
                hasErrors={!!errors?.contact_boat?.length_overall_feet}
              />
            </div>
            <div className="flex w-full items-end">
              <label
                htmlFor="contactSearch-newBoat-length-in"
                className={"sr-only"}
              >
                Length Overall (inches)
              </label>
              <Form.IconTextField
                position="right"
                icon="in"
                id="contactSearch-newBoat-length-in"
                {...register("contact_boat.length_overall_inches")}
                type="number"
                hasErrors={!!errors?.contact_boat?.length_overall_inches}
              />
            </div>
          </div>
          {errors?.contact_boat?.length_overall_feet && (
            <Form.Error>
              {errors.contact_boat.length_overall_feet.message}
            </Form.Error>
          )}
          {errors?.contact_boat?.length_overall_inches && (
            <Form.Error>
              {errors.contact_boat.length_overall_inches.message}
            </Form.Error>
          )}
        </div>
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-beam" required>
            Beam
          </Form.Label>
          <div className="flex w-full flex-row space-x-3">
            <div className="w-full">
              <Form.IconTextField
                position="right"
                icon="ft"
                id="contactSearch-newBoat-beam"
                {...register("contact_boat.beam_feet", {
                  required: "Beam is required.",
                })}
                hasErrors={!!errors?.contact_boat?.beam_feet}
                type="number"
              />
            </div>
            <div className="flex w-full items-end">
              <label
                htmlFor="contactSearch-newBoat-beam-in"
                className={"sr-only"}
              >
                Beam (inches)
              </label>
              <Form.IconTextField
                position="right"
                icon="in"
                id="contactSearch-newBoat-beam-in"
                {...register("contact_boat.beam_inches")}
                hasErrors={!!errors?.contact_boat?.beam_inches}
                type="number"
              />
            </div>
          </div>
          {errors?.contact_boat?.beam_feet && (
            <Form.Error>{errors.contact_boat.beam_feet.message}</Form.Error>
          )}
          {errors?.contact_boat?.beam_inches && (
            <Form.Error>{errors.contact_boat.beam_inches.message}</Form.Error>
          )}
        </div>
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-height">Height</Form.Label>
          <div className="flex w-full flex-row space-x-3">
            <div className="w-full">
              <Form.IconTextField
                position="right"
                icon="ft"
                id="contactSearch-newBoat-height"
                {...register("contact_boat.height_feet")}
                type="number"
                hasErrors={!!errors?.contact_boat?.height_feet}
              />
            </div>
            <div className="flex w-full items-end">
              <label
                htmlFor="contactSearch-newBoat-height-in"
                className={"sr-only"}
              >
                Height (inches)
              </label>
              <Form.IconTextField
                position="right"
                icon="in"
                id="contactSearch-newBoat-height-in"
                {...register("contact_boat.height_inches")}
                type="number"
                hasErrors={!!errors?.contact_boat?.height_inches}
              />
            </div>
          </div>
          {errors?.contact_boat?.height_feet && (
            <Form.Error>{errors.contact_boat.height_feet.message}</Form.Error>
          )}
          {errors?.contact_boat?.height_inches && (
            <Form.Error>{errors.contact_boat.height_inches.message}</Form.Error>
          )}
        </div>
        <div className="col-span-2">
          <Form.Label htmlFor="contactSearch-newBoat-draw">Draft</Form.Label>
          <div className="flex w-full flex-row space-x-3">
            <div className="w-full">
              <Form.IconTextField
                position="right"
                icon="ft"
                id="contactSearch-newBoat-draw"
                {...register("contact_boat.draw_feet")}
                type="number"
                hasErrors={!!errors?.contact_boat?.draw_feet}
              />
            </div>
            <div className="flex w-full items-end">
              <label
                htmlFor="contactSearch-newBoat-draw-in"
                className={"sr-only"}
              >
                Draft (inches)
              </label>
              <Form.IconTextField
                position="right"
                icon="in"
                id="contactSearch-newBoat-draw-in"
                {...register("contact_boat.draw_inches")}
                type="number"
                hasErrors={!!errors?.contact_boat?.draw_inches}
              />
            </div>
          </div>
          {errors?.contact_boat?.draw_feet && (
            <Form.Error>{errors.contact_boat.draw_feet.message}</Form.Error>
          )}
          {errors?.contact_boat?.draw_inches && (
            <Form.Error>{errors.contact_boat.draw_inches.message}</Form.Error>
          )}
        </div>
      </div>
    </>
  )
}

export default NewBoatSection
