import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryInvoices = ({
  marinaSlug,
  reservationId,
  page,
  pageSize = 25,
}) => {
  return queryApi(
    `/reservations/${reservationId}/invoices?marina_slug=${marinaSlug}&non_zero_amount_or_canceled_with_reversal=true&page=${page}&page_size=${pageSize}`
  )
}

export const queryInvoiceTxns = ({
  invoiceId,
  marinaSlug,
  page,
  pageSize = 25,
}) => {
  return queryApi(
    `/invoices/${invoiceId}/invoice_txns?marina_slug=${marinaSlug}&page=${page}&page_size=${pageSize}`
  )
}

export const queryRefundablePaymentMethods = ({
  reservationId,
  marinaSlug,
  isSettling = false,
}) => {
  return queryApi(
    `/reservations/${reservationId}/refundable_payment_methods?marina_slug=${marinaSlug}&is_settling=${isSettling}`
  )
}

export const querySaleRefundablePaymentMethods = ({ saleId, marinaSlug }) => {
  return queryApi(
    `/sales/${saleId}/refundable_payment_methods?marina_slug=${marinaSlug}`
  )
}

export const updateInvoice = ({ invoiceId, marinaSlug, data }) => {
  return mutateApi({
    url: `/invoices/${invoiceId}?marina_slug=${marinaSlug}`,
    method: "PATCH",
    data,
  })
}

export const createInvoiceTxn = ({ invoiceId, marinaSlug, data }) => {
  return mutateApi({
    url: `/invoices/${invoiceId}/txns?marina_slug=${marinaSlug}`,
    method: "POST",
    data,
  })
}
