import PropTypes from "prop-types"
import React, { Fragment } from "react"
import BillingDetails from "src/main/Account/Reservations/BillingDetails"
import MarinaInfo from "src/main/Account/Trips/Sales/Details/MarinaInfo"
import SaleInfo from "src/main/Account/Trips/Sales/Details/SaleInfo"
import HorizontalRule from "src/main/Chat/Sidebar/HorizontalRule"

import Button from "src/components/Button"

const AccountSaleDetails = ({ sale }) => {
  const { marina } = sale

  const handlePrint = (event) => {
    event.preventDefault()

    window.print()
  }

  const renderBillingDetails = () => (
    <Fragment>
      <div className="col-span-5 hidden px-4 md:col-span-1 lg:block lg:px-0">
        <span className="font-semibold">Billing Details</span>
      </div>
      <div className="col-span-5 flex flex-col space-y-2 px-4 lg:col-span-4 lg:px-0">
        <BillingDetails
          parentType="point_of_sale_sale"
          parentObjectId={sale.encodedId}
        />
      </div>
    </Fragment>
  )

  const renderHorizontalRule = () => (
    <div className="col-span-5 px-4 lg:px-0">
      <HorizontalRule />
    </div>
  )

  const renderActionButtons = () => (
    <div className="mb-6 flex items-center justify-between">
      <a
        href="/account/trips?active_tab=sales"
        className="btn btn-secondary flex w-min items-center space-x-2 whitespace-nowrap"
      >
        <i className="icon icon-sf-chevron-left text-xxs" />
        <span>All Sales</span>
      </a>
      <div className="hidden lg:block">
        <Button variant="secondary" onClick={handlePrint} icon="icon-print">
          Print
        </Button>
      </div>
    </div>
  )

  return (
    <div className="m-auto max-w-[1200px] px-4 pb-4 xl:px-0">
      {renderActionButtons()}
      <div className="rounded border lg:p-4" id="printable">
        <div className="grid grid-cols-5 gap-x-7 gap-y-2">
          <MarinaInfo marina={marina} />
          {renderHorizontalRule()}
          <SaleInfo sale={sale} />
          {renderHorizontalRule()}
          {renderBillingDetails()}
        </div>
      </div>
    </div>
  )
}

export const marinaPropType = {
  name: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  addressLineOne: PropTypes.string.isRequired,
  addressLineTwo: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  marinaProfileUrl: PropTypes.string.isRequired,
  marinaImageUrl: PropTypes.string,
  displayPhone: PropTypes.string,
  vhf: PropTypes.string,
}

export const salePropType = {
  contactBoatNames: PropTypes.arrayOf(PropTypes.string),
  encodedId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  settledStatus: PropTypes.string.isRequired,
  marina: PropTypes.shape(marinaPropType).isRequired,
}

AccountSaleDetails.propTypes = {
  sale: PropTypes.shape(salePropType).isRequired,
}

export default AccountSaleDetails
