import classNames from "classnames"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useState } from "react"
import DealCard from "src/main/MarinaProfile/DockwaPlusDeals/DealCard"
import ViewAllDealsModal from "src/main/MarinaProfile/DockwaPlusDeals/ViewAllDealsModal"

const DockwaPlusReservationDeals = ({ dockwaPlusReservationDeals }) => {
  const [isViewAllDealsModalOpen, setIsViewAllDealsModalOpen] = useState(false)
  if (dockwaPlusReservationDeals.length === 0) return null
  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-4">
        {dockwaPlusReservationDeals
          .slice(0, 2)
          .map((dockwaPlusReservationDeal) => (
            <div
              key={dockwaPlusReservationDeal.id}
              className={classNames({
                "col-span-12": dockwaPlusReservationDeals.length === 1,
                "col-span-12 md:col-span-6":
                  dockwaPlusReservationDeals.length > 1,
              })}
            >
              <DealCard dockwaPlusDeal={dockwaPlusReservationDeal} />
            </div>
          ))}
      </div>
      {dockwaPlusReservationDeals.length > 2 && (
        <span className="text-gray-600">
          {dockwaPlusReservationDeals.length} upcoming{" "}
          {pluralize("deal", dockwaPlusReservationDeals.length)}
          <span
            onClick={() => setIsViewAllDealsModalOpen(true)}
            className="ml-1 cursor-pointer font-semibold text-blue-600 hover:underline"
          >
            view all
          </span>
        </span>
      )}
      <ViewAllDealsModal
        isOpen={isViewAllDealsModalOpen}
        setIsOpen={setIsViewAllDealsModalOpen}
        dockwaPlusReservationDeals={dockwaPlusReservationDeals}
      />
    </div>
  )
}

DockwaPlusReservationDeals.propTypes = {
  dockwaPlusReservationDeals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired,
      expiresAt: PropTypes.string,
      validRangeReadable: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      readableDiscount: PropTypes.string.isRequired,
      expiresSoon: PropTypes.bool.isRequired,
    })
  ),
}

export default DockwaPlusReservationDeals
