import PropTypes from "prop-types"
import React from "react"

const DetailItem = ({ label, children }) => {
  return (
    <div className="grid grid-cols-9">
      <div className="col-span-3 md:col-span-2">
        <span className="font-semibold">{label}</span>
      </div>
      <div className="col-span-6 flex flex-col md:col-span-7">{children}</div>
    </div>
  )
}

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default DetailItem
