import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"

import Button from "src/components/Button"
import FormSelect from "src/components/Form/FormSelect"

import { queryOccupancyCalendar } from "src/api/Occupancy"

import { useTracker } from "src/hooks/use_tracker"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { capitalize } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { occupancyToColor } from "../utils"
import CalendarDayPanel from "./CalendarDayPanel"

const colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
]

const today = startOfToday()

const renderDockwaDealsIcon = () => {
  return (
    <div className="w-min rounded bg-blue-100 px-1 text-center align-middle text-blue-900">
      <i className="icon icon-tag-outline" />
    </div>
  )
}

const renderRecommendationIcon = () => {
  return (
    <div className="w-min rounded bg-supplementary-violet-200 px-1 text-center align-middle text-supplementary-violet-500">
      <i className="icon icon-stars" />
    </div>
  )
}

const CalendarView = ({ spaceTypes, ratesV2Enabled }) => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ]

  const [spaceType, setSpaceType] = useState(spaceTypes[0])
  const [occupancyToggle, setOccupancyToggle] = useState(true)
  const [occupancyYoYToggle, setOccupancyYoYToggle] = useState(true)
  const [ratesToggle, setRatesToggle] = useState(ratesV2Enabled)
  const [rateCardToggle, setRateCardToggle] = useState(ratesV2Enabled)
  const [dockwaDealsToggle, setDockwaDealsToggle] = useState(true)
  const [recommendationsToggle, setRecommendationsToggle] = useState(true)
  const [talfToggle, setTalfToggle] = useState("total")
  const [showPanel, setShowPanel] = useState(false)
  const [calendarDay, setCalendarDay] = useState(() =>
    format(today, "yyyy-MM-dd")
  )

  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()
  const [currMonth, setCurrMonth] = useState(() => format(today, "yyyy-MM"))
  const firstDayOfMonth = parse(currMonth, "yyyy-MM", new Date())

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  })

  const getPrevMonth = (event) => {
    event.preventDefault()
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 })
    setCurrMonth(format(firstDayOfPrevMonth, "yyyy-MM"))
  }

  const getNextMonth = (event) => {
    event.preventDefault()
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 })
    setCurrMonth(format(firstDayOfNextMonth, "yyyy-MM"))
  }

  const getPrevDay = (event) => {
    event.preventDefault()
    const prevDay = add(parse(calendarDay, "yyyy-MM-dd", new Date()), {
      days: -1,
    })
    if (!daysInMonth.some((day) => isSameDay(day, prevDay))) {
      getPrevMonth(event)
    }
    setCalendarDay(format(prevDay, "yyyy-MM-dd"))
    tracker.trackEvent("occupancy_calendar:day_details_prev_day_clicked", {
      date: format(prevDay, "yyyy-MM-dd"),
      space_type: spaceType,
    })
  }

  const getNextDay = (event) => {
    event.preventDefault()
    const nextDay = add(parse(calendarDay, "yyyy-MM-dd", new Date()), {
      days: 1,
    })
    if (!daysInMonth.some((day) => isSameDay(day, nextDay))) {
      getNextMonth(event)
    }
    setCalendarDay(format(nextDay, "yyyy-MM-dd"))
    tracker.trackEvent("occupancy_calendar:day_details_next_day_clicked", {
      date: format(nextDay, "yyyy-MM-dd"),
      space_type: spaceType,
    })
  }

  const { isLoading, isError, data } = useQuery(
    [
      "occupancyCalendar",
      marinaSlug,
      startOfWeek(firstDayOfMonth),
      endOfWeek(endOfMonth(firstDayOfMonth)),
      spaceType,
    ],
    () =>
      queryOccupancyCalendar(
        marinaSlug,
        startOfWeek(firstDayOfMonth),
        endOfWeek(endOfMonth(firstDayOfMonth)),
        spaceType
      )
  )

  const handleShowPanel = (day) => {
    setCalendarDay(format(day, "yyyy-MM-dd"))
    setShowPanel(true)
    tracker.trackEvent("occupancy_calendar:day_details_clicked", {
      date: format(day, "yyyy-MM-dd"),
      space_type: spaceType,
    })
  }

  const trackFilterToggle = (toggleName, toggleValue) => {
    tracker.trackEvent("occupancy_calendar:filter_toggled", {
      toggle_name: toggleName,
      toggle_value: toggleValue,
      space_type: spaceType,
    })
  }

  const renderCalendarDay = (day, idx) => {
    const dayData =
      data && data.calendar ? data.calendar[format(day, "yyyy-MM-dd")] : null

    return (
      <button
        key={idx}
        className={`flex aspect-square h-full w-full flex-col justify-between p-2 lg:aspect-[6/5] ${
          colStartClasses[getDay(day)]
        } ${
          isSameMonth(day, today)
            ? "bg-white hover:bg-gray-100"
            : "bg-gray-100 hover:bg-gray-200"
        }`}
        onClick={() => handleShowPanel(day, dayData)}
      >
        {!isLoading && !isError && data && dayData ? (
          <>
            <div className="flex w-full items-start justify-between">
              <div className="overflow-hidden text-left lg:overflow-auto">
                {rateCardToggle && (
                  <p
                    className={`mb-0.5 text-nowrap font-bold lg:text-wrap ${
                      dayData.rateStrategyName
                        ? ""
                        : isBefore(day, today)
                        ? "text-gray-600"
                        : "text-red-700"
                    }`}
                  >
                    {dayData.rateStrategyName || "No rate"}
                  </p>
                )}
                {ratesToggle && (
                  <p className="mb-0 text-gray-500">
                    {dayData.rateStrategyName
                      ? `
                  ${
                    dayData.minRate === dayData.maxRate
                      ? `${formattedCentsToDollars(dayData.minRate)}`
                      : `${formattedCentsToDollars(
                          dayData.minRate
                        )} - ${formattedCentsToDollars(dayData.maxRate)}${
                          dayData.rateType === "by_length" ? "/ft" : ""
                        }`
                  }`
                      : "-"}
                  </p>
                )}
              </div>
              <span
                className={`ml-4 text-gray-500 ${
                  isToday(day) ? "font-bold" : ""
                }`}
              >
                {format(day, "d")}
              </span>
            </div>
            <div className="grid w-full grid-cols-7 gap-1">
              {dockwaDealsToggle &&
                dayData.promoCodeCount > 0 &&
                renderDockwaDealsIcon()}
              {recommendationsToggle &&
                dayData.recommendedDealCount > 0 &&
                renderRecommendationIcon()}
            </div>
            <div className="w-full">
              {occupancyToggle && (
                <div
                  className={`mb-1 w-full rounded ${occupancyToColor(
                    dayData[`${talfToggle}OccupancyPercentage`]
                  )} py-2 text-center align-middle text-xl font-semibold`}
                >
                  {Math.round(
                    dayData[`${talfToggle}OccupancyPercentage`] * 100
                  )}
                  %
                </div>
              )}
              {occupancyYoYToggle && (
                <div
                  className={`w-full rounded text-center align-middle text-xs font-semibold ${
                    Math.round(
                      dayData[
                        `yoy${capitalize(talfToggle)}OccupancyPercentage`
                      ] * 100
                    ) > 0
                      ? "bg-teal-50 text-teal-600"
                      : Math.round(
                          dayData[
                            `yoy${capitalize(talfToggle)}OccupancyPercentage`
                          ] * 100
                        ) === 0
                      ? "bg-gray-200 text-gray-600"
                      : "bg-red-50 text-red-600"
                  }`}
                >
                  {Math.round(
                    dayData[`yoy${capitalize(talfToggle)}OccupancyPercentage`] *
                      100
                  ) !== 0 && (
                    <i
                      className={`icon ${
                        Math.round(
                          dayData[
                            `yoy${capitalize(talfToggle)}OccupancyPercentage`
                          ] * 100
                        ) > 0
                          ? "icon-arrow-outward"
                          : "icon-arrow-inward"
                      }`}
                    />
                  )}
                  {Math.round(
                    Math.abs(
                      dayData[`yoy${capitalize(talfToggle)}OccupancyPercentage`]
                    ) * 100
                  )}
                  % YoY
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="items-left justify-left flex w-full flex-col">
            <div className="flex items-start justify-between">
              <div className="my-1 h-4 w-full animate-pulse rounded bg-gray-300"></div>
              <span
                className={`ml-4 text-gray-500 ${
                  isToday(day) ? "font-bold" : ""
                }`}
              >
                {format(day, "d")}
              </span>
            </div>
            <div className="mt-1 h-4 w-1/2 animate-pulse rounded bg-gray-300"></div>
            <div className="mt-1 h-4 w-1/4 animate-pulse rounded bg-gray-300"></div>
            <div className="mt-2 h-12 w-full animate-pulse rounded bg-gray-300"></div>
            <div className="mt-1 h-4 w-full animate-pulse rounded bg-gray-300"></div>
          </div>
        )}
      </button>
    )
  }

  return (
    <div className="flex min-h-screen w-full flex-col md:flex-row">
      <div className="w-full min-w-[250px] p-4 md:border-r md:border-gray-200 lg:w-[5vw]">
        <p className="font-bold">Type</p>
        <FormSelect
          value={spaceType}
          onChange={(event) => setSpaceType(event.target.value)}
        >
          {spaceTypes.includes("dock") && <option value="dock">Docks</option>}
          {spaceTypes.includes("mooring") && (
            <option value="mooring">Moorings</option>
          )}
        </FormSelect>
        <p className="mt-4 font-bold">Scope</p>
        <FormSelect
          value={talfToggle}
          onChange={(event) => setTalfToggle(event.target.value)}
        >
          <option value="total">Total</option>
          <option value="transient">Transient</option>
          <option value="longTerm">Contracts</option>
        </FormSelect>
        <p className="mt-12 font-bold">Display</p>
        <div className="mb-3 flex w-full flex-row">
          <input
            className="m-0 mr-2"
            type="checkbox"
            onChange={() => {
              setOccupancyToggle(!occupancyToggle)
              trackFilterToggle("occupancy", !occupancyToggle)
            }}
            name="occupancy"
            id="occupancy"
            checked={occupancyToggle}
          />
          <label htmlFor="occupancy" className="mb-0 font-normal">
            Occupancy
          </label>
        </div>
        <div className="mb-3 flex w-full flex-row">
          <input
            className="m-0 mr-2"
            type="checkbox"
            onChange={() => {
              setOccupancyYoYToggle(!occupancyYoYToggle)
              trackFilterToggle("occupancy_yoy", !occupancyYoYToggle)
            }}
            name="occupancy-yoy"
            id="occupancy-yoy"
            checked={occupancyYoYToggle}
          />
          <label htmlFor="occupancy-yoy" className="mb-0 font-normal">
            Occupancy YoY
          </label>
        </div>
        {ratesV2Enabled && (
          <>
            <div className="mb-3 flex w-full flex-row">
              <input
                className="m-0 mr-2"
                type="checkbox"
                onChange={() => {
                  setRatesToggle(!ratesToggle)
                  trackFilterToggle("rates", !ratesToggle)
                }}
                name="rates"
                id="rates"
                checked={ratesToggle}
              />
              <label htmlFor="rates" className="mb-0 font-normal">
                Rates
              </label>
            </div>
            <div className="mb-3 flex w-full flex-row">
              <input
                className="m-0 mr-2"
                type="checkbox"
                onChange={() => {
                  setRateCardToggle(!rateCardToggle)
                  trackFilterToggle("rate_strategy_name", !rateCardToggle)
                }}
                name="rate-card"
                id="rate-card"
                checked={rateCardToggle}
              />
              <label htmlFor="rate-card" className="mb-0 font-normal">
                Rate Card Names
              </label>
            </div>
          </>
        )}
        <div className="mb-3 flex w-full flex-row">
          <input
            className="m-0 mr-2"
            type="checkbox"
            onChange={() => {
              setDockwaDealsToggle(!dockwaDealsToggle)
              trackFilterToggle("dockwa_deals", !dockwaDealsToggle)
            }}
            name="dockwa-deals"
            id="dockwa-deals"
            checked={dockwaDealsToggle}
          />
          <label htmlFor="dockwa-deals" className="mb-0 mr-2 font-normal">
            Dockwa Deals
          </label>
          {renderDockwaDealsIcon()}
        </div>
        <div className="mb-3 flex w-full flex-row">
          <input
            className="m-0 mr-2"
            type="checkbox"
            onChange={() => {
              setRecommendationsToggle(!recommendationsToggle)
              trackFilterToggle("recommendations", !recommendationsToggle)
            }}
            name="recommendations"
            id="recommendations"
            checked={recommendationsToggle}
          />
          <label htmlFor="recommendations" className="mb-0 mr-2 font-normal">
            Recommendations
          </label>
          {renderRecommendationIcon()}
        </div>
      </div>
      <div className="w-full overflow-x-auto px-6 pb-5 pt-4 lg:overflow-x-visible lg:pb-0">
        <div className="mb-4 flex w-[5vw] min-w-[250px] justify-between">
          <span className="mr-4 text-xl font-bold">
            {format(firstDayOfMonth, "MMMM yyyy")}
          </span>
          <div className="flex items-center justify-evenly gap-2">
            <Button
              icon="icon-md-arrow-back -mt-0.5"
              onClick={getPrevMonth}
              small
              iconOnly
            />
            <Button
              icon="icon-md-arrow-forward -mt-0.5"
              onClick={getNextMonth}
              small
              iconOnly
            />
          </div>
        </div>
        <div className="grid w-full min-w-[1100px] grid-cols-7 place-items-center gap-[1px] border border-b-0 bg-gray-300 text-lg text-gray-600 lg:min-w-[900px]">
          {days.map((day, idx) => {
            return (
              <div
                key={idx}
                className="flex h-10 w-full items-center justify-center bg-gray-100 font-semibold"
              >
                {capitalize(day)}
              </div>
            )
          })}
        </div>
        <div className="grid min-w-[1100px] grid-cols-7 place-items-center gap-[1px] border bg-gray-300 lg:min-w-[900px]">
          {daysInMonth.map((day, idx) => {
            return renderCalendarDay(day, idx)
          })}
        </div>
      </div>
      <CalendarDayPanel
        isOpen={showPanel}
        onClose={() => setShowPanel(false)}
        data={{ day: calendarDay, dayData: data?.calendar[calendarDay] }}
        spaceType={spaceType}
        talfToggle={talfToggle}
        getPrevDay={getPrevDay}
        getNextDay={getNextDay}
        ratesV2Enabled={ratesV2Enabled}
      />
    </div>
  )
}

CalendarView.propTypes = {
  spaceTypes: PropTypes.array.isRequired,
  ratesV2Enabled: PropTypes.bool.isRequired,
}

export default CalendarView
