import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Heading from "src/components/Heading"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import ActiveItemLimitBanner from "../../ManageItems/ActiveItemLimitBanner"
import FuelTransactionPickListModal from "../FuelTransaction"
import ItemSearch from "../ItemSearch"
import { SEARCH_ITEM_ADDED_TO_CART } from "../amplitude_events"
import { QuickKeyPanelContext } from "../index"
import CashDrawerToggle from "./CashDrawerToggle"

const QuickKeyPanelHeader = ({ hideManageLayouts = false }) => {
  const { layouts, canManagePOS, marinaAccess } = useContext(POSContext)
  const {
    selectedLayout,
    setShowNewLayoutModal,
    setShowSwitchLayoutModal,
    handleItemSelect,
  } = useContext(QuickKeyPanelContext)
  const tracker = useTracker()
  const marinaSlug = getCurrentMarinaSlug()

  const [isFuelModalOpen, setIsFuelModalOpen] = useState(false)

  const handleFuelButtonClick = () => {
    setIsFuelModalOpen(true)
  }

  const renderSwitchLayouts = () => {
    if (layouts?.length > 1) {
      return (
        <div
          role="button"
          className="cursor-pointer pl-2 font-bold text-blue-700 hover:text-blue-800"
          onClick={() => setShowSwitchLayoutModal(true)}
        >
          (switch)
        </div>
      )
    } else {
      return null
    }
  }

  const onManageLayoutSelection = (value) => {
    if (value === "new") {
      setShowNewLayoutModal(true)
    } else if (value === "edit") {
      window.location = `/manage/${marinaSlug}/point_of_sale/layouts/${selectedLayout.id}/edit`
    }
  }

  const manageDropdownLabel = (
    <div className="text-gray-900">
      <i className="icon icon-cog mx-1 text-gray-600" /> Manage
    </div>
  )

  const onItemSelected = (item) => {
    tracker.trackEvent(SEARCH_ITEM_ADDED_TO_CART)
    return handleItemSelect(item)
  }
  const renderManageLayouts = () => {
    if (hideManageLayouts) return null

    return (
      <div className="ml-4 w-32">
        {/*
            Using MaybeRestrictedTooltip + Form.Select.Custom results in
            some strange outcomes, so we are rendering it separately here
            instead of rendering Form.Select.Custom as a child of MaybeRestrictedTooltip.
             */}
        {!canManagePOS && (
          <MaybeRestrictedTooltip
            restricted
            placement="bottom"
            allowedRoles={["Admin", "Finance"]}
          >
            <Button variant="tertiary" icon="icon-cog" disabled>
              Manage
              <i className="icon icon-angle-down w-6 text-xs" />
            </Button>
          </MaybeRestrictedTooltip>
        )}
        {canManagePOS && (
          <Form.Select.Custom
            onChange={onManageLayoutSelection}
            label={manageDropdownLabel}
          >
            <Form.Select.RichOption
              value="edit"
              disabled={layouts?.length === 0}
            >
              Edit Layout
            </Form.Select.RichOption>
            <Form.Select.RichOption
              value="new"
              disabled={marinaAccess.layoutLimitMet}
            >
              New Layout
            </Form.Select.RichOption>
          </Form.Select.Custom>
        )}
      </div>
    )
  }

  return (
    <>
      <ActiveItemLimitBanner />
      <div className="flex w-full items-center justify-start pb-4">
        <>
          <Heading.ModalTitle>
            {selectedLayout?.name ?? "Point of Sale"}
          </Heading.ModalTitle>
          {renderSwitchLayouts()}
        </>
        <div className="ml-auto flex flex-row">
          <CashDrawerToggle />
          {renderManageLayouts()}
        </div>
      </div>
      <div>
        <div className="flex flex-row items-end space-x-4">
          <div className="flex-grow">
            <ItemSearch
              handleItemSelect={onItemSelected}
              disabled={marinaAccess.activeItemLimitExceeded}
            />
          </div>
          {marinaAccess.accessFuelIntegrations && (
            <div>
              <button
                className="btn btn-secondary"
                onClick={handleFuelButtonClick}
              >
                Sync Fuel from FuelCloud
              </button>
            </div>
          )}
        </div>
        {marinaAccess.barcodeScanner && (
          <div className="mt-2 text-sm text-gray-600">
            Scan a barcode with a barcode scanner to quickly add an item to the
            cart.
          </div>
        )}
        <hr className="mt-6" />
      </div>
      {isFuelModalOpen && (
        <FuelTransactionPickListModal
          isOpen={isFuelModalOpen}
          setIsOpen={() => setIsFuelModalOpen(true)}
          onClose={() => setIsFuelModalOpen(false)}
        />
      )}
    </>
  )
}

QuickKeyPanelHeader.propTypes = {
  hideManageLayouts: PropTypes.bool,
}
export default QuickKeyPanelHeader
