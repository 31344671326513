import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"
import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"

import { searchContacts } from "src/api/Waitlist/contacts"

import useDebounce from "src/hooks/use_debounce"
import { useDisplayContactOptions } from "src/hooks/use_display_contact_options"

import ContactSearchBoatSelector from "./ContactSearchBoatSelector"
import ContactSection from "./ContactSection"
import { DOCKWA_NETWORK, SAFEHARBOR_NETWORK } from "./constants"

const getOption = ({ name, email, phone }) => (
  <div className="grid grid-cols-6">
    <span className="col-span-2 truncate">{name}</span>
    <span className="col-span-3 truncate">{email}</span>
    <span className="truncate text-right">{phone}</span>
  </div>
)

const renderImportOption = ({ name, email, phone }) => (
  <div className="grid grid-cols-8 items-center" key={email}>
    <span className="col-span-2 truncate">{name}</span>
    <span className="col-span-2 truncate">{email}</span>
    <span className="col-span-1 truncate md:col-span-2">{phone}</span>
    <span className="col-span-3 justify-items-end md:col-span-2">
      <Button small variant="danger">
        Import Contact
      </Button>
    </span>
  </div>
)

const renderImportHeader = ({ network }) => (
  <div className="flex flex-col space-y-2">
    <div className="rounded bg-teal-100 p-2 text-center">
      Your search matched the following boater(s) from {network}&apos;s network:
    </div>
    <div className="grid grid-cols-8">
      <span className="col-span-2">Name</span>
      <span className="col-span-2">Email</span>
      <span className="col-span-1 md:col-span-2">Phone</span>
      <span className="col-span-3 md:col-span-2" />
    </div>
  </div>
)

const emptyContactState = {
  name: "",
  email: "",
  phone: "",
}

const emptyContactBoatState = {
  name: "",
  boat_type: "",
  make: "",
  model: "",
  year: "",
  registration_attributes: {
    number: "",
  },
  hailing_port: "",
  length_overall_feet: "",
  length_overall_inches: "",
  height_feet: "",
  height_inches: "",
  draw_feet: "",
  draw_inches: "",
  beam_feet: "",
  beam_inches: "",
}

const ContactSearch = ({
  clearable = true,
  showCreateNewOutsideForm = false,
  enableContactImport = false,
}) => {
  const {
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext()
  const [contactId, contact, contactBoatId] = watch([
    "contact_id",
    "contact",
    "contact_boat_id",
  ])

  const [createNewFlow, setCreateNewFlow] = useState(
    Boolean(
      (!contactId || contactId === "addNew") &&
        (contact?.name || contact?.email || contact?.phone)
    )
  )
  const [searchQuery, setSearchQuery] = useState("")

  const { isFetching, data } = useQuery(
    ["contactSearch", searchQuery],
    () => searchContacts(searchQuery.trim()),
    {
      enabled: Boolean(searchQuery) && searchQuery.trim().length >= 3,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const {
    getOptions,
    getRenderOption,
    showNoResultsText,
    hasContacts,
    hasArchivedContacts,
    hasNetworkContacts,
    hasDockwaUserMatch,
  } = useDisplayContactOptions({
    data,
    query: searchQuery,
    renderOption: getOption,
    renderImportOption,
    enableContactImport,
  })

  const clearSelections = useCallback(() => {
    setValue("contact", emptyContactState)
    setValue("contact_boat", emptyContactBoatState)
  }, [setValue])

  useEffect(() => {
    if (contactId !== "addNew" && createNewFlow) {
      // when we toggle back to not adding a new contact,
      // must set create new flow to false and clear out
      // the contact boat id since the contact has changed
      setCreateNewFlow(false)
      setValue("contact_boat_id", "")
    } else if (contactId === "addNew" && !createNewFlow) {
      // otherwise if we toggled to the create new flow we should
      // clear out any current selections
      setCreateNewFlow(true)
      clearSelections()
    }
  }, [contactId, clearSelections, setValue, createNewFlow])

  useEffect(() => {
    if (contactBoatId === "addNew") {
      setValue("contact_boat", emptyContactBoatState)
    }
  }, [contactBoatId, setValue])

  const [debouncedSearch] = useDebounce(setSearchQuery)

  const onSearchInputChanged = (val) => {
    debouncedSearch(val)
  }

  const toggleCreateNew = () => {
    clearErrors(["contact_id", "contact_boat_id", "contact", "contact_boat"])
    clearSelections()
    setValue("contact_id", contactId === "addNew" ? null : "addNew")
    setValue("contact_boat_id", contactBoatId === "addNew" ? null : "addNew")
  }

  const hasSelectedContact = Boolean(contactId)

  const renderOptionsHeader = () => {
    if (hasArchivedContacts) {
      return null
    }

    if (hasContacts) {
      return getOption({
        name: "Name",
        email: "Email",
        phone: "Phone",
      })
    } else if (hasNetworkContacts) {
      return renderImportHeader({ network: SAFEHARBOR_NETWORK })
    } else if (hasDockwaUserMatch) {
      return renderImportHeader({ network: DOCKWA_NETWORK })
    }

    return null
  }

  const handleSelectContact = ({ selectedContact, onChange }) => {
    if (hasContacts) {
      onChange(selectedContact?.id || "")
      setValue("contact", selectedContact ?? {})
      setValue("contact_boat_id", "")
    } else if (hasNetworkContacts) {
      onSearchInputChanged("")
      return window.open(
        `${data.brewerImportPath}/${selectedContact.encodedId}`,
        "_blank",
        "noreferrer"
      )
    } else if (hasDockwaUserMatch) {
      onSearchInputChanged("")
      return window.open(
        `${data.userImportPath}/${selectedContact.id}`,
        "_blank",
        "noreferrer"
      )
    }
  }

  return (
    <div className="flex flex-col gap-3">
      {!createNewFlow && (
        <div className="w-full">
          <Form.Label htmlFor="lookupContact">Lookup Contact</Form.Label>
          <Controller
            name="contact_id"
            rules={{ required: true }}
            defaultValue=""
            render={({ field: { onChange } }) => (
              <AutocompleteField
                id="lookupContact"
                clearable={clearable}
                onClearCallback={() => {
                  setValue("contact_id", null)
                  setValue("contact", null)
                  setValue("contact_boat_id", null)
                  setValue("contact_boat", null)
                }}
                onSelect={(selectedContact) =>
                  handleSelectContact({ selectedContact, onChange })
                }
                selectedItem={contact}
                selectedItemInputValue={
                  contact?.name || contact?.email || contact?.phone
                }
                leadingIcon={<i className="icon icon-search" />}
                placeholder="Search by Name, Email, Phone Number"
                onInputChange={onSearchInputChanged}
                options={getOptions}
                isLoading={isFetching}
                renderOptionsHeader={renderOptionsHeader}
                renderOption={({ option }) => getRenderOption(option)}
              />
            )}
          />
          {errors.contact_id?.type === "required" && (
            <Form.Error>Please select a contact</Form.Error>
          )}
          {showNoResultsText && data?.activeContacts?.length === 0 && (
            <div className="my-3 flex items-center justify-between gap-2 p-3 shadow-lg">
              <span>
                No Match Found. Please check your spelling or create a new
                contact.
              </span>
              {!showCreateNewOutsideForm && (
                <Button
                  small
                  variant="primary"
                  type="button"
                  onClick={toggleCreateNew}
                >
                  Create new
                </Button>
              )}
            </div>
          )}
          {showCreateNewOutsideForm && !contactId && (
            <div className="my-2">
              <Button
                small
                variant="ghost"
                type="button"
                onClick={toggleCreateNew}
              >
                Create new contact
              </Button>
            </div>
          )}
        </div>
      )}

      {createNewFlow && (
        <div className="my-2">
          <Button
            small
            variant="secondary"
            type="button"
            icon="icon-arrow-left text-xs"
            onClick={toggleCreateNew}
          >
            Return to contact lookup
          </Button>
        </div>
      )}

      {(hasSelectedContact || createNewFlow) && (
        <>
          <ContactSection isCreateNew={createNewFlow} />
          <Divider />
          <ContactSearchBoatSelector />
        </>
      )}
    </div>
  )
}

ContactSearch.propTypes = {
  clearable: PropTypes.bool,
  showCreateNewOutsideForm: PropTypes.bool,
  enableContactImport: PropTypes.bool,
}

export default ContactSearch
