import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Badge from "src/components/Badge"
import Button from "src/components/Button"
import Tooltip from "src/components/Tooltip"

import { createMarinaUser } from "src/api/Onboarding/Claim"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const DockmastersStep = ({ onNext, dockmasters, dockmasterRoles }) => {
  const [isAddingNew, setIsAddingNew] = useState(false)
  const [newEmail, setNewEmail] = useState("")
  const [newRole, setNewRole] = useState("Admin")
  const [error, setError] = useState(null)
  const [dockmastersList, setDockmastersList] = useState(dockmasters)
  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()
  const roleDescriptions = {
    Admin: {
      title: "Admin — Most Permissions",
      description:
        "Access to all settings, including adding users, changing permissions and configuring bank information.",
      notifications: "Dockmaster & Financial",
      color: "yellow",
    },
    Dockmaster: {
      title: "Dockmaster",
      description:
        "Can view, edit, accept, and decline reservations. No access to administrative settings.",
      notifications: "Dockmaster",
      color: "blue",
    },
    Finance: {
      title: "Finance",
      description:
        "Similar to Viewer, but with access to financial tools and bank information.",
      notifications: "Financial",
      color: "teal",
    },
    Viewer: {
      title: "Viewer — Least Permissions",
      description:
        "Can view data but can't make changes or export information.",
      notifications: "None",
      color: "gray",
    },
  }

  const handleAddNew = () => {
    if (isAddingNew) {
      // Add the new dockmaster if email is provided
      if (newEmail.trim()) {
        tracker.trackEvent("onboarding:claim:create_dockmaster_pressed", {
          email: newEmail.trim(),
          role: newRole,
        })
        addMarinaUser({
          marina_user: { email: newEmail.trim(), role: newRole },
        })
      } else {
        tracker.trackEvent("onboarding:claim:new_dockmaster_canceled")
        setIsAddingNew(false)
        setNewEmail("")
        setNewRole("Admin")
        setError(null)
      }
    } else {
      tracker.trackEvent("onboarding:claim:new_dockmaster_pressed")
      setIsAddingNew(true)
    }
  }

  const { mutate: addMarinaUser, isLoading } = useMutation(
    (data) => createMarinaUser(marinaSlug, data),
    {
      onSuccess: (data) => {
        setIsAddingNew(false)
        setDockmastersList([
          ...dockmastersList,
          { email: data.email, role: data.role },
        ])
        // Reset form
        setNewEmail("")
        setNewRole("Admin")
        setError(null)
      },
      onError: (error) => {
        setError(error.message)
      },
    }
  )

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="mb-4 flex w-full flex-col items-center justify-center px-2 text-center lg:w-1/3">
        <h2 className="text-2xl font-bold">
          Would you like to add any additional users?
        </h2>
        <p className="text-sm text-gray-500">
          Keep your marina running smoothly by adding key team members like your
          dockmaster, administrator, or accountant. Assigning the right people
          ensures seamless communication, efficient operations, and a better
          experience for your boaters.
        </p>
      </div>
      <div className="w-full flex-col items-center justify-center px-2 lg:w-1/2">
        <div className="mb-2">
          <div className="flex w-full">
            <div className="w-1/2 px-6 py-2 text-left text-sm font-bold">
              Email
            </div>
            <div className="w-1/2 px-6 py-2 text-left text-sm font-bold">
              <div className="flex items-center">
                Role
                <Tooltip
                  text={
                    <div className="space-y-1">
                      {Object.entries(roleDescriptions).map(
                        ([role, details], index) => (
                          <>
                            {index !== 0 && <hr className="pb-2" />}
                            <div
                              key={role}
                              className="border-b border-gray-100 pb-1 last:border-0"
                            >
                              <p className="text-xs font-bold text-gray-600">
                                {details.title}
                              </p>
                              <p className="mt-1 text-xs font-normal leading-relaxed text-gray-600">
                                {details.description}
                              </p>
                              <p className="mt-1 text-xs text-gray-500">
                                <span className="font-semibold">
                                  Email Notifications:
                                </span>{" "}
                                {details.notifications}
                              </p>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  }
                  placement="bottom"
                  variant="light"
                  maxWidth="384px"
                >
                  <i className="icon icon-info ml-1 cursor-pointer text-xs text-gray-400" />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-1">
          {dockmastersList.map((dockmaster) => (
            <div
              key={dockmaster.email}
              className="rounded-md hover:bg-gray-50 flex w-full"
            >
              <div className="w-1/2 px-6 py-2 text-sm text-gray-900">
                {dockmaster.email}
              </div>
              <div className="w-1/2 px-6 py-2 text-sm text-gray-900">
                <Badge
                  color={roleDescriptions[dockmaster.role]?.color || "gray"}
                  text={dockmaster.role}
                />
              </div>
            </div>
          ))}
          {isAddingNew && (
            <div className="rounded-md bg-gray-50 flex w-full p-2">
              <div className="w-1/2 px-4">
                <input
                  type="email"
                  className="form-control w-full"
                  placeholder="Email address"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="w-1/2 px-4">
                <select
                  className="form-control w-1/2"
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value)}
                >
                  {dockmasterRoles.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {error && <div className="ml-6 pb-2 text-red-500">{error}</div>}
          <div className={isAddingNew ? "ml-6" : ""}>
            <Button
              variant={isAddingNew ? "secondary" : "ghost"}
              onClick={handleAddNew}
              isLoading={isLoading}
            >
              {isAddingNew ? "Add" : "+ Add User"}
            </Button>
          </div>
        </div>

        <div className="mt-8 flex justify-center">
          <Button
            variant="primary"
            onClick={() => {
              tracker.trackEvent(
                "onboarding:claim:dockmasters_continue_pressed"
              )
              onNext()
            }}
            disabled={isAddingNew}
            className="mt-6"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

DockmastersStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  dockmasters: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ),
  dockmasterRoles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default DockmastersStep
