import classNames from "classnames"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Heading from "src/components/Heading"

import { WizardContext } from "../WizardContextProvider"
import {
  AUTOPAY_DISABLED,
  AUTOPAY_ENABLED,
  DEFAULT_RATE_ID,
} from "../constants"
import {
  AddDiscountsButton,
  ArrivalInput,
  AutopaySelector,
  Availability,
  BillingScheduleSelector,
  DepartureInput,
  ElectricSelector,
  EmailConfirmationCheckbox,
  PaymentMethodSelector,
  PriceEstimate,
  PricingStructureSelector,
  RateAmountInput,
  RateSelector,
  RateTaxInput,
  SpecialRequestInput,
  StepContent,
  StepWrapper,
  StorageProductSelector,
} from "./shared"
import CouponCodeSelector from "./shared/CouponCodeSelector"

const SummaryStep = () => {
  const { watch } = useFormContext()
  const {
    discountsError,
    loadingCreateReservation,
    errorCreatingReservation,
    getPriceEstimateError,
  } = useContext(WizardContext)
  const [paymentType, rateId] = watch(["payment_type", "rate.id"])

  return (
    <StepWrapper testId="trw-summary-step">
      <div className="flex w-full flex-col space-y-4 lg:flex-3">
        <StepContent>
          <Heading.PageTitle>Itinerary</Heading.PageTitle>
          <div className="mt-5">
            <div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <ArrivalInput />
              <DepartureInput />
              <StorageProductSelector />
              <ElectricSelector />
              <div className="lg:col-span-2">
                <SpecialRequestInput />
              </div>
            </div>
          </div>
          <Divider />
          <Heading.PageTitle>Payment</Heading.PageTitle>
          <div className="mt-5">
            <div className="grid grid-flow-row grid-cols-1 items-baseline gap-4 md:grid-cols-2 lg:grid-cols-6">
              <div
                className={classNames({
                  "lg:col-span-3": paymentType === AUTOPAY_DISABLED,
                  "lg:col-span-2": paymentType === AUTOPAY_ENABLED,
                })}
              >
                <BillingScheduleSelector />
              </div>
              <div
                className={classNames({
                  "lg:col-span-3": paymentType === AUTOPAY_DISABLED,
                  "lg:col-span-1": paymentType === AUTOPAY_ENABLED,
                })}
              >
                <AutopaySelector />
              </div>

              <div
                className={classNames({
                  "lg:col-span-3": paymentType === AUTOPAY_ENABLED,
                  "lg:col-span-1": paymentType === AUTOPAY_DISABLED,
                })}
              >
                {paymentType === AUTOPAY_ENABLED ? (
                  <PaymentMethodSelector />
                ) : null}
              </div>
            </div>
          </div>
          <Divider />
          <Heading.PageTitle>Discounts and Coupon Codes</Heading.PageTitle>
          <div className="mt-5">
            <div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-2">
              <AddDiscountsButton />
              <CouponCodeSelector />
              <div className="lg:col-span-2">
                {discountsError ? (
                  <Form.Error>{discountsError}</Form.Error>
                ) : null}
              </div>
            </div>
          </div>
          <Divider />
          <Heading.PageTitle>Rates</Heading.PageTitle>
          <div className="mt-5">
            <div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-3">
              <div className="md:col-span-3">
                <RateSelector />
              </div>
              {rateId !== DEFAULT_RATE_ID ? (
                <>
                  <PricingStructureSelector />
                  <RateAmountInput />
                  <RateTaxInput />
                </>
              ) : null}
            </div>
          </div>
        </StepContent>

        <StepContent>
          <Heading.PageTitle>Availability</Heading.PageTitle>
          <Availability />
        </StepContent>
      </div>
      <PriceEstimate>
        <EmailConfirmationCheckbox />
        <Button
          variant="primary"
          type="submit"
          isLoading={loadingCreateReservation}
          disabled={discountsError || getPriceEstimateError}
        >
          Create Reservation
        </Button>
        {errorCreatingReservation ? (
          <div className="w-full text-center">
            <Form.Error>
              There was an error creating the reservation. Please try again
              later or contact support at mayday@dockwa.com.
            </Form.Error>
          </div>
        ) : null}
      </PriceEstimate>
    </StepWrapper>
  )
}

export default SummaryStep
