import PropTypes from "prop-types"
import React, { Fragment } from "react"
import DetailItem from "src/main/Account/Trips/Sales/Details/DetailItem"
import { marinaPropType } from "src/main/Account/Trips/Sales/Details/index"

import HeadingSectionHeader from "src/components/Heading/HeadingSectionHeader"

const MarinaInfo = ({ marina }) => {
  return (
    <Fragment>
      <div className="col-span-5 lg:col-span-1">
        <img
          src={marina.marinaImageUrl}
          alt={marina.name}
          className="h-52 w-full rounded-t object-cover lg:aspect-[4/3] lg:w-48 lg:rounded-lg"
        />
      </div>
      <div className="col-span-5 flex flex-col space-y-2 px-4 lg:col-span-4 lg:px-0">
        <div className="pb-6">
          <HeadingSectionHeader>{marina.name}</HeadingSectionHeader>
        </div>
        <DetailItem label="Coordinates">
          <span>{marina.latitude}</span>
          <span>{marina.longitude}</span>
        </DetailItem>
        <DetailItem label="Address">
          <span>{marina.addressLineOne}</span>
          <span>{marina.addressLineTwo}</span>
          <span>
            {marina.city}, {marina.state} {marina.zip}
          </span>
        </DetailItem>
        {marina.displayPhone && (
          <DetailItem label="Phone">
            <span>{marina.displayPhone}</span>
          </DetailItem>
        )}
        <DetailItem label="Radio">
          <span>VHF {marina.vhf}</span>
        </DetailItem>
        <DetailItem label="Marina Profile">
          <a
            href={marina.marinaProfileUrl}
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-blue-700"
          >
            View marina
          </a>
        </DetailItem>
      </div>
    </Fragment>
  )
}

MarinaInfo.propTypes = {
  marina: PropTypes.shape(marinaPropType).isRequired,
}

export default MarinaInfo
