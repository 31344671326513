import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import { ManageDockwaPlusDealsContext } from "src/main/DockwaPlusDeals/Manage/index"

import Toggle from "src/components/Toggle"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { toggleActiveDiscount } from "src/api/DockwaPlusDeals/Manage/Fuel"

import { useToast } from "src/hooks/use_toast"

const ActiveToggle = ({ data, canUpdateDeal }) => {
  const { queryPage } = useContext(ManageDockwaPlusDealsContext)
  const showToast = useToast()
  const queryClient = useQueryClient()

  const { id } = data

  const { mutate, isLoading } = useMutation({
    mutationKey: ["discountActivation", id],
    mutationFn: toggleActiveDiscount,
    onMutate: async () => {
      await queryClient.invalidateQueries(["fuelDiscounts", queryPage])

      const previousFuelDiscounts = queryClient.getQueryData([
        "fuelDiscounts",
        queryPage,
      ])

      queryClient.setQueryData(["fuelDiscounts", queryPage], (pageData) => {
        return {
          ...pageData,
          discounts: pageData.discounts.map((discount) => {
            if (discount.id === id) {
              return {
                ...discount,
                activatedAt: discount.activatedAt
                  ? null
                  : new Date().toISOString(),
              }
            } else {
              return discount
            }
          }),
        }
      })

      return { previousFuelDiscounts }
    },
    onSuccess: (data) => {
      showToast(data.message, { type: "success" })
    },
    onError: (error, variables, context) => {
      if (context?.previousFuelDiscounts) {
        queryClient.setQueryData(
          ["fuelDiscounts", queryPage],
          context.previousFuelDiscounts
        )
      }
      showToast(error.message, { type: "error" })
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(["fuelDiscounts", queryPage])
    },
  })

  const handleToggle = (event) => {
    if (event.target.id === id) {
      mutate(data)
    }
  }

  return (
    <div className="w-min">
      <MaybeRestrictedTooltip
        restricted={!canUpdateDeal}
        allowedRoles={["Admin", "Dockmaster"]}
      >
        <Toggle
          checked={Boolean(data.activatedAt)}
          disabled={isLoading || !canUpdateDeal}
          onChange={handleToggle}
          name={data.id}
        />
      </MaybeRestrictedTooltip>
    </div>
  )
}

ActiveToggle.propTypes = {
  data: PropTypes.object,
  canUpdateDeal: PropTypes.bool.isRequired,
}

export default ActiveToggle
