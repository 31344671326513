import classNames from "classnames"
import React, { useContext } from "react"

import Table from "src/components/Table"

import { ManageItemsContext } from "./ManageItemsContainer"

const ManageItemsTableHeader = () => {
  const { sortField, sortDirection, handleSortSelection } =
    useContext(ManageItemsContext)

  const renderSortableHeader = ({ display, field }) => {
    const isActiveSortField = sortField === field
    const sortingAsc = isActiveSortField && sortDirection === "ASC"
    const sortingDesc = isActiveSortField && sortDirection === "DESC"
    return (
      <div
        role="button"
        className="group/sortable-header flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => handleSortSelection(field)}
      >
        <div>{display}</div>
        <i
          className={classNames(
            "icon text-gray-400 group-hover/sortable-header:text-gray-900",
            {
              "icon-sort-fa": !isActiveSortField,
              "icon-sort-asc": sortingAsc,
              "icon-sort-desc": sortingDesc,
              "text-gray-900": isActiveSortField,
            }
          )}
        />
      </div>
    )
  }
  return (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell columnWidth="160px">
          {renderSortableHeader({ display: "Name", field: "name" })}
        </Table.Head.Cell>
        <Table.Head.Cell>
          {renderSortableHeader({ display: "SKU", field: "sku" })}
        </Table.Head.Cell>
        <Table.Head.Cell>Barcode</Table.Head.Cell>
        <Table.Head.Cell>
          {renderSortableHeader({
            display: "Category",
            field: "product_category",
          })}
        </Table.Head.Cell>
        <Table.Head.Cell>Default Price</Table.Head.Cell>
        <Table.Head.Cell>Default Tax</Table.Head.Cell>
        <Table.Head.Cell>Qty on Hand</Table.Head.Cell>
        <Table.Head.Cell columnWidth="130px"> </Table.Head.Cell>
        <Table.Head.Cell columnWidth="45px"> </Table.Head.Cell>
      </Table.Head.Row>
    </Table.Head>
  )
}

export default ManageItemsTableHeader
