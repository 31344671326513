import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { dollarsToCents } from "src/main/Billing/Items/helpers"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  DISCOUNT_APPLY_TO_ELECTRIC,
  DISCOUNT_APPLY_TO_STORAGE,
  DISCOUNT_TYPE_DOLLAR,
  DISCOUNT_TYPE_PERCENT,
} from "./constants"
import { validateDiscountAmount, validateName } from "./validators"

const AddFullStayDiscountModal = ({ onClose, onSubmit }) => {
  const methods = useForm({
    defaultValues: {
      name: "",
      applyTo: DISCOUNT_APPLY_TO_STORAGE,
      discountType: DISCOUNT_TYPE_DOLLAR,
      amount: null,
    },
  })

  const [discountType] = methods.watch(["discountType"])

  const handleSubmitDiscount = ({ name, amount, applyTo, discountType }) => {
    const formattedAmount =
      discountType === DISCOUNT_TYPE_DOLLAR
        ? dollarsToCents(amount)
        : new Decimal(amount).dividedBy(100).toNumber()
    const tempId = Math.random().toString() // we need a temporary unique id to enable us to remove the discount if needed
    const discountParams = {
      tempId,
      category: "discount",
      name,
      applyTo,
      discountType,
      amount: formattedAmount,
    }
    onSubmit(discountParams)
    onClose()
  }

  const renderNameInput = () => (
    <>
      <Form.Label htmlFor="trw-add-discount-name">Name</Form.Label>
      <Form.TextField
        id="trw-add-discount-name"
        placeholder="E.g. pay early discount"
        {...methods.register("name", {
          validate: validateName,
        })}
        hasErrors={Boolean(methods.formState.errors?.name)}
        onBlur={() => methods.trigger("name")}
      />
      {methods.formState.errors?.name?.message && (
        <Form.Error>{methods.formState.errors.name.message}</Form.Error>
      )}
    </>
  )

  const renderApplyToInput = () => (
    <>
      <Form.Label htmlFor="trw-add-discount-apply-to">Apply to</Form.Label>
      <Form.Select
        id="trw-add-discount-apply-to"
        {...methods.register("applyTo")}
        hasErrors={Boolean(methods.formState.errors?.applyTo)}
      >
        <option value={DISCOUNT_APPLY_TO_STORAGE}>Boat Storage</option>
        <option value={DISCOUNT_APPLY_TO_ELECTRIC}>Electric</option>
      </Form.Select>
    </>
  )

  const renderTypeInput = () => (
    <>
      <Form.Label htmlFor="trw-add-discount-type">Type</Form.Label>
      <Form.Select
        id="trw-add-discount-type"
        {...methods.register("discountType")}
        hasErrors={Boolean(methods.formState.errors?.discountType)}
      >
        <option value={DISCOUNT_TYPE_DOLLAR}>$</option>
        <option value={DISCOUNT_TYPE_PERCENT}>%</option>
      </Form.Select>
    </>
  )

  const renderAmountInput = () => (
    <>
      <Form.Label htmlFor="trw-add-discount-amount">Amount</Form.Label>
      <Form.IconTextField
        icon={discountType === DISCOUNT_TYPE_DOLLAR ? "$" : "%"}
        position={discountType === DISCOUNT_TYPE_DOLLAR ? "left" : "right"}
        id="trw-add-discount-amount"
        type="number"
        inputMode="numeric"
        {...methods.register("amount", {
          validate: validateDiscountAmount(discountType),
        })}
        hasErrors={Boolean(methods.formState.errors?.amount)}
        onBlur={() => methods.trigger("amount")}
      />
      {methods.formState.errors?.amount?.message && (
        <Form.Error>{methods.formState.errors.amount.message}</Form.Error>
      )}
    </>
  )

  return (
    <FormProvider {...methods}>
      <form>
        <Modal isOpen onClose={onClose} size="mediumFixed">
          <Modal.Header title="Add Discount" />
          <Modal.Body>
            <div className="grid grid-flow-row grid-cols-3 gap-4 pb-4 lg:grid-cols-8">
              <div className="col-span-3">{renderNameInput()}</div>
              <div className="col-span-2">{renderApplyToInput()}</div>
              <div className="col-span-1">{renderTypeInput()}</div>
              <div className="col-span-3 lg:col-span-2">
                {renderAmountInput()}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            confirmBtnText="Add"
            onClose={onClose}
            onSubmit={methods.handleSubmit(handleSubmitDiscount)}
            disabled={Boolean(Object.keys(methods.formState.errors).length)}
          />
        </Modal>
      </form>
    </FormProvider>
  )
}

AddFullStayDiscountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AddFullStayDiscountModal
