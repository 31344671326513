import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const queryFuelDiscounts = ({ page = 1 }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return queryApi(
    `/manage/${marinaSlug}/fuel_discount_templates?page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
}

export const createFuelDiscount = async ({ data }) => {
  const marinaSlug = getCurrentMarinaSlug()
  let applicableTo = "fuel"
  if (data.applicableType === "Product") {
    applicableTo = data.fuelProducts
  }

  return mutateApi({
    url: `/manage/${marinaSlug}/fuel_discount_templates`,
    method: "POST",
    data: {
      label: data.label,
      amount: data.amount,
      percent_amount: data.percentAmount,
      applicable_type: data.applicableType,
      applicable_to: applicableTo,
      source: data.source,
    },
  })
}

export const updateFuelDiscount = async ({ data, id }) => {
  const marinaSlug = getCurrentMarinaSlug()
  let applicableTo = "fuel"
  if (data.applicableType === "Product") {
    applicableTo = data.fuelProducts
  }

  return mutateApi({
    url: `/manage/${marinaSlug}/fuel_discount_templates/${id}`,
    method: "PUT",
    data: {
      label: data.label,
      amount: data.amount,
      percent_amount: data.percentAmount,
      applicable_type: data.applicableType,
      applicable_to: applicableTo,
      source: data.source,
    },
  })
}

export const toggleActiveDiscount = async ({ id }) => {
  const marinaSlug = getCurrentMarinaSlug()

  return mutateApi({
    url: `/manage/${marinaSlug}/fuel_discount_templates/${id}/toggle_active`,
    method: "PATCH",
  })
}
