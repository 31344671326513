import React, { useContext } from "react"

import Form from "src/components/Form"
import Heading from "src/components/Heading"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { WizardContext } from "../../../WizardContextProvider"
import PreservedRateModalButton from "./PreservedRateModalButton"
import PriceEstimateHelpText from "./PriceEstimateHelpText"

const SimplePriceEstimate = () => {
  const {
    isLoadingPriceEstimate,
    priceEstimateData,
    getPriceEstimateError,
    priceEstimateError,
  } = useContext(WizardContext)

  const formatTotalPrice = () => {
    if (getPriceEstimateError) {
      return "$-.--"
    }

    if (priceEstimateData) {
      return priceEstimateData.total_price === "--"
        ? "$-.--"
        : formattedCentsToDollars(priceEstimateData.total_price)
    }

    return "$-.--"
  }

  const renderTotalPrice = () => (
    <div className="text-3xl font-semibold leading-9 text-teal-700">
      {formatTotalPrice()}
    </div>
  )

  const renderLoadingSpinner = () => (
    <span
      data-testid="trw-simple-price-estimate-loading"
      className="icon icon-spinner icon-spin text-2xl leading-9 text-gray-600"
    />
  )

  const renderErrorMessage = () => {
    return getPriceEstimateError && !isLoadingPriceEstimate ? (
      <Form.Error>
        {priceEstimateError?.message || "Error fetching price estimate"}
      </Form.Error>
    ) : null
  }

  return (
    <div className="rounded bg-gray-100 p-4">
      <div className="flex flex-col items-center space-y-4 py-6">
        {isLoadingPriceEstimate ? renderLoadingSpinner() : renderTotalPrice()}
        <Heading.SectionHeader>Price Estimate</Heading.SectionHeader>
        <PriceEstimateHelpText />
        {renderErrorMessage()}
      </div>
      <PreservedRateModalButton />
    </div>
  )
}

export default SimplePriceEstimate
