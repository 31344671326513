import React, { useCallback, useMemo } from "react"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const useDisplayContactOptions = ({
  data,
  query,
  renderOption,
  renderImportOption,
  guestContact = false,
  enableContactImport = false,
} = {}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const hasContacts = data?.activeContacts?.length > 0
  const hasArchivedContacts = data?.archivedContacts?.length > 0
  const hasNetworkContacts = enableContactImport
    ? data?.networkContacts?.length > 0
    : false
  const hasDockwaUserMatch = enableContactImport ? data?.user : false

  const showNoResultsText = !(
    guestContact ||
    hasContacts ||
    hasArchivedContacts ||
    hasNetworkContacts ||
    hasDockwaUserMatch
  )

  const renderArchivedContacts = (data) => (
    <div className="flex flex-col space-x-2 text-center">
      <span>Your search matched contacts that are currently archived</span>
      <a href={data.archivedContactsPath} className="font-bold text-blue-800">
        View archived contacts
      </a>
    </div>
  )

  const getOptions = useMemo(() => {
    if (hasContacts && guestContact) {
      return [...data?.activeContacts, guestContact]
    } else if (hasContacts && !guestContact) {
      return data?.activeContacts
    } else if (hasArchivedContacts) {
      return data?.archivedContacts
    } else if (hasNetworkContacts) {
      return data?.networkContacts
    } else if (hasDockwaUserMatch) {
      return [data?.user]
    } else if (guestContact) {
      return [guestContact]
    } else {
      return []
    }
  }, [data, hasContacts, hasArchivedContacts, guestContact])

  const getRenderOption = useCallback(
    (option) => {
      if (hasContacts) {
        return renderOption(option)
      } else if (hasArchivedContacts) {
        return renderArchivedContacts(data)
      } else if (hasNetworkContacts) {
        return renderImportOption(option)
      } else if (hasDockwaUserMatch) {
        return renderImportOption(option)
      } else if (guestContact) {
        return renderOption(option)
      } else {
        return null
      }
    },
    [
      data,
      hasContacts,
      hasArchivedContacts,
      hasNetworkContacts,
      hasDockwaUserMatch,
      marinaSlug,
      query,
      renderOption,
      renderArchivedContacts,
    ]
  )

  return {
    getOptions,
    getRenderOption,
    showNoResultsText,
    hasContacts,
    hasArchivedContacts,
    hasNetworkContacts,
    hasDockwaUserMatch,
  }
}
