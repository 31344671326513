import PropTypes from "prop-types"
import React, { useContext } from "react"
import { ChatContext } from "src/main/Chat/ChatContainer"

import OverflowMenu from "src/components/OverflowMenu"

const Actions = ({ data, label }) => {
  const {
    setShowDeclineModal,
    updateStatus,
    setShowAcceptToContractModal,
    setShowCancelWaitlistEntryModal,
    setShowCancelInquiryModal,
  } = useContext(ChatContext)
  const dispatchAction = (data) => {
    const { payload, type } = data
    switch (type) {
      case "inquiry_in_progress":
      case "inquiry_add_to_waitlist":
      case "inquiry_mark_as_complete":
        return updateStatus({ id: payload.encoded_id, status: payload.status })
      case "inquiry_create_contract":
        return setShowAcceptToContractModal(true)
      case "inquiry_decline":
        return setShowDeclineModal(true)
      case "waitlist_cancel_entry":
        return setShowCancelWaitlistEntryModal(true)
      case "inquiry_cancel_request":
        return setShowCancelInquiryModal(true)
      default:
    }
  }

  if (data.variant === "link") {
    return (
      <OverflowMenu.Item
        key={data.key}
        label={label}
        variant="link"
        href={data.link}
      />
    )
  } else {
    return (
      <OverflowMenu.Item
        key={data.key}
        label={label}
        onClick={() => dispatchAction(data)}
      />
    )
  }
}

Actions.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    key: PropTypes.string,
    variant: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string,
    payload: PropTypes.shape({
      encoded_id: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
}

export default Actions
