import React, { useContext } from "react"

import Form from "src/components/Form"
import Heading from "src/components/Heading"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { WizardContext } from "../../../WizardContextProvider"
import PreservedRateModalButton from "./PreservedRateModalButton"
import PriceEstimateHelpText from "./PriceEstimateHelpText"

const ComplexPriceEstimate = () => {
  const {
    isLoadingPriceEstimate,
    priceEstimateData,
    getPriceEstimateError,
    isMonthlyBilling,
    priceEstimateError,
  } = useContext(WizardContext)

  const renderLoadingSkeleton = () => {
    return isLoadingPriceEstimate ? (
      <div className="flex w-full justify-center">
        <div
          data-testid="trw-complex-price-estimate-loading"
          className="grid w-full gap-y-2"
        >
          {/* charges */}
          <div className="grid w-full">
            <div className="grid grid-cols-3">
              <div className="col-span-3 h-5 animate-pulse rounded bg-gray-200" />
            </div>
          </div>

          <hr className="my-2 w-full border-t border-solid border-gray-300" />

          {/* credits, tax and total */}
          <div className="grid w-full">
            <div className="grid grid-cols-3 gap-y-2">
              <div className="col-span-3 h-5 animate-pulse rounded bg-gray-200" />
              <div className="col-span-3 h-5 animate-pulse rounded bg-gray-200" />
              <div className="col-span-3 h-5 animate-pulse rounded bg-gray-200" />
            </div>
          </div>
        </div>
      </div>
    ) : null
  }

  const renderComplexPriceSection = () => {
    const showPriceEstimate =
      !isLoadingPriceEstimate &&
      !getPriceEstimateError &&
      Boolean(priceEstimateData)

    return showPriceEstimate ? (
      <div
        data-testid="trw-complex-price-estimate"
        className="grid w-full gap-y-2"
      >
        {priceEstimateData.charge_breakdown.map((charge) => (
          <div
            key={charge.label}
            data-testid={`trw-price-estimate-charge-${charge.label
              .toLowerCase()
              .split(" ")
              .join("-")}`}
            className="grid grid-cols-3"
          >
            <div className="col-span-2">{charge.label}</div>
            <div className="text-right">
              {formattedCentsToDollars(charge.amount)}
            </div>
          </div>
        ))}
        <hr className="my-2 w-full border-t border-solid border-gray-300" />
        {priceEstimateData.credit_breakdown.map((credit) => (
          <div
            key={credit.label}
            data-testid={`trw-price-estimate-credit-${credit.label
              .toLowerCase()
              .split(" ")
              .join("-")}`}
            className="grid grid-cols-3"
          >
            <div className="col-span-2 text-xs">{credit.label}</div>
            <div className="text-right">
              {formattedCentsToDollars(-credit.amount)}
            </div>
          </div>
        ))}
        <div data-testid="trw-price-estimate-tax" className="grid grid-cols-2">
          <div className="text-xs">Tax</div>
          <div className="text-right">
            {formattedCentsToDollars(priceEstimateData.tax)}
          </div>
        </div>
        <div
          data-testid="trw-price-estimate-total"
          className="grid grid-cols-2"
        >
          <div className="text-sm font-bold">
            {isMonthlyBilling ? "Monthly Total" : "Total"}
          </div>
          <div className="text-right text-sm font-bold">
            {formattedCentsToDollars(priceEstimateData.total_price)}
          </div>
        </div>
      </div>
    ) : null
  }

  const renderErrorMessage = () => {
    return getPriceEstimateError && !isLoadingPriceEstimate ? (
      <div className="flex w-full grow flex-col items-center justify-center">
        <Form.Error>
          {priceEstimateError?.message || "Error fetching price estimate"}
        </Form.Error>
      </div>
    ) : null
  }

  return (
    <div className="rounded bg-gray-100 p-4">
      <div className="flex min-h-48 w-full flex-col items-start space-y-4">
        <Heading.SectionHeader>Pricing</Heading.SectionHeader>
        {renderLoadingSkeleton()}
        {renderComplexPriceSection()}
        <PriceEstimateHelpText />
        {renderErrorMessage()}
        <PreservedRateModalButton />
      </div>
    </div>
  )
}

export default ComplexPriceEstimate
