import PropTypes from "prop-types"
import React from "react"

const ProgressIndicator = ({ currentStep }) => {
  const steps = [1, 2, 3, 4]

  return (
    <div className="flex w-full items-center justify-center py-6">
      {steps.map((step, index) => (
        <div key={step} className="flex items-center">
          {/* Connecting Line */}
          {index !== 0 && (
            <div className="relative h-1 w-16 md:w-20">
              {/* Background Line */}
              <div className="absolute h-full w-full rounded-full bg-gray-300" />
              {/* Progress Line */}
              <div
                className={`absolute h-full rounded-full transition-all duration-300 ${
                  step <= currentStep ? "w-full bg-blue-600" : "w-0 bg-blue-600"
                }`}
                style={{
                  transitionDelay: `${(step - 1) * 150}ms`,
                }}
              />
            </div>
          )}

          {/* Step Circle */}
          <div
            className={`mx-1 flex h-12 w-12 items-center justify-center rounded-full border text-base font-semibold transition-colors duration-300 ${
              step < currentStep
                ? "border-2 border-blue-600 bg-blue-600 text-white"
                : step === currentStep
                ? "border-2 border-blue-600 bg-white text-blue-600"
                : "border-2 border-gray-300 bg-white text-gray-500"
            }`}
            style={{
              transitionDelay: `${(step - 1) * 150}ms`,
            }}
          >
            {step}
          </div>
        </div>
      ))}
    </div>
  )
}

ProgressIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
}

export default ProgressIndicator
